import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import BrandDetailSection from '../../../sections/brand/BrandDetailSection';
import TopBar from '../../../layouts/top_bar/TitleActionBar';
import BottomNav from '../../../layouts/bottom_bar/BottomNav';
import { getVendorByVid } from '../../../services/REST_apis/vendor'

function BrandDetailPage() {

  const [vendor, setVendor] = useState({})
  const { Page3depth } = useParams();

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const vendor = await getVendorByVid(Page3depth);
        setVendor(vendor);
        
      } catch(error) {
        console.log(error);
      }
    };

    fetchVendorData();
  }, [Page3depth]);
  
  return (
    <>
      <TopBar title={vendor?.store_name_ko || ''}/>
      <article id="brandDetailPage">
        <BrandDetailSection store={vendor}/>
      </article>
      <BottomNav />
    </>
  )
}

export default BrandDetailPage;
