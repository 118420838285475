import React, { useState, useEffect } from 'react';
import useInputStore from '../../store/common/useInputStore'
import { useNavigate } from "react-router-dom";
import entryStore from './../../store/Pages/useEntryStore';

const checkList = [
  {
    'title':'명품/수입브랜드',
    'body':'패션 및 라이프 상품의 명품 및 수입브랜드를 판매하는 스토어'
  },
  {
    'title':'브랜드(패션/용품)',
    'body':'자사 브랜드를 보유하고 상품을 직접 제작 및 판매하는 스토어'
  },
  {
    'title':'프리오더',
    'body':'주문 방식을 free order 형식으로 판매하는 스토어'
  },
  {
    'title':'쇼핑몰',
    'body':'패션 및 패션잡화 상품을 사입 및 제작하여 판매하는 스토어'
  },
  {
    'title':'라이프/유형',
    'body':'패션파트외 라이프 스타일 상품군을 제작 및 판매하는 스토어'
  },
  {
    'title':'라이프/무형',
    'body':'문화/여행/체험, 금융, 케어, 교육, 컨텐츠/게임 등을 판매하는 스토어'
  },
];

const VendorRegister05Section = () => {

  const navigate = useNavigate();
  const { applicationData, setApplicationData } = entryStore();
  
  const [checkedStates, setCheckedStates] = useState(new Array(checkList.length).fill(false));
  const [selectedBrands, setSelectedBrands] = useState([]);

  const handleCheckboxChange = (position) => {
    if (selectedBrands.length >= 1 && !checkedStates[position]) {
      return;
    }

    const updatedCheckedStates = checkedStates.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedStates(updatedCheckedStates);

    if (!checkedStates[position]) {
      setSelectedBrands([...selectedBrands, checkList[position]]);
      setApplicationData({
        ...applicationData,
        store_category: checkList[position].title
      });
    } else {
      setSelectedBrands(selectedBrands.filter((brand) => brand !== checkList[position]));
      setApplicationData({
        ...applicationData,
        store_category: ''
      });
    }
  };

  return (
    <>
      <section className="section-register">
        <div className="inner">
          {/* 타이틀 영역 [START] */}
          <div className="h3-title-wrap mt0">
            <h3 className="title">스토어 입점 유형 선택</h3>
            <p className="text">
              입점할 스토어의 유형을 선택해주세요.
            </p>
            <div className="pagination">
              <span className="item active">
                1
              </span>
              <span className="item">
                2
              </span>
              <span className="item">
                3
              </span>
            </div>
          </div>
          {/* 타이틀 영역 [END] */}

          {/* 아이템 체크 영역[START]*/}
          <div className="check-wrap mt30">
            <ul className="check-list vendor">
              {checkList.map((item, index) => (
                <li key={index}>
                  {/* selectedBrands의 길이가 3이상이고, 
                      현재 항목이 선택되지 않았다면 disabled 클래스를 추가 */}
                  <label 
                    htmlFor={`check${index}`} 
                    className={`${checkedStates[index] ? 'active' : ''} 
                    ${selectedBrands.length >= 3 && !checkedStates[index] ? 'disabled' : ''}`}
                  >
                    {item.title}
                    <input
                      className="bg hide"
                      id={`check${index}`}
                      type="checkbox"
                      checked={checkedStates[index]}
                      onChange={() => handleCheckboxChange(index)}
                      disabled={selectedBrands.length >= 1 && !checkedStates[index]}
                    />
                    <p className="f14">{item.body}</p>
                    <div 
                      className={`btn purple big round mt20 ${selectedBrands.length >= 1 && !checkedStates[index] ? 'gray' : ''}`}
                    >
                      선택하기
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          </div>
          {/* 아이템 체크 영역[END]*/}
        </div>
      </section>
      {/* 다음 버튼 [START]*/}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big"
              onClick={()=>navigate('/vendor/6')}
              disabled={!(selectedBrands.length >= 1)}
            >
              다음
            </button>
          </div>
        </div>
      </div>
      {/* 다음 버튼 [END]*/}
    </>
  );
};

export default VendorRegister05Section;

