import React, { useState } from 'react';
import usePopupStore from '../../store/common/usePopupStore';
import useInputStore from '../../store/common/useInputStore';
import { sendSms, confirmPhone, confirmCode } from '../../services/SEND_apis'
import { useLocation } from 'react-router-dom';

const InputPhone = ({ disabled = false, checkFunc }) => { 
  const location = useLocation();

  const { openSnackBarPopup } = usePopupStore((state) => ({
  openSnackBarPopup: state.openSnackBarPopup,
  }));

  const { input, actions } = useInputStore(state => ({
    input: state.input,
    actions: state.actions,
  }));

  const [authCode, setAuthCode] = useState(null)

  // 전화번호 유효성 검사 및 인증번호 요청 함수
  const handleRequestVerificationCode = async () => {
    const regex = /^[0-9]{10,11}$/;
    if (!regex.test(input.phoneNumber)) {
      openSnackBarPopup('유효한 휴대폰 번호를 입력해주세요.', '', 'error');
      return;
    }
  
    try {
      /// 1) 핸드폰 중복여부 확인
      if (location.pathname === '/register/1') {
        await confirmPhone(input.phoneNumber);
      }

      /// 2) 인증번호 전송
      const response = await sendSms(input.phoneNumber);
      if (response.status == 200) {
        openSnackBarPopup('인증번호가 전송되었습니다.', '', 'success');
        setAuthCode(response.data.access_id)
        actions.setIsPhoneSend(true); // 인증번호 전송 상태를 true로 설정
      } else {
        openSnackBarPopup('인증번호 전송 실패: ' + response, '', 'error');
      }
    } catch (error) {
      console.log(error)
      if(error.response.status == 409) {
        openSnackBarPopup('이미 존재하는 휴대전화 번호입니다.', '', 'error');
      } else {
        openSnackBarPopup('네트워크 오류가 발생했습니다: ' + error, '', 'error');
      }
    }
  };
  
  // 인증번호 일치 여부 확인 및 결과 표시 함수
  const handleVerifyCode = async () => {
    // checkFunc가 있는 경우 먼저 실행
    if (checkFunc) {
      const isCheckFuncPassed = await checkFunc(); // checkFunc가 비동기 함수일 수 있으므로 await 사용
      if (!isCheckFuncPassed) {
        return;
      }
    }

    if (input.phoneCode) {
      confirmCode(authCode, input.phoneCode).then(() => {
        openSnackBarPopup('인증되었습니다.', '', 'success');
        actions.setIsPhoneVerification(true)
      }).catch(e => {
        openSnackBarPopup('인증코드가 일치하지 않습니다.', '', 'error')
        return
      })
    } else {
      openSnackBarPopup('인증번호를 입력해주세요.', '', 'error');
    }
  };

  return (
    <>
      <div className="input-box">
        <label htmlFor="phone" className="title starred">
          전화번호
        </label>
        <div className="input">
          <input 
            id="phone" 
            type="number"
            placeholder="휴대전화번호 입력 (-제외)"
            onInput={(e) => actions.setPhoneNumber(e.target.value)}
            disabled={disabled || input.isPhoneSend} 
          />
          <button 
            type="button" 
            className="btn purple"
            disabled={disabled || !input.phoneNumber || input.isPhoneSend} 
            onClick={handleRequestVerificationCode}
          >
            인증번호받기
          </button>
        </div>
        <div className="input">
          <input 
            id="phoneCode" 
            type="number" 
            placeholder="인증번호 입력"
            onInput={(e) => actions.setPhoneCode(e.target.value)}
            disabled={disabled || input.isPhoneVerification} 
          />
          <button 
            type="button" 
            className="btn purple"
            onClick={handleVerifyCode}
            disabled={disabled || input.isPhoneVerification} 
          >
            확인
          </button>
        </div>
        {input.isPhoneSend && (
          <span className="caption mt10">인증번호가 전송되었습니다. 입력해주세요.</span>
        )}
      </div>
    </>
  );
};

export default InputPhone;
