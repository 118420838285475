import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import 'swiper/css';
import "../../assets/styles/scss/structure/articles/_front-guide.scss";

import TopNav from '../../layouts/top_bar/TopNav';
import BottomNav from '../../layouts/bottom_bar/BottomNav';
import useMainStore from '../../store/Pages/useMainStore'
import H3Title from '../../layouts/title/H3Title';
import GridSection from '../../sections/grid/GridSection';
import SwipeGridSection from '../../sections/grid/SwipeGridSection';
import BannerASection from '../../sections/banner/BannerASection';
import BannerBSection from '../../sections/banner/BannerBSection';
import BannerCSection from '../../sections/banner/BannerCSection';
import BannerDSection from '../../sections/banner/BannerDSection';
import BannerESection from '../../sections/banner/BannerESection';
import BannerFSection from '../../sections/banner/BannerFSection';
import BannerGSection from '../../sections/banner/BannerGSection';

import BigTab from '../../components/Tabs/BigTab'; 
import SmallTab from '../../components/Tabs/SmallTab'; 

const tabs = [
    { name: 'HOME', class: 'home', route: '/' },
    { name: '핫딜', class: 'hotdeal', route: '/hotdeal' },
    { name: '랭킹', class: 'ranking', route: '/ranking' },
    { name: '해외직구', class: 'overseas', route: '/overseas' },
    { name: '쿠폰/혜택', class: 'couponBenefit', route: '/couponBenefit' },
    { name: '브랜드', class: 'brand', route: '/brand' },
];

// 빅탭 리스트
const bigTabs = [
    {title: '임부복'},
    {title: '뷰티/헬스'},
    {title: '베이비의류 0~24M'},
    {title: '키즈의류24M~8Y'},
    {title: '주니어의류24M~8Y'},
    {title: '신발악세서리'},
    {title: '기저귀물티슈'},
    {title: '목욕위생세재'},
    {title: '수유기구'},
    {title: '장난감교육교제'},
    {title: '역류방지쿠션매트'},
    {title: '힙시트캐리어유모차'},
    {title: '침구류가구홈데코'},
    {title: '푸드'},
    {title: '3PL물류'},
];

// 스몰탭 리스트
const smallTabs = [
    { key: 'all', label: 'All' },
    { key: 'category1', label: '맞춤' },
    { key: 'category2', label: '남아의류' },
    { key: 'category3', label: '신발' },
    { key: 'category4', label: '코트' },
];



const GlobalBizPage = () => {
    const [activeSmallTab, setActiveSmallTab] = useState("all");
    const navigate = useNavigate();
    const handleSmallTabClick = (key) => {
        setActiveSmallTab(key);
        // 여기에 클릭 시 추가 로직이 필요하면 추가 가능
};

const { currentPage, activeTab, setCurrentPage, setActiveTab } = useMainStore();

const [swiper, setSwiper] = useState(null);

useEffect(() => {
    const currentTab = tabs.find(tab => location.pathname === tab.route);
    const pageIndex = currentTab ? tabs.indexOf(currentTab) : 0;
    setCurrentPage(pageIndex); // Zustand 스토어의 상태 업데이트 메소드를 사용
    setActiveTab(currentTab ? currentTab.name : 'HOME'); // Zustand 스토어의 상태 업데이트 메소드를 사용
    if(swiper) swiper.slideTo(pageIndex, 0);
}, [location, swiper, setCurrentPage, setActiveTab]);

const handleTabClick = (tab) => {
    const pageIndex = tabs.findIndex((t) => t.name === tab.name);
    setCurrentPage(pageIndex);
    setActiveTab(tab.name);
    swiper?.slideTo(pageIndex)
};
    return (
        <>
            <TopNav/>
            <article id="GlobalBizPage">
                {/*[D] 배너[START] */}
                <BannerDSection id='biz_b1'/>
                {/*[D] 배너[END] */}
                
                <div className="white-wrap">
                    <div className="banner-wrap">
                        {/* 빅탭 [START] */}
                        <BigTab tabs={bigTabs} />
                        {/* 빅탭 [END] */}

                        <br />

                        {/*[A] 배너[START] */}
                        <div className="banner-wrap vw100">
                        <BannerASection id='biz_b2'/>
                        </div>
                        {/*[A] 배너[END] */}

                        <br/>

                        {/*[A] 배너[START] */}
                        <div className="banner-wrap vw100">
                        <BannerASection id='biz_b3'/>
                        </div>
                        {/*[A] 배너[END] */}

                        <br/>

                        {/* 타이틀+텍스트 [START] */}
                        <h3 className="biz_title">
                            HOT ISSUE PRODUCTS
                        </h3>

                        <p className="biz_contents">
                            HOT ISSUE PRODUCTS <br />
                            What is the hot product right now?
                        </p>
                        {/* 타이틀+텍스트 [END] */}

                        <br/>

                        <div className="banner-wrap mb16">
                            {/*[D] 배너[START] */}
                            <BannerDSection id='biz_b4'/>
                            {/*[D] 배너[END] */}
                        </div>

                        {/* [SMALL] 상품 리스트 [START] */}
                            <SwipeGridSection 
                            collection=""
                            />
                        {/* [SMALL] 상품 리스트 [END] */}

                        {/* [SMALL] 상품 리스트 [START] */}
                            <SwipeGridSection 
                            collection=""
                            />
                        {/* [SMALL] 상품 리스트 [END] */}

                        <br />

                        <div className="banner-wrap mb16">
                            {/*[D] 배너[START] */}
                            <BannerDSection id='biz_b5'/>
                            {/*[D] 배너[END] */}
                        </div>

                        <button 
                        type="button" 
                        className="btn big round shortcuts mt20"
                        onClick={() => navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
                        >
                        셀러 바로가기
                        </button>
                    </div>
                </div>

                <div className="white-wrap">
                    <div className="banner-wrap mb16">
                        {/*[D] 배너[START] */}
                        <BannerDSection id='biz_b6'/>
                        {/*[D] 배너[END] */}
                    </div>

                    <button 
                    type="button" 
                    className="btn big round shortcuts mt20"
                    onClick={() => navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
                    >
                    셀러 바로가기
                    </button>
                </div>

                <div className="white-wrap">
                    <div className="banner-wrap mb16">
                        {/*[D] 배너[START] */}
                        <BannerDSection id='biz_b7'/>
                        {/*[D] 배너[END] */}
                    </div>

                    <button 
                    type="button" 
                    className="btn big round shortcuts mt20"
                    onClick={() => navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
                    >
                    셀러 바로가기
                    </button>
                </div>

                <div className="white-wrap">
                    <div className="banner-wrap mb16">
                        {/*[D] 배너[START] */}
                        <BannerDSection id='biz_b8'/>
                        {/*[D] 배너[END] */}
                    </div>

                    <button 
                    type="button" 
                    className="btn big round shortcuts mt20"
                    onClick={() => navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
                    >
                    셀러 바로가기
                    </button>
                </div>

                <div className="white-wrap">

                    {/* 타이틀+텍스트 [START] */}
                    <h3 className="biz_title">
                        PUPULAR ITEMS
                    </h3>
                    <p className="biz_contents">
                        What rpdicts are most sought after by buyer?
                    </p>

                    <br />

                    <div className="banner-wrap mb16">
                    {/*[D] 배너[START] */}
                    <BannerDSection id='biz_b9'/>
                    {/*[D] 배너[END] */}
                    </div>
                    
                    {/* 타이틀+텍스트 [END] */}
                    <GridSection 
                    type="big" 
                    option={false}
                    collection=""
                    />
                    <button 
                    type="button" 
                    className="btn big round more mt40"
                    onClick={()=>navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
                    >
                    더 보기
                    </button>

                </div>

                <div className="white-wrap">

                    {/* 타이틀+텍스트 [START] */}
                    <h3 className="biz_title">
                        NEW
                    </h3>

                    <p className="biz_contents">
                        What rpdicts are most sought after by buyer?
                    </p>
                    {/* 타이틀+텍스트 [END] */}

                    <br />

                    {/*[D] 배너[START] */}
                    <div className="banner-wrap mb16">
                    <BannerDSection id='biz_b10'/>
                    </div>
                    {/*[D] 배너[END] */}

                    <GridSection 
                    type="big" 
                    option={false}
                    collection=""
                    />

                    <button 
                    type="button" 
                    className="btn big round more mt40"
                    onClick={() => navigate(`/product-list/PUPULAR ITEMS`)}
                    >
                    더 보기
                    </button>

                </div>

                <div className="white-wrap">

                    {/* 타이틀+텍스트 [START] */}
                    <h3 className="biz_title">
                        SPECIAL PROMOTIONS
                    </h3>

                    <p className="biz_contents">
                        What rpdicts are most sought after by buyer?
                    </p>
                    {/* 타이틀+텍스트 [END] */}

                    <br />

                    {/*[D] 배너[START] */}
                    <div className="banner-wrap mb16">
                    <BannerDSection id='biz_b11'/>
                    </div>
                    {/*[D] 배너[END] */}

                    {/* [SMALL] 상품 리스트 [START] */}
                    <SwipeGridSection 
                    collection=""
                    />
                    {/* [SMALL] 상품 리스트 [END] */}
                </div>
                
                <div className="white-wrap">
                    {/* 타이틀+텍스트 [START] */}
                    <h3 className="biz_title">
                        SPOTLIGHT ITEMS
                    </h3>

                    <p className="biz_contents">
                        What rpdicts are most sought after by buyer?
                    </p>
                    {/* 타이틀+텍스트 [END] */}

                    <br />

                    <div className="slide-tabs no-fixed pl0 pr0">
                        <div className="tabs">
                        {tabs.map((tab, index) => (
                            <button
                            key={index}
                            onClick={() => handleTabClick(tab)}
                            className={`tab ${tab.class} ${activeTab === tab.name ? 'active' : ''}`}
                            >
                            {tab.name}
                            </button>
                        ))}
                        </div>
                    </div>
                    <div className="bottom-border mb20"></div>

                    {/* 스몰탭 [START] */}
                    <SmallTab
                    tabs={smallTabs}
                    activeTab={activeSmallTab}
                    onTabClick={handleSmallTabClick}
                    />
                    {/* 스몰탭 [END] */}

                    <br />

                    {/* [BIG] 상품 리스트 [START] */}
                    <GridSection 
                    type="big" 
                    option={false}
                    collection="Q29sbGVjdGlvbjo2" // Home2 ID
                    />
                    <button 
                    type="button" 
                    className="btn big round more mt40"
                    onClick={() => navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
                    >
                    더 보기
                    </button>
                    {/* [BIG] 상품 리스트 [END] */}
                </div>

                <div className="white-wrap">

                    {/* 타이틀+텍스트 [START] */}
                    <h3 className="biz_title">
                        RECOMMENDATIONS
                    </h3>

                    <p className="biz_contents">
                        What rpdicts are most sought after by buyer?
                    </p>
                    {/* 타이틀+텍스트 [END] */}

                    <br />

                    {/*[D] 배너[START] */}
                    <div className="banner-wrap mb16">
                    <BannerDSection id='biz_b12'/>
                    </div>
                    {/*[D] 배너[END] */}

                    <GridSection 
                    type="big" 
                    option={false}
                    collection=""
                    />

                    <button 
                    type="button" 
                    className="btn big round more mt40"
                    onClick={() => navigate(`/product-list/PUPULAR ITEMS`)}
                    >
                    더 보기
                    </button>

                </div>
                <div className="white-wrap">
                    <div className="banner-wrap mb16">
                        {/*[B] 배너[START] */}
                        <BannerBSection id='biz_b13'/>
                        {/*[B] 배너[END] */}
                    </div>
                    <div className="banner-wrap">
                        {/*[B] 배너[START] */}
                        <BannerBSection id='biz_b14'/>
                        {/*[B] 배너[END] */}
                    </div>
                </div>


            </article>
            <BottomNav/>
        </>

      );
};

export default GlobalBizPage;
