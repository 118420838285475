import React from "react";
import { useParams } from 'react-router-dom';

import TopBar from '../../layouts/top_bar/TitleBar';
import BottomNav from '../../layouts/bottom_bar/BottomNav';

import MyMenuProfileSection from '../../sections/mymenu/MymenuProfileSection'
import MyMenuKidsSection from '../../sections/mymenu/MyMenuKidsSection'
import MyMenuKidProfileSection from '../../sections/mymenu/MyMenuKidProfileSection'
import MyMenuProfileModifySection from '../../sections/mymenu/MyMenuProfileModifySection'
import MyMenuCouponsSection from '../../sections/mymenu/MyMenuCouponsSection'
import MyMenuMyReviewsSection from '../../sections/mymenu/MyMenuMyReviewsSection'
import MyMenuMyReviewDetailSection from '../../sections/mymenu/MyMenuMyReviewDetailSection'
import MyMenuMyReviewWriteSection from '../../sections/mymenu/MyMenuMyReviewWriteSection'
import MyMenuMykidDetailSection from '../../sections/mymenu/MyMenuMykidDetailSection'
import MyMenuRewardSection from '../../sections/mymenu/MyMenuRewardSection'
import MyMenuWishListSection from '../../sections/mymenu/MyMenuWishListSection'
import MyMenuRecentProductSection from '../../sections/mymenu/MyMenuRecentProductSection'

function MymenuPage() {

  const { Page2depth } = useParams();

  const getTitle = (Page2depth) => {
    switch (Page2depth) {
      case 'profile' :
        return '마이 메뉴'
      case 'mykids' :
        return '등록된 아이 정보'
      case 'mykid-profile' :
        return '아이 프로필 입력'
      case 'mykid-modify' :
        return '아이 프로필 수정'
      case 'profile-modify' :
        return '회원정보수정'
      case 'coupons1' :
        return '쿠폰'
      case 'coupons2' :
        return '쿠폰'
      case 'myreviews' :
        return '내 후기'
      case 'myreview-detail' :
        return '리뷰 상세보기'
      case 'myreview-write' :
        return '리뷰 작성하기'
      case 'mykid-detail' :
        return '내 아이 자세히 보기'
      case 'reward' :
        return '적립금'
      case 'recent-product' :
        return '최근 본 상품'
      case 'wish-list' :
        return '위시리스트'
      default:
        return '주문 목록'
    }
  };

  return (
    <>
      <TopBar  
        title={getTitle(Page2depth)}
      />
      <article id="mymenuPage">
        {Page2depth === 'profile' && <MyMenuProfileSection/>}
        {Page2depth === 'mykids' && <MyMenuKidsSection/>}
        {Page2depth === 'mykid-profile' && <MyMenuKidProfileSection/>}
        {Page2depth === 'mykid-modify' && <MyMenuKidProfileSection/>}
        {Page2depth === 'profile-modify' && <MyMenuProfileModifySection/>}
        {Page2depth === 'coupons1' && <MyMenuCouponsSection/>}
        {Page2depth === 'coupons2' && <MyMenuCouponsSection/>}
        {Page2depth === 'myreviews' && <MyMenuMyReviewsSection/>}
        {Page2depth === 'myreview-detail' && <MyMenuMyReviewDetailSection/>}
        {Page2depth === 'myreview-write' && <MyMenuMyReviewWriteSection/>}
        {Page2depth === 'mykid-detail' && <MyMenuMykidDetailSection/>}
        {Page2depth === 'recent-product' && <MyMenuRecentProductSection/>}
        {Page2depth === 'wish-list' && <MyMenuWishListSection/>}
        {Page2depth === 'reward' && <MyMenuRewardSection/>}
      </article>
      {
        (
          Page2depth !== 'mykid-profile' &&
          Page2depth !== 'mykid-modify' &&
          Page2depth !== 'profile-modify' &&
          Page2depth !== 'coupons1' &&
          Page2depth !== 'coupons2' &&
          Page2depth !== 'myreview-write'
        ) && <BottomNav />
      }
    </>
  )
}

export default MymenuPage;
