import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const FAB = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false); // 활성화 상태를 저장할 state

  const toggleActive = () => {
    setIsActive(!isActive); // 기존 상태를 반전
  };

  return (
    <div className={`fab ${isActive ? 'active' : ''}`}> 
      <button 
        type="button" 
        className="action profile"
        onClick={() => navigate('/mymenu/profile')}
      >
        하늘
      </button>
      <button 
        type="button" 
        className="action zaelook"
        onClick={() => navigate('/zaelook/:Page2depth')}
      >
        재룩
      </button>
      <button 
        type="button" 
        className="action plus"
        onClick={toggleActive} // 클릭 이벤트 핸들러로 toggleActive 함수 지정
      >
      </button>
    </div>
  );
};

export default FAB;
