import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import entryStore from './../../store/Pages/useEntryStore';
import * as SignUpData from "./SignUpData";
import api from "../../services/api"
import { getCategory, getCategory2 } from '../../services/GQ_apis/category';

const VendorRegister06Section = () => {

  const navigate = useNavigate();
  const { applicationData, setApplicationData } = entryStore();
  const [categoryUsage, setCategoryUsage] = useState({ fashion: false, life: false, certification: false });
  const [ctfInfo, setCtfInfo] = useState([]);
  const [fashionInfo, setFashionInfo] = useState([[], [], { start: '00', end: '00' }, [], [], '']); // 패션상품 - 전체

  useEffect(() => {
    console.log(applicationData);
  }, [applicationData])

  const [styleName, setStyleName] = useState([ // 스토어 스타일 지정
    { title: '심플베이직', isChecked: false, name: 'SimpleBasic', value: '01' },
    { title: '캐주얼', isChecked: false, name: 'Casual', value: '02' },
    { title: '모던시크', isChecked: false, name: 'ModernChic', value: '03' },
    { title: '러블리', isChecked: false, name: 'Lovely', value: '04' },
    { title: '로맨틱', isChecked: false, name: 'Romantic', value: '05' },
    { title: '유니크', isChecked: false, name: 'Unique', value: '06' },
    { title: '빈티지', isChecked: false, name: 'Vintage', value: '07' },
    { title: '오피스룩', isChecked: false, name: 'OfficeLook', value: '08' },
    { title: '캠퍼스룩', isChecked: false, name: 'CampusLook', value: '09' },
    { title: '아메카지', isChecked: false, name: 'Americana', value: '10' },
    { title: '컨템포러리', isChecked: false, name: 'Contemporary', value: '11' },
    { title: '유니섹스', isChecked: false, name: 'Unisex', value: '12' }
  ]);

  const [sizeCtg, setSizeCtg] = useState([ // 사이즈표
    { title: '의류', isChecked: false, value: '01' },
    { title: '모자', isChecked: false, value: '02' },
    { title: '신발', isChecked: false, value: '03' },
    { title: '양말', isChecked: false, value: '04' }
  ]);

  // vendor cateogory ///////////////////////////////////////
  // 패션상품
  const [fashionDepth1Tabs, setFashionDepth1Tabs] = useState([]);
  const [fashionDepth2Tabs, setFashionDepth2Tabs] = useState([]);
  const [fashionDepth3Tabs, setFashionDepth3Tabs] = useState([]);

  const [lifeDepth1Tabs, setLifeDepth1Tabs] = useState([]);
  const [lifeDepth2Tabs, setLifeDepth2Tabs] = useState([]);
  const [lifeDepth3Tabs, setLifeDepth3Tabs] = useState([]);

  useEffect(() => {
    const fetchCategory = async (level, key, search) => {
      await setCategory(level, key, search);
    };

    const updateCategoryData = (categoryName, level, key, value) => {
      const updatedData = {
        ...applicationData,
        [categoryName]: {
          ...applicationData[categoryName],
          [level]: { key, value },
        },
      };
      if (JSON.stringify(applicationData) !== JSON.stringify(updatedData)) {
        setApplicationData(updatedData);
      }
    };

    const resetCategoryData = (categoryName) => {
      const updatedData = {
        ...applicationData,
        [categoryName]: {
          top: { key: "", value: "" },
          middle: { key: "", value: "" },
          bottom: { key: "", value: "" },
        },
      };
      if (JSON.stringify(applicationData) !== JSON.stringify(updatedData)) {
        setApplicationData(updatedData);
      }
    };

    if (categoryUsage.fashion) {
      fetchCategory(0, "", "패션");
      fetchCategory(1, "45", "패션");

      if (applicationData.fashion_category?.top?.key === "") {
        updateCategoryData("fashion_category", "top", 45, "패션");
      }
    } else if (applicationData?.fashion_category) {
      resetCategoryData("fashion_category");
    }

    if (categoryUsage.life) {
      fetchCategory(0, "", "라이프");
      fetchCategory(1, "46", "라이프");

      if (applicationData.life_category?.top?.key === "") {
        updateCategoryData("life_category", "top", 46, "라이프");
      }
    } else if (applicationData?.life_category) {
      resetCategoryData("life_category");
    }
  }, [categoryUsage.fashion, categoryUsage.life, applicationData, setApplicationData]);

  const handleChangeCategory = (e) => {
    const { name, value } = e.target;

    const categorys = name.split(':');
    const dataFieldName = categorys[0];
    const dataLevel = categorys[1];

    const values = value.split(':');
    const selectedItemKey = values[0];
    const selectedItemValue = values[1];

    const search = categorys[0] === 'fashion_category' ? "패션" : categorys[0] === 'life_category' ? "라이프" : ""
    const level = categorys[1] === 'top' ? 0 : categorys[1] === 'middle' ? 1 : categorys[1] === 'bottom' ? 2 : -1

    const fetchCategory = async () => {
      await setCategory(level + 1, selectedItemKey, search);
    };

    const reflectApplicationData = () => {
      setApplicationData({
        ...applicationData,
        [dataFieldName]: {
          ...applicationData[dataFieldName],
          [dataLevel]: {
            key: parseInt(selectedItemKey),
            value: selectedItemValue,
          },
        },
      })
    }
    if (level < 2) {
      fetchCategory();
    }
    reflectApplicationData();
  };

  const setCategory = async (level, big, search) => {
    const data = await getCategories(level, big, search)
    if (search === '패션') {
      if (level === 0) setFashionDepth1Tabs(data)
      else if (level === 1) setFashionDepth2Tabs(data)
      else if (level === 2) setFashionDepth3Tabs(data);
    } else if (search === '라이프') {
      if (level === 0) setLifeDepth1Tabs(data)
      else if (level === 1) setLifeDepth2Tabs(data)
      else if (level === 2) setLifeDepth3Tabs(data);
    }
  }

  const getCategories = useCallback(async (level, big, search) => {
    return big === "" ? await getCategory(level, search)
      .then((res) => {
        return res.data.categories.edges;
      })
      : await getCategory2(level, big).then((res) => {
        return res.data.categories.edges;
      })
  }, [])

  // 라이프/유형
  // const [lifeTopCategory, setLifeTopCategory] = useState("");
  // const [lifeMiddleCategories, setLifeMiddleCategories] = useState([]);
  // const [lifeBottomCategories, setLifeBottomCategories] = useState([]);
  // const [selectedLifeMiddleCategory, setSelectedLifeMiddleCategory] = useState("");

  // const handleChangeLifeTopCategory = (e) => {
  //   const selectedTop = e.target.value;
  //   setLifeTopCategory(selectedTop);
  //   setLifeMiddleCategories(SignUpData.lifeCtgData[1].value.filter(item => item.key === selectedTop));
  //   setSelectedLifeMiddleCategory("");
  //   setLifeBottomCategories([]);
  // };

  // const handleChangeLifeMiddleCategory = (e) => {
  //   const selectedMiddle = e.target.value;
  //   setSelectedLifeMiddleCategory(selectedMiddle);
  //   setLifeBottomCategories(SignUpData.lifeCtgData[2].value.filter(item => item.key === selectedMiddle));
  // };

  // 패션 상품
  // const handleChangeFashionMiddleCategory = (e) => {
  //   const { name, value } = e.target;

  //   const categorys = name.split(':');
  //   const search = categorys[0] === 'fashion_category' ? "패션" : categorys[0] === 'life_category' ? "라이프" : ""
  //   const level = categorys[1] === 'top' ? 0 : categorys[1] === 'middle' ? 1 : categorys[1] === 'bottom' ? 2 : -1

  //   const values = value.split(':');
  //   const data = {
  //     key: values[0],
  //     value: values[1],
  //   };

  //   const fetchCategory = async () => {
  //     await setCategory(level + 1, data.key, search);
  //   };
  //   // const fetchFashionCategory = async () => {
  //   //   await setCategory(2, data.key, "패션");
  //   // };
  //   const reflectApplicationData = () => {
  //     setApplicationData({
  //       ...applicationData,
  //       [categorys[0]]: {
  //         ...applicationData[categorys[0]],
  //         [categorys[1]]: { ...data },
  //       }
  //     })
  //   }
  //   if (level < 2) {
  //     fetchCategory();
  //   }
  //   // fetchFashionCategory();
  //   reflectApplicationData();
  //   // setSelectedFashionMiddleCategory(selectedMiddle);
  //   // setFashionBottomCategories(SignUpData.fashionCtgData[2].value.filter(item => item.key === selectedMiddle));
  // };

  //////////////////////////////////////////////////////////////////////////////////////////
  // const [fashionTopCategory, setFashionTopCategory] = useState("");
  // const [fashionTopCategories, setFashionTopCategories] = useState([]);
  // const [fashionMiddleCategories, setFashionMiddleCategories] = useState([]);
  // const [fashionBottomCategories, setFashionBottomCategories] = useState([]);
  // const [selectedFashionMiddleCategory, setSelectedFashionMiddleCategory] = useState("");

  // const handleChangeFashionTopCategory = (e) => {
  //   const selectedTop = e.target.value;
  //   setFashionTopCategory(selectedTop);
  //   setFashionMiddleCategories(SignUpData.fashionCtgData[1].value.filter(item => item.key === selectedTop));
  //   setSelectedFashionMiddleCategory("");
  //   setFashionBottomCategories([]);
  // };

  // // const handleChangeFashionMiddleCategory = (e) => {
  // //   const selectedMiddle = e.target.value;
  // //   setSelectedFashionMiddleCategory(selectedMiddle);
  // //   setFashionBottomCategories(SignUpData.fashionCtgData[2].value.filter(item => item.key === selectedMiddle));
  // // };
  // ////////////////////////////////////////////////////////////

  // methods
  const btnToggle = (clickedItem, type) => {
    let data, func, index;
    let newItem = fashionInfo
    let retIndex = false

    if (type === 'style') {
      data = styleName
      func = setStyleName
      index = 3
    } else {
      data = sizeCtg
      func = setSizeCtg
      index = 4
    }

    const updatedStyleName = data.map((item, i) => {
      if (item.title === clickedItem.title) {
        retIndex = i;
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });

    func(updatedStyleName);
    newItem[index] = updatedStyleName
    setFashionInfo(newItem)
    return retIndex !== false ? newItem[index][retIndex] : newItem[index]
  }

  const handleBtnJson = (item, keyword) => {
    const big_k = keyword;
    const trueCount = Object.values(applicationData[big_k]).filter(value => value === true).length;

    if (trueCount >= 5 && !item.isChecked) {
      alert("최대 5개까지만 선택할 수 있습니다.")
      return
    }

    const newItem = btnToggle(item, 'style');
    const small_k = newItem.name;

    const updatedItem = {
      ...applicationData[big_k],
      [small_k]: newItem.isChecked
    };

    setApplicationData({
      ...applicationData,
      [big_k]: updatedItem
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setApplicationData({
      ...applicationData,
      [name]: value
    });
  };

  const handleCtfInfo = (e) => {
    const { name, type, value } = e.target;
    if (type === "button") {
      let attrName, displayOption;
      if (name === "addCtfInfo") {
        if (ctfInfo.length)


          attrName = `certification_info_${ctfInfo.length + 1}`
        displayOption = true
        if (ctfInfo.length < 3) {
          const lastElement = ctfInfo[ctfInfo.length - 1];
          if (lastElement.value === '') {
            alert('업로드할 파일을 선택 후 항목을 추가해주세요.');
            return;
          }
          setCtfInfo([...ctfInfo, { id: ctfInfo.length, value: '' }]);
        }

      } else if (name === "removeCtfInfo") {
        attrName = `certification_info_${ctfInfo.length}`
        displayOption = false
        setCtfInfo(ctfInfo.slice(0, -1));
      }

      setApplicationData({
        ...applicationData,
        [attrName]: { 'display': displayOption, 'file': '' }
      });

    } else if (type === "radio") {
      if (name === "certification_info_1" && value === "신고대상") {
        if (ctfInfo.length < 1) {
          setApplicationData({
            ...applicationData,
            [name]: { 'display': true, 'file': '', 'file_name': '', 'disable_option': null }
          });
          setCtfInfo([...ctfInfo, { id: ctfInfo.length, value: '' }]);
          setCategoryUsage({ ...categoryUsage, certification: true });
        }
      } else if (name === "certification_info_1" && (value === "별도표기" || value === "대상아님")) {
        if (ctfInfo.length > 0) {
          setCtfInfo([]);
          setCategoryUsage({ ...categoryUsage, certification: false });
        }
        setApplicationData({
          ...applicationData,
          ["certification_info_1"]: { 'display': false, 'file': '', 'file_name': '', 'disable_option': 1 },
          ["certification_info_2"]: { 'display': false, 'file': '', 'file_name': '', 'disable_option': null },
          ["certification_info_3"]: { 'display': false, 'file': '', 'file_name': '', 'disable_option': null }
        });

      }
    } else if (type === "file") {
      const newCtfFile = {
        id: name,
        value: value,
      }
      setCtfInfo([...ctfInfo, newCtfFile]);
    }
  }

  const handleCtfFile = (id, e) => {

    let attrName = `certification_info_${id + 1}`;
    const { value, files, nextElementSibling } = e.target

    const file = files[0];
    const fileNameDisplay = nextElementSibling;

    if (file) {
      // 허용된 파일 타입 리스트
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/tiff',
        'image/webp'
      ];

      if (!allowedTypes.includes(file.type)) {
        alert("PDF, 이미지 파일만 첨부 가능합니다.");
        e.target.value = "";
        fileNameDisplay.textContent = "업로드할 파일을 선택해주세요.";
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setApplicationData({
          ...applicationData,
          [attrName]: { 
            'display': true, 
            'file': reader.result, 
            'file_name': file.name, 
            'disable_option': attrName[attrName.length-1] == '1' ? 1 : null
          }
        });
      };
      reader.readAsDataURL(file);
    }

    setCtfInfo(ctfInfo.map(ctf => (ctf.id === id ? { ...ctf, value } : ctf)));
  }

  const handleChangeCategoryUsage = (e) => {
    const { name, checked } = e.target;

    setCategoryUsage({ ...categoryUsage, [name]: checked })
  }

  const handleChangeJson = (e) => {
    const { name, type, checked, value } = e.target;
    const [big_k, small_k] = name.split(':');

    let result = type === 'checkbox' ? checked : value

    const updatedItem = {
      ...applicationData[big_k],
      [small_k]: result
    };

    setApplicationData({
      ...applicationData,
      [big_k]: updatedItem
    });
  }

  const handleRadioJson = (e, name) => {
    const { checked } = e.target;
    const splitedItem = name.split(':');
    const key1 = splitedItem[0];
    const options = splitedItem.slice(1);

    let updatedItem = Object.keys(applicationData[key1]).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});

    options.forEach(key => {
      if (key in applicationData[key1]) {
        updatedItem[key] = checked;
      }
    });

    setApplicationData({
      ...applicationData,
      [key1]: updatedItem
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    const fileNameDisplay = e.target.nextElementSibling;

    if (file) {
      // 허용된 파일 타입 리스트
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/tiff',
        'image/webp'
      ];

      if (!allowedTypes.includes(file.type)) {
        alert("PDF, 이미지 파일만 첨부 가능합니다.");
        e.target.value = "";
        fileNameDisplay.textContent = "업로드할 파일을 선택해주세요.";
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setApplicationData({
          ...applicationData,
          [name]: reader.result,
          [`${name}_name`]: file.name
        });
      };
      reader.readAsDataURL(file);
    }
  };


  const checkEcmNo = () => { // 통신판매업 신고번호 조회
    if (!applicationData.b_no.trim()) {
      alert('가입정보가 초기화되었습니다.\n다시 시도해주세요.')
      navigate('/vendor/3')
      return
    }

    api.post('/api-tg/check-ecm', {
      brno: applicationData.b_no
    }).then(res => {
      if (!res.data.status) {
        alert('사업자등록번호와 부합하는 통신판매업 신고번호가 없습니다.\n확인 후 다시 시도해주세요.')
        return
      }

      alert('정상 조회되었습니다\n통신판매업신고번호: ' + res.data.data)
      setApplicationData({
        ...applicationData,
        ['e_commerce_license_number']: res.data.data
      });

      console.log('통신판매업 조회:: ', res)
    }).catch(e => {
      alert('오류 발생\n계속될 경우 관리자에게 문의해주세요.')
      console.log(e)
    })
  }

  const validateFormData = () => { // 정보입력: 유효성 검사
    const newErrors = {};
    const validations = [
      {
        condition: !applicationData.store_name.trim(),
        field: 'store_name',
        message: "스토어명을 입력해주세요."
      },
      {
        condition: !applicationData.store_type.trim(),
        field: 'store_type',
        message: "스토어 유형을 선택해주세요."
      },
      // {
      //   condition: Object.values(applicationData.fashion_category).every(value => !value),
      //   field: 'fashion_category',
      //   message: "패션상품 카테고리를 최소 하나 이상 선택해주세요."
      // },
      {
        condition: categoryUsage.fashion && (!applicationData.fashion_category.top.value.trim() || !applicationData.fashion_category.middle.value.trim() || !applicationData.fashion_category.bottom.value.trim()),
        field: 'fashion_category',
        message: "패션상품 스토어 분류 지정을 선택해주세요."
      },
      {
        condition: categoryUsage.fashion && (Object.values(applicationData.gender_category).every(value => !value)),
        field: 'gender_category',
        message: "스토어 성별을 최소 하나 이상 선택해주세요."
      },
      {
        condition: categoryUsage.fashion && (!applicationData.age_range.min.trim() || !applicationData.age_range.max.trim()),
        field: 'age_range',
        message: "패션상품 사용가능 연령대를 선택해주세요."
      },
      {
        condition: categoryUsage.fashion && (Object.values(applicationData.store_style).every(value => !value)),
        field: 'store_style',
        message: "스토어 스타일을 최소 하나 이상 선택해주세요."
      },
      {
        condition: categoryUsage.fashion && (!applicationData.parallel_import),
        field: 'parallel_import',
        message: "패션상품 수입 방법을 선택해주세요."
      },
      /////////////////// 
      {
        condition: categoryUsage.life && (!applicationData.life_category.top.value.trim() || !applicationData.life_category.middle.value.trim() || !applicationData.life_category.bottom.value.trim()),
        field: 'life_category',
        message: "라이프/유형 스토어 분류 지정을 선택해주세요."
      },
      {
        condition: categoryUsage.life && (!applicationData.life_age_range.min.trim() || !applicationData.life_age_range.max.trim()),
        field: 'life_age_range',
        message: "라이프/유형 사용가능 연령대를 선택해주세요."
      },
      {
        condition: categoryUsage.life && (!applicationData.life_parallel_import),
        field: 'life_parallel_import',
        message: "라이프/유형 수입 방법을 선택해주세요."
      },
      {
        condition: Object.values(applicationData.sale_countries).every(value => !value),
        field: 'sale_countries',
        message: "판매 국가를 선택해주세요."
      },
      {
        condition: !applicationData.contact_name.trim(),
        field: 'contact_name',
        message: "담당자 이름을 입력해주세요."
      },
      {
        condition: !applicationData.contact_mobile.trim() || !/^\d{10,11}$/.test(applicationData.contact_mobile),
        field: 'contact_mobile',
        message: "휴대폰 번호 형식이 올바르지 않습니다."
      },
      {
        condition: !applicationData.contact_phone.trim(),
        field: 'contact_phone',
        message: "담당자 유선 전화를 입력해주세요."
      },
      {
        condition: !/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(applicationData.contact_email),
        field: 'contact_email',
        message: "이메일 형식이 올바르지 않습니다."
      },
      {
        condition: !applicationData.business_registration_image.trim(),
        field: 'business_registration_image',
        message: "사업자등록증을 업로드해주세요."
      },
      {
        condition: !applicationData.e_commerce_license_image.trim(),
        field: 'e_commerce_license_image',
        message: "통신판매업증을 업로드해주세요."
      },
      {
        condition: !applicationData.e_commerce_license_number.trim(),
        field: 'e_commerce_license_number',
        message: "통신판매업 신고번호를 조회해주세요."
      },
      {
        condition: !applicationData.company_name.trim(),
        field: 'company_name',
        message: "업체(법인)명을 입력해주세요."
      },
      {
        condition: (applicationData.certification_info_1.display && (!ctfInfo.length || !ctfInfo[ctfInfo.length - 1].value)) || (!applicationData.certification_info_1.display && applicationData.certification_info_1.disable_option === 0)
        ,
        field: 'certification_info_1',
        message: "인증 정보를 입력해주세요."
      },
    ];

    for (const validation of validations) {
      if (validation.condition) {
        newErrors[validation.field] = validation.message;
        return newErrors;
      }
    }
    return newErrors;
  };

  const isOkNext = () => { // 다음페이지 이동가능 여부
    // category_no 설정
    setApplicationData(
      {
        ...applicationData,
        ...(categoryUsage.fashion && !categoryUsage.life ? { category_no: applicationData.fashion_category.bottom.key }
          : !categoryUsage.fashion && categoryUsage.life ? { category_no: applicationData.life_category.bottom.key }
            : { category_no: applicationData.fashion_category.bottom.key, category_no_life: applicationData.life_category.bottom.key })
      }
    )
    const formErrors = validateFormData();
    console.log(formErrors)
    return Object.keys(formErrors).length === 0;
  }

  const combineFunctions = (dynamicFunction) => {
    return (e) => {
      dynamicFunction(e);
      handleChangeJson(e);
    };
  };

  const handleSubmit = () => { // 입점신청 폼 입력
    const formErrors = validateFormData();
    if (Object.keys(formErrors).length !== 0) {
      alert(Object.values(formErrors)[0]);
      return;
    }

    if (isOkNext()) navigate('/vendor/7')
  }


  return (
    <>
      <section className="section-register">
        <div className="inner">

          {/* 타이틀 영역 [START] */}
          <div className="h3-title-wrap mt0">
            <h3 className="title">스토어 정보 입력</h3>
            <p className="text">
              스토어 입점을 위한 수집 정보를 입력해주세요.
            </p>
            <div className="pagination">
              <span className="item">
                1
              </span>
              <span className="item active">
                2
              </span>
              <span className="item">
                3
              </span>
            </div>
          </div>
          {/* 타이틀 영역 [END] */}

          {/* 스토어 정보 영역 [START] */}
          <div className="white-wrap pl0 pr0">

            {/* 타이틀 영역 [START] */}
            <div className="h3-title-wrap mt0">
              <h3>
                스토어 정보
              </h3>
            </div>
            {/* 타이틀 영역 [END] */}

            {/* 스토어명 [START] */}
            <div className="input-box">
              <label htmlFor="storeName" className="title starred">
                스토어명
              </label>
              <div className="input">
                <input
                  id="storeName"
                  name="store_name"
                  className="space"
                  type="text"
                  placeholder="스토어명을 입력해주세요."
                  onInput={handleChange}
                  value={applicationData.store_name}
                />
              </div>
            </div>
            {/* 스토어명 [END] */}

            {/* 스토어 유형 [START] */}
            <div className="input-box">
              <div className="title starred">
                스토어 유형
              </div>
              <ul className="input-list row">
                <li className="input radio">
                  <label htmlFor="radio-brand"><input id="radio-brand" name="store_type" type="radio" onChange={handleChange} value="브랜드몰"></input>
                    브랜드몰
                  </label>
                </li>
                <li className="input radio">
                  <label htmlFor="radio-edit">
                    <input id="radio-edit" name="store_type" type="radio" onChange={handleChange} value="편집샵"></input>
                    편집샵
                  </label>
                </li>
              </ul>
            </div>
            {/* 스토어 유형 [END] */}

          </div>
          {/* 스토어 정보 영역 [END] */}

          {/* 패션상품 영역 [START] */}
          <div className="white-wrap pl0 pr0">
            {/* 타이틀 영역 [START] */}
            <div className="h3-title-wrap mt0 input check bg">
              <input
                id="fashionCategoryUsage"
                name="fashion"
                type="checkbox"
                onChange={handleChangeCategoryUsage}
              />
              <h3>
                패션상품
              </h3>
            </div>
            {/* 타이틀 영역 [END] */}

            {
              categoryUsage.fashion === true ? (
                <div>
                  {/* 스토어 분류 지정 [START] */}
                  <div className="input-box">
                    <div className="title starred">
                      스토어 분류 지정
                    </div>
                    <div className="flex a-center gap10">
                      <div className="input w100p">
                        <select name="fashion_category:top"
                          onChange={handleChangeCategory}
                          defaultValue="" disabled>
                          {/* <option value="" disabled>상위 분류를 선택해주세요</option> */}
                          {/* {SignUpData.fashionCtgData[0].value.map((e, idx) => (
                            <option
                              value={e.key}
                              key={`ctg-select-${idx}-${e.value}`}
                            >
                              {e.value}
                            </option>
                          ))} */}
                          {fashionDepth1Tabs.map((e) => (
                            <option
                              value={e.node.name}
                              key={e.node.decryptId}
                            >
                              {e.node.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="input mt0 w100p">
                        <select name="fashion_category:middle"
                          onChange={handleChangeCategory}
                          defaultValue=""
                          disabled={!fashionDepth2Tabs.length}
                        >
                          <option value="" disabled>중간 분류를 선택해주세요</option>
                          {/* {fashionMiddleCategories.map((e, idx) => ( */}
                          {/* {SignUpData.fashionCtgData[1].value.map((e, idx) => (
                            <option
                              value={e.value}
                              key={`ctg-select-${idx}-${e.value}`}
                            >
                              {e.value}
                            </option>
                          ))} */}
                          {fashionDepth2Tabs.map((e) => (
                            <option
                              value={`${e.node.decryptId}:${e.node.name}`}
                              key={e.node.decryptId}
                            >
                              {e.node.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="input mt0 w100p">
                        <select name="fashion_category:bottom"
                          onChange={handleChangeCategory}
                          defaultValue=""
                          disabled={!fashionDepth3Tabs.length}>
                          <option value="" disabled>하위 분류를 선택해주세요</option>
                          {/* {fashionBottomCategories.map((e, idx) => (
                            <option
                              value={e.value}
                              key={`ctg-select-${idx}-${e.value}`}
                            >
                              {e.value}
                            </option>
                          ))} */}
                          {fashionDepth3Tabs.map((e, idx) => (
                            <option
                              value={`${e.node.decryptId}:${e.node.name}`}
                              key={e.node.decryptId}
                            >
                              {e.node.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* 스토어 분류 지정 [END] */}

                  {/* 스토어 성별 지정 [START] */}
                  <div className="input-box">
                    <div className="title starred">
                      스토어 성별 지정
                    </div>
                    <ul className="input-list row">
                      <li className="input check bg">
                        <label htmlFor="check-mt-1">
                          <input id="check-mt-1" name="gender_category:Unisex" type="checkbox" onChange={(e) => handleChangeJson(e)} value="unisex"></input>
                          남녀공용
                        </label>
                      </li>
                      <li className="input check bg">
                        <label htmlFor="check-mt-2">
                          <input id="check-mt-2" name="gender_category:girl" type="checkbox" onChange={(e) => handleChangeJson(e)} value="girl"></input>
                          여아용
                        </label>
                      </li>
                      <li className="input check bg">
                        <label htmlFor="check-mt-3">
                          <input id="check-mt-3" name="gender_category:boy" type="checkbox" onChange={(e) => handleChangeJson(e)} value="boy"></input>
                          남아용
                        </label>
                      </li>
                    </ul>
                  </div>
                  {/* 스토어 성별 지정 [END] */}

                  {/* 사용가능 연령대 [START] */}
                  <div className="input-box">
                    <div className="title starred">
                      사용가능 연령대
                    </div>
                    <div className="flex a-center gap10">
                      <div className="input w100p">
                        <select name="age_range:min" onChange={handleChangeJson} defaultValue="">
                          <option value="" disabled>최소 연령을 선택해주세요.</option>
                          {SignUpData.timeData[0].value.map((e, idx) => (
                            <option
                              value={e.key}
                              key={`age-select-1-${idx}-${e.value}`}
                            >
                              {e.value}
                            </option>
                          ))}
                        </select>
                      </div>
                      ~
                      <div className="input mt0 w100p">
                        <select name="age_range:max" onChange={handleChangeJson} defaultValue="">
                          <option value="" disabled>최대 연령을 선택해주세요.</option>
                          {SignUpData.timeData[0].value.map((e, idx) => (
                            <option
                              value={e.key}
                              key={`age-select-1-${idx}-${e.value}`}
                            >
                              {e.value}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                  </div>
                  {/* 사용가능 연령대 [END] */}

                  {/* 스토어 스타일 지정 [START] */}
                  <div className="input-box">
                    <div className="title starred">
                      스토어 스타일 지정
                    </div>
                    <div className="btn-wrap flex-wrap">
                      <>
                        {
                          styleName.map(button => (
                            <button
                              key={button.value}
                              type="button"
                              className={`btn white ${applicationData['store_style'][button.name] ? 'active' : ''}`}
                              onClick={() => handleBtnJson(button, 'store_style')}
                            >
                              {button.title}
                            </button>
                          ))
                        }
                      </>
                    </div>
                    <p className="mt10 caption f-black50">최대 5개 이하를 순차적으로 선택해주세요.</p>
                  </div>
                  {/* 스토어 스타일 지정 [END] */}

                  {/* 수입 방법 [START] */}
                  <div className="input-box">
                    <div className="title starred">
                      수입 방법
                    </div>
                    <ul className="input-list row">
                      <li className="input radio">
                        <label htmlFor="radio-mt-t">
                          <input id="radio-mt-t" name="parallel_import" type="radio" value='True' onChange={handleChange}></input>
                          병행 수입
                        </label>
                      </li>
                      <li className="input radio">
                        <label htmlFor="radio-mt-f">
                          <input id="radio-mt-f" name="parallel_import" type="radio" value='False' onChange={handleChange}></input>
                          병행 수입 아님
                        </label>
                      </li>
                    </ul>
                  </div>
                  {/* 수입 방법 [END] */}
                </div>
              ) : <></>
            }

            {/* 패션상품 영역 [END] */}

            {/* 라이프/유형 영역 [START] */}
            <div className="white-wrap pl0 pr0">

              {/* 타이틀 영역 [START] */}
              <div className="h3-title-wrap mt10 input check bg">
                <input
                  id="fashionCategoryUsage"
                  name="life"
                  type="checkbox"
                  onChange={handleChangeCategoryUsage}
                />
                <h3>
                  라이프/유형
                </h3>
              </div>
              {/* 타이틀 영역 END] */}
              {
                categoryUsage.life === true ? (
                  <div>

                    {/* 스토어 분류 지정 [START] */}
                    <div className="input-box">
                      <div className="title starred">
                        스토어 분류 지정
                      </div>
                      <div className="flex a-center gap10">
                        <div className="input w100p">
                          <select name="life_category:top"
                            onChange={handleChangeCategory}
                            defaultValue="" disabled>
                            {/* <option value="" disabled>상위 분류를 선택해주세요</option> */}
                            {/* {SignUpData.lifeCtgData[0].value.map((e, idx) => (
                              <option
                                value={e.key}
                                key={`ctg-select-${idx}-${e.value}`}
                              >
                                {e.value}
                              </option>
                            ))} */}
                            {lifeDepth1Tabs.map((e, idx) => (
                              <option
                                value={`${e.node.decryptId}:${e.node.name}`}
                                key={e.node.decryptId}
                              >
                                {e.node.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="input mt0 w100p">
                          <select name="life_category:middle"
                            onChange={handleChangeCategory}
                            defaultValue=""
                            disabled={!lifeDepth2Tabs.length}
                          >
                            <option value="" disabled>중간 분류를 선택해주세요</option>
                            {/* {lifeMiddleCategories.map((e, idx) => ( */}
                            {/* {SignUpData.lifeCtgData[1].value.map((e, idx) => (
                              <option
                                value={e.value}
                                key={`ctg-select-${idx}-${e.value}`}
                              >
                                {e.value}
                              </option>
                            ))} */}
                            {lifeDepth2Tabs.map((e, idx) => (
                              <option
                                value={`${e.node.decryptId}:${e.node.name}`}
                                key={e.node.decryptId}
                              >
                                {e.node.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="input mt0 w100p">
                          <select name="life_category:bottom"
                            onChange={handleChangeCategory}
                            defaultValue=""
                            disabled={!lifeDepth3Tabs.length}>
                            <option value="" disabled>하위 분류를 선택해주세요</option>
                            {/* {lifeBottomCategories.map((e, idx) => (
                              <option
                                value={e.value}
                                key={`ctg-select-${idx}-${e.value}`}
                              >
                                {e.value}
                              </option>
                            ))} */}
                            {lifeDepth3Tabs.map((e, idx) => (
                              <option
                                value={`${e.node.decryptId}:${e.node.name}`}
                                key={e.node.decryptId}
                              >
                                {e.node.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* 스토어 분류 지정 [END] */}


                    {/* 사용가능 연령대 [START] */}
                    <div className="input-box">
                      <div className="title starred">
                        사용가능 연령대
                      </div>
                      <div className="flex a-center gap10">
                        <div className="input w100p">
                          <select name="life_age_range:min" onChange={handleChangeJson} defaultValue="">
                            <option value="" disabled>최소 연령을 선택해주세요.</option>
                            {SignUpData.timeData[0].value.map((e, idx) => (
                              <option
                                value={e.key}
                                key={`age-select-2-${idx}-${e.value}`}
                              >
                                {e.value}
                              </option>
                            ))}
                          </select>
                        </div>
                        ~
                        <div className="input mt0 w100p">
                          <select name="life_age_range:max" onChange={handleChangeJson} defaultValue="">
                            <option value="" disabled>최대 연령을 선택해주세요.</option>
                            {SignUpData.timeData[0].value.map((e, idx) => (
                              <option
                                value={e.key}
                                key={`age-select-2-${idx}-${e.value}`}
                              >
                                {e.value}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                    </div>
                    {/* 사용가능 연령대 [END] */}

                    {/* 수입 방법 [START] */}
                    <div className="input-box">
                      <div className="title starred">
                        수입 방법
                      </div>
                      <ul className="input-list row">
                        <li className="input radio">
                          <label htmlFor="radio-mt-y">
                            <input id="radio-mt-y" name="life_parallel_import" type="radio" value='True' onChange={handleChange} ></input>
                            병행 수입
                          </label>
                        </li>
                        <li className="input radio">
                          <label htmlFor="radio-mt-n">
                            <input id="radio-mt-n" name="life_parallel_import" type="radio" value='False' onChange={handleChange}></input>
                            병행 수입 아님
                          </label>
                        </li>
                      </ul>
                    </div>
                    {/* 수입 방법 [END] */}
                  </div>
                ) : <></>
              }
            </div>
          </div>
          {/* 라이프/유형 영역 [END] */}

          {/* 판매 국가 정보 영역 [START] */}
          <div className="white-wrap pl0 pr0">

            {/* 타이틀 영역 [START] */}
            <div className="h3-title-wrap mt0">
              <h3>
                판매 국가 정보
              </h3>
            </div>
            {/* 타이틀 영역 [END] */}

            {/* 판매 국가 선택 [START] */}
            <div className="input-box">
              <div className="title starred">
                판매 국가 선택
              </div>
              <ul className="input-list row">
                <li className="input check bg">
                  <label htmlFor="country1">
                    <input id="country1" name="country-check" type="checkbox" onChange={(e) => handleChangeJson(e, 'sale_countries:domestic')}></input>
                    국내
                  </label>
                </li>
                <li className="input check bg">
                  <label htmlFor="country2">
                    <input id="country2" name="country-check" type="checkbox" onChange={(e) => handleChangeJson(e, 'sale_countries:global')}></input>
                    글로벌
                  </label>
                </li>
              </ul>
            </div>
            {/* 판매 국가 선택 [END] */}

          </div>
          {/* 판매 국가 정보 영역 [END] */}

          {/* 스토어 담당자 정보 영역 [START] */}
          <div className="white-wrap pl0 pr0">

            {/* 타이틀 영역 [START] */}
            <div className="h3-title-wrap mt0">
              <h3>
                스토어 담당자 정보
              </h3>
            </div>
            {/* 타이틀 영역 [END] */}

            {/* 담당자 이름 [START] */}
            <div className="input-box">
              <label htmlFor="name" className="title starred">
                담당자 이름
              </label>
              <div className="input">
                <input
                  id="name"
                  type="text"
                  name="contact_name"
                  placeholder="담당자 이름을 입력해주세요."
                  onInput={handleChange}
                  value={applicationData.contact_name}
                />
              </div>
            </div>
            {/* 담당자 이름 [END] */}

            {/* 담당자 휴대폰 [START] */}
            <div className="input-box">
              <label htmlFor="phoneNumber" className="title starred">
                담당자 휴대폰
              </label>
              <div className="input">
                <input
                  id="phoneNumber"
                  type="number"
                  name="contact_mobile"
                  placeholder="담당자 휴대폰 번호를 입력해주세요."
                  onInput={handleChange}
                  value={applicationData.contact_mobile}
                />
              </div>
            </div>
            {/* 담당자 휴대폰 [END] */}

            {/* 담당자 유선 전화 [START] */}
            <div className="input-box">
              <label htmlFor="lineNumber" className="title">
                담당자 유선 전화
              </label>
              <div className="input">
                <input
                  id="lineNumber"
                  name="contact_phone"
                  type="number"
                  placeholder="담당자 유선 전화를 입력해주세요."
                  onInput={handleChange}
                  value={applicationData.contact_phone}
                />
              </div>
            </div>
            {/* 담당자 유선 전화 [END] */}

            {/* 담당자 이메일 [START] */}
            <div className="input-box">
              <label htmlFor="lineNumber" className="title">
                담당자 이메일
              </label>
              <div className="input">
                <input
                  id="lineNumber"
                  name="contact_email"
                  type="email"
                  placeholder="담당자 이메일을 입력해주세요."
                  onInput={handleChange}
                  value={applicationData.contact_email}
                />
              </div>
            </div>
            {/* 담당자 이메일 [END] */}

          </div>
          {/* 스토어 담당자 정보 영역 [END] */}

          {/* 사업자 정보 영역 [START] */}
          <div className="white-wrap pl0 pr0">

            {/* 타이틀 영역 [START] */}
            <div className="h3-title-wrap mt0">
              <h3>
                사업자 정보
              </h3>
              <p className="text">
                아래 정보로 세금계산서가 발행됩니다.
              </p>
            </div>
            {/* 타이틀 영역 [END] */}

            {/* 사업자등록증 [START] */}
            <div className="input-box">
              <label htmlFor="inputImg1" className="title starred">
                사업자등록증
              </label>
              <div className="input file-upload">
                <button
                  type="button"
                  className="btn purple update-btn"
                >
                  파일 업로드
                </button>
                <input type="file" onChange={handleFileChange} name="business_registration_image"
                  className="hide" />
                <div className="file-name-display">
                  업로드할 파일을 선택해주세요.
                </div>
              </div>
              <span className="caption f-purple">PDF, 이미지 파일만 첨부 가능합니다.</span>
            </div>
            {/* 사업자등록증 [END] */}

            {/* 사업자등록번호 [START] */}
            <div className="input-box">
              <label htmlFor="busiNumber" className="title starred">
                사업자등록번호
              </label>
              <div className="input">
                <input
                  id="busiNumber"
                  type="number"
                  value={applicationData.b_no}
                  readOnly
                  disabled
                />
              </div>
            </div>
            {/* 사업자등록번호 [END] */}

            {/* 통신판매업증 [START] */}
            <div className="input-box">
              <label htmlFor="inputImg2" className="title starred">
                통신판매업증
              </label>
              <div className="input file-upload">
                <button
                  type="button"
                  className="btn purple update-btn"
                >
                  파일 업로드
                </button>
                <input type="file" name="e_commerce_license_image" onChange={handleFileChange} className="hide" />
                <div className="file-name-display">
                  업로드할 파일을 선택해주세요.
                </div>
              </div>
              <span className="caption f-purple">PDF, 이미지 파일만 첨부 가능합니다.</span>
            </div>
            {/* 통신판매업증 [END] */}

            {/* 통신판매업 신고번호 [START] */}
            <div className="input-box">
              <label htmlFor="sellerNumber" className="title starred">
                통신판매업 신고번호
              </label>
              <div className="input">
                <input
                  id="sellerNumber"
                  type="text"
                  name="e_commerce_license_number"
                  placeholder="[신고번호 조회] 버튼을 눌러 신고번호를 조회하세요."
                  onInput={handleChange}
                  disabled
                  value={applicationData.e_commerce_license_number}
                />
                <button
                  type="button"
                  className="btn purple"
                  onClick={checkEcmNo}
                >
                  신고번호 조회
                </button>
              </div>
            </div>
            {/* 통신판매업 신고번호 [END] */}

            {/* 업체(법인)명 [START] */}
            <div className="input-box">
              <label htmlFor="businessName" className="title starred">
                업체(법인)명
              </label>
              <div className="input">
                <input
                  id="businessName"
                  className="space"
                  name="company_name"
                  type="text"
                  placeholder="업체(법인)명을 입력해주세요"
                  onInput={handleChange}
                  value={applicationData.company_name}
                />
              </div>
            </div>
            {/* 업체(법인)명 [END] */}

          </div>
          {/* 사업자 정보 영역 [END] */}

          {/* 인증 정보 영역 [START] */}
          <div className="white-wrap pl0 pr0">

            {/* 타이틀 영역 [START] */}
            <div className="h3-title-wrap mt0">
              <h3>
                인증 정보
              </h3>
            </div>
            {/* 타이틀 영역 [END] */}

            {/* 인증정보 리스트 [START] */}
            <ul className="input-list">
              <li className="input radio flex etc">
                <label htmlFor="authentication1">
                  <input id="authentication1" name="certification_info_1" type="radio" value="신고대상" onChange={handleCtfInfo}></input> 인증: 신고 대상
                </label>
                <div className="input-box w100p">
                  {
                    ctfInfo.map((ctf) => (
                      <div className="mb10">
                        <div className="input file-upload mt10" key={ctf.id}>
                          <button
                            type="button"
                            className="btn purple update-btn"
                          >
                            파일 업로드
                          </button>
                          <input type="file" className="hide" name={ctf.id} onChange={(e) => handleCtfFile(ctf.id, e)} />
                          <div className="file-name-display">
                            업로드할 파일을 선택해주세요.
                          </div>
                        </div>
                        <span className="caption f-purple">PDF, 이미지 파일만 첨부 가능합니다.</span>
                      </div>
                    ))
                  }
                  {
                    categoryUsage.certification === true ? (
                      <div className='check-list vendor'>
                        <button name="addCtfInfo" type="button" className="btn purple" onClick={handleCtfInfo} disabled={ctfInfo.length < 3 ? false : true}>
                          추가
                        </button>
                        <button name="removeCtfInfo" type="button" className="btn purple" onClick={handleCtfInfo} disabled={ctfInfo.length > 1 ? false : true}>
                          삭제
                        </button>
                      </div>
                    ) : <></>
                  }
                </div>
              </li>
              <li className="input radio">
                <label htmlFor="authentication2">
                  <input id="authentication2" name="certification_info_1" type="radio" value="별도표기" onChange={handleCtfInfo}></input>
                  상세페이지 별도표기
                </label>
              </li>
              <li className="input radio">
                <label htmlFor="authentication3">
                  <input id="authentication3" name="certification_info_1" type="radio" value="대상아님" onChange={handleCtfInfo}></input>
                  인증: 신고 대상 아님
                </label>
              </li>

            </ul>
            {/* 인증정보 리스트 [END] */}

          </div>
          {/* 인증 정보 영역 [END] */}

          {/* 개발용 디버그 박스 [START] */}
          {/* <div className="debug-box mt20">
            <p><b>아이 별명 :</b> {input.kidNickName}</p>
            <p><b>아이 성별 :</b> {input.kidGender}</p>
            <p><b>아이 출생시간 :</b> {input.kidBirthDay}</p>
            <p><b>아이 키 :</b> {input.kidHeight}</p>
            <p><b>아이 몸무게 :</b> {input.kidWeight}</p>
            <p><b>아이 발사이즈 :</b> {input.kidFootSize}</p>
            <p><b>아이 머리사이즈 :</b> {input.kidHeadSize}</p>
          </div> */}
          {/* 개발용 디버그 박스 [END] */}

        </div>
      </section >
      {/* 다음 버튼 [START]*/}
      < div className="bottom-bar" >
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button
              type="button"
              className="btn purple big"
              onClick={() => handleSubmit()}
            // disabled={!isOkNext()}
            >
              다음
            </button>
          </div>
        </div>
      </div >
      {/* 다음 버튼 [END]*/}
    </>
  );
};

export default VendorRegister06Section;

