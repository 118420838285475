import React from "react";

import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

const HeroBannerSection = ({images}) => {

  return (
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        spaceBetween={0}
        slidesPerView={1}
        speed={900}
        loop={images.length > 1 ? true : false}
        pagination={{
          type: 'fraction',
          clickable: true
        }}
        className="hero-slide-wrap mb0"
      >
        {
          images.map((slide)=>(
            <SwiperSlide key={slide.url}>
              <img src={slide.url} alt={slide.alt} />
            </SwiperSlide>
          ))
        }
      </Swiper>
  );
};

export default HeroBannerSection;
