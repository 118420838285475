import { gql, ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client'; // createUploadLink를 import
import { jwtDecode } from 'jwt-decode'; // JWT 디코딩을 위해 수정된 임포트
import { meData } from './GQ_apis/me';

const authLink = new ApolloLink((operation, forward) => {
  let user = localStorage.getItem('user');

  if (user) {
    user = JSON.parse(user);
    const { tokenCreate } = user;

    if (tokenCreate && tokenCreate.token) {
      const decodedToken = jwtDecode(tokenCreate.token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        // 토큰 만료됨. Saleor의 tokenRefresh 뮤테이션 사용
        const client = new ApolloClient({
          link: createUploadLink({ uri: process.env.REACT_APP_API_URL }), // HttpLink 대신 createUploadLink 사용
          cache: new InMemoryCache(),
        });

        return client
          .mutate({
            mutation: gql`
              mutation RefreshToken($refreshToken: String!) {
                tokenRefresh(refreshToken: $refreshToken) {
                  token
                }
              }
            `,
            variables: { refreshToken: tokenCreate.refreshToken },
          })
          .then((response) => {
            const { data } = response;
            if (data.tokenRefresh.token) {
              // 새 토큰 저장
              user.tokenCreate.token = data.tokenRefresh.token;
              localStorage.setItem('user', JSON.stringify(user));

              // 요청에 새 토큰 적용
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  Authorization: `Bearer ${data.tokenRefresh.token}`,
                },
              }));

              meData().then((res) => {
                localStorage.setItem('me', JSON.stringify(res));
              });
            }
            return forward(operation);
          })
          .catch((error) => {
            console.error('Token refresh error:', error);
            return forward(operation);
          });
      }

      // 토큰이 아직 유효한 경우
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          Authorization: `Bearer ${tokenCreate.token}`,
        },
      }));
    }
  }

  return forward(operation);
});

// createUploadLink로 파일 업로드 지원
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL, // GraphQL 서버 엔드포인트
});

// Apollo Client 설정
const client = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink]), // authLink와 uploadLink를 결합
  cache: new InMemoryCache(),
});

// 토큰을 전혀 추가하지 않는 Apollo 링크
const nonTokenLink = new ApolloLink((operation, forward) => {
  // 토큰을 추가하지 않고 그대로 요청을 전달
  return forward(operation);
});

// nonTokenClient Apollo Client 설정
const nonTokenClient = new ApolloClient({
  link: nonTokenLink.concat(uploadLink), // HttpLink 대신 uploadLink 사용
  cache: new InMemoryCache(),
});

export { client, nonTokenClient };
