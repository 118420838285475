import React from "react";
import { useNavigate } from "react-router-dom";

const DeliveryOverseasSection = () => {

  const navigate = useNavigate();

  return (
    <>
      <section className="section-delivery-overseas">
        <div className="inner">
          <button 
            type="button" 
            className="btn purple delivery"
            onClick={()=>navigate('/delivery/domestic')}
          >
            <span className="icon-change"></span>
            국내 배송
          </button>
          <div className="input-box">
            <label htmlFor="inputId" className="title starred">배송지명</label>
            <div className="input">          
              <input id="inputId" type="text" placeholder="배송지명"></input>
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="inputId" className="title starred">배송국가</label>
            <select>
              <option>배송국가를 선택하세요</option>
            </select>
          </div>
          <div className="input-box">
            <label htmlFor="inputId" className="title starred">받는사람</label>
            <div className="input">          
              <input id="inputId" type="number" placeholder="영문으로 작성해주세요"></input>
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="inputId" className="title starred">연락처</label>
            <div className="input">          
              <input id="inputId" type="number" placeholder="숫자만 작성해주세요"></input>
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="inputId" className="title starred">주소</label>
            <div className="input">
              <strong className="sub-title">Adress</strong>
              <input id="inputId" type="text" placeholder="영문/숫자 작성해 주세요"></input>
            </div>
            <div className="input">      
              <strong className="sub-title">City</strong>
              <input id="inputId" type="text" placeholder="영문/숫자 작성해 주세요"></input>
            </div>
            <div className="input">      
              <strong className="sub-title">State/Province</strong>
              <input id="inputId" type="text" placeholder="영문/숫자 작성해 주세요"></input>
            </div>
            <div className="input">      
              <strong className="sub-title">Zip Code</strong>
              <input id="inputId" type="text" placeholder="영문/숫자 작성해 주세요"></input>
            </div>
          </div>
          <div className="input-box">
            <div className="input check bg">
              <label htmlFor="check0">
                <input id="check0" type="checkbox"></input>  해당 주소를 기본 배송지로 등록
              </label>
            </div>
          </div>
        </div>
      </section>
      
      {/* 저장하기 [START]*/}
      <div className="bottom-bar">
        <div className="inner">
          <button 
            type="button"
            className="btn big purple"
            onClick={()=>navigate('/delivery/list')}
          >
            저장하기
          </button>
        </div>
      </div>
      {/* 저장하기 [END]*/}
    </>
  );
};

export default DeliveryOverseasSection;
