import React, { useState, useRef, useEffect } from "react";
import { createReview } from "../../services/GQ_apis/page";
import SampleImage from "../../assets/images/sample/banner.png";
import useProductStore from '../../store/common/useProductStore';
import { meData } from "../../services/GQ_apis/me";
import { createPage, updateMetadata, createPageMedia } from "../../services/GQ_apis/page";
import { fetchOrderById } from '../../services/GQ_apis/order';
import { useNavigate, useLocation } from 'react-router-dom'; 
import H3Title from '../../layouts/title/H3Title';
import ImageSwipeGridSection from '../../sections/grid/ImageSwipeGridSection';

const MyReviewWriteSection = () => {
  const [rating, setRating] = useState(0); // 별점 상태
  const [content, setContent] = useState(""); // 리뷰 내용 상태
  const [photoFiles, setPhotoFiles] = useState([]); // 파일 업로드를 위한 파일 객체 상태
  const [photoURLs, setPhotoURLs] = useState([]); // 이미지 미리보기 URL 상태
  const [productId, setProductId] = useState(""); // Product ID 상태
  const [vendorId, setVendorId] = useState(""); // Product ID 상태
  const fileInputRef = useRef(null); // 파일 입력 요소를 위한 ref
  const { nowProductData } = useProductStore((state) => ({ nowProductData: state.nowProductData }));
  const [me, setMe] = useState({});
  const location = useLocation();
  const orderId = location.state?.orderId;

  // 별점을 처리하는 함수
  const handleRating = (newRating) => {
    setRating(newRating);
  };

  // 리뷰 내용을 처리하는 함수
  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  // 사진 첨부를 처리하는 함수1
  // const handleFileChange = (event) => {
  //   const newPhotos = Array.from(event.target.files).map(file => URL.createObjectURL(file));
  //   setPhotos([...photos, ...newPhotos]);
  // };

  // 사진 등록을 처리하는 함수2
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newPhotoURLs = [];
    const newPhotoFiles = [];
  
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
        newPhotoURLs.push(base64String); // 미리보기용 URL 추가
        setPhotoURLs(prev => [...prev, ...newPhotoURLs]); // URL 상태 업데이트
        setPhotoFiles(prev => [...prev, ...newPhotoFiles]); // 파일 상태 업데이트
      };
      reader.readAsDataURL(file); // 파일을 읽어 데이터 URL로 변환
      newPhotoFiles.push(file); // 실제 파일 객체 저장
    });
  };

  // 사진 등록을 처리하는 함수
  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   const newPhotoURLs = files.map(file => URL.createObjectURL(file));
  //   setPhotoFiles([...photoFiles, ...files]); // 파일 객체 저장
  //   setPhotoURLs([...photoURLs, ...newPhotoURLs]); // 미리보기 URL 저장
  // };

  // "사진 첨부하기" 버튼 클릭을 처리하는 함수
  const handleAttachPhotoClick = () => {
    fileInputRef.current.click(); // 숨겨진 파일 입력 요소를 프로그래밍 방식으로 클릭
  };

  // 사진 첨부를 제거하는 함수
  const removePhoto = (index) => {
    setPhotoFiles(photoFiles.filter((_, i) => i !== index));
    setPhotoURLs(photoURLs.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderById(orderId).then((res) => {
        console.log('Order data:', res.data.order);
        const productId = res.data.order.lines[0].variant.product.id;
        const vendorId = res.data.order.lines[0].variant.product.vendor.id;
        setProductId(productId); 
        setVendorId(vendorId); 
        console.log(productId,vendorId)
      }).catch(error => {
        console.error('Error fetching order:', error);
      });
    }

    meData().then((res) => {
      setMe(res.data.me);
    });

    console.log('nowProductData', nowProductData);
    console.log('nowProductData name', nowProductData.name);

  }, [orderId]);

  const pageTypeId = 'UGFnZVR5cGU6NQ=='; //리뷰
  const publicationDate = new Date().toISOString().split('T')[0];

  const saveReview = () => {
    if (!productId) {
      console.error('Product ID is not available.');
      return;
    }
  
    createPage(content, pageTypeId, publicationDate)
    .then((res) => {
      const pageId = res.data.pageCreate.page.id;
      console.log('createPage',res);
      console.log('productId',productId);
      console.log('pageId',pageId);
      console.log('photoFiles[0]', photoFiles[0]);

      if (photoFiles.length > 0) { 
        return createPageMedia(productId, pageId, photoFiles[0]).then(mediaRes => {
          console.log('미디어 생성 완료:', mediaRes);
  
          const metadata = [
            { key: "product_id", value: productId },
            { key: "user_id", value: me.metafields.nickName },
            { key: "user_thumbnail", value: me.metafields.profileImg },
            { key: "user_email", value: me.email },
            { key: "publication_date", value: publicationDate },
            { key: "rating", value: rating },
            { key: "ZZ_VID", value: vendorId },
            { key: "ZZ_UID", value: me.id },
          ];
  
          return updateMetadata(pageId, metadata);
        });
      } else {
        return Promise.reject(new Error("No valid image file provided"));
      }
    })
    .then((finalRes) => {
      alert("리뷰 작성 및 미디어 추가가 완료되었습니다.");
      console.log('리뷰 및 미디어 생성 완료:', finalRes);
    })
    .catch((error) => {
      console.error("페이지 생성 오류:", error);
  
      if (error.message.includes("PermissionDenied")) {
        alert("권한이 부족하여 페이지를 생성할 수 없습니다.");
      } else {
        alert("페이지 생성 중 오류가 발생했습니다.");
      }
    });
  };
  
  return (
    <>
      <section className="section-mymenu-review-write">
        <div className="white-wrap">
          <div className="card order">
            <div className="img-wrap">
              <img src={SampleImage} alt=""></img>
            </div>
            <div className="text-wrap">
              <p className="date">2023-12-28</p>
              <p className="brand">베리클로젯</p>
              <p className="name">스타일 레더 패딩 (겨울)</p>
              <p className="price">72,900 / 1개</p>
            </div>
          </div>
          <div className="stars-wrap">
            <h4 className="title">
              상품 별점
            </h4>
            <div className="stars big gap4">
              {[...Array(5)].map((star, index) => {
                const ratingValue = index + 1;
                return (
                  <button
                    key={ratingValue}
                    className={`icon-star-${ratingValue <= rating ? 'fill' : 'empty'} big`}
                    onClick={() => handleRating(ratingValue)}
                    style={{background: 'none', border: 'none', cursor: 'pointer'}}
                    title={`${ratingValue}점`}
                  >
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        <div className="white-wrap">
        <div className="input-box textarea">
            <label htmlFor="textarea" className="title">
              내용
            </label>
            <textarea 
              id="textarea" 
              placeholder="내용을 입력해주세요." 
              value={content}
              onChange={handleContentChange}
            ></textarea>
        </div>
          <input 
            type="file" 
            // 추가
            accept="image/*"
            multiple 
            onChange={handleFileChange} 
            ref={fileInputRef} 
            style={{ display: 'none' }} // 파일 입력 요소를 숨깁니다
          />
          <button 
            type="button" 
            className="btn white2 big mt16" 
            onClick={handleAttachPhotoClick} // 버튼 클릭 시 파일 입력을 활성화합니다
          >
            <span className="icon-camera black"></span>
            사진 첨부하기
          </button>
          <div className="pt16"> 
            <ImageSwipeGridSection photos={photoURLs} onRemovePhoto={removePhoto}/>
          </div>
          {/* 개발 디버그용 */}
          <div className="debug-box mt16">
            <p><b>별점 :</b> {rating}</p>
            <p><b>내용 :</b> {content}</p>
            <b>첨부 사진 :</b>
            <ul>
              {photoURLs.map((photo, index) => (
                <li key={index}>{photo}</li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      {/* 저장하기 버튼 */}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big" 
              disabled={rating === 0}
              onClick={saveReview}
            >
              저장하기
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyReviewWriteSection;
