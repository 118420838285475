// eslint-disable-next-line
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getProductList, toggleProductWishList, getWishlistItems } from '../../services/GQ_apis/product';
import useAuthStore from '../../store/common/useAuthStore';
import ThumbnailImage from '../../components/common/ThumbnailImage';
import blackImg from '../../assets/images/sample/black_img.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';


const SwipeGridSection = ({
  type='middle',
  option=false, 
  category='',
  collection='',
  meta='',
  infinity=false
}) => {


  const { globalObserver, observerUpdate } = useAuthStore();

  // 상품 데이터 호출 메소드 [START]
  const [gridLi, setGridLi] = useState([]);
  const [loading, setLoading] = useState(false); // 페이지 변수 삭제
  const [hasMore, setHasMore] = useState(true); // 'hasMore' 상태 관리를 변경 가능하도록 수정
  const [afterCursor, setAfterCursor] = useState(null); // 커서 기반 페이지네이션을 위한 상태 추가
  const observer = useRef();  // Intersection Observer를 위한 ref 생성
  const lastGridElementRef = useRef(null); // 마지막 그리드 요소를 참조하기 위한 ref
  const [wishList, setWishList] = useState([]);

  const fetchProducts = useCallback(() => {

    if ((loading || !hasMore)) return; // 로딩 중이거나 더 불러올 데이터가 없다면 early return 처리
    
    if(localStorage.getItem('user') !== null) {
      getWishlistItems().then((res)=>{
        setWishList(res.data.userWishlistItems);
      }).catch((e)=>{
        console.log(e);
      });
    }

    setLoading(true);
    getProductList(
      afterCursor, // Next 페이지 ID
      category, // 카테고리
      collection, // 콜렉션
      meta,
      100
    ).then((res) => { // getProductList 호출 시 현재 커서 전달
      const data = res.data.products.edges;
      if (gridLi.length === 0) setGridLi(data);
      else setGridLi(prev => [...prev, ...data]); // 기존 목록에 새로운 데이터 추가
      if(data[data.length-1]) setAfterCursor(data[data.length - 1].cursor); // 다음 페이지 로드를 위한 커서 업데이트
      setHasMore(res.data.products.pageInfo.hasNextPage); // 더 불러올 데이터가 있는지 업데이트
      setLoading(false);
      // console.log('data GET :', res);

      console.log('swipe GET :', res);

    });
  }, [afterCursor, hasMore, loading]);
  
  useEffect(() => {

    if(localStorage.getItem('user') !== null) {
      getWishlistItems().then((res)=>{
        setWishList(res.data.userWishlistItems);
      }).catch((e)=>{
        console.log(e);
      });
    }
    
  },[globalObserver])

  useEffect(() => {
    const currentObserver = observer.current;
    if (currentObserver) currentObserver.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          fetchProducts();
        }
      });

      if (lastGridElementRef.current) {
        observer.current.observe(lastGridElementRef.current);
      }

    return () => {
      if (currentObserver) currentObserver.disconnect();
    };

  }, [fetchProducts, loading, hasMore, infinity]);

  // 상품 데이터 호출 메소드 [END]

  const updateWishList = (productId) => {
    toggleProductWishList(productId).then((res)=>{ observerUpdate(); })
  }

  const isWishList = (productId) => {
    if (!wishList || wishList.length === 0) return false;
  
    return wishList.some(item => item.product.id === productId);
  }

  useEffect(()=>{
    setGridLi([]);
    setHasMore(true);
    setAfterCursor(null);
    if(infinity) fetchProducts();
  },[category, meta])

  if (gridLi.length === 0 && loading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }
  
  return (
    <>

      {/* 그리드(리스트) [START] */}
      <section className="section-grid">

        <Swiper
          // 스와이퍼 옵션
          modules={[Autoplay, FreeMode]}
          spaceBetween={8}
          slidesPerView='auto'
          loop={gridLi.length > 1 ? true : false}
          freeMode={true}
          className="card-tabs"
          nested={true} // 중첩 스와이퍼로 설정
          touchMoveStopPropagation={true} // 부모 스와이퍼에 이벤트 전파 중지
        >
          {gridLi.map((item, index) => (
            <SwiperSlide key={index} style={{ width: '110rem' }}>
              <div className='tab card small'>
                <div className="info-wrap">
                <div className="img-wrap">
                  <Link 
                    title="클릭시 해당 상품 페이지로 이동"
                    to={"/product/"+item.node.id}
                  > 
                    {
                      item.node.thumbnail ?
                      <ThumbnailImage 
                        thumbnailUrl={item.node.thumbnail.url} 
                        thumbnailAlt={item.node.thumbnail.alt} 
                      /> :
                      <img src={blackImg} alt='이미지 없음'/>
                    }
                  </Link>
                  <button 
                    type="button" 
                    className={`icon-heart white ${isWishList(item.node.id) ? 'active' : ''}`}
                    onClick={()=>{updateWishList(item.node.id)}}
                  >
                  </button>
                </div>
                  {item.node.vendor != null && 
                    <Link 
                      title="클릭시 해당 상품 페이지로 이동"
                      to={"/brand/detail/"+item.node.vendor.id}
                      className="brand"
                    >
                      {item.node.vendor.storeNameKo}
                    </Link>

                  }
                  <Link 
                    title="클릭시 해당 상품 페이지로 이동"
                    to={"/product/"+item.node.id}
                  >
                    <div className="title">
                      {item.node.name}
                    </div>
                    <div className="price-wrap">
                      {
                        item.node.pricing.priceRange.start.gross.amount !== item.node.pricing.priceRange.stop.gross.amount &&
                        (
                          <div className="before-price">
                            <span className="f-purple">쿠폰할인가</span>
                            <span className="price">
                              {/* {item.node.pricing.priceRange.stop.gross.currency}  */}
                              {item.node.pricing.priceRange.stop.gross.amount}
                            </span>
                          </div>
                        )
                      }
                      <div className="now-price">
                        {/* <span className="f-purple">{item.discount.discounted.raw}%</span> */}
                        <span className="price">
                          {/* {item.node.pricing.priceRange.stop.gross.currency}  */}
                          {window.WSformatPrice(item.node.pricing.priceRange.start.gross.amount)}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

      </section>
      {/* 그리드(리스트) [END] */}

      {/* 스크롤 타켓 [START] */}
      <div className="scroll-target" ref={lastGridElementRef}>
        {
          hasMore && loading &&
          <div className="loading-wrap">
            <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
          </div>
        }
      </div>
      {/* 스크롤 타켓 [END] */}

    </>
  );
};

export default SwipeGridSection;
