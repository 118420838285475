import {create} from 'zustand';

const useProductStore = create(set => ({

  nowProductData : {},
  productFilter: [],

  wishList : [],

  setNowProductData: (object) => {
    set(state => ({
      nowProductData: object
    }));
  },

  fetchProductFilter: (arr) => {
    set(state => ({
      productFilter: arr
    }));
  },

  fetchWishList: (arr) => {
    set(state => ({
      wishList: arr
    }));
  }
  
}));

export default useProductStore;
