export const storeTypeList = [
  { key: "", value: "선택" },
  { key: "Clothes", value: "의류" },
  { key: "Bag", value: "가방" },
  { key: "Shoes", value: "슈즈" },
  { key: "InnerWear", value: "이너웨이" },
  { key: "Jewelry", value: "주얼리" },
  { key: "FashionAccessory", value: "패션소품" },
];

export const timeData = [
  {
    type: "hour",
    value: [
      { key: "0m", value: "0개월" },
      { key: "1m", value: "1개월" },
      { key: "2m", value: "2개월" },
      { key: "3m", value: "3개월" },
      { key: "4m", value: "4개월" },
      { key: "5m", value: "5개월" },
      { key: "6m", value: "6개월" },
      { key: "7m", value: "7개월" },
      { key: "8m", value: "8개월" },
      { key: "9m", value: "9개월" },
      { key: "10m", value: "10개월" },
      { key: "11m", value: "11개월" },
      { key: "1y", value: "1년" },
      { key: "2y", value: "2년" },
      { key: "3y", value: "3년" },
      { key: "4y", value: "4년" },
      { key: "5y", value: "5년" },
      { key: "6y", value: "6년" },
      { key: "7y", value: "7년" },
      { key: "8y", value: "8년" },
      { key: "9y", value: "9년" },
      { key: "10y", value: "10년" },
      { key: "11y", value: "11년" },
      { key: "12y", value: "12년" },
      { key: "13y", value: "13년" },
      { key: "13y+", value: "13년 이상" }
    ],
  },
  {
    type: "time",
    value: [
      { key: "00", value: "0분" },
      { key: "05", value: "5분" },
      { key: "10", value: "10분" },
      { key: "15", value: "15분" },
      { key: "20", value: "20분" },
      { key: "25", value: "25분" },
      { key: "30", value: "30분" },
      { key: "35", value: "35분" },
      { key: "40", value: "40분" },
      { key: "45", value: "45분" },
      { key: "50", value: "50분" },
      { key: "55", value: "55분" },
    ],
  },
];

export const fashionCtgData = [
  {
    type: "top",
    value: [
      { key: "패션", value: "패션" },
    ]
  },
  {
    type: "middle",
    value: [
      { key: "패션", value: "임부복" },
      { key: "패션", value: "베이비" },
      { key: "패션", value: "키즈" },
      { key: "패션", value: "주니어" },
      { key: "패션", value: "신발/잡화" },
    ]
  },
  {
    type: "bottom",
    value: [
      { key: "임부복", value: "원피스" },
      { key: "임부복", value: "상의" },
      { key: "임부복", value: "하의" },
      { key: "임부복", value: "레깅스/스타킹" },
      { key: "임부복", value: "언더웨어" },
      { key: "임부복", value: "수유복" },
      { key: "임부복", value: "아우터" },
      { key: "임부복", value: "홈웨어/파자마" },
      { key: "임부복", value: "요가복" },
      { key: "임부복", value: "수영복" },
      { key: "임부복", value: "코디아이템" },

      { key: "베이비", value: "신생아" },
      { key: "베이비", value: "언더웨어" },
      { key: "베이비", value: "티셔츠" },
      { key: "베이비", value: "자켓/점퍼" },
      { key: "베이비", value: "상하복/세트상품" },
      { key: "베이비", value: "팬츠" },
      { key: "베이비", value: "레깅스/쫄바지" },
      { key: "베이비", value: "셔츠" },
      { key: "베이비", value: "블라우스" },
      { key: "베이비", value: "원피스" },
      { key: "베이비", value: "스커트" },
      { key: "베이비", value: "정장/드레스" },
      { key: "베이비", value: "래쉬가드" },

      { key: "키즈", value: "자켓/점퍼" },
      { key: "키즈", value: "코트" },
      { key: "키즈", value: "트레이닝/상하복" },
      { key: "키즈", value: "티셔츠" },
      { key: "키즈", value: "니트/가디건" },
      { key: "키즈", value: "셔츠" },
      { key: "키즈", value: "팬츠" },
      { key: "키즈", value: "블라우스" },
      { key: "키즈", value: "원피스" },
      { key: "키즈", value: "스커트" },
      { key: "키즈", value: "수영복/래쉬가드" },
      { key: "키즈", value: "레깅스/쫄바지" },
      { key: "키즈", value: "한복" },
      { key: "키즈", value: "보드/스키복" },

      { key: "주니어", value: "티셔츠" },
      { key: "주니어", value: "셔츠" },
      { key: "주니어", value: "니트/가디건" },
      { key: "주니어", value: "트레이닝/상하복" },
      { key: "주니어", value: "바지" },
      { key: "주니어", value: "레깅스/쫄바지" },
      { key: "주니어", value: "자켓/점퍼" },
      { key: "주니어", value: "코트" },
      { key: "주니어", value: "언더웨어/실내복" },
      { key: "주니어", value: "블라우스" },
      { key: "주니어", value: "원피스" },
      { key: "주니어", value: "스커트" },
      { key: "주니어", value: "스키/보드복" },
      { key: "주니어", value: "수영복/래쉬가드" },

      { key: "신발/잡화", value: "신발" },
      { key: "신발/잡화", value: "가방" },
      { key: "신발/잡화", value: "모자" },
      { key: "신발/잡화", value: "양말/타이즈" },
      { key: "신발/잡화", value: "우의/장화" },
      { key: "신발/잡화", value: "헤어ACC/소품" },
      { key: "신발/잡화", value: "목도리/장갑" },
      { key: "신발/잡화", value: "기타" },

    ]
  }
]
export const lifeCtgData = [
  {
    type: "top",
    value: [
      { key: "라이프", value: "라이프" },
    ]
  },
  {
    type: "middle",
    value: [
      { key: "라이프", value: "DIY" },
      { key: "라이프", value: "기저귀/물티슈" },
      { key: "라이프", value: "유아식/분유/유기농과일" },
      { key: "라이프", value: "출산/육아용품" },
      { key: "라이프", value: "장난감/교구/도서" },
      { key: "라이프", value: "뷰티/헬스" },
      { key: "라이프", value: "홈/리빙" },
      { key: "라이프", value: "뷰티/헬스" },
      { key: "라이프", value: "문화/여행/체험" },
      { key: "라이프", value: "금융" },
      { key: "라이프", value: "케어" },
      { key: "라이프", value: "교육" },
      { key: "라이프", value: "컨텐츠/게임" },
    ]
  },
  {
    type: "bottom",
    value: [
      { key: "DIY", value: "출산DIY" },
      { key: "DIY", value: "가구" },
      { key: "DIY", value: "홈파티" },

      { key: "기저귀/물티슈", value: "기저귀" },
      { key: "기저귀/물티슈", value: "물티슈" },
      { key: "기저귀/물티슈", value: "여성용품" },

      { key: "유아식/분유/유기농과일", value: "유아식" },
      { key: "유아식/분유/유기농과일", value: "분유" },
      { key: "유아식/분유/유기농과일", value: "유아동건강식품" },


      { key: "출산/육아용품", value: "수유/이유용품" },
      { key: "출산/육아용품", value: "유아세제/위생" },
      { key: "출산/육아용품", value: "유아가구/침구" },
      { key: "출산/육아용품", value: "안전용품" },
      { key: "출산/육아용품", value: "출산준비/선물" },
      { key: "출산/육아용품", value: "스킨케어/목욕" },
      { key: "출산/육아용품", value: "외출용품" },

      { key: "장난감/교구/도서", value: "작동완구" },
      { key: "장난감/교구/도서", value: "블록/게임완구" },
      { key: "장난감/교구/도서", value: "인형" },
      { key: "장난감/교구/도서", value: "스포츠/시즌완구" },
      { key: "장난감/교구/도서", value: "승용완구" },
      { key: "장난감/교구/도서", value: "대형완구" },
      { key: "장난감/교구/도서", value: "교육완구" },
      { key: "장난감/교구/도서", value: "신생아/영유아완구" },
      { key: "장난감/교구/도서", value: "테마별단행본" },
      { key: "장난감/교구/도서", value: "테마별전집" },
      { key: "장난감/교구/도서", value: "연령별단행본" },
      { key: "장난감/교구/도서", value: "연령별전집" },
      { key: "장난감/교구/도서", value: "인기출판사" },
      { key: "장난감/교구/도서", value: "CD/DVD" },


      { key: "뷰티/헬스", value: "출산관리" },
      { key: "뷰티/헬스", value: "다이어트" },
      { key: "뷰티/헬스", value: "헬스/요가" },
      { key: "뷰티/헬스", value: "스킨케어" },
      { key: "뷰티/헬스", value: "위생" },

      { key: "홈/리빙", value: "침구류" },
      { key: "홈/리빙", value: "아이방꾸미기" },
      { key: "홈/리빙", value: "가구" },
      { key: "홈/리빙", value: "수납/정리" },
      { key: "홈/리빙", value: "생활가전" },
      { key: "홈/리빙", value: "문구/팬시" },
      { key: "홈/리빙", value: "여행/소품/가방" },
      { key: "홈/리빙", value: "캠핑/낚시" },
      { key: "홈/리빙", value: "기타" },


      { key: "문화/여행/체험", value: "파티(베이비샤워/백일/돌/생일)" },
      { key: "문화/여행/체험", value: "키즈카페" },
      { key: "문화/여행/체험", value: "체험교실" },
      { key: "문화/여행/체험", value: "놀이동산" },
      { key: "문화/여행/체험", value: "문화" },
      { key: "문화/여행/체험", value: "여행" },

      { key: "금융", value: "저축" },
      { key: "금융", value: "보험" },
      { key: "금융", value: "증권" },

      { key: "케어", value: "산후조리원" },
      { key: "케어", value: "돌봄서비스" },
      { key: "케어", value: "발달케어" },
      { key: "케어", value: "맛사지" },

      { key: "교육", value: "학원" },
      { key: "교육", value: "학습지" },
      { key: "교육", value: "개인과외" },
      { key: "교육", value: "온라인강의" },
      { key: "교육", value: "화상프리토킹" },

      { key: "컨텐츠/게임", value: "컨텐츠" },
      { key: "컨텐츠/게임", value: "게임" },

    ]
  }
]