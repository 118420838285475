import React from "react";
import "../assets/styles/scss/structure/articles/_front-guide.scss";
import sampleImage from "../assets/images/sample/banner/하늘이_해외직구.jpeg";
// import useAuthStore from '../store/common/useAuthStore';
import { useNavigate } from "react-router-dom";

function FrontGuidePage() {

  const navigate = useNavigate();

  // const {login} = useAuthStore((state)=> ({
  //   login : state.login
  // }));

  return (
    <article id="frontGuidePage">

      <section id="List">
        <div className="front-guide-title">
          1. Pages
        </div>
        <ul className="front-list">
          <li>
            <p className="depth1">
              Main
            </p>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/" target="_blank">
              1-1-1. Main - HOME (로그인 전)
            </a>
            <a 
              className="depth2" 
              title="클릭시 해당 페이지로 이동" 
              onClick={() => {
                // login({email: 'zaezae@naver.com'}); // 임시 로그인 처리
                navigate('/');
              }}     
            >
              1-1-2. Main - HOME (로그인 후)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/hot" target="_blank">
              1-2. Main - HOT
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/new" target="_blank">
              1-3. Main - NEW
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/brand" target="_blank">
              1-4. 브랜드 (메인)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/brand/detail" target="_blank">
              1-5. 브랜드 (상세)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/overseas" target="_blank">
              1-6. 해외직구
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/exhibitions" target="_blank">
              1-7. 기획전
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/seasonoff" target="_blank">
              1-8. 시즌오프
            </a>
            <p className="depth1">
              Sub / Etc
            </p>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/product/1" target="_blank">
              2-1. 상품상세
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/category" target="_blank">
              2-2. 카테고리
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/promotion" target="_blank">
              2-3. 프로모션
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/profile" target="_blank">
              2-4. 마이메뉴
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/register/2" target="_blank">
              2-6. 팝업 (자세히 버튼 클릭)
            </a>
            <p className="depth1">
              로그인
            </p>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/login/main1" target="_blank">
              3-1. Login (메인01)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/login/main2" target="_blank">
              3-2. Login (메인02)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/login/findid" target="_blank">
              3-3. Login (아이디찾기01, 아이디찾기02)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/login/findpw" target="_blank">
              3-4. Login (비밀번호찾기01, 비밀번호찾기02)
            </a>
            <p className="depth1">
              회원가입
            </p>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/register/1" target="_blank">
              4-1. Register (회원가입 1)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/register/2" target="_blank">
              4-2. Register (회원가입 2)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/register/kid-1" target="_blank">
              4-3. Register (아이등록 - 아이 프로필 입력)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/register/kid-2" target="_blank">
              4-4. Register (아이등록 - 선호 브랜드)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/register/kid-3" target="_blank">
              4-5. Register (아이등록 - 선호 색상)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/register/kid-4" target="_blank">
              4-6. Register (아이등록 - 선호 아이템)
            </a>
            <p className="depth1">
              마이메뉴
            </p>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/profile" target="_blank">
              5-1. Mymenu (마이메뉴 - 메인/프로필)
            </a>
            <a className="depth2 disabled" title="클릭시 해당 페이지로 이동" href="/mymenu/mykids" target="_blank">
              5-2. Mymenu (마이메뉴 - 등록된 아이 정보)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/mykid-profile" target="_blank">
              5-3. Mymenu (마이메뉴 - 아이 프로필 입력)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/profile-modify" target="_blank">
              5-4. Mymenu (마이메뉴 - 회원정보수정)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/coupons/1" target="_blank">
              5-5. Mymenu (마이메뉴 - 쿠폰)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/myreviews" target="_blank">
              5-6. Mymenu (마이메뉴 - 내 후기)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/myreview-detail" target="_blank">
              5-7. Mymenu (마이메뉴 - 리뷰 상세)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/order-list" target="_blank">
              5-8. Mymenu (마이메뉴 - 주문목록)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/request-cancel1" target="_blank">
              5-9. Mymenu (마이메뉴 - 취소요청 1)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/request-cancel2" target="_blank">
              5-10. Mymenu (마이메뉴 - 취소요청 2)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/myreview-write" target="_blank">
              5-11. Mymenu (마이메뉴 - 리뷰작성)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/request-exchange" target="_blank">
              5-12. Mymenu (마이메뉴 - 교환요청)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/exchange-list" target="_blank">
              5-13. Mymenu (마이메뉴 - 교환목록)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/exchange-detail" target="_blank">
              5-14. Mymenu (마이메뉴 - 교환 신청 내역 확인)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/return-list" target="_blank">
              5-15. Mymenu (마이메뉴 - 반품상세보기)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/return-detail" target="_blank">
              5-16. Mymenu (마이메뉴 - 반품 신청 내역 확인)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/cancel-list" target="_blank">
              5-17. Mymenu (마이메뉴 - 취소목록)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/cancel-detail" target="_blank">
              5-18. Mymenu (마이메뉴 - 취소 신청 내역 확인)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/mykid-detail" target="_blank">
              5-19. Mymenu (마이메뉴 - 내 아이 자세히 보기)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/mymenu/reward" target="_blank">
              5-20. Mymenu (마이메뉴 - 적립금)
            </a>
            <p className="depth1">
              결제
            </p>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/pay/shopping" target="_blank">
              6-1. Pay (장바구니 목록)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/pay/process" target="_blank">
              6-2. Pay (결제 과정)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/pay/result" target="_blank">
              6-3. Pay (결제 결과)
            </a>
            <p className="depth1">
              배송지관리
            </p>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/delivery/list" target="_blank">
              7-1. Delivery (배송지관리-목록)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/delivery/domestic" target="_blank">
              7-2. Delivery (배송지관리-국내배송)
            </a>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/delivery/overseas" target="_blank">
              7-3. Delivery (배송지관리-해외배송)
            </a>
            <p className="depth1">
              재룩
            </p>
            <a className="depth2" title="클릭시 해당 페이지로 이동" href="/zaelook/1" target="_blank">
              8-1. 재룩
            </a>
          </li>
        </ul>
      </section>
      <section id="elements">

        <div className="front-guide-title">
          2. Elements
        </div>

        <button type="button" className="btn big purple">
          로그인
        </button>

        <br/>

        <button type="button" disabled className="btn big purple">
          로그인
        </button>

        <br/>

        <button type="button" className="btn big round purple">
          로그인
        </button>

        <br/>

        <button type="button" className="btn big round more">
          상품정보 더 보기
        </button>

        <br/>

        <button type="button" className="btn purple">
          인증번호받기
        </button>

        <br/>
        
        <button type="button" disabled className="btn purple">
          인증번호받기
        </button>

        <br/>

        <button type="button" className="btn light-purple">
          쿠폰받기
        </button>

        <br/>

        <div className="btn-wrap">
          <div className="title">
            혜택 정보
          </div>
          <div className="btns">
            <button type="button" className="btn gray">
              로그인
            </button>
            <button type="button" className="btn gray">
              회원가입
            </button>
          </div>
        </div>

        <br/>

        <button type="button" className="btn big white round">
          <span className="icon-coupon mr6"></span>쿠폰받기
        </button>

        <br/>

        <span className="badge red">
          한정판매
        </span>

        <br/><br/>
        
        <span className="badge blue">
          무료배송
        </span>

        <br/><br/>
        
        <span className="badge purple">
          무료배송
        </span>

        <br/><br/>

        <div className="stars flex">
          <a href="#none" title="1점" className="icon-star-fill"></a>
          <a href="#none" title="2점" className="icon-star-fill"></a>
          <a href="#none" title="3점" className="icon-star-fill"></a>
          <a href="#none" title="4점" className="icon-star-fill"></a>
          <a href="#none" title="5점" className="icon-star-empty"></a>
        </div>
        <br/>
        <div className="stars gap4">
          <a href="#none" title="1점" className="icon-star-fill"></a>
          <a href="#none" title="2점" className="icon-star-fill"></a>
          <a href="#none" title="3점" className="icon-star-fill"></a>
          <a href="#none" title="4점" className="icon-star-fill"></a>
          <a href="#none" title="5점" className="icon-star-empty"></a>
        </div>
        <br/>
        <div className="stars big gap4">
          <a href="#none" title="1점" className="icon-star-fill"></a>
          <a href="#none" title="2점" className="icon-star-fill"></a>
          <a href="#none" title="3점" className="icon-star-fill"></a>
          <a href="#none" title="4점" className="icon-star-fill"></a>
          <a href="#none" title="5점" className="icon-star-empty big"></a>
        </div>

      </section>
      <section id="input">

        <div className="front-guide-title">
          3. Input
        </div>

        <div className="input-box">
          <div className="input">          
            <label htmlFor="inputId" className="hide">기본타입</label>
            <input id="inputId" type="text" placeholder="기본 타입"></input>
          </div>
        </div>
        <div className="input-box">
          <label htmlFor="inputId" className="title">타이틀</label>
          <div className="input">          
            <input id="inputId" type="email" placeholder="이메일 타입"></input>
          </div>        
        </div>
        <div className="input-box">
          <label htmlFor="inputId1" className="title">인풋박스 두개 이상</label>
          <div className="input">
            <input id="inputId1" type="text" placeholder="기본 타입"></input>
          </div>
          <div className="input">     
            <label htmlFor="inputId2" className="hide">인풋박스 두개 이상</label>
            <input id="inputId2" type="text" placeholder="기본 타입"></input>
          </div>
        </div>
        <div className="input-box">
          <label htmlFor="inputId" className="title starred">별표가 붙은 타이틀</label>
          <div className="input">          
            <input id="inputId" type="number" placeholder="번호 타입"></input>
          </div>
        </div>
        <div className="input-box">
          <label htmlFor="inputId" className="title starred">생년월일</label>
          <div className="input">          
            <input id="inputId" type="date" placeholder="날짜 타입"></input>
          </div>
        </div>
        <div className="input-box">
          <div className="input">          
            <label htmlFor="inputId" className="hide">버튼타입</label>
            <input id="inputId" type="text" placeholder="버튼 타입"></input>
            <button type="button" className="btn purple">인증번호받기</button>
          </div>
        </div>
        <div className="input-box">
          <div className="input">
            <label htmlFor="inputId" className="hide">버튼타입</label>
            <input 
              id="inputId" 
              type="password" 
              placeholder="비밀번호 타입"
              className="private"
            ></input>
            <button type="button" className="btn icon-private" onClick={(e)=>window.WStoggleInputPrivate(e)}></button>
          </div>
          <span className="caption f-purple">숫자, 영문, 특수문자 조합 최소 6자 이상</span>
        </div>
        <div className="input-box textarea">
          <label htmlFor="textarea" className="title">
            내용
          </label>
          <textarea id="textarea" placeholder="내용을 입력해주세요."></textarea>
        </div>
        <div className="input-box">
          <div className="input">
            <select>
              <option>0</option>
              <option>1</option>
            </select>
          </div>
        </div>
        <div className="input-box">
          <div className="input">
            <select className="purple">
              <option>0</option>
              <option>1</option>
            </select>
          </div>
        </div>
        <div className="input-box">
          <div className="input">
            <input type="text" placeholder="성별"></input>
            <span className="inner-text">cm</span>
          </div>
        </div>
        <div className="input-box-wrap">
          <div className="input-box">
            <div className="title">타이틀1</div>
            <div className="input">
              <select>
                <option value="0">0</option>
                <option value="1">1</option>
              </select>
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="inputId" className="title">타이틀2</label>
            <div className="input-box">
              <div className="input">
                <input id="inputId" type="text" placeholder="성별"></input>
                <span className="inner-text">cm</span>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <input type="checkbox"></input>
        <br/><br/>
        <input className="bg" type="checkbox"></input>
        <br/><br/>
        <input type="radio" name="radio"></input>
        <br/>
        <input type="radio" name="radio"></input>
        <br/><br/>
        <div className="input-box">
          <div className="input check bg check-all mb14">
            <label htmlFor="check0">
              <input id="check0" type="checkbox"></input>  약관 전체 동의하기
            </label>
          </div>
          <ul className="input-list">
            <li className="input check">
              <label htmlFor="check1"><input id="check1" type="checkbox"></input> (필수) 만 14세 이상입니다.</label>
              <a href="#none" className="under-line" title="">자세히</a>
            </li>
            <li className="input check">
              <label htmlFor="check2"><input id="check2" type="checkbox"></input> (필수) 서비스 이용약관에 동의</label>
              <a href="#none" className="under-line" title="">자세히</a>
            </li>
          </ul>
        </div>
        <br/><br/>
        <div className="input-box">
          <ul className="input-list">
            <li className="input radio">
              <label htmlFor="radio1"><input id="radio1" name="radio" type="radio"></input> (필수) 만 14세 이상입니다.</label>
            </li>
            <li className="input radio">
              <label htmlFor="radio2"><input id="radio2" name="radio" type="radio"></input> (필수) 서비스 이용약관에 동의</label>
            </li>
            <li className="input radio etc">
              <label htmlFor="radio3">
                <input id="radio3" name="radio" type="radio"></input> 기타 사유
              </label>
              <textarea disabled placeholder="상세 사유를 입력하세요"></textarea>
            </li>
          </ul>
        </div>
        <br/><br/>
        <div className="input-box">
          <div className="input file-upload">
            <button type="button" className="btn purple update-btn">파일 업로드</button>
            <input type="file" className="hide"/>
            <div className="file-name-display">
              업로드할 파일을 선택해주세요.
            </div>
          </div>
        </div>
      </section>
      <section id="cards">
        
        <div className="front-guide-title">
          3. Card
        </div>

        <div className="grid-wrap">

          {/* 카드 small [START] */}
          <div className="grid grid3">
            <div className="card small">
              <a 
                href="#none" 
                title=""
                // onClick={() => navigate('/product/1')}
              >
                <div className="img-wrap">
                  <img src={sampleImage} alt="설명" />
                </div>
                <div className="info-wrap">
                  <div className="brand">
                    Brand
                  </div>
                  <div className="title">
                    타이틀 타이틀 타이틀 타이틀타이틀 타이틀 타이틀 타이틀타이틀 타이틀 타이틀 타이틀
                  </div>
                  <div className="price-wrap">
                    <div className="before-price">
                      <span className="f-purple">쿠폰할인가</span>
                      <span className="price">￦37,900</span>
                    </div>
                    <div className="now-price">
                      <span className="f-purple">0%</span>
                      <span className="price">￦27,900</span>
                    </div>
                  </div>
                  <div className="badge-wrap">
                    <span className="badge red">
                      한정판매
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </div>
          {/* 카드 small [END] */}

          <br/>

          {/* 카드 middle [START] */}
          <div className="grid grid1">
            <div className="card middle">
              <a 
                href="#none" 
                title=""
                // onClick={() => navigate('/product/1')}
              >
                <div className="img-wrap">
                  <img src={sampleImage} alt="설명" />
                </div>
                <div className="info-wrap">
                  <div className="brand">
                    Brand
                  </div>
                  <div className="title">
                    타이틀 타이틀 타이틀 타이틀타이틀 타이틀 타이틀 타이틀타이틀 타이틀 타이틀 타이틀
                  </div>
                  <div className="price-wrap">
                    <div className="before-price">
                      <span className="f-purple">쿠폰할인가</span>
                      <span className="price">￦37,900</span>
                    </div>
                    <div className="now-price">
                      <span className="f-purple">0%</span>
                      <span className="price">￦27,900</span>
                    </div>
                  </div>
                  <div className="badge-wrap">
                    <span className="badge red">
                      한정판매
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </div>
          {/* 카드 middle [END] */}

          <br/>

          {/* 카드 big [START] */}
          <div className="grid grid2">
            <div className="card big">
              <a 
                href="#none" 
                title=""
                // onClick={() => navigate('/product/1')}
              >
                <div className="img-wrap">
                  <img src={sampleImage} alt="설명" />
                </div>
                <div className="info-wrap">
                  <div className="brand">
                    Brand
                  </div>
                  <div className="title">
                    타이틀 타이틀 타이틀 타이틀타이틀 타이틀 타이틀 타이틀타이틀 타이틀 타이틀 타이틀
                  </div>
                  <div className="price-wrap">
                    <div className="before-price">
                      <span className="f-purple">쿠폰할인가</span>
                      <span className="price">￦37,900</span>
                    </div>
                    <div className="now-price">
                      <span className="f-purple">0%</span>
                      <span className="price">￦27,900</span>
                    </div>
                  </div>
                  <div className="badge-wrap">
                    <span className="badge red">
                      한정판매
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </div>
          {/* 카드 big [END] */}

          <br/>

          {/* 카드 brand [START] */}
          <div className="grid grid2">
            <div className="card brand">
              <a 
                href="#none" 
                title=""
                // onClick={() => navigate('/product/1')}
              >
                <div className="img-wrap">
                  <img src={sampleImage} alt="설명" />
                </div>
                <div className="info-wrap">
                  <div className="brand">
                    Brand
                  </div>
                  <div className="title">
                    타이틀 타이틀 타이틀 타이틀타이틀 타이틀 타이틀 타이틀타이틀 타이틀 타이틀 타이틀
                  </div>
                  <div className="price-wrap">
                    <div className="now-price">
                      <span className="price">￦27,900</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          {/* 카드 big [END] */}
        </div>

      </section>
      
      <section>

              

      </section>

      {/* <section id="topBars">
        
        <div className="front-guide-title">
          4. Top Bars
        </div>

        <div className="top-bar-inner">
          <a href="/" title="zaezae 메인으로 이동">
            <h1 id="logo">
              <img src={LogoImage} alt="zaezae" />
            </h1>
          </a>
          <div className="actions">
            <button className="icon-search" type="button"></button>
            <button className="icon-b2b" type="button"></button>
            <button className="icon-language" type="button"></button>
            <button className="icon-notification active" type="button"></button>
            <button className="icon-shoppingbag" type="button">
              <span className="num">2</span>
            </button>
          </div>
        </div>

        <br></br>
        
        <div className="top-bar-inner">
          <a href="/" title="클릭시 이전 페이지로" className="title">
            스타일노리터
          </a>
          <div className="actions">
            <button className="icon-search" type="button"></button>
            <button className="icon-shoppingbag" type="button">
              <span className="num">2</span>
            </button>
          </div>
        </div>

        <br></br>

        <div className="top-bar-inner">
          <a href="/" title="클릭시 이전 페이지로" className="title">
            비밀번호찾기
          </a>
        </div>

        <br></br>

        <div className="top-bar-inner">
          <div className="title">
            비밀번호찾기
          </div>
        </div>

      </section>
      <section id="bottomBars">
        
        <div className="front-guide-title">
          5. Bottom Bars
        </div>
        <div className="bottom-bar-inner">
          <div className="tabs">
            <button
              className={`tab home active`}
              type="button"
            >
              홈
            </button>
            <button
              className={`tab category`}
              type="button"
            >
              카테고리
            </button>
            <button
              className={`tab promotion`}
              type="button"
            >
              프로모션
            </button>
            <button
              className={`tab mymenu`}
              type="button"
            >
              마이메뉴
            </button>
          </div>
        </div>
        
        <br></br>

        <div className="bottom-bar-inner">
          <div className="btn-wrap">
            <button type="button" className="btn purple big">
              저장하기
            </button>
          </div>
        </div>

        <br></br>

        <div className="bottom-bar-inner">
          <div className="btn-wrap">
            <button type="button" className="heart-btn">
              찜하기
              <span className="icon-gray-heart"></span>
            </button>
            <button type="button" className="btn purple big round">
              구매하기
            </button>
          </div>
        </div>


      </section> */}
    </article>
    
  );
}

export default FrontGuidePage;
