import React from 'react';
import { useNavigate } from 'react-router-dom';
import usePopupStore from '../../../../store/common/usePopupStore';

import { orderRequestCancel } from '../../../../services/GQ_apis/order';

const PopupOrderCancel = ({closePopup, id}) => {

  const handleOrderCancel = () => {
    orderRequestCancel(id).then((res) => {
      console.log(res);
      closePopup();
      openSnackBarPopup('주문 취소요청을 보냈습니다.', '', '');
    });
  };

  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  return (
    <>
      <div className="popup-body">
        <div className="h3-title-wrap">
          <h3 className="title">
            주문 취소요청
          </h3>
          <p className="mt16 f16">
            정말로 주문을 취소하시겠습니까?
          </p>
        </div>
      </div>
      <div className="popup-footer">
        <div className="btn-wrap">
          <button type="button" className="btn gray" onClick={closePopup}>아니요</button>
          <button type="button" className="btn purple" onClick={handleOrderCancel}>예</button>
        </div>
      </div>
    </>
  );
};

export default PopupOrderCancel;
