import React from 'react';

const PopupRegisterDetail17 = ({closePopup}) => {
  return (
    <>
    <div className="popup-body">
      <div className="h3-title-wrap">
        <h3 className="title">
          판매수수료/배송비 정책 동의
        </h3>
        <div className="gray-box mt16">
          <ul className="text-type-dot">
            <li>판매국가는 입점 신청 시 선택 가능하며, 입점 된 이후에도 언제든 확장이 가능합니다.</li>
            <li>주식회사 Zaezae에서 운영하는 서비스를 통해 상품 판매 시, 고객의 총 결제금액(배송비 포함)에 대한 수수료가 부과 됩니다. (부가세 별도)</li>
            <li>귀사의 이용 수수료는 아래와 같습니다.</li>
            <li>주식회사 Zaezae는 내부 정책에 따라 프로모션을 진행할 수 있습니다. 이 경우 파트너센터를 통해 고객에게 해당 프로모션 내용을 별도로 고지하며, 고지된 내용에 따라 할인된 수수료율을 적용 및 부과 할 수 있습니다.</li>
          </ul>
        </div>
        <div className="table-wrap mt20">
          <table>
            <thead>
              <tr>
                <th scope="col" colSpan="3">
                  카테고리
                </th>
                <th scope="col">
                  국내
                </th>
                <th scope="col">
                  해외
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">판매수수료</th>
                <td>패션</td>
                <td>브랜드</td>
                <td>27%</td>
                <td>27%</td>
              </tr>
              <tr>
                <th scope="row">배송비</th>
                <td colSpan="4">판매자 부담</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div className="popup-footer">
      <div className="btn-wrap">
        <button type="button" className="btn purple" onClick={closePopup}>완료</button>
      </div>
    </div>
  </>
  );
};

export default PopupRegisterDetail17;
