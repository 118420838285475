import React from "react";
import GridSection from '../../sections/grid/GridSection';

import TopBar from '../../layouts/top_bar/TopNav';
import BottomNav from '../../layouts/bottom_bar/BottomNav';
import HeroBannerSection from '../../sections/banner/HeroBannerSection';

function PromotionPage() {
  return (
    <>
      <TopBar/>
      <article id="promotionPage">

        {/* 히어로배너 섹션[START] */}
        <HeroBannerSection />
        {/* 히어로배너 섹션[END] */}

        {/* BIG 리스트 [START] */}
        <GridSection type="big" option={true} />
        {/* BIG 리스트 [END] */}

      </article>
      <BottomNav />
    </>
  )
}

export default PromotionPage;
