import React from "react";
import { useParams } from 'react-router-dom';

import TopBar from '../../layouts/top_bar/TitleBar';
import PayShoppingSection from '../../sections/pay/PayShoppingSection'; 
import PayProcessSection from '../../sections/pay/PayProcessSection'; 
import PayResultSection from '../../sections/pay/PayResultSection'; 

function PayPage() {

  const { Page2depth } = useParams();

  const getTitle = (Page2depth) => {
    switch (Page2depth) {
      case 'shopping' :
        return '주문 목록'
      default:
        return '결제'
    }
  };

  return (
    <>
        <TopBar  
          title={getTitle(Page2depth)}
          url='/'
        />

      <article id="payPage">
        {Page2depth === 'shopping' && <PayShoppingSection/>}
        {Page2depth === 'process' && <PayProcessSection/>}
        {Page2depth === 'result' && <PayResultSection/>}
      </article>
    </>
  )
}

export default PayPage;
