import React from 'react';
import LogoImage from "../../assets/images/common/logo.svg";
import { useNavigate, Link } from 'react-router-dom';

const BizPage = () => {
  return (
    <article id="BizPage" className="biz-page">
      <section className="logo-section">
        <Link to="/" title="zaezae 메인으로 이동">
          <div className="logo mt102 mb70">
            <img src={LogoImage} alt="zaezae" />
          </div>
        </Link>
      </section>

      <section className="welcome-section mb6">
        <h3>Welcome!</h3>
      </section>

      <section className="info-section">
        <h2 className='biz-title mb24'>BIZ</h2>
      </section>

      <section className="button-section">
        <Link to="/globalbiz" title="globalbiz 페이지로 이동">
          <button className="global-button bg-white mr20" aria-label="Global">
            GLOBAL
          </button>
        </Link>
        <Link to="/globalbiz" title="globalbiz 페이지로 이동">
          <button className="korea-button bg-white" aria-label="Korea">
            KOREA
          </button>
        </Link>
      </section>
    </article>
  );
};

export default BizPage;
