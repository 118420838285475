import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { addAddress, updateAddress, setDefaultAddress, fetchAddress } from '../../services/GQ_apis/address';
import DaumAddress from "../../components/api/DaumAddress";
const DeliveryDomesticSection = () => {

  const navigate = useNavigate();
  const { Page3depth } = useParams();

  useEffect(()=>{

    if(! Page3depth) return;

    fetchAddress(Page3depth).then((res)=>{
      var address = res.data.address;
      console.log(res.data.address);
      setAddressName(address.metadata[0].value);
      setFirstName(address.firstName);
      setPhoneNumber(window.WSformatPhoneNumber(address.phone));
      setPostalCode(address.postalCode);
      setStreetAddress1(address.streetAddress1);
      setStreetAddress2(address.streetAddress2);
      setCustomsCode(address.metadata[1].value);
      setCity(address.city);
      setCountryArea(address.countryArea);
    })
  },[])

  // 주소 데이터를 받아 상태를 업데이트하는 함수
  const handleSelectAddress = (data) => {
    console.log("Selected Address:", data);

    setPostalCode(data.zonecode); // 우편번호
    setCountryArea(data.sido); // 주소 - 구
    setCity(data.sigungu); // 도시
    setStreetAddress1(data.address); // 주소 1
    setIsDefault(data.isDefaultShippingAddress);
  };

  const [addressName, setAddressName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [countryArea, setCountryArea] = useState('');
  const [city, setCity] = useState('');
  const [customsCode, setCustomsCode] = useState('');  
  const [isDefault, setIsDefault] = useState(false);
  const [country] = useState('KR');
  const [streetAddress1, setStreetAddress1] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');

  const saveAddress = () => {

    let input = {
      "firstName": firstName,
      "phone": phone,
      "postalCode": postalCode,
      "countryArea": countryArea,
      "city": city,
      "country": country,
      "streetAddress1":streetAddress1,
      "streetAddress2":streetAddress2,
      "metadata": [
        {"key": "addressName", "value": addressName},
        {"key": "customsCode", "value": customsCode}
      ]
    }

    // Page3depth가 없을 경우, 배송지 생성
    if(! Page3depth) {

      addAddress(input).then((res) => {

        console.log(res);
      
        if (res.data.accountAddressCreate.errors.length > 0) {
          
          alert("잘못된 주소입니다.");

        } else {
  
          window.location.href = '/delivery/list';

        }
      
        if (!isDefault) return;
      
        setDefaultAddress(res.data.accountAddressCreate.address.id, 'SHIPPING');
        setDefaultAddress(res.data.accountAddressCreate.address.id, 'BILLING');
      });

    } 
    // Page3depth가 있을 경우, 배송지 업데이트
    else {
      
      updateAddress(Page3depth, input).then((res)=>{
        console.log(res);
        window.location.href = '/delivery/list';
  
        if(! isDefault) return;
        
        setDefaultAddress(Page3depth,'SHIPPING');
        setDefaultAddress(Page3depth,'BILLING');
      })

    }


  }

  return (

    <>
      <section className="section-delivery-domestic">
        <div className="inner">
          <button 
            type="button" 
            className="btn purple delivery"
            onClick={()=>navigate('/delivery/overseas')}
          >
            <span className="icon-change"></span>
            해외 배송지 입력
          </button>
          <div className="input-box">
            <label htmlFor="inputAddressName" className="title starred">배송지명</label>
            <div className="input">          
              <input 
                id="inputAddressName" 
                className="space"
                type="text" 
                placeholder="배송지명"
                onInput={(e) => setAddressName(e.target.value)}
                value={addressName}
              >
              </input>
            </div>
          </div>
          <div className="input-box">
            <label 
              htmlFor="inputName" 
              className="title starred"
            >
              받는사람
            </label>
            <div className="input">          
              <input 
                id="inputName" 
                type="text" 
                placeholder="받는사람"
                onInput={(e) => setFirstName(e.target.value)}
                value={firstName}
              >
              </input>
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="inputPhone" className="title starred">연락처</label>
            <div className="input">          
              <input 
                id="inputPhone" 
                type="number"
                placeholder="연락처"
                onInput={(e) => setPhoneNumber(e.target.value)}
                value={phone}
              >
              </input>
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="inputPostalCode" className="title starred">주소</label>
            <div className="input">          
              <input 
                id="inputPostalCode" 
                type="text" 
                placeholder="우편번호"
                readOnly
                value={postalCode}
              >
              </input>
              <DaumAddress onSelectAddress={handleSelectAddress} />
            </div>
            <div className="input">
              <label htmlFor="countryArea" className="title starred hide">주소</label>
              <input 
                id="countryArea" 
                type="text" 
                placeholder="주소"
                readOnly
                value={streetAddress1}
              >
              </input>
            </div>
            <div className="input">          
              <label htmlFor="city" className="title starred hide">주소</label>
              <input 
                id="city"
                className="space"
                type="text" 
                placeholder="상세 주소"
                onInput={(e) => setStreetAddress2(e.target.value)}
                value={streetAddress2}
              ></input>
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="customsCode" className="title starred">개인통관부호</label>
            <div className="input">          
              <input 
                id="customsCode" 
                type="number" 
                placeholder="개인통관부호"
                onInput={(e) => setCustomsCode(e.target.value)}
                value={customsCode}
              ></input>
            </div>
            <p className="caption f-purple mt4 mb4">개인통관부호가 없으신 분은 관세청에서 통관부호를 발급받으세요</p>
            <p className="caption f-purple">https://unipass.customs.go.kr/csp/index.do</p>
          </div>
          <div className="input-box">
            <div className="input check bg">
              <label htmlFor="check0">
                <input 
                  id="check0" 
                  type="checkbox"
                  onChange={(e) => setIsDefault(e.target.checked)}
                  checked={isDefault}
                ></input>  해당 주소를 기본 배송지로 등록
              </label>
            </div>
          </div>
        </div>
      </section>
      
      {/* 저장하기 [START]*/}
      <div className="bottom-bar">
        <div className="inner">
          <button 
            type="button"
            className="btn big purple"
            onClick={()=>saveAddress()}
          >
            저장하기
          </button>
        </div>
      </div>
      {/* 저장하기 [END]*/}
    </>
  );
};

export default DeliveryDomesticSection;
