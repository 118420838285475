import React, { useEffect, useState } from 'react';
import useInputStore from '../../store/common/useInputStore';
import { useNavigate } from "react-router-dom";
import api from "../../services/api";

import smapleImg from "../../assets/images/common/icons/icon_profile.svg";

const VendorRegister10Section = () => {
  const navigate = useNavigate();

  useEffect(() => {
    fetchEntryList();
  }, []);

  const [entryList, setEntryList] = useState([]);
  const fetchEntryList = async () => {
    try {
      const res = await api.get('/api-tg/vendor/entry-list');
      console.log(res.data);
      setEntryList(res.data.data);
    } catch (err) {
      // setError(err);
      // setLoading(false);
    }
  };

  const approveEntry = (uid) => {
    api.post('/api-tg/vendor/approve-entry', {
      uuid: uid
    }).then(res => {
      alert('승인 완료되었습니다.');
      fetchEntryList();
    }).catch(e => {
      console.log(e);
      alert('오류가 발생했습니다.\n다시 시도해주세요.');
    });
  };

  const styles = {
    entryItem: {
      display: "flex",
      justifyContent: "space-between"
    },
    grayText: {
      color: '#bdbdbd'
    },
    buttonContainer: {
      paddingTop: "10px"
    },
    confirmButton: {
      padding: "10px"
    },
    approveButton: {
      padding: "10px",
      backgroundColor: 'yellow'
    }
  };

  return (
    <>
      <section className="section-register">
        <div className="inner">
          {/* 타이틀 영역 [START] */}
          <div className="h3-title-wrap mt0">
            <h3 className="title">입점승인 테스트</h3>
            <p className="text">
              입점승인 테스트를 위한 페이지입니다.
            </p>
          </div>
          {/* 타이틀 영역 [END] */}
        
          {entryList.length === 0 ? (
            <p>신청내역이 없습니다.</p>
          ) : (
            <ol>
              {entryList.map((entry, index) => {
                const textStyle = entry.is_confirmed ? styles.grayText : {};
                return (
                  <li key={index} style={styles.entryItem}>
                    <div>
                      <span style={textStyle}>[{index + 1}]</span>&nbsp;
                      <span style={textStyle}>
                        {entry.store_name_ko}
                      </span><br />
                      <span style={textStyle}>
                        신청일: {entry.created_at.split('T')[0]}
                      </span>
                    </div>
                    <div style={styles.buttonContainer}>
                      {entry.is_confirmed ? (
                        <button disabled style={styles.confirmButton}>
                          승인완료
                        </button>
                      ) : (
                        <button style={styles.approveButton} onClick={() => approveEntry(entry.m_uid)}>
                          승인하기
                        </button>
                      )}
                    </div>
                  </li>
                );
              })}
            </ol>
          )}
        </div>
      </section>
      {/* 로그인 링크 버튼 [START] */}
      {/* <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big"
              onClick={() => navigate('/login/main2')}
            >
              로그인하러 가기
            </button>
          </div>
        </div>
      </div> */}
      {/* 다음 버튼 [END] */}
    </>
  );

};

export default VendorRegister10Section;
