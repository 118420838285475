import React from "react";
import TopBar from '../../layouts/top_bar/TitleZaelookBar';
import sampleImage from "../../assets/images/sample/sample_product_detail.png";
import FAB from '../../components/Buttons/FAB';

function ZaeLookDetailPage() {

    return (
        <>
            <TopBar title="재룩"/>
            <article id="zaeLookDetailPage">
                <div className="inner">
                    <div className="zaezae-option-wrap">
                        <div className="input check bg">
                        <label htmlFor="checkAll">
                            <input id="checkAll" type="checkbox"></input>
                            <b>총 2개</b>
                        </label>
                        <button type="button" className="f14 ml10 f-black50">
                            선택삭제
                        </button>
                        </div>
                        <div className="btn-wrap">
                        <button type="button" className="icon-option">
                        </button>
                    </div>
                </div>
                <div className="grid-wrap">
                    <ul className="">
                        <li className="card big">
                            <div className="input innercheck bg">
                                <label htmlFor="checkAll">
                                    <input id="checkAll" type="checkbox"></input>
                                </label>
                            </div>
                            <div className="img-wrap h400">
                                <img src={sampleImage} alt=""></img>
                            </div>
                            <div className="card very-small mt10">
                                <div className="img-wrap">
                                    <img src={sampleImage} alt=""></img>
                                </div>
                                <div className="block">
                                    <div className="info-wrap">
                                        <p className="f12">베리클로젯</p>
                                    </div>
                                    <div className="info-wrap">
                                        <b className="f12">0</b>
                                    </div>
                                </div>
                            </div>
                            <div className="mt10">
                                <p className="f14">아이가 잘 입어서 기쁘다</p>
                            </div>
                            <button type="button" className="mt10 unlock lock-btn">
                                전체공개
                            </button>
                        </li>
                    </ul>
                </div>
                </div>
            </article>
            <FAB />
        {/* 필요시 BottonNav 추가  */}
        </>
    )
}

export default ZaeLookDetailPage;
