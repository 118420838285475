import React from "react";
import { useParams } from 'react-router-dom';

import TopBar from '../../layouts/top_bar/TitleBar';

import Register01Section from '../../sections/register/Register01Section'; 
import Register02Section from '../../sections/register/Register02Section'; 
import KidRegister01Section from '../../sections/register/KidRegister01Section'; 
import KidRegister02Section from '../../sections/register/KidRegister02Section'; 
import KidRegister03Section from '../../sections/register/KidRegister03Section'; 
import KidRegister04Section from '../../sections/register/KidRegister04Section'; 

function RegisterPage() {

  const { Page2depth } = useParams();

  const getTitle = (Page2depth) => {
    switch (Page2depth) {
      case '1' :
        return '회원가입'
      case '2' :
        return '회원가입'
      default:
        return '아이 프로필 입력'
    }
  };

  return (
    <>
        <TopBar  
          title={getTitle(Page2depth)}
        />

      <article id="registerPage">
        {Page2depth === '1' && <Register01Section/>}
        {Page2depth === '2' && <Register02Section/>}
        {Page2depth === 'kid-1' && <KidRegister01Section/>}
        {Page2depth === 'kid-2' && <KidRegister02Section/>}
        {Page2depth === 'kid-3' && <KidRegister03Section/>}
        {Page2depth === 'kid-4' && <KidRegister04Section/>}
      </article>
      
    </>
  )
}

export default RegisterPage;
