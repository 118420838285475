import React from "react";
import { useParams } from 'react-router-dom';

import TopBar from '../../layouts/top_bar/TitleBar';

import VendorRegister01Section from '../../sections/vendor/VendorRegister01Section';
import VendorRegister02Section from '../../sections/vendor/VendorRegister02Section';
import VendorRegister03Section from '../../sections/vendor/VendorRegister03Section';
import VendorRegister04Section from '../../sections/vendor/VendorRegister04Section';
import VendorRegister05Section from '../../sections/vendor/VendorRegister05Section';
import VendorRegister06Section from '../../sections/vendor/VendorRegister06Section';
import VendorRegister07Section from '../../sections/vendor/VendorRegister07Section';
import VendorRegister08Section from '../../sections/vendor/VendorRegister08Section';
import VendorRegister09Section from '../../sections/vendor/VendorRegister09Section';
import VendorRegister10Section from '../../sections/vendor/VendorRegister10Section ';

function VendorPage() {

  const { Page2depth } = useParams();

  const getTitle = (Page2depth) => {
    switch (Page2depth) {
      case '1' :
        return '판매자 회원가입'
      case '2' :
        return '판매자 회원가입'
      case '3' :
        return '입점신청'
      case '4' :
        return '입점신청'
      case '5' :
        return '입점신청'
      case '6' :
        return '입점신청'
      case '7' :
        return '입점신청'
      case '8' :
        return '입점신청 - 수수료/배송비 정책 동의'
      case '9' :
        return '입점신청 - 입점 정책 동의'
      case '10' :
        return '입점승인 테스트'
      default:
        return '입점신청'
    }
  };

  return (
    <>
        <TopBar  
          title={getTitle(Page2depth)}
        />
      <article id="vendorPage">
        {(Page2depth === '1' || Page2depth === '2') && <VendorRegister01Section/>}
        {Page2depth === '3' && <VendorRegister03Section/>}
        {Page2depth === '4' && <VendorRegister04Section/>}
        {Page2depth === '5' && <VendorRegister05Section/>}
        {Page2depth === '6' && <VendorRegister06Section/>}
        {Page2depth === '7' && <VendorRegister07Section/>}
        {Page2depth === '8' && <VendorRegister08Section/>}
        {Page2depth === '9' && <VendorRegister09Section/>}
        {Page2depth === '10' && <VendorRegister10Section/>}
      </article>
      
    </>
  )
}

export default VendorPage;
