import React from 'react';
import PropTypes from 'prop-types';

const BigTab = ({ tabs }) => {
  return (
    <div className="big-tabs">
      {tabs.map((tab, index) => (
        <div className="big-tab" key={index}>
          {tab.title}
        </div>
      ))}
    </div>
  );
};

BigTab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  ).isRequired
};

export default BigTab;
