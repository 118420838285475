import React, { useState } from "react";
import SmallTab from '../../components/Tabs/SmallTab'; 
import GridSection from '../../sections/grid/GridSection';

// 스몰탭 리스트
const smallTabs = [
  { key: 'burberry', label: '버버리 키즈' },
  { key: 'montclair', label: '몽클레어 키즈' },
  { key: 'island', label: '스톤아일랜드 키즈' },
  { key: 'nursing', label: '수유용품' },
];

const collections = [
  {title:'랭킹1',id:'Q29sbGVjdGlvbjo0OA=='},

];

const RankingFashionSection = () => {

  const [activeSmallTab, setActiveSmallTab] = useState("burberry");
  
  const handleSmallTabClick = (key) => {
    setActiveSmallTab(key);
  };

  return (
    <>
      <section className="section-ranking-fashion pl16 pr16">
        
        <div className="mt20"></div>
        
        {/* 스몰탭 [START] */}
        {/* <SmallTab
          tabs={smallTabs}
          activeTab={activeSmallTab}
          onTabClick={handleSmallTabClick}
        /> */}
        {/* 스몰탭 [END] */}

        <div className="mt20"></div>
        
        <GridSection 
          type="big" 
          option={false}
          collection={collections[0].id}
        />
      </section>
    </>
  );
};

export default RankingFashionSection;
