import React from 'react';

const PopupRegisterDetail15 = ({closePopup}) => {
  return (
    <>
    <div className="popup-body">
      <div className="h3-title-wrap">
        <h3 className="title">
          서비스 이용약관 동의
        </h3>
        <p className="mt16 f16">
          이 헌법은 1988년 2월 25일부터 시행한다. 다만, 이 헌법을 시행하기 위하여 필요한 법률의 제정·개정과 이 헌법에 의한 대통령 및 국회의원의 선거 기타 이 헌법시행에 관한 준비는 이 헌법시행 전에 할 수 있다.

          지방의회의 조직·권한·의원선거와 지방자치단체의 장의 선임방법 기타 지방자치단체의 조직과 운영에 관한 사항은 법률로 정한다. 모든 국민은 헌법과 법률이 정한 법관에 의하여 법률에 의한 재판을 받을 권리를 가진다.

          예비비는 총액으로 국회의 의결을 얻어야 한다. 예비비의 지출은 차기국회의 승인을 얻어야 한다. 국가는 법률이 정하는 바에 의하여 재외국민을 보호할 의무를 진다.

          대통령이 제1항의 기간내에 공포나 재의의 요구를 하지 아니한 때에도 그 법률안은 법률로서 확정된다. 헌법재판소 재판관은 탄핵 또는 금고 이상의 형의 선고에 의하지 아니하고는 파면되지 아니한다.

          국무총리는 국회의 동의를 얻어 대통령이 임명한다. 국회는 국가의 예산안을 심의·확정한다. 국가는 국민 모두의 생산 및 생활의 기반이 되는 국토의 효율적이고 균형있는 이용·개발과 보전을 위하여 법률이 정하는 바에 의하여 그에 관한 필요한 제한과 의무를 과할 수 있다.

          헌법재판소의 조직과 운영 기타 필요한 사항은 법률로 정한다. 국회의원은 그 지위를 남용하여 국가·공공단체 또는 기업체와의 계약이나 그 처분에 의하여 재산상의 권리·이익 또는 직위를 취득하거나 타인을 위하여 그 취득을 알선할 수 없다
        </p>
      </div>
    </div>
    <div className="popup-footer">
      <div className="btn-wrap">
        <button type="button" className="btn purple" onClick={closePopup}>완료</button>
      </div>
    </div>
  </>
  );
};

export default PopupRegisterDetail15;
