import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import indexImg from "../../assets/images/vendor/vendor_index.png";
import LogoImage from "../../assets/images/common/logo_yellow.svg";

const VendorRegister03Section = () => {

  const navigate = useNavigate();

  return (
    <>
      <section className="section-register h100p">
        <div className="inner flex center a-center h100p">
          <div className="img-wrap w300">
            <div className="inner-title-wrap">
              <img 
                src={LogoImage} 
                alt="zaezae" 
                style={styles.logoImage}
              />
              <div style={styles.welcomeText}>환영합니다!</div>
            </div>
            <div style={styles.innerContentsWrap}>
              <img 
                src={indexImg} 
                alt="입점신청" 
                style={styles.indexImage}
              />
              <div style={styles.zaezaeText}>zaezae</div>
            </div>
          </div>
        </div>
      </section>

      {/* 다음 버튼 [START]*/}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big"
              onClick={()=>navigate('/vendor/4')}
            >
              입점신청
            </button>
          </div>
        </div>
      </div>
      {/* 다음 버튼 [END]*/}
    </>
  );
};


const styles = {
  logoImage: {
    width: '200px',
  },
  welcomeText: {
    fontSize: '1.2em',
    fontWeight: '600',
    margin: '20px 0',
    color: '#222'
  },
  innerContentsWrap: {
    border: '1px solid #d1d1d3',
    padding: '40px',
  },
  indexImage: {
    width: '200px',
    filter: 'opacity(0.5) drop-shadow(0 0 0 #932ef4)',
  },
  zaezaeText: {
    fontSize: '2.0em',
    fontWeight: 'bold',
    color: '#932ef4',
  },
};

export default VendorRegister03Section;

