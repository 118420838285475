import { create } from "zustand";
import api from "../../services/api"

const entryStore = create((set, get) => ({
  applicationData: {
    uuid: '', // UUID
    email: '', // 이메일(ID)
    b_no: '', // 사업자등록번호
    store_category: '', // 스토어 입점 유형
    // [스토어 정보]
    store_name: '', // 스토어명
    store_type: '', // 스토어 유형
    // [패션상품]
    fashion_category: {
      "top": { "key": 45, "value": "패션" },
      "middle": { "key": "", "value": "" },
      "bottom": { "key": "", "value": "" }
    },  // 스토어 분류 지정
    gender_category: { "Unisex": false, "girl": false, "boy": false }, // 스토어 성별 지정
    age_range: { "min": '', "max": '' }, // 사용가능 연령대
    store_style: {  // 스토어 스타일 지정
      "SimpleBasic": false,
      "Casual": false,
      "ModernChic": false,
      "Lovely": false,
      "Romantic": false,
      "Unique": false,
      "Vintage": false,
      "OfficeLook": false,
      "CampusLook": false,
      "Americana": false,
      "Contemporary": false,
      "Unisex": false
    },
    size_chart_id: "Size001", // 권장 사이즈표(임시 데이터 / 현재 UI X)
    parallel_import: false, // 수입 방법 (임시데이터)
    // [라이프/유형]
    life_category: {
      "top": { "key": 46, "value": "라이프" },
      "middle": { "key": "", "value": "" },
      "bottom": { "key": "", "value": "" }
    }, // 스토어 분류 지정
    life_age_range: { "min": '', "max": '' }, // 사용가능 연령대
    life_parallel_import: false, // 수입 방법 (임시데이터)
    // [판매 국가 정보]
    sale_countries: { "domestic": false, "global": false }, // 판매 국가 선택
    // [스토어 담당자 정보]
    contact_name: "", // 담당자 이름
    contact_mobile: "", // 담당자 휴대폰
    contact_email: "", // 담당자 이메일
    contact_phone: "", // 담당자 유선 전화
    // [사업자 정보]
    business_registration_image: "", // 사업자등록증
    e_commerce_license_image: "", // 통신판매업증
    e_commerce_license_number: "", // 통신판매업 신고번호
    company_name: "", // 업체(법인)명
    business_location: {
      "country_area": "",
      "city": "",
      "company_name": "",
      "postal_code": "",
      "address": "",
    },
    customer_center_info: {
      "op_end_hours": "",
      "op_start_hours": "",
      "lunch_end_hours": "",
      "lunch_start_hours": "",
      "working_days":
        { "monday": false, "tuesday": false, "wednesday": false, "thursday": false, "friday": false, "saturday": false, "sunday": false },
    },
    // [인증 정보]
    certification_info_1: { "display": false, "file": "", "disable_option": 0 }, // 인증1 - 임시 데이터(무엇을 인증하는지 모름)
    certification_info_2: { "display": false, "file": "", "file_name": "", "disable_option": null }, // 인증2 - 임시 데이터
    certification_info_3: { "display": false, "file": "", "file_name": "", "disable_option": null }  // 인증3 - 임시 데이터
  },
  setApplicationData: (data) => set({ applicationData: data }),
  submitApplication: async () => {
    const applicationData = get().applicationData;
    try {
      const response = await api.post('/api-tg/vendor/apply-entry', applicationData);
      alert('입점 신청이 완료되었습니다.\n신청 승인은 내부 시스템에 따라 최대 2~3일 정도 소요될 수 있습니다.\n기타 문의사항은 시스템 관리자에게 문의주시기 바랍니다.');
      console.log(response.data);
      return true
    } catch (error) {
      alert('입점 신청 중 문제가 발생했습니다.\n계속 반복될 경우 관리자에게 문의주시기 바랍니다.');
      console.error(error);
      return false
    }
  }
}));

export default entryStore;