import React from 'react';

const PopupRegisterDetail5 = ({closePopup}) => {
  return (
    <>
    <div className="popup-body">
      <div className="h3-title-wrap">
        <h3 className="title">
          홍보 및 마케팅 이용에 동의
        </h3>
        <p className="mt16 f16">홍보 및 마케팅 이용에 동의</p>
      </div>
    </div>
    <div className="popup-footer">
      <div className="btn-wrap">
        <button type="button" className="btn purple" onClick={closePopup}>완료</button>
      </div>
    </div>
  </>
  );
};

export default PopupRegisterDetail5;
