import React from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

const H3Title = ({ title = '타이틀을 입력해주세요.', id='' }) => {

  const navigate = useNavigate();
  
  return (
    <div className="h3-title-wrap">
      <h3 className="title">
        {parse(title)}
      </h3>
      <button 
        className="more-btn big" 
        type="button" 
        onClick={()=>navigate(`/product-list?id=${id}&title=${title}`)}
      >
      </button>
    </div>
  );
};

export default H3Title;
