import React from "react";
import TopBar from '../../layouts/top_bar/TitleBar';
import IconSearch from '../../assets/images/common/icons/icon_alert.svg';

function AlertPage() {
  return (
    <>
      <TopBar title="알림"/>
      <section className="section-alert">
        <div className="inner pt78">
          <div className="img-wrap flex center">
            <img className="w120" src={IconSearch} alt="도착한 알림이 없습니다."></img>
          </div>
          <strong className="f22 mt20 flex center">도착한 알림이 없습니다.</strong>
          <p className="f14 mt10 flex center">알림을 받기 위해서는 설정에 들어가 주세요.</p>
        </div>
      </section>
    </>  
  );
}

export default AlertPage;