import React from "react";
import { useParams } from 'react-router-dom';

import TopBar from '../../layouts/top_bar/TitleBar';

import LoginMain01Section from '../../sections/login/LoginMain01Section'; 
import LoginMain02Section from '../../sections/login/LoginMain02Section';
import LoginFindIdSection from '../../sections/login/LoginFindIdSection';
import LoginFindPwSection from '../../sections/login/LoginFindPwSection';

function LoginPage() {

  const { Page2depth } = useParams();

  const getTitle = (Page2depth) => {
    switch (Page2depth) {
      case 'findid' :
        return '아이디 찾기'
      case 'findpw' :
        return '비밀번호 찾기'
      default:
        return '아이디 찾기'
    }
  };

  return (
    <>
      
      {
        (Page2depth === 'findid' || Page2depth === 'findpw') && 
        <TopBar  
          title={getTitle(Page2depth)}
        />
      }

      <article 
        id="loginPage" 
        className={`${Page2depth === 'main1' || Page2depth === 'main2' ? 'main' : ''}`}
      >
        {Page2depth === 'main1' && <LoginMain01Section/>}
        {Page2depth === 'main2' && <LoginMain02Section/>}
        {Page2depth === 'findid' && <LoginFindIdSection/>}
        {Page2depth === 'findpw' && <LoginFindPwSection/>}
      </article>
    </>
  )
}

export default LoginPage;
