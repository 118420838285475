import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import useInputStore from '../../store/common/useInputStore';
import usePopupStore from '../../store/common/usePopupStore';
import api from "../../services/api";
import InputPhone from '../../components/Inputs/InputPhone';

const LoginFindPwSection = () => {

  const navigate = useNavigate();

  const { 
    input,
    actions,
  } = useInputStore(state => ({
    input: state.input,
    actions: state.actions,
  }));

  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  // 비밀번호 찾기 관련 메소드 --------------------------------------------------------

  const validateInput = () => {
    return input.name &&
           input.email &&
           input.phoneNumber &&
           input.isPhoneVerification;
  };

  const checkForPw = async () => {
    try {
      const res = await api.post('/api-tg/fpw-check', {
        name: input.name,
        email: input.email,
        phone: input.phoneNumber
      });
      actions.setUUID(res.data.data.id);
      actions.setIsPhoneVerification(true);
      return true;
    } catch (e) {
      console.log(e);
      openSnackBarPopup('일치하는 정보가 없습니다.\n다른 가입 정보로 다시 시도해주세요.', '', 'error');
      return false;
    }
  };

  const resetPw = () => {
    if (input.password !== input.password_re) {
      openSnackBarPopup('비밀번호가 일치하지 않습니다.', '', 'error');
      return;
    }

    api.post('/api-tg/reset-pw', {
      id: input.uuid,
      email: input.email,
      new_password: input.password
    }).then(res => {
      openSnackBarPopup('비밀번호 변경이 완료되었습니다.', '', 'success');
      navigate('/login/main2');
    }).catch(e => {
      console.log(e);
      openSnackBarPopup('오류가 발생했습니다.\n다시 시도해주세요.', '', 'error');
      navigate(0);
    });
  };

  return (
    <>
      <section className="section-login-findpw">
        <div className="inner">

          {/* 개발 디버그용 [START] */}
          {/* <div className="debug-box">
            <p><b>아이디(이메일) email :</b> {input.email}</p>
            <p><b>이름 name :</b> {input.name} </p>
            <p><b>휴대폰 번호 phoneNumber :</b> {input.phoneNumber} </p>
            <p><b>휴대폰 인증번호 phoneCode :</b> {input.phoneCode} </p>
            <p><b>비밀번호 password :</b> {input.password} </p>
            <p><b>비밀번호 재입력 password_re :</b> {input.password_re} </p>
          </div> */}
          {/* 개발 디버그용 [END] */}

          <div className="h3-title-wrap mt0">
            <h3 className="title">비밀번호를 잊으셨나요?</h3>
          </div>

          {/* 아이디(이메일) [START] */}
          <div className="input-box">
            <label htmlFor="email" className="title starred">
              아이디(이메일)
            </label>
            <div className="input">
              <input 
                id="email" 
                type="email" 
                placeholder="아이디를 입력해주세요."
                onInput={(e) => actions.setEmail(e.target.value)}
                value={input.email}
              />
            </div>
          </div>
          {/* 아이디(이메일) [END] */}

          {/* 이름 [START] */}
          <div className="input-box">
            <label htmlFor="name" className="title starred">
              이름
            </label>
            <div className="input">
              <input 
                id="name" 
                type="text" 
                placeholder="이름을 입력해주세요."
                onInput={(e) => actions.setName(e.target.value)}
                value={input.name}
              />
            </div>
          </div>
          {/* 이름 [END] */}

          {/* 휴대전화 [START] */}
          <InputPhone disabled={!input.name || !input.email} checkFunc={checkForPw} />
          {/* 휴대전화 [END] */}

          {
            validateInput() &&
            <>
              {/* 새로운 비밀번호 [START] */}
              <div className="input-box">
                <label htmlFor="pw1" className="title starred">
                  새로운 비밀번호
                </label>
                <div className="input">
                  <input 
                    id="pw1" 
                    type="password" 
                    className="private"
                    placeholder="비밀번호를 입력해주세요."
                    onInput={(e) => actions.setPassword(e.target.value)}
                    value={input.password}
                  />
                  <button type="button" className="btn icon-private" onClick={(e)=>window.WStoggleInputPrivate(e)}></button>
                </div>
                <span className="caption f-purple">영문/숫자/특수문자 혼용 8~16자</span>
                <div className="input mt20">
                  <label htmlFor="pw2" className="hide">버튼타입</label>
                  <input 
                    id="pw2" 
                    type="password" 
                    className="private"
                    placeholder="비밀번호를 재입력해주세요."
                    onInput={(e) => actions.setPasswordRe(e.target.value)}
                    value={input.password_re}
                  />
                  {
                    (input.password != input.password_re) &&
                    <span className="caption f-red">비밀번호가 일치하지 않습니다.</span>
                  }
                  <button 
                    type="button" 
                    className="btn icon-private" 
                    onClick={(e)=>window.WStoggleInputPrivate(e)}
                  >
                  </button>
                </div>
                {input.passwordAlert && 
                  <span
                    className={`caption ${input.passwordAlertType === 'error' ? 'f-red' : 'f-blue'}`}
                  >
                    {input.passwordAlert}
                  </span>
                }
              </div>
              {/* 비밀번호 [END] */}
            </>
          }

        </div>
      </section>
      <div className="bottom-bar">
        <div className="inner">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big"
              disabled={!input.password || (input.password !== input.password_re)}
              onClick={resetPw}
            >
              비밀번호 재설정하기
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginFindPwSection;
