import React from "react";
import { useNavigate } from "react-router-dom";

import SampleImage from "../../assets/images/sample/banner.png";

const MyMenuRequestCancel2Section = () => {

  const navigate = useNavigate();

  return (
    <>
      <section className="section-mymenu-request-cancel2">
        <div className="order-card selected">
          <div className="title-wrap">
            <strong className="title">선택한 상품 1건</strong>
          </div>
          <div className="item-info-wrap">
            <div className="img-wrap">
              <img src={SampleImage} alt=""></img>
            </div>
            <div className="text-wrap">
              <p className="date">2023-12-28</p>
              <p className="brand">베리클로젯</p>
              <p className="name">스타일 레더 패딩 (겨울)</p>
              <p className="price">72,900 / 1개</p>
            </div>
          </div>
        </div>
        <div className="white-wrap">
          <h4 className="title">
            선택한 사유
          </h4>
          <p>
            상품이 마음에 들지 않음 (단순변심)
          </p>
        </div>
        <div className="white-wrap">
          <h4 className="title mb10">
            환불 정보를 확인해주세요.
          </h4>
          <ul className="order-infos">
            <li className="info"><span>총 주문금액</span><span>109,000원</span></li>
            <li className="info"><span>총 배송비</span><span>109,000원</span></li>
            <li className="info"><span>총 즉시할인금액</span><span>109,000원</span></li>
            <li className="info"><span>총 쿠폰할인금액</span><span>109,000원</span></li>
            <li className="info"><span>총 사용적립금</span><span>109,000원</span></li>
            <li className="info"><span>총 환불예상금액</span><span>109,000원</span></li>
            <li className="info"><span>환불수단</span><span>롯데카드 / 일시불 88,600원</span></li>
          </ul>
        </div>
      </section>

      {/* 환불 진행하기 버튼 [START]*/}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big"
              onClick={()=>navigate('/mymenu/cancel-detail')}
            >
              환불 진행하기
            </button>
          </div>
        </div>
      </div>
      {/* 환불 진행하기 버튼 [END]*/}

    </>
  );
};

export default MyMenuRequestCancel2Section;

