import { create } from 'zustand';

const usePopupStore = create((set) => ({

  ToastPopup: {
    currentPopup: null,
    display: 'none',
    y: 1600, // 팝업의 위치
    opacity: 0, // Dim의 투명도
  },

  Etc: {
    etc: '',
    etc2: '',
    etc3: '',
  },

  SnackBarPopup: {
    currentPopup: null,
    display: 'none',
    opacity: 0,
    text:'',
    link_text:'',
    link_url:'/',
  },

  //토스트 팝업을 열 때 호출될 함수
  openToastPopup: (popupType, etc='', etc2='', etc3='') => set((state) => ({
    ToastPopup: {
      ...state.ToastPopup,
      currentPopup: popupType,
      display: 'block',
      y: 0,
      opacity: 0.15,
    },
    Etc: {
      etc,
      etc2,
      etc3
    }
  })),

  //토스트 팝업을 닫을 때 호출될 함수
  closeToastPopup: () => set((state) => ({
    ToastPopup: {
      ...state.ToastPopup,
      y: 1600,
      opacity: 0,
      display: 'none',
    },
  })),

  //스낵바 팝업을 열 때 호출될 함수
  openSnackBarPopup: (text, link_text, link_url) => set((state) => ({
    SnackBarPopup: {
      ...state.SnackBarPopup,
      display: 'block',
      opacity: 1,
      text: text,
      link_text: link_text,
      link_url: link_url,
    },
  })),

  //스낵바 팝업을 닫을 때 호출될 함수
  closeSnackBarPopup: () => set((state) => ({
    SnackBarPopup: {
      ...state.SnackBarPopup,
      opacity: 0,
      display: 'none',
    },
  })),

  setSnackBarPopupContent: ({ text, link_text, link_url }) => set((state) => ({
    SnackBarPopup: {
      ...state.SnackBarPopup,
      text: text,
      link_text: link_text,
      link_url: link_url,
    },
  })),

  
}));

export default usePopupStore;
