import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../store/common/useAuthStore';

// Function to handle registration of the transaction
async function registerTransaction(data) {
  const url = "https://testpgapi.easypay.co.kr/api/trades/webpay";
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return response.json(); // Parse JSON response
  } catch (error) {
    console.error('Register Transaction Error:', error);
    throw error; // Re-throw to handle it in the calling function
  }
}

const EasyPayCheckoutPage = ({ payData }) => {
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const [paymentWindow, setPaymentWindow] = useState(null);
  const [authId, setAuthId] = useState('');

  const [easyPayData, setEasyPayData] = useState({
    mallId: 'T0020516',
    shopTransactionId: `${user.id}-${new Date().toISOString().replace(/[^0-9]/g, '').slice(0, 14)}`,
    shopOrderNo: `${user.id}-${new Date().toISOString().replace(/[^0-9]/g, '').slice(0, 14)}`,
    approvalReqDate: new Date().toISOString().split('T')[0].replace(/-/g, ''),
  });

  useEffect(() => {
    const checkPopupClosed = () => {
      if (paymentWindow && paymentWindow.closed) {
        clearInterval(intervalId);
        if (
          easyPayData &&
          easyPayData.mallId &&
          easyPayData.shopTransactionId &&
          easyPayData.shopOrderNo &&
          easyPayData.approvalReqDate &&
          authId
        ) {
          const params = new URLSearchParams({
            mallId: easyPayData.mallId,
            shopTransactionId: easyPayData.shopTransactionId,
            shopOrderNo: easyPayData.shopOrderNo,
            approvalReqDate: easyPayData.approvalReqDate,
            authorizationId: authId
          }).toString();
          navigate(`/pay/easypay/success?${params}`);
        } else {
          alert('에러발생');
        }
      }
    };

    let intervalId = setInterval(checkPopupClosed, 500);

    return () => clearInterval(intervalId);

  }, [paymentWindow, navigate, payData]);

  // 총 결제 금액과 총 수량 계산
  const totals = useMemo(() => {
    let totalAmount = 0;
    let totalQuantity = 0;

    payData.forEach(checkout => {
      totalAmount += checkout.totalPrice.gross.amount;
      totalQuantity += checkout.lines.reduce((sum, line) => sum + line.quantity, 0);
    });

    return {
      totalAmount,
      totalQuantity
    };
  }, [payData]);

  const handlePayment = async () => {
    if (
      !easyPayData.mallId ||
      !easyPayData.shopTransactionId ||
      !easyPayData.shopOrderNo ||
      !easyPayData.approvalReqDate
    ) {
      console.error('Payment data is incomplete:', easyPayData);
      alert('Payment data is incomplete. Please check your input.');
      return;
    }

    // 모든 체크아웃의 basketInfoList를 합치기
    const allBasketInfoList = payData.flatMap(checkout => {
      const items = checkout.lines.map(line => ({
        productNo: line.variant.id,
        productName: line.variant.product.name,
        productAmount: line.totalPrice.gross.amount,
        sellerId: `T0020631`,
        feeUsed: true,
        feeTypeCode: "P",
        feeCharge: line.variant.product.vendor.commissionData.totalRate * 10000
      }));

      // 배송비가 있는 경우 이를 basketInfoList에 추가
      if (checkout.shippingPrice && checkout.shippingPrice.gross.amount > 0) {
        items.push({
          productNo: "SHIPPING",
          productName: "배송비",
          productAmount: checkout.shippingPrice.gross.amount,
          sellerId: "SYSTEM",
          feeUsed: false,
        });
      }

      return items;
    });

    const registerData = {
      mallId: easyPayData.mallId,
      shopOrderNo: easyPayData.shopOrderNo,
      amount: totals.totalAmount,
      payMethodTypeCode: '11', // Card payment fixed
      currency: "00",
      returnUrl: `${process.env.REACT_APP_REST_API_URL}/api-tg/pay/easypay/success${window.location.search}`,
      deviceTypeCode: 'pc',
      clientTypeCode: '00',
      orderInfo: {
        goodsName: "Example Product Name",
        customerInfo: {
          customerId: user.id,
          customerName: user.name,
        }
      },
      basketInfoList: allBasketInfoList
    };

    try {
      const registerResult = await registerTransaction(registerData);

      if (registerResult.resCd === '0000' && registerResult.authPageUrl) {
        const authUrl = new URL(registerResult.authPageUrl);
        const urlParams = new URLSearchParams(authUrl.search);
        setAuthId(urlParams.get('authorizationId'));

        const width = 500;
        const height = 600;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);
        const newWindow = window.open(registerResult.authPageUrl, 'PaymentAuthorization', `width=${width},height=${height},top=${top},left=${left}`);
        setPaymentWindow(newWindow);
      } else {
        alert('Transaction Registration Failed: ' + registerResult.error);
      }
    } catch (error) {
      alert('Payment processing error: ' + error.message);
    }
  };

  // useEffect(() => {
  //   console.log('user', user);
  //   console.log('payData', payData);
  // }, [user, payData]);

  // useEffect(() => {
  //   console.log('authId', authId);
  // }, [authId]);

  return (
    <div className="wrapper w-100">
      <div className="max-w-540 w-100">
        <div className="bottom-bar">
          <button type="button" className="btn pay" onClick={handlePayment}>
            <span>총 {totals.totalQuantity} 개</span>
            <strong>{window.WSformatPrice(totals.totalAmount)} 결제하기</strong>
          </button>
        </div>
      </div>
    </div>
  );
}

export default EasyPayCheckoutPage;
