import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const MyMenuRequestCancel1Section = () => {
  const navigate = useNavigate();
  const [selectedReason, setSelectedReason] = useState(""); // 취소 사유를 저장할 상태
  const [otherReason, setOtherReason] = useState(""); // '기타 사유' 입력값을 저장할 상태

  // 사유 선택 핸들러
  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  // '기타 사유' 입력값 변경 핸들러
  const handleOtherReasonChange = (event) => {
    setOtherReason(event.target.value);
  };

  return (
    <>
      <section className="section-mymenu-request-cancel1">
        <div className="inner pt16">
          <h4 className="title mb10">취소 사유를 선택해주세요.</h4>
          <div className="input-box">
            <ul className="input-list">
              <li className="input radio">
                <label htmlFor="radio1">
                  <input 
                    id="radio1" 
                    name="radio" 
                    type="radio" 
                    value="dislike" 
                    onChange={handleReasonChange}
                  /> 상품이 마음에 들지 않음 (단순변심)
                </label>
              </li>
              <li className="input radio">
                <label htmlFor="radio2">
                  <input 
                    id="radio2" 
                    name="radio" 
                    type="radio" 
                    value="reorder" 
                    onChange={handleReasonChange}
                  /> 다른 상품 추가 후 재주문 예정
                </label>
              </li>
              <li className="input radio etc">
                <label htmlFor="radio3">
                  <input 
                    id="radio3" 
                    name="radio" 
                    type="radio" 
                    value="etc" 
                    onChange={handleReasonChange}
                  /> 기타 사유
                </label>
                <textarea 
                  disabled={selectedReason !== "etc"}
                  placeholder="상세 사유를 입력하세요"
                  value={otherReason}
                  onChange={handleOtherReasonChange}
                ></textarea>
              </li>
            </ul>
          </div>
          <div className="debug-box">
            <p><b>선택 사항 selectedReason :</b> {selectedReason}</p>
            <p><b>기타 사유 상세 otherReason :</b> {otherReason}</p>
          </div>
        </div>
      </section>

      {/* 다음단계 버튼 [START] */}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big"
              onClick={() => navigate('/mymenu/request-cancel2')}
              disabled={!selectedReason}
            >
              다음단계
            </button>
          </div>
        </div>
      </div>
      {/* 다음단계 버튼 [END] */}
    </>
  );
};

export default MyMenuRequestCancel1Section;
