import React, {useState, useEffect} from "react";
import usePopupStore from '../../store/common/usePopupStore'
import { fetchVouchers, TGVoucherDownload } from '../../services/GQ_apis/discounts';
import useAuthStore from '../../store/common/useAuthStore';

const CouponsAllSection = () => {

  const { user } = useAuthStore();

  // SnackBar 팝업 사용 함수 openSnackBarPopup
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  const [couponList, setCouponList] = useState([]);
  const [isCouponLoading, setIsCouponLoading] = useState(true);
  const [downloadableCoupons, setDownloadableCoupons] = useState([]);

  useEffect(() => {
    fetchAndSetVouchers();
  }, [user]);


  const fetchAndSetVouchers = () => {
    if(!user) return;
    setIsCouponLoading(true);
    fetchVouchers().then(res => {
        console.log('All Vouchers');
        setCouponList(res.data.TGvouchers.edges);
        console.log(res.data.TGvouchers.edges);
        console.log('All Vouchers');
      })
      .catch(error => {
        console.error('Error fetching vouchers:', error);
      })
      .finally(() => {
        setIsCouponLoading(false);
      });
  };

  const voucherDownload = (code) => {

    TGVoucherDownload(code).then((res) => {

      openSnackBarPopup('정상적으로 쿠폰을 다운로드하였습니다.', '', '');
      console.log('voucherDownload res', res);
      fetchAndSetVouchers();

    }).catch((error) => {
      openSnackBarPopup('쿠폰 다운로드 중 오류가 발생했습니다.', '', '');
      console.error('Error downloading voucher:', error);
      fetchAndSetVouchers();
    });

    // if (downloadableCoupons.includes(code)) {
    //   TGVoucherDownload(code).then((res) => {
    //     if (res.data.TGVoucherDownload.errors && res.data.TGVoucherDownload.errors.length > 0) {
    //       openSnackBarPopup('쿠폰 다운로드에 실패하였습니다.', '', '');
    //       console.log(res.data);
    //     } else {
    //       openSnackBarPopup('정상적으로 쿠폰을 다운로드하였습니다.', '', '');
    //       setDownloadableCoupons(prev => prev.filter(x => x !== code)); // 다운로드된 쿠폰 제거
    //     }
    //   }).catch((error) => {
    //     openSnackBarPopup('쿠폰 다운로드 중 오류가 발생했습니다.', '', '');
    //     console.error('Error downloading voucher:', error);
    //   });
    // } else {
    //   openSnackBarPopup('이미 다운로드된 쿠폰입니다.', '', '');
    // }

  };

  if (isCouponLoading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <>
      <section className="section-coupons">
        <div className="inner">
        <strong className="title">받을 수 있는 쿠폰 <b className="f-purple">0개</b></strong>
          <ul className="coupons-list">
            {
              couponList.map((coupon,index)=>(
                ! coupon.node.isDownloaded && 
                <li className="card coupon" key={coupon.node.id}>
                  <div className="top-wrap">
                    <strong className="f-purple">
                      50%
                    </strong>
                    <button 
                      type="button" 
                      className="btn white round"
                      onClick={()=>{voucherDownload(coupon.node.code)}}
                      >
                      <span className="icon-download">
                      </span>
                      다운로드
                    </button>
                  </div>
                  <div className="bottom-wrap">
                    <strong className="title">
                      {coupon.node.name}
                    </strong>
                    {/* {
                      coupon.node.metadata &&
                      <p className="text">
                        {coupon.node.metadata.find(attr => attr.key === '설명').value}
                      </p>
                    } */}
                    <strong className="d-day">
                      만료까지 D-3
                      <span className="caption">10,000이상 구매시 사용가능</span>
                    </strong>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      </section>
      {/* 쿠폰 전체 다운받기 버튼 [START]*/}
      {/* <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              onClick={()=>openSnackBarPopup('정상적으로 쿠폰을 다운로드하였습니다.','','')}
              className="btn purple big"
              disabled={downloadableCoupons.length === 0}
            >
              쿠폰 전체 다운받기
            </button>
          </div>
        </div>
      </div> */}
      {/* 쿠폰 전체 다운받기 버튼 [END]*/}
    </>
  );
};

export default CouponsAllSection;

