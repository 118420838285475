import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { fetchPages } from "../../services/GQ_apis/page";
import { fetchOrders } from '../../services/GQ_apis/order';
import sampleImage from "../../assets/images/sample/banner.png";
import { meData } from "../../services/GQ_apis/me";

const MyReviewsSection = () => {
  const navigate = useNavigate();
  const [pageData, setPageData] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [me, setMe] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    meData().then((res) => {
      setMe(res.data.me);

      console.log('order', res.data);

      fetchOrders({ customer: res.data.me.email }).then(orderRes => {
        setOrderList(orderRes.data.orders.edges);
        fetchPages({
          pageTypes: "UGFnZVR5cGU6NQ==",
          metadata: [{ key: "user_email", value: res.data.me.email }]
        }).then(pageRes => {
          setPageData(pageRes.data.pages.edges);
          setLoading(false);
        });
      });
    });
  }, []);
  console.log(me);
  console.log(orderList);
  console.log(pageData);

  // 이 함수는 버튼 클릭 시 호출되어, 리뷰 상세 페이지로 이동합니다.
  const handleReviewDetail = (reviewId) => {
    navigate(`/mymenu/myreview-detail/${reviewId}`);
  };

  const findReviewsForProduct = (productId) => {
    return pageData.filter(page => page.node.metafields.product_id === productId);
  };

  const renderedOrderIds = new Set();
  const renderedProductIds = new Set();

  return (
    <>
      <section className="section-mymenu-orderlist">
        <div className="order-list-wrap">
          <ul className="order-list">
            {orderList.map((order) => {
              return order.node.lines.map((line, index) => {
                if (!line.variant || !line.variant.product || renderedOrderIds.has(order.node.id) || renderedProductIds.has(line.variant.product.id)) {
                  return null;
                }

                const productId = line.variant.product.id;
                const reviews = findReviewsForProduct(productId);

                if (reviews.length === 0) return null;

                renderedOrderIds.add(order.node.id);
                renderedProductIds.add(productId);

                return (
                  <li key={index} className="card order2 border-bottom-white pl16 pr16 pt20">
                    <div className="order-number-wrap flex between">
                      <span className="order-number">
                        주문번호 
                        <span className="f-purple pl6">{order.node.id}</span>
                      </span>
                      <Link to={`/order/order-detail/${order.node.id}`} className="a-more-btn f14">
                        주문 상세보기
                      </Link>
                    </div>
                    <div className="item-info-wrap flex">
                      <div className="img-wrap mr16">
                        <img src={line.thumbnail.url || sampleImage} alt={line.productName} />
                      </div>
                      <div className="text-wrap flex-grow">
                        <p className="name f14">{line.productName} {line.productSku}</p>
                        {/* <p className="price">{line.totalPrice.gross.amount}원 / {line.quantity}개</p> */}
                        {reviews.map((review, reviewIndex) => (
                        <div key={reviewIndex} className="mt20">
                          <div className="btn-wrap flex justify-content-end">
                          <button onClick={() => handleReviewDetail(review.node.id)} className="btn white round w100 h34 f14 mt10">
                            리뷰 확인하기
                          </button>
                          </div>
                        </div>
                        ))}
                      </div>
                      <div>
                      
                    </div>
                    </div>
                  </li>
                );
              });
            })}
          </ul>
        </div>
      </section>
    </>
  );
};

export default MyReviewsSection;
