import React from "react";
import { useNavigate } from 'react-router-dom';

const MainLoginBeforeSection = () => {
  const navigate = useNavigate();

  return (
    <section className="section-login">
      <div className="inner pt16 pb16 flex between">
        <p className="f14 f-black80">
          로그인하여 내 아이의 <br/>
          추천사이즈를 확인해보세요.
        </p>
        <button
          type="button"
          className="btn round white w86 h42 f14"
          onClick={() => navigate('/login/main1')}
        >
          로그인
          <span className="icon-more purple"></span>
        </button>
      </div>
    </section>
  );
};

export default MainLoginBeforeSection;
