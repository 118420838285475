import React, { useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import usePopupStore from '../../store/common/usePopupStore';

// (필수) 만 14세 이상입니다.
import PopupRegisterDetail1 from './popup-body/register/PopupRegisterDetail1';
// (필수) 서비스 이용약관에 동의
import PopupRegisterDetail2 from './popup-body/register/PopupRegisterDetail2';
// (필수) 개인정보 수집이용에 동의
import PopupRegisterDetail3 from './popup-body/register/PopupRegisterDetail3';
// (선택) 야간 수신혜택에 동의
import PopupRegisterDetail4 from './popup-body/register/PopupRegisterDetail4';
// (선택) 홍보 및 마케팅 이용에 동의
import PopupRegisterDetail5 from './popup-body/register/PopupRegisterDetail5';
// (선택) 마케팅 개인정보 제 3자 제공 동의
import PopupRegisterDetail6 from './popup-body/register/PopupRegisterDetail6';
// (필수) 재재약관 동의
import PopupRegisterDetail7 from './popup-body/register/PopupRegisterDetail7';
// (필수) 전자금융거래 이용약관 동의
import PopupRegisterDetail8 from './popup-body/register/PopupRegisterDetail8';
// (필수) 개인정보 수집 및 이용 동의
import PopupRegisterDetail9 from './popup-body/register/PopupRegisterDetail9';
// (필수) 개인정보 제3자 제공 동의
import PopupRegisterDetail10 from './popup-body/register/PopupRegisterDetail10';
// (선택) 마케팅 목적의 개인정보 수집 및 이용 동의
import PopupRegisterDetail11 from './popup-body/register/PopupRegisterDetail11';
// (선택) 이메일 수신 동의
import PopupRegisterDetail12 from './popup-body/register/PopupRegisterDetail12';
// (선택) SMS, SNS 수신 동의
import PopupRegisterDetail13 from './popup-body/register/PopupRegisterDetail13';
// (선택) 앱 푸시 수신 동의
import PopupRegisterDetail14 from './popup-body/register/PopupRegisterDetail14';
// (필수) 서비스 이용약관 동의
import PopupRegisterDetail15 from './popup-body/register/PopupRegisterDetail15';
// (필수) 판매자 개인정보 수집 동의
import PopupRegisterDetail16 from './popup-body/register/PopupRegisterDetail16';
// (필수) 판매수수료/배송비 정책 동의
import PopupRegisterDetail17 from './popup-body/register/PopupRegisterDetail17';
// (필수) 입점 정책 동의
import PopupRegisterDetail18 from './popup-body/register/PopupRegisterDetail18';

// 구매하기
import PopupBuy1 from './popup-body/product/PopupBuy1';
// 쿠폰
import Coupon1 from './popup-body/coupon/Coupon1';

// 로그아웃
import PopupLogout from './popup-body/confirm/PopupLogout';
// 아이삭제
import PopupKidDelete from './popup-body/confirm/PopupKidDelete';
// 아이등록 다음에하기
import PopupKidNext from './popup-body/confirm/PopupKidNext';

// 주문 - 취소요청
import PopupOrderCancel from './popup-body/order/PopupOrderCancel';

const ToastPopup = () => {

  const { ToastPopup, closeToastPopup, Etc } = usePopupStore((state) => ({
    ToastPopup: state.ToastPopup,
    closeToastPopup: state.closeToastPopup,
    Etc: state.Etc
  }));

  const [{ y, opacity }, set] = useSpring(() => ({
    y: ToastPopup.y,
    opacity: ToastPopup.opacity,
  }));

  // Zustand 스토어의 상태가 변경될 때마다 useSpring의 값을 업데이트합니다.
  useEffect(() => {
    set({
      y: ToastPopup.y,
      opacity: ToastPopup.opacity,
    });
  }, [ToastPopup, set]);

  const bind = useDrag(({ down, movement: [, my], velocity: [, vy], cancel }) => {
    if (my < 0 && down) {
      cancel();
    }

    if (down && my >= 0) {
      set({ y: my, opacity: 0.15 - (my / 1600 * 0.15), immediate: false });
    } else {
      if (my > 300 || vy > 0.5) {
        closeToastPopup();
      } else {
        set({ y: ToastPopup.y, opacity: ToastPopup.opacity, immediate: false });
      }
    }
  }, { axis: 'y' });

  const renderPopup = () => {
    switch (ToastPopup.currentPopup) {
      case '(필수) 만 14세 이상입니다.':
        return <PopupRegisterDetail1 closePopup={closeToastPopup} />;
      case '(필수) 서비스 이용약관에 동의':
        return <PopupRegisterDetail2 closePopup={closeToastPopup} />;
      case '(필수) 개인정보 수집이용에 동의':
        return <PopupRegisterDetail3 closePopup={closeToastPopup} />;
      case '(선택) 야간 수신혜택에 동의':
        return <PopupRegisterDetail4 closePopup={closeToastPopup} />;
      case '(선택) 홍보 및 마케팅 이용에 동의':
        return <PopupRegisterDetail5 closePopup={closeToastPopup} />;
      case '(선택) 마케팅 개인정보 제 3자 제공 동의':
        return <PopupRegisterDetail6 closePopup={closeToastPopup} />;
      case '(필수) 재재약관 동의':
        return <PopupRegisterDetail7 closePopup={closeToastPopup} />;
      case '(필수) 전자금융거래 이용약관 동의':
        return <PopupRegisterDetail8 closePopup={closeToastPopup} />;
      case '(필수) 개인정보 수집 및 이용 동의':
        return <PopupRegisterDetail9 closePopup={closeToastPopup} />;
      case '(필수) 개인정보 제3자 제공 동의':
        return <PopupRegisterDetail10 closePopup={closeToastPopup} />;
      case '(선택) 마케팅 목적의 개인정보 수집 및 이용 동의':
        return <PopupRegisterDetail11 closePopup={closeToastPopup} />;
      case '(선택) 이메일 수신 동의':
        return <PopupRegisterDetail12 closePopup={closeToastPopup} />;
      case '(선택) SMS, SNS 수신 동의':
        return <PopupRegisterDetail13 closePopup={closeToastPopup} />;
      case '(선택) 앱 푸시 수신 동의':
        return <PopupRegisterDetail14 closePopup={closeToastPopup} />;
      case '(필수) 서비스 이용약관 동의':
        return <PopupRegisterDetail15 closePopup={closeToastPopup} />;
      case '(필수) 판매자 개인정보 수집 동의':
        return <PopupRegisterDetail16 closePopup={closeToastPopup} />;
      case '(필수) 판매수수료/배송비 정책 동의':
        return <PopupRegisterDetail17 closePopup={closeToastPopup} />;
      case '(필수) 입점 정책 동의':
        return <PopupRegisterDetail18 closePopup={closeToastPopup} />;
      case '구매하기':
        return <PopupBuy1 closePopup={closeToastPopup} display={ToastPopup.display}/>;
      case '로그아웃':
        return <PopupLogout closePopup={closeToastPopup} />;
      case '아이삭제':
        return <PopupKidDelete closePopup={closeToastPopup} id={Etc.etc}/>;
      case '쿠폰조회':
        return <Coupon1 closePopup={closeToastPopup} />;
      case '다음에하기':
        return <PopupKidNext closePopup={closeToastPopup} />;
      case '주문 취소요청':
        return <PopupOrderCancel closePopup={closeToastPopup} id={Etc.etc} />;
      default:
        return <PopupRegisterDetail1 closePopup={closeToastPopup} />;
    }
  };

  return (
    <>
      <animated.div className="dim" style={{ opacity, display: ToastPopup.display }} onClick={closeToastPopup}>
        {/* DIM */}
      </animated.div>
      
      <animated.div id="toastPopup" className="popup-wrap" style={{ y }}>
        <div className="popup-head" {...bind()}>
          {/* 팝업 터치(스와이프) 영역 */}
        </div>
        {renderPopup()}
      </animated.div>
    </>
  );
};

export default ToastPopup;
