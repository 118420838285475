import React from 'react';
import usePopupStore from '../../../../store/common/usePopupStore';
import { deleteChild, getMyChildren } from '../../../../services/GQ_apis/kid';

const PopupKidDelete = ({ closePopup, id }) => {
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  const handleDeleteKid = async () => {
    try {
      const remainingChildrenResponse = await getMyChildren();
      const remainingChildren = remainingChildrenResponse.data.myChildren;

      const currentChild = remainingChildren.find(child => child.id === id);
      closePopup();

      if (remainingChildren.length > 1 && currentChild.main) {
        openSnackBarPopup('메인 프로필 해제 후 다시 진행해주세요.', '', '', '');
        return; 
      }

      const response = await deleteChild(id);

      const accountChild = response.data.accountChildDelete;
      if (accountChild.accountErrors.length === 0) {
        console.log(`Kid with ID ${id} deleted successfully.`);
        openSnackBarPopup('삭제되었습니다.', '', '', '');
      } else {
        console.error(`Error updating kid with ID ${id}`);
      }

      setTimeout(() => {
        window.location.reload();
      }, 1000);

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="popup-body">
        <div className="h3-title-wrap">
          <h3 className="title">
            알림
          </h3>
          <p className="mt16 f16">
            정말로 삭제하시겠습니까?
          </p>
        </div>
      </div>
      <div className="popup-footer">
        <div className="btn-wrap">
          <button type="button" className="btn gray" onClick={closePopup}>취소</button>
          <button type="button" className="btn purple" onClick={handleDeleteKid}>삭제</button>
        </div>
      </div>
    </>
  );
};

export default PopupKidDelete;
