import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import TopBar from '../../layouts/top_bar/TitleBar';
import { fetchPages } from "../../services/GQ_apis/page";

function CustomerCenterPage() {
  const [activeTab, setActiveTab] = useState('공지사항');
  const [activeQuestion, setActiveQuestion] = useState(null); // 활성화된 질문을 관리하기 위한 state 추가
  const [pageData, setPageData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const tabs = [
    { name: '공지사항', class: 'notice', filter: "UGFnZVR5cGU6Nw==" }, // 공지사항 필터
    { name: 'FAQ', class: 'faq', filter: "UGFnZVR5cGU6OA==" }, // FAQ 필터
  ];

  // 탭 클릭 핸들러
  const handleTabClick = (tab) => {
    setActiveTab(tab.name);
    navigate(`/customer-center?tab=${tab.name === '공지사항' ? 'notice' : 'faq'}`); // URL 업데이트
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabFromUrl = queryParams.get('tab') || 'notice';
    
    // URL에서 받은 탭 이름으로 활성화된 탭 설정
    setActiveTab(tabFromUrl === 'faq' ? 'FAQ' : '공지사항');

    const selectedTab = tabs.find(tab => tab.name === (tabFromUrl === 'faq' ? 'FAQ' : '공지사항'));
    const filter = selectedTab ? { pageTypes: selectedTab.filter } : {};

    fetchPages(filter).then((res) => {
      console.log('Fetched pages:', res.data.pages.edges);
      setPageData(res.data.pages.edges);
    }).catch(error => {
      console.error('Error fetching pages:', error);
    });
  }, [location.search]);

  // 질문 항목 클릭 이벤트를 처리하는 함수
  const toggleQuestion = (index) => {
    if (activeQuestion === index) {
      setActiveQuestion(null);
    } else {
      setActiveQuestion(index);
    }
  };

  // JSON 데이터에서 텍스트를 추출하는 함수
  const parseContentJson = (contentJson) => {
    try {
      const parsedContent = JSON.parse(contentJson);
      return parsedContent.blocks
        .map(block => block.data.text)
        .join('\n');
    } catch (error) {
      console.error('Error parsing contentJson:', error);
      return '내용을 불러오는 데 오류가 발생했습니다.';
    }
  };

  return (
    <>
      <TopBar title="고객센터"/>
      <section className="section-customer-center">
        <div className="slide-tabs purple pl0 pr0 relative">
          <div className="tabs">
            {tabs.map((tab, index) => (
              <button
                key={index}
                onClick={() => handleTabClick(tab)}
                className={`w100p tab ${tab.class} ${activeTab === tab.name ? 'active' : ''}`}
              >
                {tab.name}
              </button>
            ))}
          </div>
        </div>
        <ul className="toggle-list mt54">
          {pageData.length > 0 ? (
            pageData.map((page, index) => (
              <li key={index}>
                <button className="q" type="button" onClick={() => toggleQuestion(index)}>
                  <div className="title-wrap">
                    <span className="badge">{page.node.pageType.name}</span>
                    <div className="title">
                      {page.node.title}
                    </div>
                  </div>
                  <div className="date">
                    {new Date(page.node.publicationDate).toLocaleDateString().replace(/\.$/, '')}
                  </div>
                </button>
                {activeQuestion === index && (
                  <button className="a" onClick={() => toggleQuestion(index)}>
                    {parseContentJson(page.node.contentJson)}
                  </button>
                )}
              </li>
            ))
          ) : (
            <li>내용이 없습니다.</li>
          )}
        </ul>
      </section>
    </>
  );
}

export default CustomerCenterPage;
