import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';

import TopBar from '../../layouts/top_bar/TitleBar';

import CouponsAllSection from '../../sections/coupon/CouponsAllSection'
import CouponsUserSection from '../../sections/coupon/CouponsUserSection'

function CouponPage() {
  const { Page2depth } = useParams();
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(1);

  // 현재 위치에 따른 페이지 인덱스 설정
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/coupons/1')) {
      setPageIndex(1);
    } else if (location.pathname.includes('/coupons/2')) {
      setPageIndex(2);
    }
  }, [location]);

  return (
    <>
      <TopBar  
        title={'쿠폰'}
        url='/'
      />
      <article id="couponPage">
        <div className="switch-tab">
          <button 
            type="button" 
            className={`tab ${pageIndex === 1 ? 'active' : ''}`}
            onClick={() => {navigate('/coupons/1'); setPageIndex(1);}}
          >
            보유쿠폰
          </button>
          <button 
            type="button" 
            className={`tab ${pageIndex === 2 ? 'active' : ''}`}
            onClick={() => {navigate('/coupons/2'); setPageIndex(2);}}
          >
            쿠폰받기
          </button>
        </div>
        {Page2depth === '1' && <CouponsUserSection/>}
        {Page2depth === '2' && <CouponsAllSection/>}
      </article>
    </>
  )
}

export default CouponPage;
