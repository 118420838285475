import React,{ useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { meData } from '../../services/GQ_apis/me';
import { getCheckout, updateShippingMethod } from '../../services/GQ_apis/checkout';
import { updateCheckoutShippingAddress, updateBillingAddress } from '../../services/GQ_apis/address';
import EasyPayCheckoutPage from "../../pages/pay/EasyPayCheckoutPage"; // 이지페이 결제 체크아웃
import usePopupStore from '../../store/common/usePopupStore';
import useAuthStore from '../../store/common/useAuthStore';

const PayProcessSection = () => {

  // init [START]
  const { globalObserver } = useAuthStore();
  const { openToastPopup } = usePopupStore((state) => ({ openToastPopup: state.openToastPopup}));
  const [isOrderOpen, setIsOrderOpen] = useState({}); // 각 브랜드별로 열림/닫힘 상태를 관리
  // const [isCouponOpen, setIsCouponOpen] = useState(false);
  const [tabList, setTabList] = useState([]);
  const [addressActiveTab, setAddressActiveTab] = useState({});
  // init [END]


  const [checkouts, setCheckouts] = useState({});
  const [orderLists, setOrderLists] = useState({});

  const [hasM,setHasM] = useState(false);

  // fetchCheckout = 체크아웃 불러오기
  const fetchCheckout = (isFirst = false) => {

    // 1. 배송지 선택 [START]---------------------
    meData().then((res) => {
      setTabList(res.data.me.addresses);
      if (!isFirst) return;
      const defaultShippingAddress = res.data.me.addresses.find(address => address.isDefaultShippingAddress === true);
      if (defaultShippingAddress) {
        setAddressActiveTab(defaultShippingAddress);
      } else {
        setAddressActiveTab(res.data.me.addresses[0]);
      }
    });
    // 배송지 선택 [END]--------------------------


    // 체크아웃 불러오기 (checkouts, orderLists) [START] ---------------------
    const vendorIds = Object.keys(localStorage).filter(key => key.startsWith('paymentToken_'));

    const checkoutPromises = vendorIds.map(vendorKey => {
      const paymentToken = localStorage.getItem(vendorKey);
      return getCheckout(paymentToken).then(res => {
        const checkoutData = res.data.checkout;

        console.log('checkoutData', res);

        return {
          checkout: checkoutData,
          orderList: checkoutData.lines,
          vendorId: vendorKey.split('paymentToken_')[1],
        };

      });
    });

    Promise.all(checkoutPromises).then(allCheckouts => {

      const updatedCheckouts = {};
      const updatedOrderLists = {};

      allCheckouts.forEach(({ checkout, orderList, vendorId }) => {
        updatedCheckouts[vendorId] = checkout;
        updatedOrderLists[vendorId] = orderList;

        console.log('before updateShippingMethod');

        if (checkout.availableShippingMethods.length > 0 && ! checkout.deliveryMethod) {
          updateShippingMethod(checkout.id, checkout.availableShippingMethods[0].id).then((res)=>{
            console.log('after updateShippingMethod', res);
            setHasM(true);
          });
        }
      });
      setCheckouts(updatedCheckouts);
      setOrderLists(updatedOrderLists);

      // console.log('updatedCheckouts',updatedCheckouts);
      // console.log('updatedOrderLists',updatedOrderLists);

    }).catch(error => {
      console.error('Error fetching checkout information:', error);
    });
    // 체크아웃 불러오기 (checkouts, orderLists) [END] ---------------------

  };

  // 토글 버튼 클릭 핸들러
  const handleToggle = (vendorId) => {
    setIsOrderOpen(prevState => ({
      ...prevState,
      [vendorId]: !prevState[vendorId] // 해당 vendorId의 상태를 반전시킴
    }));
  };

  const calculateTotals = () => {
    let totalOrderAmount = 0;
    let totalShippingAmount = 0;
    let totalDiscountAmount = 0;
    let totalPaymentAmount = 0;

    Object.values(checkouts).forEach(checkout => {
      totalOrderAmount += checkout.subtotalPrice.gross.amount;
      totalShippingAmount += checkout.shippingPrice.gross.amount;
      totalDiscountAmount += checkout.discount.amount;
      totalPaymentAmount += checkout.totalPrice.gross.amount;
    });

    return {
      totalOrderAmount,
      totalShippingAmount,
      totalDiscountAmount,
      totalPaymentAmount,
    };
  };

  const totals = calculateTotals();

  // 배송지 변경하기 [START]
  const changeAddress = (tab) => {
    if (!tab || !addressActiveTab.country || !addressActiveTab.metadata) return;

    setAddressActiveTab(tab);
  };

  useEffect(() => {
    changeAddress(addressActiveTab);
    console.log('tabList', tabList);
  }, [tabList, addressActiveTab]);
  // 배송지 변경하기 [END]
  
  useEffect(() => {

    if ( !addressActiveTab.country || !addressActiveTab.metadata) return;

    const newAddress = {
      "city": addressActiveTab.city,
      "cityArea": addressActiveTab.cityArea,
      "firstName": addressActiveTab.firstName,
      "phone": addressActiveTab.phone,
      "country": addressActiveTab.country.code,
      "countryArea": addressActiveTab.countryArea,
      "postalCode": addressActiveTab.postalCode,
      "streetAddress1": addressActiveTab.streetAddress1,
      "streetAddress2": addressActiveTab.streetAddress2,
      "metadata": addressActiveTab.metadata.map(item => ({
        key: item.key,
        value: item.value
      }))
    };

    Object.keys(checkouts).forEach(vendorId => {
      const checkoutId = checkouts[vendorId].id;
      updateCheckoutShippingAddress(checkoutId, newAddress).then((res) => {
        console.log('updateCheckoutShippingAddress',res);
        updateBillingAddress(checkoutId, newAddress);
        if(! hasM) fetchCheckout(true);
      }).catch((err)=>{
        console.log('updateCheckoutShippingAddress err :', err);
      })
    });

  },[checkouts, addressActiveTab])

  useEffect(() => {
    fetchCheckout(true);
  }, [globalObserver]);

  if (Object.keys(orderLists).length === 0) { 
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  const payDataArray = Object.values(checkouts);

  return (
    <>
      <section className="section-pay-process">
        <div className="white-wrap">
          <div className="h4-title-wrap">
            <h4 className="title">배송지</h4>
            <Link to="/delivery/list" title="" className="a-more-btn">
              배송지 관리
            </Link>
          </div>
          <div className="location-tabs pt14">
            <ul className="tabs">
              {tabList.map((tab) => (
                <li key={tab.id}>
                  <button
                    type="button"
                    className={`tab ${addressActiveTab.id === tab.id ? "active" : ""}`}
                    onClick={() => changeAddress(tab)}
                  >
                    {tab.metadata[0].value}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {addressActiveTab ? (
            <div className="address-wrap">
              <strong className="user-info">
                <span className="name">{addressActiveTab.firstName}</span>
                {addressActiveTab.phone}
                {addressActiveTab.isDefaultShippingAddress && (
                  <span className="badge white">기본배송지</span>
                )}
              </strong>
              <p className="address">{addressActiveTab.streetAddress1} {addressActiveTab.streetAddress2}</p>
              {/* <div className="input-box">
                <div className="input">
                  <select>
                    <option>배송메모를 선택해주세요.</option>
                    <option>1</option>
                    <option>2</option>
                  </select>
                </div>
              </div> */}
            </div>
          ) : (
            <div>기본 배송지를 등록해주세요.</div>
          )}
        </div>

        {Object.keys(orderLists).map(vendorId => (
          <div key={vendorId} className={isOrderOpen[vendorId] ? "white-wrap toggle-wrap active" : "white-wrap toggle-wrap"}>
            <div className="h4-title-wrap mb10">
              {
                orderLists[vendorId].length > 0 &&
                <h4 className="title mb0">{orderLists[vendorId][0].variant.product.vendor.storeNameKo}</h4>
              }
              <button 
                type="button" 
                title="토글버튼" 
                className="toggle big active"
                onClick={() => handleToggle(vendorId)}
              >
                <span className="icon-under"></span>
              </button>
            </div>
            <ul className="shopping-list">
              {orderLists[vendorId].map((order, index) => (
                <li className="card shopping" key={order.id}>
                  <div className="img-wrap">
                    <img 
                      src={order.variant.product.thumbnail.url} 
                      alt={order.variant.product.thumbnail.alt} 
                    />
                  </div>
                  <div className="info-wrap">
                    <div className="order-number">
                      {order.id}
                    </div>
                    <div className="title">
                      {order.variant.product.name}
                    </div>
                    <div className="price-wrap">
                      {order.undiscountedTotalPrice.amount !== order.totalPrice.gross.amount && (
                        <div className="sale-price">
                          <b className="f-purple f10 mr4">쿠폰할인가</b>
                          <del className="f10 f-black50">{window.WSformatPrice(order.undiscountedTotalPrice.amount)}</del>
                        </div>
                      )}
                      <div className="now-price">
                        {window.WSformatPrice(order.totalPrice.gross.amount)}
                      </div>
                      <div className="after-price">
                        {order.variant.name} / {order.variant?.attributes[0]?.values[0]?.name} / 수량 : {order.quantity}
                      </div>
                    </div>
                    <div className="badge-wrap">
                      <button 
                        type="button" 
                        className="badge coupon"
                        onClick={() => openToastPopup('쿠폰조회')}
                      >
                        쿠폰사용
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}

        {/* <div className={isCouponOpen ? "white-wrap toggle-wrap active" : "white-wrap toggle-wrap"}>
          <div className="h4-title-wrap mb10">
            <h4 className="title mb0">쿠폰</h4>
            <button 
              type="button" 
              title="토글버튼" 
              className="toggle big active"
              onClick={() => setIsCouponOpen(!isCouponOpen)}
            />
          </div>
          <div className="input-box">
            <label htmlFor="input1" className="hide">쿠폰 조회</label>
            <div className="input">
              <input 
                id="input1" 
                type="text" 
                value={totals.totalDiscountAmount === 0 ? '0' : `- ${window.WSformatPrice(totals.totalDiscountAmount)}`}
                readOnly
              />
              <button 
                type="button" 
                className="btn purple"
                onClick={() => openToastPopup('쿠폰조회')}
              >
                쿠폰 조회
              </button>
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="input2" className="hide">모두 사용</label>
            <div className="input type2">
              <input id="input2" type="number" value="0" readOnly />
              <button type="button" className="btn purple" disabled>모두 사용</button>
            </div>
          </div>
          <span className="f-purple f14 a-right mt10">보유 적립금 : 0</span>
        </div> */}

        <div className="white-wrap pl0 pr0 pt0 pb0">
          {/* 결제 페이지: 모든 브랜드의 데이터를 배열로 전달 */}
          <EasyPayCheckoutPage payData={payDataArray} />
        </div>

        <div className="white-wrap pb10">
          <div className="h4-title-wrap border">
            <h4 className="title">결제 금액</h4>
          </div>
          <ul className="order-list">
            <li><span>총 주문 금액</span><b>{window.WSformatPrice(totals.totalOrderAmount)}</b></li>
            <li><span>총 배송비</span><b>{window.WSformatPrice(totals.totalShippingAmount)}</b></li>
            <li><span>총 할인금액</span>
              <b>
                {totals.totalDiscountAmount === 0 ? '0원' : `- ${window.WSformatPrice(totals.totalDiscountAmount)}`}
              </b>
            </li>
            <li>
              <span>총 결제 금액</span><b className="f-purple f16">
                {window.WSformatPrice(totals.totalPaymentAmount)}
              </b>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default PayProcessSection;