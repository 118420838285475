import {create} from 'zustand';

const useMainStore = create(set => ({
  currentPage: 0,
  activeTab: 'HOME',
  setCurrentPage: (pageIndex) => set({ currentPage: pageIndex }),
  setActiveTab: (tabName) => set({ activeTab: tabName }),
}));

export default useMainStore;
