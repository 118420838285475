import React, { useEffect } from 'react';
import useInputStore from '../../store/common/useInputStore'
import { useNavigate } from "react-router-dom";

import smapleImg from "../../assets/images/common/icons/icon_profile.svg";

const VendorRegister04Section = () => {

  const navigate = useNavigate();

  const {
    input,
    actions,
  } = useInputStore(state => ({
    input : state.input,
    actions: state.actions,
  }));
  
  // 마운트시 resetInput 초기화
  useEffect(() => {
    actions.resetInput();
  }, [actions.resetInput]);

  // 필수 입력 항목 검사
  const validateInput = () => {
    return input.kidNickName && input.kidGender && input.kidHeight && input.kidWeight && input.kidFootSize;
  };

  return (
    <>
      <section className="section-register">
        <div className="inner">
          {/* 타이틀 영역 [START] */}
          <div className="h3-title-wrap mt0">
            <h3 className="title">아이 프로필 입력</h3>
            <p className="text">
              아이에게 맞는 <span className="f-purple">옷을 추천받기 위한</span><br/> 기본 정보를 입력해주세요.
            </p>
            <div className="pagination">
              <span className="item active">
                1
              </span>
              <span className="item">
                2
              </span>
              <span className="item">
                3
              </span>
              <span className="item">
                4
              </span>
            </div>
          </div>
          {/* 타이틀 영역 [END] */}

          {/* 프로필 영역 [START] */}
          <div className="profile-img-wrap">
            <label htmlFor="profileImgInput" className="upload-button">
              <div className="img-wrap">
                <img 
                  src={input.profileImg || smapleImg } 
                  alt="프로필 이미지" 
                  title="클릭시 프로필 사진 첨부 이동"
                />
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={actions.handleFileChange}
                style={{ display: 'none' }}
                id="profileImgInput"
              />
              <p className="title">프로필 사진</p>
            </label>
          </div>
          {/* 프로필 영역 [END] */}

          {/* 별명 [START] */}
          <div className="input-box">
            <label htmlFor="nickname" className="title starred">
              별명
            </label>
            <div className="input">
              <input 
                id="nickname" 
                type="text" 
                placeholder="별명을 입력해주세요."
                onInput={(e) => actions.setKidNickName(e.target.value)}
              />
            </div>
          </div>
          {/* 별명 [END] */}

          {/* 성별 선택 & 생년월일 [START] */}
          <div className="input-box-wrap">
            <div className="input-box">
              <div className="title starred">성별선택</div>
              <div className="input">
                <select 
                  className={input.kidGender !== '0' ? 'selected' : ''}
                  onInput={(e) => actions.setKidGender(e.target.value)}
                >
                  <option value="0">성별선택</option>
                  <option value="boy">남</option>
                  <option value="girl">여</option>
                </select>
              </div>
            </div>
            <div className="input-box">
              <label htmlFor="date" className="title">
                생년월일
              </label>
              <div className="input">
                <input 
                  id="date" 
                  type="date"
                  placeholder="생년월일을 입력해주세요."
                />
              </div>
            </div>
          </div>
          {/* 성별 선택 & 생년월일 [END] */}

          {/* 키 & 몸무게 [START] */}
          <div className="input-box-wrap">
            <div className="input-box">
              <label htmlFor="height" className="title starred">키(cm)</label>
              <div className="input">
                <input 
                  id="height" 
                  type="number" 
                  placeholder="키"
                  onInput={(e) => actions.setKidHeight(e.target.value)}
                >
                </input>
                <span className="inner-text">cm</span>
              </div>
            </div>
            <div className="input-box">
              <label htmlFor="weight" className="title starred">몸무게(kg)</label>
              <div className="input-box">
                <div className="input">
                  <input 
                    id="weight" 
                    type="number" 
                    placeholder="몸무게"
                    onInput={(e) => actions.setKidWeight(e.target.value)}
                  >
                  </input>
                  <span className="inner-text">kg</span>
                </div>
              </div>
            </div>
          </div>
          {/* 키 & 몸무게 [END] */}

          {/* 발 & 머리 둘레 [START] */}
          <div className="input-box-wrap">
            <div className="input-box">
              <label htmlFor="foot" className="title starred">발(cm)</label>
              <div className="input">
                <input 
                  id="foot" 
                  type="number" 
                  placeholder="발"
                  onInput={(e) => actions.setKidFootSize(e.target.value)}
                ></input>
                <span className="inner-text">cm</span>
              </div>
            </div>
            <div className="input-box">
              <label htmlFor="weight" className="title">머리 둘레(cm)</label>
              <div className="input-box">
                <div className="input">
                  <input 
                    id="weight" 
                    type="number" 
                    placeholder="머리 둘레"
                    onInput={(e) => actions.setKidHeadSize(e.target.value)}
                  >
                  </input>
                  <span className="inner-text">cm</span>
                </div>
              </div>
            </div>
          </div>
          {/* 발 & 머리 둘레 [END] */}

          <div className="debug-box mt20">
            <p><b>아이 별명 :</b> {input.kidNickName}</p>
            <p><b>아이 성별 :</b> {input.kidGender}</p>
            <p><b>아이 출생시간 :</b> {input.kidBirthDay}</p>
            <p><b>아이 키 :</b> {input.kidHeight}</p>
            <p><b>아이 몸무게 :</b> {input.kidWeight}</p>
            <p><b>아이 발사이즈 :</b> {input.kidFootSize}</p>
            <p><b>아이 머리사이즈 :</b> {input.kidHeadSize}</p>
          </div>   

        </div>
      </section>
      {/* 다음 버튼 [START]*/}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              disabled={!validateInput()}
              className="btn purple big"
              onClick={()=>navigate('/register/kid-2')}
            >
              다음
            </button>
          </div>
        </div>
      </div>
      {/* 다음 버튼 [END]*/}
    </>
  );
};

export default VendorRegister04Section;

