import React, { useEffect, useState } from "react";
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { getBannersById } from '../../services/REST_apis/banner';

const BannerASection = ({ id }) => {
  const [slides, setSlides] = useState([]);
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  useEffect(() => {
    const fetchBanners = async () => {
      if (id) {
        try {
          const data = await getBannersById(id);
          const bannerData = window.WSremovePrefix(data)[id];
          
          const banners = bannerData.banner;
          setCategory(bannerData.category);

          const newSlides = banners.map(banner => ({
            img: banner.banner_img_url, 
            title: banner.description,
            src: banner.banner_ref_url
          }));
          setSlides(newSlides);
        } catch (error) {
          console.error('Error fetching banner data:', error);
        } finally {
          setLoading(false); // 로딩 완료
        }
      }
    };

    fetchBanners();
  }, [id]);

  // 로딩 중이면 렌더링하지 않음
  if (loading) {
    return null; // 로딩 중일 때는 아무것도 렌더링하지 않음
  }

  return (
    <>
      {/* ID: {id} | type: A | {category.id} */}
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        speed={900}
        loop={slides.length > 1 ? true : false}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        pagination={{
          type: 'fraction',
          clickable: true
        }}
        className="banner banner-a"
        touchMoveStopPropagation={true} // 부모 스와이퍼에 이벤트 전파 중지
        nested={true}
      >
        {
          slides.map((slide,index)=>(
            <SwiperSlide key={index}>
              <a href={slide.src} title="" target="_blank" rel="noopener noreferrer">
                <img src={slide.img} alt={slide.title} />
              </a>
              {id}
            </SwiperSlide>
          ))
        }
      </Swiper>
    </>
  );
};

export default BannerASection;
