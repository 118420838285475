import React, { useState, useEffect } from "react";
import sampleImage from "../../assets/images/sample/sample_product_detail.png";
import { fetchPages } from "../../services/GQ_apis/page";
import TopBar from '../../layouts/top_bar/TitleBar'; // TopBar import 추가
import SmallTab from '../../components/Tabs/SmallTab'; 
import { useNavigate,Link } from 'react-router-dom'; 
import { useParams } from 'react-router-dom';

// 스몰탭 리스트
const smallTabs = [
  { key: 'all', label: 'All' },
  { key: 'product', label: '상품문의' },
  { key: 'size', label: '사이즈문의' },
  { key: 'delivery', label: '배송문의' },
  { key: 'etc', label: '기타문의' },
];

const Product04Section = () => {
  const navigate = useNavigate();
  const [activeSmallTab, setActiveSmallTab] = useState("all");
  const [pageData, setPageData] = useState([]);
  const { Page2depth } = useParams();
  
  const handleSmallTabClick = (key) => {
    setActiveSmallTab(key);
    // 여기에 클릭 시 추가 로직이 필요하면 추가 가능
  };

  useEffect(() => {
    const filter = {
      pageTypes: "UGFnZVR5cGU6Ng==", //문의 페이지타입
      metadata: [{key: "product_id", value: Page2depth}] // 선택한 상품만 선택
    };

    fetchPages(filter).then((res) => {
      console.log('Fetched pages:', res.data.pages.edges);
    
      // publicationDate를 기준으로 내림차순 정렬
      let sortedPages = [...res.data.pages.edges].sort((a, b) => {
        return new Date(b.node.publicationDate) - new Date(a.node.publicationDate);
      });
    
      setPageData(sortedPages);
    }).catch(error => {
      console.error('Error fetching pages:', error);
    });

  }, []); // Dependency array 추가

  return (
    <>
      <TopBar title="상품 상세" />
      <section className="section-product-info">
        <div className="pl16 pr16 pb16 pt20">
          <div className="h4-class-wrap flex between mb20">
            <h4 className="title">
              문의 <b className="f-purple">{pageData.length}</b>
            </h4>
            <Link to={`/question-write/${Page2depth}`}><b className="f14 f-purple a-more-btn">문의 작성하기</b></Link>
          </div>

          {/*  스몰탭 [START] */}
          {/* <SmallTab
            tabs={smallTabs}
            activeTab={activeSmallTab}
            onTabClick={handleSmallTabClick}
          /> */}
          {/* 스몰탭 [END] */}
        </div>

        {pageData.map((page, index) => (
          <div className="card review2" key={index}>
            <div className="profile-wrap">
              <div className="img-wrap">
                <img src={page.node.metafields.user_thumbnail} alt="Profile" />
              </div>
              <strong className="f14">
                {page.node.metafields.user_id.length > 3 
                  ? `${page.node.metafields.user_id.substring(0, 3)}***` // 닉네임 뒤에 ***표시
                  : page.node.metafields.user_id}
              </strong>
              
              <span
                className={!page?.node?.metafields?.answer 
                  ? "status" 
                  : "answer-status"}
              >
                {!page?.node?.metafields?.answer 
                  ? "답변 대기" 
                  : "답변 완료"}
              </span>

              <span className="date">{new Date(page.node.publicationDate).toLocaleDateString().replace(/\.$/, '')}</span>
            </div>
            <div className="review mt10">
              <p className="f13">
                {page.node.title}
              </p>

            </div>
            <div className="card answer flex between mt10">
              <p className="f13 ">
                {page?.node?.metafields?.answer  || "답변 대기중..."}
              </p>
              <p className="f13">
                {page?.node?.metafields?.answer_date 
                  ? new Date(page.node.metafields.answer_date).toLocaleDateString().replace(/\.$/, '') 
                  : ""}
              </p>
            </div>
          </div>
        ))}
      </section>
    </>
  );
}

export default Product04Section;
