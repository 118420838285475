import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import usePopupStore from '../../store/common/usePopupStore'
import { fetchVouchers, fetchUserVouchers, TGVoucherDownload } from '../../services/GQ_apis/discounts';
import useAuthStore from '../../store/common/useAuthStore';

const MyCouponSection = () => {

  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [pageIndex, setPageIndex] = useState(1);

  // 현재 위치에 따른 페이지 인덱스 설정
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/coupons/1')) {
      setPageIndex(1);
    } else if (location.pathname.includes('/coupons/2')) {
      setPageIndex(2);
    }
  }, [location]);

  // SnackBar 팝업 사용 함수 openSnackBarPopup
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup, // 스낵바 오픈 함수
  }));


  // 쿠폰 다운로드
  const voucherdownload = () => {
    TGVoucherDownload('86d84d85-2409-4183-a3d6-c6f267dc36c6').then((res)=>{
      console.log(res);
      openSnackBarPopup('정상적으로 쿠폰을 다운로드하였습니다.','','')
    });
  }

  const [couponList2, setCouponList2] = useState([]);

  useEffect(()=>{
    fetchUserVouchers(user.email).then((res)=>{
      console.log('vouchers');
      console.log(res);
      // console.log(res.data.TGvouchers.edges);
      // setCouponList2(res.data.TGvouchers.edges);
      console.log('vouchers');
    })
  },[])


  return (
    <>
      <section className="section-coupons">
        <div className="inner">
          <div className="switch-tab">
            <button 
              type="button" 
              className={`tab ${pageIndex === 1 ? 'active' : ''}`}
              onClick={() => {navigate('/coupons/1'); setPageIndex(1);}}
            >
              보유쿠폰
            </button>
            <button 
              type="button" 
              className={`tab ${pageIndex === 2 ? 'active' : ''}`}
              onClick={() => {navigate('/coupons/2'); setPageIndex(2);}}
            >
              쿠폰받기
            </button>
          </div>
          {
            pageIndex === 1 &&
            <>
              <strong className="title">사용할 수 있는 쿠폰 <b className="f-purple">1개</b></strong>
              <ul className="coupons-list">
                <li className="coupon">
                  <div className="top-wrap">
                    <strong className="f-purple">
                      50%
                    </strong>
                    <button type="button" className="btn white round">
                      <span className="icon-coupon white">
                      </span>
                      사용 가능
                    </button>
                  </div>
                  <div className="bottom-wrap">
                    <strong className="title">
                      장희님 전용 쿠폰
                    </strong>
                    <p className="text">
                      키즈 상품 할인 적용 가능 쿠폰               
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                    </p>
                    <strong className="d-day">
                      만료까지 D-3
                      <span className="caption">10,000이상 구매시 사용가능</span>
                    </strong>
                  </div>
                </li>
              </ul>
            </>
          }
          {
            pageIndex === 2 &&
            <>
              <strong className="title">받을 수 있는 쿠폰 <b className="f-purple">{couponList2.length}개</b></strong>
              <ul className="coupons-list">
                {
                  couponList2.map((coupon,index)=>(
                    <li className="coupon" key={coupon.node.id}>
                      <div className="top-wrap">
                        <strong className="f-purple">
                          50%
                        </strong>
                        <button 
                          type="button" 
                          className="btn white round"
                          onClick={()=>{voucherdownload(coupon.node.code)}}
                        >
                          <span className="icon-download">
                          </span>
                          다운로드 
                        </button>
                      </div>
                      <div className="bottom-wrap">
                        <strong className="title">
                          {coupon.node.name}
                        </strong>
                        <p className="text">
                          키즈 상품 할인 적용 가능 쿠폰
                        </p>
                        <strong className="d-day">
                          만료까지 D-3
                          <span className="caption">10,000이상 구매시 사용가능</span>
                        </strong>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </>
          }
        </div>
      </section>
      {/* 쿠폰 전체 다운받기 버튼 [START]*/}
      {
        pageIndex === 2 &&

        <div className="bottom-bar">
          <div className="bottom-bar-inner pr0 pl0">
            <div className="btn-wrap">
              <button 
                type="button" 
                onClick={()=>openSnackBarPopup('정상적으로 쿠폰을 다운로드하였습니다.','','')}
                className="btn purple big"
              >
                쿠폰 전체 다운받기
              </button>
            </div>
          </div>
        </div>
      }
      {/* 쿠폰 전체 다운받기 버튼 [END]*/}
    </>
  );
};

export default MyCouponSection;

