import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import usePopupStore from '../../store/common/usePopupStore';

const OrderStatusCard = ({order, index}) => {

  const navigate = useNavigate();

  const { openToastPopup, openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
    openToastPopup: state.openToastPopup,
  }));

  // useEffect(()=>{
  //   console.log('order', order);
  // },[order])

  return (
    <>
      {/* 상품 준비중 UNCONFIRMED, UNFULFILLED [START] */}
      {
        (order.node.status === 'UNCONFIRMED' || order.node.status === 'UNFULFILLED') &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              주문번호 {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              주문 상세보기
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">상품 준비중</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <img src={line.thumbnail.url} alt=""></img>
                  }
                </div>
                <div className="text-wrap">
                  <p className="date">05/23(목)까지 배송 예정</p>
                  {
                    line.variant && line.variant.product &&

                    <>
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">{line.totalPrice.gross.amount}원 / {line.quantity}개</p>
                    </>
                  }
                </div>
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            <button 
              type="button" 
              className="btn gray"
              // onClick={()=>alert('test')}
              onClick={() => openToastPopup('주문 취소요청',order.node.id)}
            >
              취소요청
            </button>
            {/* <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate('/mymenu/request-cancel1')}
            >
              장바구니 담기
            </button> */}
          </div>
        </li>
      }
      {/* 상품 준비중 UNCONFIRMED, UNFULFILLED [END] */}

      {/* 배송중 FULFILLED [START] */}
      {
        order.node.status === 'FULFILLED' &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              주문번호 {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              주문 상세보기
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">배송중</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <img src={line.thumbnail.url} alt=""></img>
                  }
                </div>
                <div className="text-wrap">
                  <p className="date">05/23(목)까지 배송 예정</p>
                  {
                    line.variant && line.variant.product &&

                    <>
                      
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">{line.totalPrice.gross.amount}원 / {line.quantity}개</p>
                    </>
                  }
                </div>
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            <button 
              type="button" 
              className="btn purple10"
              onClick={()=>alert('굿스플로 API 연동 예정')}
            >
              배송 상세보기
            </button>
          </div>
        </li>
      }
      {/* 배송중 FULFILLED [END] */}

      {/* 배송완료 DELIVERED [START] */}
      {
        order.node.status === 'DELIVERED' &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              주문번호 {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              주문 상세보기
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">배송완료</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <img src={line.thumbnail.url} alt=""></img>
                  }
                </div>
                <div className="text-wrap">
                  <p className="date">05/23(목)까지 배송 예정</p>
                  {
                    line.variant && line.variant.product &&

                    <>
                      
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">{line.totalPrice.gross.amount}원 / {line.quantity}개</p>
                      <div className="badge-wrap">
                        <button 
                          type="button" 
                          className="badge coupon"
                        >
                          리뷰쓰기
                        </button>
                      </div>
                    </>
                  }
                </div>
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            <button 
              type="button" 
              className="btn purple10"
              // onClick={()=>alert('교환요청 기능 개발 예정')}
              onClick={()=>navigate(`/order/request-exchange/${order.node.id}`)}
            >
              교환요청
            </button>
            <button 
              type="button" 
              className="btn gray"
              onClick={()=>alert('반품요청 기능 개발 예정')}
              // onClick={()=>navigate(`/order/request-return/${order.node.id}`)}
            >
              반품요청
            </button>
          </div>
        </li>
      }
      {/* 배송완료 DELIVERED [END] */}

      {/* 교환요청 REQUEST_REPLACE [START] */}
      {
        order.node.status === 'REQUEST_REPLACE' &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              주문번호 {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              주문 상세보기
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">교환 대기중</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <img src={line.thumbnail.url} alt=""></img>
                  }
                </div>
                <div className="text-wrap">
                  <p className="date">05/23(목)까지 배송 예정</p>
                  {
                    line.variant && line.variant.product &&

                    <>
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">{line.totalPrice.gross.amount}원 / {line.quantity}개</p>
                    </>
                  }
                </div>
              </div>
            ))
          }
        </li>
      }
      {/* 교환요청 REQUEST_REPLACE [END] */}

      {/* 교환처리됨 REPLACED [START] */}
      {
        order.node.status === 'REPLACED' &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              주문번호 {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              주문 상세보기
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">교환 처리됨</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <img src={line.thumbnail.url} alt=""></img>
                  }
                </div>
                <div className="text-wrap">
                  <p className="date">05/23(목)까지 배송 예정</p>
                  {
                    line.variant && line.variant.product &&

                    <>
                      
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">{line.totalPrice.gross.amount}원 / {line.quantity}개</p>
                    </>
                  }
                </div>
              </div>
            ))
          }
        </li>
      }
      {/* 교환처리됨 REPLACED [END] */}

      {/* 반품요청 REQUEST_RETURN [START] */}
      {
        (
          order.node.status === 'REQUEST_RETURN'
        ) &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              주문번호 {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              주문 상세보기
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">반품 대기중</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <img src={line.thumbnail.url} alt=""></img>
                  }
                </div>
                <div className="text-wrap">
                  <p className="date">05/23(목)까지 배송 예정</p>
                  {
                    line.variant && line.variant.product &&

                    <>
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">{line.totalPrice.gross.amount}원 / {line.quantity}개</p>
                    </>
                  }
                </div>
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate('/mymenu/request-cancel1')}
            >
              취소요청
            </button>
            {/* <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate('/mymenu/request-cancel1')}
            >
              장바구니 담기
            </button> */}
          </div>
        </li>
      }
      {/* 반품요청 REQUEST_RETURN [END] */}

      {/* 반품 처리됨 RETURNED [START] */}
      {
        (
          order.node.status === 'RETURNED'
        ) &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              주문번호 {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              주문 상세보기
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">반품 처리됨</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <img src={line.thumbnail.url} alt=""></img>
                  }
                </div>
                <div className="text-wrap">
                  <p className="date">05/23(목)까지 배송 예정</p>
                  {
                    line.variant && line.variant.product &&

                    <>
                      
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">{line.totalPrice.gross.amount}원 / {line.quantity}개</p>
                      {/* <div className="badge-wrap">
                        <button 
                          type="button" 
                          className="badge coupon"
                        >
                          리뷰쓰기
                        </button>
                      </div> */}
                    </>
                  }
                </div>
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            <button 
              type="button" 
              className="btn purple10"
              // onClick={()=>navigate('/mymenu/request-cancel1')}
            >
              배송 상세 보기
            </button>
          </div>
        </li>
      }
      {/* 반품 처리됨 RETURNED [END] */}

      {/* 취소요청 REQUEST_CANCEL [START] */}
      {
        (
          order.node.status === 'REQUEST_CANCEL'
        ) &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              주문번호 {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              주문 상세보기
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">취소 처리중</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <img src={line.thumbnail.url} alt=""></img>
                  }
                </div>
                <div className="text-wrap">
                  <p className="date">05/23(목)까지 배송 예정</p>
                  {
                    line.variant && line.variant.product &&

                    <>
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">{line.totalPrice.gross.amount}원 / {line.quantity}개</p>
                    </>
                  }
                </div>
              </div>
            ))
          }
        </li>
      }
      {/* 취소요청 REQUEST_CANCEL [END] */}

      {/* 취소완료(환불됨) CANCELED + FULLY_REFUNDED [START] */}
      {
        (
          order.node.status === 'CANCELED' &&
          order.node.paymentStatus === 'FULLY_REFUNDED'

        ) &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              주문번호 {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              주문 상세보기
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">취소</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <img src={line.thumbnail.url} alt=""></img>
                  }
                </div>
                <div className="text-wrap">
                  <p className="date">05/23(목)까지 배송 예정</p>
                  {
                    line.variant && line.variant.product &&

                    <>
                      
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">{line.totalPrice.gross.amount}원 / {line.quantity}개</p>
                      <div className="badge-wrap">
                        <button 
                          type="button" 
                          className="badge coupon"
                        >
                          리뷰쓰기
                        </button>
                      </div>
                    </>
                  }
                </div>
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate('/mymenu/request-cancel1')}
            >
              취소요청
            </button>
            {/* <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate('/mymenu/request-cancel1')}
            >
              장바구니 담기
            </button> */}
          </div>
        </li>
      }
      {/* 취소완료(환불됨) CANCELED [END] */}

      {/* 취소됨 CANCELED [START] */}
      {
        (
          order.node.status === 'CANCELED' &&
          ! order.node.paymentStatus === 'FULLY_REFUNDED'

        ) &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              주문번호 {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              주문 상세보기
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">취소</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <img src={line.thumbnail.url} alt=""></img>
                  }
                </div>
                <div className="text-wrap">
                  <p className="date">05/23(목)까지 배송 예정</p>
                  {
                    line.variant && line.variant.product &&

                    <>
                      
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">{line.totalPrice.gross.amount}원 / {line.quantity}개</p>
                      <div className="badge-wrap">
                        <button 
                          type="button" 
                          className="badge coupon"
                        >
                          리뷰쓰기
                        </button>
                      </div>
                    </>
                  }
                </div>
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate('/mymenu/request-cancel1')}
            >
              취소요청
            </button>
            {/* <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate('/mymenu/request-cancel1')}
            >
              장바구니 담기
            </button> */}
          </div>
        </li>
      }
      {/* 취소됨 CANCELED [END] */}


    </>

  );
};

export default OrderStatusCard;
