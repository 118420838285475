import React, { useEffect } from 'react';
import usePopupStore from '../../store/common/usePopupStore';
import { Link } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

const SnackBarPopup = () => {
  const { 
    SnackBarPopup: snackBarPopupState, // 이름 변경
    closeSnackBarPopup,
  } = usePopupStore((state) => ({
    SnackBarPopup: state.SnackBarPopup,
    closeSnackBarPopup: state.closeSnackBarPopup,
  }));
  
  const [{ opacity }, set] = useSpring(() => ({
    opacity: snackBarPopupState.opacity, // 변경된 이름 사용
    onRest: () => {
      if (snackBarPopupState.opacity === 0) {
        closeSnackBarPopup();
      }
    },
  }));

  useEffect(() => {
    if (snackBarPopupState.display !== 'none') {
      set({ opacity: 1 });
      setTimeout(() => {
        set({ opacity: 0 });
      }, 3000);
    }
  }, [snackBarPopupState.display, set]);

  return (
    <>
      <animated.div 
        id="snackBarPopup" 
        className="popup-wrap" 
        style={{
          opacity,
          display: opacity.to(o => (o === 0 ? 'none' : '')),
        }}
      >
        <div className="inner">
          <p className="text">
            {snackBarPopupState.text}
          </p>
          <Link 
            to={snackBarPopupState.link_url}
            title=""
            onClick={closeSnackBarPopup}
            style={{
              display: snackBarPopupState.link_text && snackBarPopupState.link_url ? '' : 'none'
            }}
          >
            {snackBarPopupState.link_text}
          </Link>
        </div>
      </animated.div>
    </>
  );
}

export default SnackBarPopup;
