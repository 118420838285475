import React, { useState, useRef, useEffect } from "react";
import { useParams } from 'react-router-dom';
import TopBar from '../../layouts/top_bar/TitleBar';
import { createPage } from "../../services/GQ_apis/page" 
import { updateMetadata } from "../../services/GQ_apis/page" 
import { useNavigate } from 'react-router-dom'; 
import { meData } from "../../services/GQ_apis/me";
import useProductStore from '../../store/common/useProductStore';

function QuestionPage() {
  const { Page2depth } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState(""); // 문의 내용 상태
  const [me, setMe] = useState({});
  const { nowProductData } = useProductStore((state) => ({ nowProductData: state.nowProductData }));

  // 문의 내용을 처리하는 함수
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  // JSON 파싱 및 복호화
  const productDataDate = JSON.parse(nowProductData.descriptionJson);

  // 타임스탬프를 날짜로 변환하는 함수
  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString();
    return dateString.endsWith('.') ? dateString.slice(0, -1) : dateString; // 마지막 '.' 제거
  };

  const formattedDate = convertTimestampToDate(productDataDate.time);

  useEffect(() => {
    meData().then((res) => {
      setMe(res.data.me);
    });
  }, []);

  useEffect(()=> {
    console.log('nowProductData',nowProductData);
  },[])

  const pageTypeId = 'UGFnZVR5cGU6Ng=='; //문의
  const publicationDate = new Date().toISOString().split('T')[0];

  const saveQuestion = () => {
    createPage(
      title, 
      pageTypeId,
      publicationDate,
    )
    .then((res) => {
      const pageId = res.data.pageCreate.page.id;
      const metadata = [
        {
          key: "product_id",
          value: Page2depth 
        },
        {
          key: "user_id",
          value: me.metafields.nickName 
        },
        {
          key: "user_thumbnail",
          value: me.metafields.profileImg 
        },
        {
          key: "user_email",
          value: me.email 
        },
        {
          key: "publication_date",
          value: publicationDate 
        },
        {
          key: "answer",
          value: "" 
        },
      ];

      return updateMetadata(pageId, metadata);
    })
    .then(() => {
      alert("문의가 접수되었습니다. 확인 후 신속하게 답변드리겠습니다.");
      navigate(`/product/${Page2depth}/inquiry`); 
    })
    .catch((error) => {
      console.error("페이지 생성 오류:", error);
      alert("페이지 생성 중 오류가 발생했습니다.");
    });
  };

  return (
    <>
      <TopBar title="문의 작성하기" />
      <section id="questionSection" className="mt50">
        <div className="white-wrap">
          <div className="card order">
            <div className="img-wrap">
              <img src={nowProductData.images} alt="Product" />
            </div>
            <div className="text-wrap">
              <p className="date">
                {formattedDate} {/* 변환된 날짜 출력 */}
              </p> 
              <p className="brand">
                {nowProductData.attributes.find(attr => attr.attribute.name === '판매자')?.values[0]?.name}
              </p>
              <p className="name">
                {nowProductData.name}
              </p>
              <p className="price">
                {window.WSformatPrice(nowProductData.pricing.priceRangeUndiscounted.start.gross.amount)}
              </p>
            </div>
          </div>
        </div>
        <div className="white-wrap">
          <div className="input-box textarea">
            <label htmlFor="textarea" className="title">내용</label>
            <textarea 
              id="textarea" 
              placeholder="내용을 입력해주세요." 
              value={title}
              onChange={handleTitleChange}
            />
          </div>
        </div>
      </section>
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big" 
              disabled={title === ""}
              onClick={saveQuestion}
            >
              제출하기
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionPage;
