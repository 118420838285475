import React, { useState } from "react";
import usePopupStore from '../../store/common/usePopupStore';
import entryStore from "../../store/Pages/useEntryStore"
import { useNavigate } from "react-router-dom";

const VendorRegister07Section = () => {

  const navigate = useNavigate();
  const { applicationData, setApplicationData, submitApplication } = entryStore();

  const [checkboxes, setCheckboxes] = useState([
    { title: "(필수) 서비스 이용약관 동의", checked: false },
    { title: "(필수) 판매자 개인정보 수집 동의", checked: false },
    { title: "(필수) 판매수수료/배송비 정책 동의", checked: false },
    { title: "(필수) 입점 정책 동의", checked: false },
  ]);

  const [allChecked, setAllChecked] = useState(false);

  const handleAllCheckChange = () => {
    const newAllChecked = !allChecked;
    setAllChecked(newAllChecked);
    setCheckboxes(checkboxes.map(checkbox => ({ ...checkbox, checked: newAllChecked })));
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = { ...updatedCheckboxes[index], checked: !updatedCheckboxes[index].checked };
    setCheckboxes(updatedCheckboxes);
    setAllChecked(updatedCheckboxes.every(checkbox => checkbox.checked));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(submitApplication()) navigate('/login/main2');
  };

  const { openToastPopup } = usePopupStore((state) => ({
    openToastPopup: state.openToastPopup,
  }));

  // 필수 입력 항목 검사
  const validateInput = () => {
    return checkboxes[0].checked && checkboxes[1].checked && checkboxes[2].checked && checkboxes[3].checked;
  };

  return (
    <>
      <section className="section-register">
        <div className="inner">

          {/* 타이틀 영역 [START] */}
          <div className="h3-title-wrap mt0">
            <h3 className="title">이용약관</h3>
            <p className="text">
              각 이용약관을 자세히 읽고 진행해주세요.
            </p>
            <div className="pagination">
              <span className="item">
                1
              </span>
              <span className="item">
                2
              </span>
              <span className="item active">
                3
              </span>
            </div>
          </div>
          {/* 타이틀 영역 [END] */}

          <div className="input-box">
            <div className="input check bg check-all mb14">
              <label htmlFor="checkAll">
                <input
                  id="checkAll"
                  type="checkbox" 
                  checked={allChecked} 
                  onChange={handleAllCheckChange} 
                />  약관 전체 동의하기
              </label>
            </div>
            <ul className="input-list">
              {checkboxes.map((checkbox, index) => (
                <li key={index} className="input check">
                  <label htmlFor={'check__'+index}>
                    <input
                      id={'check__'+index}
                      type="checkbox" 
                      checked={checkbox.checked} 
                      onChange={() => handleCheckboxChange(index)} 
                    /> {checkbox.title}
                  </label>
                  <button
                    type="button" 
                    className="under-line"
                    onClick={() => openToastPopup(checkbox.title)}
                  >
                    자세히
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {/* 개발 디버그용 [START] */}
          {/* <div className="debug-box mt20">
            <p><b>채널명 :</b>{process.env.REACT_APP_CHANNEL_NAME}</p>
            <p><b>전체 체크(allChecked) :</b>{allChecked ? 'True' : 'False'}</p>
            <p><b>1번 체크(checkboxes[0]) :</b>{checkboxes[0].checked ? 'True' : 'False'}</p>
            <p><b>필수항목체크상태 :</b>{validateInput() ? 'True' : 'False'}</p>
          </div> */}
          {/* 개발 디버그용 [END] */}
        </div>
      </section>
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big"
              disabled={! validateInput()}
              onClick={handleSubmit}
            >
              완료
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorRegister07Section;
