import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../../../store/common/useAuthStore';

const PopupLogout = ({closePopup}) => {

  const navigate = useNavigate();
  const { logout } = useAuthStore();

  const handleLogout = () => {
    logout();
    closePopup();
    navigate('/');
  }

  return (
    <>
      <div className="popup-body">
        <div className="h3-title-wrap">
          <h3 className="title">
            로그아웃
          </h3>
          <p className="mt16 f16">
            정말로 로그아웃하시겠습니까?
          </p>
        </div>
      </div>
      <div className="popup-footer">
        <div className="btn-wrap">
          <button type="button" className="btn gray" onClick={closePopup}>취소</button>
          <button type="button" className="btn purple" onClick={handleLogout}>로그아웃</button>
        </div>
      </div>
    </>
  );
};

export default PopupLogout;
