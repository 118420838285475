import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAuthStore from '../../../store/common/useAuthStore';
import MainLoginBeforeSection from '../../../sections/main/MainLoginBeforeSection';
import MainLoginAfterSection from '../../../sections/main/MainLoginAfterSection';
import GridSection from '../../../sections/grid/GridSection';
import H3Title from '../../../layouts/title/H3Title';
import SwipeGridSection from '../../../sections/grid/SwipeGridSection';

import BannerASection from '../../../sections/banner/BannerASection';
import BannerBSection from '../../../sections/banner/BannerBSection';
import BannerDSection from '../../../sections/banner/BannerDSection';

import BigTab from '../../../components/Tabs/BigTab'; 
import { getCollection } from '../../../services/GQ_apis/collection';

// 빅탭 리스트
const bigTabs = [
  { title: '임부복' },
  { title: '뷰티/헬스' },
  { title: '베이비의류 0~24M' },
  { title: '키즈의류24M~8Y' },
  { title: '주니어의류24M~8Y' },
  { title: '신발악세서리' },
  { title: '기저귀물티슈' },
  { title: '목욕위생세재' },
  { title: '수유기구' },
  { title: '장난감교육교제' },
  { title: '역류방지쿠션매트' },
  { title: '힙시트캐리어유모차' },
  { title: '침구류가구홈데코' },
  { title: '푸드' },
  { title: '3PL물류' },
];

const slugs = [
  'home1',
  'home2',
  'home3',
  'home4',
  'home5',
  'home6',
  'home7',
  'home8',
  'home9',
  'home10',
  'home11'
];

function HomePage() {
  const navigate = useNavigate();
  const { userToken } = useAuthStore();
  const [collection, setCollection] = useState([]);
  
  useEffect(() => {
    getCollections();
  }, []);

  const getCollections = async () => {
    try {
      const res = await getCollection(slugs);
      let data = [...res.data.collections.edges];

      data.sort((a, b) => {
        const numA = parseInt(a.node.name.match(/\d+/), 10);
        const numB = parseInt(b.node.name.match(/\d+/), 10);
        
        return numA - numB;
      });

      const formattedData = data.map(el => {
        let title = '타이틀을 입력해주세요.';
        if (el.node.description) {
          const parsedDescription = JSON.parse(el.node.description);

          title = parsedDescription.blocks
            .map(block => block.data.text)
            .join('<br>');
        }
        
        return {
          title: title,
          id: el.node.id
        };
      });

      setCollection(formattedData);
    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };


  return (
    <article id="homePage">

      {/* 로그인 섹션[START] */}
      {userToken() ? <MainLoginAfterSection/> : <MainLoginBeforeSection/>}
      {/* 로그인 섹션[END] */}

      <div className="white-wrap">
        {/*[D] 배너[START] */}
        <div className="banner-wrap vw100 mb20">
          <BannerDSection id='home_b1'/>
        </div>
        {/*[D] 배너[END] */}

        {/* 빅탭 [START] */}
        <BigTab tabs={bigTabs} />
        {/* 빅탭 [END] */}
      </div>
      
      {/* Home 1 영역 [START] */}
      <div className="white-wrap">
        {collection[0] && (
          <>
            <H3Title id={collection[0].id} title={collection[0].title}/>
            <GridSection 
              type="big" 
              option={false}
              collection={collection[0].id}
            />
            <button 
              type="button" 
              className="btn big round more mt40"
              onClick={() => navigate(`/product-list?id=${collection[0].id}&title=${collection[0].title}`)}
            >
              더 보기
            </button>
          </>
        )}
      </div>
      {/* Home 1 영역 [END] */}

      {/* Home 2 영역 [START] */}
      <div className="white-wrap">
        {collection[1] && (
          <>
            <H3Title id={collection[1].id} title={collection[1].title}/>

            {/*[D] 배너[START] */}
            <div className="banner-wrap mb20">
              <BannerDSection id='home_b2'/>
            </div>
            {/*[D] 배너[END] */}

            <GridSection 
              type="big" 
              option={false}
              collection={collection[1].id}
            />

            <button 
              type="button" 
              className="btn big round more mt40"
              onClick={() => navigate(`/product-list?id=${collection[1].id}&title=${collection[1].title}`)}
            >
              더 보기
            </button>
          </>
        )}
      </div>
      {/* Home 2 영역 [END] */}

      {/* Home 3 영역 [START] */}
      <div className="white-wrap">
        {collection[2] && (
          <>
            <H3Title id={collection[2].id} title={collection[2].title}/>

            {/*[D] 배너[START] */}
            <div className="banner-wrap mb20">
              <BannerDSection id='home_b3'/>
            </div>
            {/*[D] 배너[END] */}

            {/*[A] 배너[START] */}
            <div className="banner-wrap mb8">
              <BannerASection id='home_b4'/>
            </div>
            {/*[A] 배너[END] */}

            {/* [SMALL] 상품 리스트 [START] */}
            <SwipeGridSection 
              collection={collection[2].id}
            />
            {/* [SMALL] 상품 리스트 [END] */}

            <div className="banner-wrap mt8">
              {/*[B] 배너[START] */}
              <BannerBSection id='home_b5'/>
              {/*[B] 배너[END] */}
            </div>
          </>
        )}
      </div>
      {/* Home 3 영역 [END] */}

      {/* Home 4 영역 [START] */}
      <div className="white-wrap">
        {collection[3] && (
          <>
            <H3Title id={collection[3].id} title={collection[3].title}/>

            <div className="banner-wrap mb8">
              {/*[D] 배너[START] */}
              <BannerDSection id='home_b6'/>
              {/*[D] 배너[END] */}
            </div>

            {/* [SMALL] 상품 리스트 [START] */}
            <SwipeGridSection 
              collection={collection[3].id}
            />
            {/* [SMALL] 상품 리스트 [END] */}
          </>
        )}
      </div>
      {/* Home 4 영역 [END] */}

      {/* Home 5 영역 [START] */}
      <div className="white-wrap">
        {collection[4] && (
          <>
            <H3Title id={collection[4].id} title={collection[4].title}/>

            {/*[D] 배너[START] */}
            <div className="banner-wrap mb20">
              <BannerDSection id='home_b7'/>
            </div>
            {/*[D] 배너[END] */}

            <GridSection 
              type="big" 
              option={false}
              collection={collection[4].id}
            />

            <button 
              type="button" 
              className="btn big round more mt40"
              onClick={() => navigate(`/product-list?id=${collection[4].id}&title=${collection[4].title}`)}
            >
              더 보기
            </button>
          </>
        )}
      </div>
      {/* Home 5 영역 [END] */}

      {/* Home 6 영역 [START] */}
      <div className="white-wrap">
        {collection[5] && (
          <>
            <H3Title id={collection[5].id} title={collection[5].title}/>

            {/*[D] 배너[START] */}
            <div className="banner-wrap mb20">
              <BannerDSection id='home_b8'/>
            </div>
            {/*[D] 배너[END] */}

            <GridSection 
              type="big" 
              option={false}
              collection={collection[5].id}
            />

            <button 
              type="button" 
              className="btn big round more mt40"
              onClick={() => navigate(`/product-list?id=${collection[5].id}&title=${collection[5].title}`)}
            >
              더 보기
            </button>
          </>
        )}
      </div>
      {/* Home 6 영역 [END] */}

      {/* Home 7 영역 [START] */}
      <div className="white-wrap">
        {collection[6] && (
          <>
            <H3Title id={collection[6].id} title={collection[6].title}/>
            <SwipeGridSection 
              collection={collection[6].id}
            />
          </>
        )}
      </div>
      {/* Home 7 영역 [END] */}

      {/* Home 8 영역 [START] */}
      <div className="white-wrap">
        {collection[7] && (
          <>
            <H3Title id={collection[7].id} title={collection[7].title}/>
            <SwipeGridSection 
              collection={collection[7].id}
            />
          </>
        )}
      </div>
      {/* Home 8 영역 [END] */}

      {/* Home 9 영역 [START] */}
      <div className="white-wrap">
        {collection[8] && (
          <>
            <H3Title id={collection[8].id} title={collection[8].title}/>
            {/*[D] 배너[START] */}
            <div className="banner-wrap mb20">
              <BannerDSection id='home_b9'/>
            </div>
            {/*[D] 배너[END] */}

            <GridSection 
              type="big" 
              option={false}
              collection={collection[8].id}
            />

            <button 
              type="button" 
              className="btn big round more mt40"
              onClick={() => navigate(`/product-list?id=${collection[8].id}&title=${collection[8].title}`)}
            >
              더 보기
            </button>
          </>
        )}
      </div>
      {/* Home 9 영역 [END] */}

      {/* Home 10 영역 [START] */}
      <div className="white-wrap">
        {collection[9] && (
          <>
            <H3Title id={collection[9].id} title={collection[9].title}/>

            {/*[D] 배너[START] */}
            <div className="banner-wrap mb20">
              <BannerDSection id='home_b10'/>
            </div>
            {/*[D] 배너[END] */}

            <GridSection 
              type="big" 
              option={false}
              collection={collection[9].id}
            />

            <button 
              type="button" 
              className="btn big round more mt40"
              onClick={() => navigate(`/product-list?id=${collection[9].id}&title=${collection[9].title}`)}
            >
              더 보기
            </button>
          </>
        )}
      </div>
      {/* Home 10 영역 [END] */}

      {/* Home 11 영역 [START] */}
      <div className="white-wrap">
        {collection[10] && (
          <>
            <H3Title id={collection[10].id} title={collection[10].title}/>
            {/*[D] 배너[START] */}
            <div className="banner-wrap mb20">
              <BannerDSection id='home_b11'/>
            </div>
            {/*[D] 배너[END] */}

            <GridSection 
              type="middle" 
              option={false}
              collection={collection[10].id}
            />

            <button 
              type="button" 
              className="btn big round more mt40"
              onClick={() => navigate(`/product-list?id=${collection[10].id}&title=${collection[10].title}`)}
            >
              더 보기
            </button>
          </>
        )}
      </div>     
      {/* Home 11 영역 [END] */}

      <div className="white-wrap">
        <div className="banner-wrap mb8">
          {/*[D] 배너[START] */}
          <BannerDSection id='home_b12'/>
          {/*[D] 배너[END] */}
        </div>
        <div className="banner-wrap mb8">
          {/*[D] 배너[START] */}
          <BannerDSection id='home_b13'/>
          {/*[D] 배너[END] */}
        </div>
        <div className="banner-wrap vw100 mb8">
          <BannerASection id='home_b14'/>
        </div>
        <div className="banner-wrap vw100 mb8">
          <BannerASection id='home_b15'/>
        </div>
      </div> 
    </article>
  );
}

export default HomePage;
