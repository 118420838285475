import React from "react";

import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

import sampleImage3 from "../../assets/images/sample/sample_hero_img01.png";
import sampleImage2 from "../../assets/images/sample/banner/하늘이_해외직구.jpeg";
import sampleImage1 from "../../assets/images/sample/banner/가로_축복이_NEW.jpeg";

const HeroBannerSection = () => {

  const slides = [
    {
      img: sampleImage1,
      title: "봄맞이 10% 할인<br/>베스트 상품 기획전",
      content: "쿠폰과 프로모션 혜택까지 챙겨보세요!",
    },
    {
      img: sampleImage2,
      title: "신상품 출시 기념<br/>한정판 굿즈 증정",
      content: "새로운 시작을 여러분과 함께합니다!",
    },
    {
      img: sampleImage3,
      title: "여름맞이 냉장고 정리<br/>최대 20% 할인",
      content: "이번 기회에 주방을 새롭게 꾸며보세요!",
    }
  ]
  
  return (
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        speed={900}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log('슬라이드 변경')}
        loop={slides.length > 1 ? true : false}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        pagination={{
          type: 'fraction',
          clickable: true
        }}
        className="hero-slide-wrap"
      >
        {
          slides.map((slide)=>(
            <SwiperSlide key={slide.title}>
              <Link to="/brand/detail" title="">
                <img src={slide.img} alt={slide.title} />
                <div className="text-wrap">
                  <div className="title">{parse(slide.title)}</div>
                  <div className="text">{parse(slide.content)}</div>
                </div>
              </Link>
            </SwiperSlide>
          ))
        }
      </Swiper>
  );
};

export default HeroBannerSection;
