import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import useMainStore from '../../store/Pages/useMainStore'
import MainLoginBeforeSection from '../../sections/main/MainLoginBeforeSection';
import useAuthStore from '../../store/common/useAuthStore';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import TopBar from '../../layouts/top_bar/TopNav';
import BottomNav from '../../layouts/bottom_bar/BottomNav';
import FAB from '../../components/Buttons/FAB';

import HomePage from '../../pages/main/home/HomePage';
// import HotPage from '../../pages/main/hot/HotPage';
import HotdealPage from '../../pages/main/hotdeal/HotdealPage';
import RankingPage from '../../pages/main/ranking/RankingPage';
import BrandPage from '../../pages/main/brand/BrandPage';
import OverseasPage from '../../pages/main/overseas/OverseasPage';
import CouponBenefitPage from '../../pages/main/couponBenefit/CouponBenefitPage';

const MainPage = () => {
  const tabs = [
    { name: 'HOME', class: 'home', route: '/' },
    { name: '핫딜', class: 'hotdeal', route: '/hotdeal' },
    { name: '랭킹', class: 'ranking', route: '/ranking' },
    { name: '해외직구', class: 'overseas', route: '/overseas' },
    { name: '쿠폰/혜택', class: 'couponBenefit', route: '/couponBenefit' },
    { name: '브랜드', class: 'brand', route: '/brand' },
  ];
  const { userToken } = useAuthStore();

  // const [currentPage, setCurrentPage] = useState(0);
  // const [activeTab, setActiveTab] = useState('HOME');
  const { currentPage, activeTab, setCurrentPage, setActiveTab } = useMainStore();

  const [swiper, setSwiper] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const pages = [
    <HomePage />,
    <HotdealPage />,
    <RankingPage />,
    <OverseasPage />,
    <CouponBenefitPage />,
    <BrandPage />,
  ];

  useEffect(() => {
    const currentTab = tabs.find(tab => location.pathname === tab.route);
    const pageIndex = currentTab ? tabs.indexOf(currentTab) : 0;
    setCurrentPage(pageIndex); // Zustand 스토어의 상태 업데이트 메소드를 사용
    setActiveTab(currentTab ? currentTab.name : 'HOME'); // Zustand 스토어의 상태 업데이트 메소드를 사용
    if(swiper) swiper.slideTo(pageIndex, 0);
  }, [location, swiper, setCurrentPage, setActiveTab]);

  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
    setActiveTab(tabs[pageIndex].name);
    navigate(tabs[pageIndex].route);
  };

  const handleTabClick = (tab) => {
    const pageIndex = tabs.findIndex((t) => t.name === tab.name);
    setCurrentPage(pageIndex);
    setActiveTab(tab.name);
    swiper?.slideTo(pageIndex)
  };

  return (
    <>
      <TopBar />
      <div className="home-slide-tabs">
        <div className="tabs">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => handleTabClick(tab)}
              className={`tab ${tab.class} ${activeTab === tab.name ? 'active' : ''}`}
            >
              {tab.name}
            </button>
          ))}
        </div>
      </div>
      <div id="mainSlidePage">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          autoHeight={true}
          onSwiper={(swiper) => setSwiper(swiper)}    
          onSlideChange={(swiper) => handlePageChange(swiper.activeIndex)}
          className="layout-slide-wrap"
          loop={false}
          initialSlide={currentPage}
        >
          {pages.map((page, index) => (
            <SwiperSlide key={index}>
              {page}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <FAB />
      <BottomNav />
    </>
  );
};

export default MainPage;
