import api from '../api'

const sendEmail = (email) => { // 이메일 인증번호 전송 메소드
  return api.post('/api-tg/send-code', {
    type: 'email',
    to: [email]
  })
}

const sendSms = (phone) => { // SMS 인증번호 전송 메소드
  return api.post('/api-tg/send-code', {
    type: 'sms',
    to: [phone]
  })
}

const confirmPhone = (phone) => { // 전화번호 유일여부 확인 메소드
  return api.post('/api-tg/confirm-phone', {
    phone
  })
}

const confirmCode = (id, code) => { // 인증번호 확인 메소드
  return api.post('/api-tg/confirm-code', {
    id,
    code
  })
}

export {
  sendEmail,
  sendSms,
  confirmPhone,
  confirmCode
};
