import React, {useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOrderById } from '../../services/GQ_apis/order';

import SampleImage from "../../assets/images/sample/banner.png";

const MyMenuExchangeDetailSection = () => {

  const navigate = useNavigate();
  const { Page3depth } = useParams();

  const [orderData, setOrderData] = useState({});

  fetchOrderById(Page3depth).then((res)=>{
    console.log('res');
    console.log(res);
    setOrderData(res.data.order)
    console.log('res.data.order',res.data.order.id);
  })

  if (! orderData || ! orderData.lines) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <>
      <section className="section-mymenu-exchange-detail">
        <div className="order-card selected">
          <div className="title-wrap">
            <strong className="title">{window.WSgetStatus(orderData.status)}</strong>
            <span className="caption">주문번호 {orderData.id}</span> 
          </div>
          {
           orderData.lines.map((line,index)=>(
            <div key={index} className="item-info-wrap">
              <div className="img-wrap">
                <img src={line.thumbnail.url} alt={line.thumbnail.alt}></img>
              </div>
              <div className="text-wrap">
                {/* <p className="date">2023-12-28</p> */}
                <p className="brand">{line.variant.product.attributes[0].values[0].name}</p>
                <p className="name">{line.productName}</p>
                <p className="price">{window.WSformatPrice(line.variant.product.pricing.priceRange.start.gross.amount)} / {line.quantity}개</p>
              </div>
            </div>
           ))
          }

          {/* <div className="btn-wrap flex mt16">
            <button 
              type="button" 
              className="btn black20"
              onClick={()=>navigate('/order/request-exchange')}
            >
              교환요청
            </button>
            <button 
              type="button" 
              className="btn purple10"
              onClick={() => navigate('/mymenu/myreview-write', { state: { orderId: orderData.id } })}
            >
              리뷰작성
            </button>
          </div> */}
        </div>
        <div className="white-wrap exchange-wrap">
          <h4 className="title">
            주문정보
          </h4>
          <div className="text-wrap">
            <b>
              {orderData.shippingAddress.firstName}
            </b>
            <span>({orderData.shippingAddress.postalCode}) {orderData.shippingAddress.streetAddress1}</span>
            <span>{orderData.shippingAddress.streetAddress2}</span>
            <span className="f-black60">{window.WSformatPhoneNumber(orderData.shippingAddress.phone)}</span>
          </div>
        </div>
        <div className="white-wrap delivery-wrap">
          <h4 className="title">
            배송요청사항
          </h4>
          <div className="text-wrap">
            <span>문앞에 놓아주세요.</span>
          </div>
          <span className="border-black40"></span>
        </div>
      </section>
    </>
  );
};

export default MyMenuExchangeDetailSection;

