import { gql } from '@apollo/client';
import {client, nonTokenClient} from '../apolloClient';

// 사용자 등록을 위한 GraphQL 뮤테이션
const REGISTER_USER = gql`
  mutation RegisterUser (
    $email: String!,
    $password: String!, 
    $redirectUrl: String!,
    $channel: String!,
    $firstName: String!
  ) {
    accountRegister( input: {
      email: $email,
      password: $password,
      redirectUrl: $redirectUrl,
      channel: $channel,
      firstName: $firstName
    }) {
      user {
        id
        email
      }
      accountErrors {
        field
        message
        code
      }
    }
  }
`;

// 사용자 등록을 처리하는 함수
export function accountRegister(
  redirectUrl, 
  channel,
  email, 
  password, 
  firstName,
) {
  
  return nonTokenClient.mutate({
    mutation: REGISTER_USER,
    variables: {
      email,
      password,
      redirectUrl,
      channel,
      firstName
    }
  });
}

// 로그인을 위한 GraphQL 뮤테이션
const LOGIN_USER = gql`
  mutation tokenCreate($email: String!, $password: String!) {
    tokenCreate(email: $email, password: $password) {
      token
      refreshToken
      errors {
        field
        message
        addressType
      }
      accountErrors {
        addressType
        code
        field
      }
      user {
        id
        firstName
        email
      }
    }
  }
`;

// 로그인을 처리하는 함수
export function accountLogin(email, password) {
  return nonTokenClient.mutate({
    mutation: LOGIN_USER,
    variables: {
      email,
      password,
    }
  });
}

// 회원정보 수정
export function updateAccount(firstName, metadata) {
  const UPDATE_ACCOUNT = gql`
    mutation UpdateAccount($firstName: String!, $metadata: [MetadataInput!]!) {
      accountUpdate(input: { firstName: $firstName, metadata: $metadata }) {
        user {
          id
          email
          firstName
          metadata {
            key
            value
          }
        }
        accountErrors {
          code
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: UPDATE_ACCOUNT,
    variables: {
      firstName: firstName,
      metadata: metadata
    }
  });
}

// 회원 인증 (아마도)
export function confirmUserAccount(email) {
  const CONFIRM_USER_ACCOUNT = gql`
    mutation ConfirmUserAccount($email: String!, $token: String!) {
      confirmUserAccount(email: $email, token: $token) {
        user {
          id
          email
          isActive
        }
        errors {
          code
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: CONFIRM_USER_ACCOUNT,
    variables: {
      email: email,
    }
  });
}

// 이메일 존재 여부 체크
export async function checkEmail(email) {
  const CHECK_EMAIL_EXISTS = gql`
    query CheckEmailExists($email: String!) {
      checkEmailExists(email: $email)
    }
  `;

  try {
    const { data, loading, error } = await client.query({
      query: CHECK_EMAIL_EXISTS,
      variables: { email: email },
      fetchPolicy: 'network-only'  // 항상 네트워크에서 최신 데이터를 가져옵니다.
    });

    if (loading) return '로딩 중...';  // 비동기 함수에서는 실제로 'loading' 상태가 필요하지 않습니다.
    if (error) return `오류 발생: ${error.message}`;
    if (data && data.checkEmailExists) {
      return `이미 존재하는 이메일입니다.`;
    } else {
      return '이메일이 존재하지 않습니다.';
    }
  } catch (error) {
    return `오류 발생: ${error.message}`;
  }
}
