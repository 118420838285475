import React from 'react';

const PopupRegisterDetail11 = ({closePopup}) => {
  return (
    <>
    <div className="popup-body">
      <div className="h3-title-wrap">
        <h3 className="title">
          마케팅 개인정보 제 3자 제공 동의
        </h3>
        <p className="mt16 f16">마케팅 개인정보 제 3자 제공 동의</p>
      </div>
    </div>
    <div className="popup-footer">
      <div className="btn-wrap">
        <button type="button" className="btn purple" onClick={closePopup}>완료</button>
      </div>
    </div>
  </>
  );
};

export default PopupRegisterDetail11;
