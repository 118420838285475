import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import 'swiper/css';

const SmallTab = ({ tabs = [], activeTab, onTabClick }) => {
  const handleTabClick = (e, key) => {
    e.preventDefault();
    e.stopPropagation();
    onTabClick(key);
  };

  return (
    <div className="category-tabs-wrap">
      <Swiper
        modules={[Autoplay, FreeMode, Pagination]}
        spaceBetween={12}
        slidesPerView="auto"
        loop={false}
        freeMode={true}
        className="category-swiper"
        nested={true}
        touchMoveStopPropagation={true}
      >
        {tabs.map((tab, index) => (
          <SwiperSlide key={`slide-${tab.key}-${index}`}>
            <li>
              <button
                type="button"
                className={`tab ${activeTab === tab.key ? 'active' : ''}`}
                onMouseDown={(e) => handleTabClick(e, tab.key)}
              >
                {tab.label}
              </button>
            </li>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SmallTab;
