import React from 'react';

const PopupRegisterDetail1 = ({closePopup}) => {
  return (
    <>
    <div className="popup-body">
      <div className="h3-title-wrap">
        <h3 className="title">
          만 14세 이상입니다.
        </h3>
        <p className="mt16 f16">만 14세 이상입니다.</p>
      </div>
    </div>
    <div className="popup-footer">
      <div className="btn-wrap">
        <button type="button" className="btn purple" onClick={closePopup}>완료</button>
      </div>
    </div>
  </>
  );
};

export default PopupRegisterDetail1;
