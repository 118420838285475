import React from "react";
import { useSearchParams } from 'react-router-dom';

import TopBar from '../../layouts/top_bar/TitleBar';
import GridSection from '../../sections/grid/GridSection';

function ProductListPage() {
  
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  let title = searchParams.get('title');
  if (title)  title = title.replace(/<br\s*\/?>/gi, ' ');
  return (
    <div className="product-wrap">
      <TopBar title={title || "Default Title"}/> 
      <article id="productListPage" className="pb0">
        <div className="inner">
          <GridSection 
            type="middle" 
            option={true} 
            infinity={true}
            collection={id || ""} 
          />
        </div>
      </article>
    </div>
  )
}

export default ProductListPage;
