import React from "react";
import BrandMainSection from '../../../sections/brand/BrandMainSection';

function BrandPage() {
  return (
    <article id="brandPage">
      <BrandMainSection/>
    </article>
  )
}

export default BrandPage;
