import React from 'react';
import { createRoot } from "react-dom/client";
import App from './App';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  // React.StrictMode를 사용하면 랜더링이 두번씩 되기 때문에 주석
  // <React.StrictMode>
      <App />
  // </React.StrictMode>
);
