// App.js
import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import "./assets/styles/scss/app.scss";
import Init from './components/common/Init';
import PagesRoute from './routes/PagesRoute';
import ToastPopup from './components/Popups/ToastPopup';
import SnackBarPopup from './components/Popups/SnackBarPopup';

function App() {
  return (
    <div id="wrap">
      <Router>
        <Init/>
        <PagesRoute/>
        <ToastPopup/>
        <SnackBarPopup/>
      </Router>
    </div>
  );
}

export default App;
