import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { 
  fetchVouchers, 
  fetchUserVouchers
} from '../../../../services/GQ_apis/discounts';
import { getCheckout } from '../../../../services/GQ_apis/checkout';
import useAuthStore from '../../../../store/common/useAuthStore';
import { addPromoCodeToCheckout } from '../../../../services/GQ_apis/discounts';
import usePopupStore from '../../../../store/common/usePopupStore';

const Coupon1 = ({closePopup}) => {

  const { user, observerUpdate } = useAuthStore();
  const navigate = useNavigate();
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  const [couponList, setCouponList] = useState([]);

  useEffect(()=>{
    fetchUserVouchers(user.email).then((res)=>{
      console.log('vouchers');
      console.log(res.data.TGUserVouchers);
      setCouponList(res.data.TGUserVouchers);
      console.log('vouchers');
    })

    console.log(user);

  },[])

  const useCoupon = (voucherCode) => {

    // 1. 체크아웃 정보
    getCheckout(localStorage.getItem('paymentToken')).then((res)=>{

      console.log('------현재 체크아웃 정보------');
      console.log(res.data);
      console.log('------현재 체크아웃 정보------');

      addPromoCodeToCheckout(
        res.data.checkout.id,
        voucherCode
      ).then((res)=>{
        console.log('쿠폰 사용 ');
        console.log(res);
        console.log('쿠폰 사용 ');
        closePopup();
        observerUpdate();
        openSnackBarPopup('정상적으로 쿠폰이 적용되었습니다.','','')
      })

    })


  }

  return (
    <>
      <div className="popup-body">
        {/* <div className="h3-title-wrap">
          <h3 className="title">
            로그아웃
          </h3>
        </div> */}
        <div className="section-coupons">
          <div className="inner w100p">
            <strong className="title">
              사용할 수 있는 쿠폰 <b className="f-purple">{couponList.length}개</b></strong>
            <ul className="coupons-list">
              {
                couponList.map((coupon, index)=>(
                  <li className="card coupon" key={index}>
                    <div className="top-wrap">
                      <strong className="f-purple">
                        50%
                      </strong>
                      <button 
                        type="button" 
                        className="btn white round"
                        onClick={()=>{useCoupon(coupon.voucher.code)}}
                      >
                        <span className="icon-coupon white">
                        </span>
                        사용하기
                      </button>
                    </div>
                    <div className="bottom-wrap">
                      <strong className="title">
                        {coupon.voucher.name}
                      </strong>
                      {/* <p className="text">
                        키즈 상품 할인 적용 가능 쿠폰               
                        키즈 상품 할인 적용 가능 쿠폰
                        키즈 상품 할인 적용 가능 쿠폰
                        키즈 상품 할인 적용 가능 쿠폰
                        키즈 상품 할인 적용 가능 쿠폰
                        키즈 상품 할인 적용 가능 쿠폰
                        키즈 상품 할인 적용 가능 쿠폰
                        키즈 상품 할인 적용 가능 쿠폰
                        키즈 상품 할인 적용 가능 쿠폰
                        키즈 상품 할인 적용 가능 쿠폰
                      </p> */}
                      <strong className="d-day">
                        만료까지 D-3
                        <span className="caption">10,000이상 구매시 사용가능</span>
                      </strong>
                    </div>
                  </li>
                ))
              }

            </ul>
          </div>
        </div>
      </div>
      <div className="popup-footer">
        <div className="btn-wrap">
          <button type="button" className="btn gray" onClick={closePopup}>취소</button>
        </div>
      </div>
    </>
  );
};

export default Coupon1;
