import React from "react";
import { useNavigate, Link } from 'react-router-dom';

const LoginMain01Section = () => {
  const navigate = useNavigate();

  return (
    <section className="section-login-main01">
      {/*  노란 배경 [START] */}
      <div className="bg-yellow">
        <div className="images-wrap">
          <span className="icon-logo-white">
          </span>
          <span className="icon-duck">
          </span>
        </div>
        <div className="btn-wrap">
          <div className="btns">
            <button onClick={() => navigate('/login/main2')} type="button" className="btn white">
              로그인
            </button>
            <button onClick={() => navigate('/register/1')} type="button" className="btn white">
              회원가입
            </button>
          </div>
        </div>
      </div>
      {/*  노란 배경 [END] */}
      {/*  하얀 배경 [START] */}
      <div className="bg-white">
        <div className="btn-wrap">
          <div className="title">
              혜택 정보
          </div>
          <div className="btns">
            <button type="button" className="btn gray">
              회원혜택
            </button>
            <button type="button" className="btn gray">
              제휴 카드 안내
            </button>
          </div>
        </div>
        <div className="mt30 btn-wrap">
          <div className="title">
              고객센터
          </div>
          <div className="btns">
            <button onClick={() => navigate('/customer-center?tab=notice')} type="button" className="btn gray">
              공지사항
            </button>
            <button onClick={() => navigate('/customer-center?tab=faq')} type="button" className="btn gray">
              FAQ
            </button>
          </div>
        </div>
        <Link className="under-line" to="/" title="">
          혜택 받지 않고 둘러보기
        </Link>
      </div>
      {/*  하얀 배경 [END] */}
    </section>
  );
};

export default LoginMain01Section;
