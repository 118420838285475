import React from "react";
import SampleImage from "../../assets/images/sample/banner.png";

const myreviewDetailSection = () => {
  return (
    <>
      <section className="section-mymenu-review-detail">
        <div className="white-wrap">
          <div className="card review">
            <div className="img-wrap">
              <img src={SampleImage} alt=""></img>
            </div>
            <div className="review-wrap">
              <div className="text-wrap pt40">
                <p>덤블배색기모맨투맨</p>
              </div>
              <div className="more-dots-wrap">
                <button type="button" className="icon-more-dots">
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="white-wrap">
          <div className="card review-detail">
            <div className="stars-wrap">
              <div className="stars gap4">
                <a href="#none" title="1점" className="icon-star-fill"></a>
                <a href="#none" title="2점" className="icon-star-fill"></a>
                <a href="#none" title="3점" className="icon-star-fill"></a>
                <a href="#none" title="4점" className="icon-star-fill"></a>
                <a href="#none" title="5점" className="icon-star-empty"></a>
              </div>
              <strong className="date">
                2023-5-30
              </strong>
            </div>
            {/* <div className="text-wrap">
              <strong>한줄평</strong>
              <ul>
                <li>
                  <span className="key">색상</span>
                  <span className="value f-black50">화면과 똑같아요</span>
                </li>
                <li>
                  <span className="key">크기</span>
                  <span className="value f-black50">딱 맞아요</span>
                </li>
                <li>
                  <span className="key">마감</span>
                  <span className="value f-black50">좋아요</span>
                </li>
                <li>
                  <span className="key">재룩</span>
                  <span className="value f-purple">등록됨</span>
                </li>
              </ul>
            </div> */}
            <div className="text-wrap">
              <p className="f14">
                좋은 상품을 합리적인 가격에 구매하였습니다. <br />
                감사합니다!
              </p>
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default myreviewDetailSection;

