import { gql } from '@apollo/client';
import { client } from '../apolloClient';

// 자녀 정보 생성
export function createChild(
  name, 
  birthDate, 
  height, 
  weight, 
  footSize, 
  headSize, 
  gender, 
  main, 
  thumbnail,
  brands,
  colors,
  items,
  etc='-'
) {
  const CREATE_CHILD = gql`
    mutation CreateChild(
      $name: String!,
      $birthDate: DateTime!,
      $height: Float!,
      $weight: Float!,
      $footSize: Float!,
      $headSize: Float!,
      $gender: String!,
      $main: Boolean!,
      $thumbnail: String!,
      $brands: String!,
      $colors: String!,
      $items: String!,
      $etc: String!
    ) {
      accountChildCreate(input: {
        name: $name,
        birthDate: $birthDate,
        height: $height,
        weight: $weight,
        footSize: $footSize,
        headSize: $headSize,
        gender: $gender,
        main: $main,
        thumbnail: $thumbnail,
        brands: $brands,
        colors: $colors,
        items: $items,
        etc: $etc
      }) {
        child {
          id
          name
          birthDate
          height
          weight
          footSize
          headSize
          gender
          main
          thumbnail
          brands
          colors
          items
        }
        accountErrors {
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: CREATE_CHILD,
    variables: {
      name,
      birthDate,
      height,
      weight,
      footSize,
      headSize,
      gender,
      main,
      thumbnail,
      brands,
      colors,
      items,
      etc
    },
    fetchPolicy: 'network-only'
  });
}

// 자녀 정보 삭제
export function deleteChild (id) {
  const DELETE_CHILD = gql`
    mutation DeleteChild(
      $id: ID!,
    ) {
      accountChildDelete(id: $id) {
        success
        accountErrors {
          field
          message
          addressType
        }
      }
    }
  `;

  return client.mutate({
    mutation: DELETE_CHILD,
    variables: {
      id,
    },
    fetchPolicy: 'network-only'
  });
}

// 자녀 정보 수정(업데이트)
export async function updateChild(id, updates) {
  const UPDATE_CHILD = gql`
    mutation UpdateChild(
      $id: ID!,
      $name: String,
      $birthDate: DateTime,
      $height: Float,
      $weight: Float,
      $footSize: Float,
      $headSize: Float,
      $gender: String,
      $main: Boolean,
      $thumbnail: String
    ) {
      accountChildUpdate(input: {
        id: $id, 
        name: $name,
        birthDate: $birthDate,
        height: $height,
        weight: $weight,
        footSize: $footSize,
        headSize: $headSize,
        gender: $gender,
        main: $main,
        thumbnail: $thumbnail
      }) {
        child {
          id
          name
          birthDate
          height
          weight
          footSize
          headSize
          gender
          main
          thumbnail
        }
        accountErrors {
          field
          message
        }
      }
    }
  `;


  const filteredUpdates = Object.keys(updates).reduce((acc, key) => {
    if (updates[key] !== null && updates[key] !== undefined) {
      acc[key] = updates[key];
    }
    return acc;
  }, {});


  const response = await client.mutate({
    mutation: UPDATE_CHILD,
    variables: {
      id,
      ...filteredUpdates, 
    },
    fetchPolicy: 'network-only'
  });

  return response;
}

// 전체 자녀 정보 조회
export function getMyChildren() {
  const GET_MY_CHILDREN = gql`
    query GetMyChildren {
      myChildren {
        id
        name
        birthDate
        height
        weight
        footSize
        headSize
        gender
        main
        thumbnail
        createdAt
      }
    }
  `;

  return client.query({
    query: GET_MY_CHILDREN,
    fetchPolicy: 'network-only'
  });
}

// 단일 자녀 정보 조회
export function getMyChild(id) {
  const GET_MY_CHILD = gql`
    query GetMyChild($id: ID!) {
      myChild(id: $id) {
        id
        name
        birthDate
        height
        weight
        footSize
        headSize
        gender
        main
        thumbnail
        createdAt
        updatedAt
        brands
        colors
        items
      }
    }
  `;

  return client.query({
    query: GET_MY_CHILD,
    variables: { id },
    fetchPolicy: 'network-only'
  });
}