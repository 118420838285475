import React from "react";
import { useParams } from 'react-router-dom';
import TopBar from '../../layouts/top_bar/SearchBar';

import SearchProcessSection from '../../sections/search/SearchProcessSection'
import SearchResultSection from '../../sections/search/SearchResultSection'
import SearchEmptySection from '../../sections/search/SearchEmptySection'

function SearchPage() {

  const { Page2depth } = useParams();

  return (
    <>
      <TopBar/>
      <article id="searchPage">
        {Page2depth === 'process' && <SearchProcessSection/>}
        {Page2depth === 'result' && <SearchResultSection/>}
        {Page2depth === 'empty' && <SearchEmptySection/>}
      </article>
    </>
  )
}

export default SearchPage;
