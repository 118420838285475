import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useInputStore from '../../store/common/useInputStore';
import usePopupStore from './../../store/common/usePopupStore';
import { createChild, updateChild, getMyChild } from '../../services/GQ_apis/kid';
import { getMyChildren } from '../../services/GQ_apis/kid';

const itemList = ['맨투맨','셔츠','자켓','청바지','면바지','점퍼','원피스','치마','상하복'];

const KidRegister04Section = () => {

  const navigate = useNavigate();
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));
  const [kids, setKids] = useState([]);

  useEffect(()=>{
    getMyChildren().then((res)=>{
      setKids(res.data.myChildren)
    })
  },[])

  const {
    input,
    actions,
  } = useInputStore(state => ({
    input : state.input,
    kid: state.kid,
    actions: state.actions,
  }));

  const [checkedStates, setCheckedStates] = useState(new Array(itemList.length).fill(false));
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (position) => {
    if (selectedItems.length >= 3 && !checkedStates[position]) return;

    const updatedCheckedStates = checkedStates.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedStates(updatedCheckedStates);

    if (!checkedStates[position]) {
      setSelectedItems([...selectedItems, itemList[position]]);
    } else {
      setSelectedItems(selectedItems.filter((brand) => brand !== itemList[position]));
    }
  };

  const handleSubmit = async () => {

    console.log(selectedItems)

    let main = false;
    if(kids.length == 0) main = true;

    try {
      const updatedKidItemList = JSON.stringify(selectedItems);

      const response = await createChild(
        input.kidNickName, // name 
        new Date(input.kidBirthDay).toISOString(), //birthDate
        parseFloat(input.kidHeight), // height
        parseFloat(input.kidWeight), // weight 
        parseFloat(input.kidFootSize), // foorSize
        parseFloat(input.kidHeadSize), // headSize
        input.kidGender, // gender
        main, // main
        input.kidProfileImg || '', // thumbnail
        input.kidBrandList, // brands
        input.kidColorList, // colors
        updatedKidItemList // items
        // etc
      );

      const accountChild = response.data.accountChildCreate
      if(accountChild.accountErrors.length == 0) {
        openSnackBarPopup('아이 프로필 등록이 완료되었습니다.', '', 'success');
        navigate('/');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <section className="section-register">
        <div className="inner">

          {/* 타이틀 영역 [START] */}
          <div className="h3-title-wrap m0">
            <h3 className="title">선호 아이템</h3>
            <p className="text">
              평소 선호하는 아이템을<br/>
              1순위부터 3순위까지 선택해주세요.
            </p>
            <div className="pagination">
              <span className="item">
                1
              </span>
              <span className="item">
                2
              </span>
              <span className="item">
                3
              </span>
              <span className="item active">
                4
              </span>
            </div>
          </div>
          {/* 타이틀 영역 [END] */}

          {/* 아이템 체크 영역[START]*/}
          <div className="check-wrap mt30">
            <ul className="check-list item">
              {itemList.map((item, index) => (
                <li key={index}>
                  {/* selectedItems의 길이가 3이상이고, 
                      현재 항목이 선택되지 않았다면 disabled 클래스를 추가 */}
                  <label 
                    htmlFor={`check${index}`} 
                    className={`${checkedStates[index] ? 'active' : ''} 
                    ${selectedItems.length >= 3 && !checkedStates[index] ? 'disabled' : ''}`}
                  >
                    {item}
                    <input 
                      className="bg" 
                      id={`check${index}`} 
                      type="checkbox"
                      checked={checkedStates[index]}
                      onChange={() => handleCheckboxChange(index)}
                      disabled={selectedItems.length >= 3 && !checkedStates[index]}
                    />
                  </label>
                </li>
              ))}
              </ul>
          </div>
          {/* 아이템 체크 영역[END]*/}
          
          {/* 디버그 박스 [START] */}
          {/* <div className="debug-box mt20">
            <p><b>선택한 브랜드 순위:</b> {selectedItems.join(', ')}</p>
          </div> */}
          {/* 디버그 박스 [END] */}

        </div>
      </section>
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              disabled={!(selectedItems.length >= 3)}
              className="btn purple big"
              onClick={()=>handleSubmit()}
            >
              등록 완료
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default KidRegister04Section;

