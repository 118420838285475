import api from '../api';

export const getVendorList = async () => {
  try {
      const response = await api.post('/api-tg/vendor/vendor-list')
          .then(res => { return res.data.data; });
      return response;
  } catch (error) {
      console.error('Error fetching vendor list:', error);
      throw error; 
  }
};

export const getBrandList = async (brand_id) => {
  try {
      const response = await api.get(`/api-tg/vendor/vendor-list-brand`)
          .then(res => { return res.data.data; });
      return response;
  } catch (error) {
      console.error('Error fetching vendor list:', error);
      throw error; 
  }
};

export const getVendorByVid = async (id) => {
  try {
      const response = await api.get(`/api-tg/vendor/vendor-data?id=${id}`)
          .then(res => { return res.data; });
      return response;
  } catch (error) {
      console.error('Error fetching vendors:', error);
      throw error;
  }
};

export const getVendorRanking = async () => {
  try {
    const response = await api.post('/api-tg/log/analyze', {
      type1: 'popular',
      type2: 'vendor'
    });
    return response.data.result; 
  } catch (error) {
    console.error('Error fetching vendor analysis ranking:', error);
    throw error;
  }
};
