import React, { useEffect, useState } from 'react';
import usePopupStore from '../../store/common/usePopupStore';
import useInputStore from '../../store/common/useInputStore'
import { useNavigate, useParams } from "react-router-dom";
import sampleImg from "../../assets/images/common/icons/icon_profile.svg";
import { getMyChild, updateChild } from '../../services/GQ_apis/kid';

const MyMenuKidProfileSection = () => {

  const { Page3depth } = useParams();
  const navigate = useNavigate();

  const init = {
    id: '',
    name: '',
    birthDate: '',
    height: '',
    weight: '',
    footSize: '',
    headSize: '',
    thumbnail: '',
    etc: '',
    main: false,
    created_at: '',
    gender: '',
    brands: [],
    colors: [],
    items: []
  };

  const {
    actions,
  } = useInputStore(state => ({
    actions: state.actions,
  }));

  const [kidData, setKidData] = useState(init);
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  const formatDateForInput = (dateStr) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    return date.toISOString().split('T')[0]; 
  };

  const parseDateForServer = (dateStr) => {
    if (!dateStr) return null;
    return new Date(dateStr).toISOString();
  };

  useEffect(() => {
    actions.resetInput();
  }, [actions]);

  useEffect(() => {
    if (Page3depth) {
      getMyChild(Page3depth).then((res) => {
        const child = res.data.myChild;
        console.log(child);

        setKidData(() => ({
          id: child.id,
          name: child.name,
          gender: child.gender,
          birthDate: formatDateForInput(child.birthDate),
          height: child.height,
          weight: child.weight,
          footSize: child.footSize,
          headSize: child.headSize,
          thumbnail: child.thumbnail,
          main: child.main,
          created_at: child.createdAt,
          brands: JSON.parse(child.brands),
          colors: JSON.parse(child.colors),
          items: JSON.parse(child.items)
        }));
      });
    }
  }, [Page3depth]);

  // 필수 입력 항목 검사
  const validateInput = () => {
    return kidData.name && kidData.gender && kidData.height && kidData.weight && kidData.footSize && kidData.headSize;
  };

  const handleSave = async () => {
    console.log("Kid Data before update:", kidData);
    try {
      const updatedKidData = {
        ...kidData,
        birthDate: parseDateForServer(kidData.birthDate),
      };
      
      const response = await updateChild(Page3depth, updatedKidData);
      console.log("API Response:", response);
  
      const accountChild = response.data.accountChildUpdate;
      if (accountChild.accountErrors.length === 0) {
        openSnackBarPopup('아이 정보가 성공적으로 수정되었습니다.', '', 'success');
        navigate(`/mymenu/mykid-detail/${Page3depth}`);
      } else {
        console.error(`Error updating kid with ID ${Page3depth}`);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  

  return (
    <>
      <section className="section-mymenu-kid-profile">
        <div className="inner">
          {/* 타이틀 영역 [START] */}
          <div className="h3-title-wrap mt0">
            <h3 className="title">아이 프로필 수정</h3>
            <p className="text">
              아이의 <span className="f-purple">기본 정보를 수정</span>해 주세요.
            </p>
          </div>
          {/* 타이틀 영역 [END] */}

          {/* 프로필 영역 [START] */}
          <div className="profile-img-wrap">
            <label htmlFor="profileImgInput" className="upload-button">
              <div className="img-wrap">
                <img 
                  src={kidData.thumbnail || sampleImg} 
                  alt="프로필 이미지" 
                  title="클릭시 프로필 사진 첨부 이동"
                />
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={actions.handleImageChange}
                style={{ display: 'none' }}
                id="profileImgInput"
              />
              <p className="title">프로필 사진</p>
            </label>
          </div>
          {/* 프로필 영역 [END] */}

          {/* 별명 [START] */}
          <div className="input-box">
            <label htmlFor="nickname" className="title starred">
              별명
            </label>
            <div className="input">
              <input 
                id="nickname" 
                type="text" 
                placeholder="별명을 입력해주세요."
                value={kidData.name}
                onInput={(e) => setKidData({ ...kidData, name: e.target.value })}
              />
            </div>
          </div>
          {/* 별명 [END] */}

          {/* 성별 선택 & 생년월일 [START] */}
          <div className="input-box-wrap">
            <div className="input-box">
              <div className="title starred">성별선택</div>
              <div className="input">
                <select 
                  className={kidData.gender !== '0' ? 'selected' : ''}
                  value={kidData.gender}
                  onInput={(e) => setKidData({ ...kidData, gender: e.target.value })}
                >
                  <option value="0">성별선택</option>
                  <option value="boy">남</option>
                  <option value="girl">여</option>
                </select>
              </div>
            </div>
            <div className="input-box">
              <label htmlFor="date" className="title">
                생년월일
              </label>
              <div className="input">
                <input 
                  id="date" 
                  type="date"
                  placeholder="생년월일을 입력해주세요."
                  value={kidData.birthDate}
                  onInput={(e) => setKidData({ ...kidData, birthDate: e.target.value })}
                />
              </div>
            </div>
          </div>
          {/* 성별 선택 & 생년월일 [END] */}

          {/* 키 & 몸무게 [START] */}
          <div className="input-box-wrap">
            <div className="input-box">
              <label htmlFor="height" className="title starred">키(cm)</label>
              <div className="input">
                <input 
                  id="height" 
                  type="number" 
                  placeholder="키"
                  value={kidData.height}
                  onInput={(e) => setKidData({ ...kidData, height: e.target.value })}
                >
                </input>
                <span className="inner-text">cm</span>
              </div>
            </div>
            <div className="input-box">
              <label htmlFor="weight" className="title starred">몸무게(kg)</label>
              <div className="input-box">
                <div className="input">
                  <input 
                    id="weight" 
                    type="number" 
                    placeholder="몸무게"
                    value={kidData.weight}
                    onInput={(e) => setKidData({ ...kidData, weight: e.target.value })}
                  >
                  </input>
                  <span className="inner-text">kg</span>
                </div>
              </div>
            </div>
          </div>
          {/* 키 & 몸무게 [END] */}

          {/* 발 & 머리 둘레 [START] */}
          <div className="input-box-wrap">
            <div className="input-box">
              <label htmlFor="foot" className="title starred">발(cm)</label>
              <div className="input">
                <input 
                  id="foot" 
                  type="number" 
                  placeholder="발"
                  value={kidData.footSize}
                  onInput={(e) => setKidData({ ...kidData, footSize: e.target.value })}
                ></input>
                <span className="inner-text">cm</span>
              </div>
            </div>
            <div className="input-box">
              <label htmlFor="head" className="title">머리 둘레(cm)</label>
              <div className="input-box">
                <div className="input">
                  <input 
                    id="head" 
                    type="number" 
                    placeholder="머리 둘레"
                    value={kidData.headSize}
                    onInput={(e) => setKidData({ ...kidData, headSize: e.target.value })}
                  >
                  </input>
                  <span className="inner-text">cm</span>
                </div>
              </div>
            </div>
          </div>
          {/* 발 & 머리 둘레 [END] */}
        </div>
      </section>
      {/* 저장 버튼 [START]*/}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              disabled={!validateInput()}
              className="btn purple big"
              onClick={handleSave}
            >
              저장
            </button>
          </div>
        </div>
      </div>
      {/* 저장 버튼 [END]*/}
    </>
  );
};

export default MyMenuKidProfileSection;
