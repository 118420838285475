import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import usePopupStore from '../../store/common/usePopupStore'
import { fetchVouchers, fetchUserVouchers, TGVoucherDownload } from '../../services/GQ_apis/discounts';
import useAuthStore from '../../store/common/useAuthStore';

const CouponsUserSection = () => {

  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [pageIndex, setPageIndex] = useState(1);

  // 현재 위치에 따른 페이지 인덱스 설정
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/coupons/1')) {
      setPageIndex(1);
    } else if (location.pathname.includes('/coupons/2')) {
      setPageIndex(2);
    }
  }, [location]);

  // SnackBar 팝업 사용 함수 openSnackBarPopup
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup, // 스낵바 오픈 함수
  }));

  const [couponList, setCouponList] = useState([]);

  useEffect(()=>{

    if(! user) return;

    fetchUserVouchers(user.email).then((res)=>{
      console.log('vouchers');
      console.log(res.data.TGUserVouchers);
      setCouponList(res.data.TGUserVouchers);
      console.log('vouchers');
    })
  },[])

  return (
    <>
      <section className="section-coupons">
        <div className="inner">
          <strong className="title">사용할 수 있는 쿠폰 <b className="f-purple">{couponList.length}개</b></strong>
          <ul className="coupons-list">
            {
              couponList.map((coupon, index)=>(
                <li className="card coupon" key={index}>
                  <div className="top-wrap">
                    <strong className="f-purple">
                      {/* {coupon.voucher.discountValue} */}
                      50%
                    </strong>
                    {/* <button type="button" className="btn white round">
                      <span className="icon-coupon white">
                      </span>
                      사용 가능
                    </button> */}
                  </div>
                  <div className="bottom-wrap">
                    <strong className="title">
                      {coupon.voucher.name}
                    </strong>
                    {/* <p className="text">
                      키즈 상품 할인 적용 가능 쿠폰               
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                      키즈 상품 할인 적용 가능 쿠폰
                    </p> */}
                    <strong className="d-day">
                      만료까지 D-3
                      <span className="caption">10,000이상 구매시 사용가능</span>
                    </strong>
                  </div>
                </li>
              ))
            }

          </ul>
        </div>
      </section>
    </>
  );
};

export default CouponsUserSection;

