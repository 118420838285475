import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { meData } from '../../services/GQ_apis/me';
import { deleteAddress, setDefaultAddress } from '../../services/GQ_apis/address';

const DeliveryListSection = () => {

  const navigate = useNavigate();
  const [addressList, setAddressList] = useState([]);

  // 배송지 리스트 불러오는 메소드
  const fetchDelivery = () => {

    meData().then((res)=>{
      console.log('addressList');
      console.log(res.data.me.addresses);
      setAddressList(res.data.me.addresses);
      console.log('addressList');
    })
  
  }

  const deleteDelivery = (addressId) => {
    deleteAddress(addressId).then((res)=>{
      console.log('address delete');
      console.log(res);
      console.log('address delete');
      fetchDelivery();
      window.location.reload();
    });
  }

  const selectedDefaultAddress = (id) => {
    setDefaultAddress(id,'SHIPPING');
    setDefaultAddress(id,'BILLING');
    window.history.back();
    setTimeout(() => {
      window.location.reload();
    }, 100); // 100밀리초 후 페이지 새로고침
  }

  // mounted
  useEffect(()=>{
    fetchDelivery();
  },[])

  return (
    <>
      <section className="section-delivery-list">
        <div className="inner">

        {
          addressList.map((address) => {
            return (
              <div className="card delivery" key={address.id}>
                <div className="name-wrap">
                  <strong className="name">
                    {address.metadata[0].value}
                  </strong>
                  <span className="badge red">
                    {address.country.code}
                  </span>
                  {
                    address.isDefaultShippingAddress &&
                    <span className="badge blue">
                      기본배송지
                    </span>
                  }
                  <button 
                    type="button" 
                    className="btn purple"
                    onClick={()=>selectedDefaultAddress(address.id)}
                  >
                    선택
                  </button>
                </div>
                <div className="info-wrap">
                  {address.firstName} {address.phone}
                </div>
                <div className="address-wrap">
                  {address.streetAddress1} {address.streetAddress2}
                </div>
                <div className="btn-wrap">
                  <Link to={`/delivery/domestic/${address.id}`} title="">
                    수정
                  </Link>
                  <button 
                    type="button"
                    onClick={()=>deleteDelivery(address.id)}
                  >
                    삭제
                  </button>
                </div>
              </div>
            );
          })
        }

        </div>
      </section>
      {/* 결제하기 영역 [START]*/}
      <div className="bottom-bar">
        <div className="inner">
          <button 
            type="button" 
            className="btn big purple"
            onClick={()=>navigate('/delivery/domestic')}
          >
            배송지 추가
          </button>
        </div>
      </div>
      {/* 결제하기 영역 [END]*/}
    </>
  );
};

export default DeliveryListSection;
