import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import MainSlides from '../../components/Slides/MainSlides';
import useProductStore from '../../store/common/useProductStore';
import { fetchPages } from "../../services/GQ_apis/page";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import ThumbnailImage from '../../components/common/ThumbnailImage';

const Product01Section = ({reviewList,setActiveTab}) => { 

  const navigate = useNavigate();
  const [imgMore,setImgMore] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [description, setDescription] = useState(null);
  const [pageData, setPageData] = useState([]);
  const { Page2depth } = useParams();

  const { nowProductData } = useProductStore((state) => ({ nowProductData: state.nowProductData }));

  useEffect(() => {
    if (nowProductData.descriptionJson) {
      setDescription(JSON.parse(nowProductData.descriptionJson));
    }
    // 
    const filter = {
      pageTypes: "UGFnZVR5cGU6Ng==", //문의 페이지타입
      metadata: [{key: "product_id", value: Page2depth}] // 선택한 상품만 선택
    };

    fetchPages(filter).then((res) => {
      console.log('Fetched pages:', res.data.pages.edges);
    
      // publicationDate를 기준으로 내림차순 정렬
      let sortedPages = [...res.data.pages.edges].sort((a, b) => {
        return new Date(b.node.publicationDate) - new Date(a.node.publicationDate);
      });
    
      setPageData(sortedPages);
    }).catch(error => {
      console.error('Error fetching pages:', error);
    });
    // 

  }, [nowProductData.descriptionJson]);

  const averageRating = reviewList.length ? 
  reviewList.reduce((acc, review) => acc + review.node.rating, 0) / reviewList.length : 0;

  const { mainMediaData, bodyMediaData } = useMemo(() => {
    const mainMedia = [];
    const bodyMedia = [];

    if(nowProductData.media.length === 0) return { mainMediaData: [], bodyMediaData: [] }

    nowProductData.media.forEach(media => {
        const viewMetadata = media.metadata.find(meta => meta.key === 'view');
        if (viewMetadata) {
            if (viewMetadata.value === 'main') {
                mainMedia.push(media);
            } else if (viewMetadata.value === 'body') {
                bodyMedia.push(media);
            }
        }
    });

    return { mainMediaData: mainMedia, bodyMediaData: bodyMedia };
}, [nowProductData]);


  useEffect(() => {
    console.log('nowProductData', nowProductData);
  },[nowProductData])

  // 썸네일 업데이트를 위한 상태 추가
  const [selectedThumbnails, setSelectedThumbnails] = useState({});

  const colorClick = (color, variants, productId) => {
    console.log(`Selected color: ${color}, for product: ${productId}`);
  
    const selectedVariant = variants.find(variant =>
      variant.attributes.some(attr => 
        attr.values.some(value => value.name.toLowerCase().trim() === color.toLowerCase().trim())
      )
    );
  
    if (selectedVariant && selectedVariant.media.length > 0) {
      const newThumbnailUrl = selectedVariant.media[0].url;
      console.log(`New thumbnail URL for ${color}:`, newThumbnailUrl);
  
      setSelectedThumbnails(prev => ({
        ...prev,
        [productId]: newThumbnailUrl  // productId를 키로 사용
      }));
    } else {
      setSelectedThumbnails(prev => ({
        ...prev,
      }));
      console.log(`No matching variant or media found for color: ${color}`);
    }
  };
  
  useEffect(()=>{
    console.log('selectedThumbnails', selectedThumbnails);
  },[selectedThumbnails])



  if (! nowProductData) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <>
      <section className="section-product-info">
        <div className="img-wrap">
          {
            selectedThumbnails[nowProductData.id] ? 
            <div className="hero-slide-wrap">
              <ThumbnailImage thumbnailUrl={selectedThumbnails[nowProductData.id]} thumbnailAlt={''} />
            </div> :
            <MainSlides images={mainMediaData}/>
          }
        </div>
        <div className="info-wrap">
          <div className="brand">
            {nowProductData.vendor.storeNameKo}
          </div>
          <div className="title">
            {nowProductData.name}
          </div>
          {/* <div className="badge-wrap">
            <span className="badge red">
              한정판매
            </span>
          </div> */}
          <div className="price-wrap">
            {
              nowProductData.pricing.priceRange.start.net.amount !== nowProductData.pricing.priceRange.stop.net.amount &&
              <div className="before-price">
                <span className="f-purple">쿠폰할인가</span>
                {/* <span className="price">￦{nowProductData.price.original.raw}</span> */}
              </div>
            }
            <div className="now-price">
              {
                nowProductData.pricing.priceRange.start.net.amount !== nowProductData.pricing.priceRange.stop.net.amount &&
                <span className="f-purple">0%</span>
              }
              <span className="price">
                {window.WSformatPrice(nowProductData.pricing.priceRangeUndiscounted.start.gross.amount)}
              </span>
            </div>
            <div className="coupon">
              <button 
                type="button" 
                className="btn big white round"
                onClick={()=>navigate('/coupons/2')}
              >
                <span className="icon-coupon mr6"></span>쿠폰받기
              </button>
            </div>
          </div>
          <div className="colors-wrap">
            <ul className="colors">
              {nowProductData.color.values.length > 0 &&
                nowProductData.color.values.map((colorItem, colorIndex) => (
                  <li className="color" key={colorItem + colorIndex}>
                    <button
                      type="button"
                      className={colorItem.name}
                      onClick={() => colorClick(colorItem.name, nowProductData.variants, nowProductData.id)}
                      // onClick={() => colorClick(colorItem.name, item.node.variants, item.node.id)}  // productId 추가
                    >
                    </button>
                  </li>
                ))}
            </ul>
          </div>

          {
            reviewList.length !== 0 &&
            <div className="review-wrap">
              <div className="stars">
                {
                  reviewList.length !==0 &&
                  window.WSgetStarArr(Math.round(averageRating)).map((starClass,index)=>(
                    <span key={starClass+index} className={starClass}></span>
                  ))
                }
              </div>
              <div className="review">
                리뷰 <b className="f-purple">{reviewList.length}</b>개
              </div>
            </div>
          }
        </div>
        <div className="size-wrap">
          <div className="title-wrap">
            <div className="title">
              <span className="name">하늘이</span> 오늘 사이즈
            </div>
            <button 
              type="button" 
              className="more"
              onClick={()=>navigate('/mymenu/mykid-profile')}
            >
              사이즈 수정
            </button>
          </div>
          <div className="white-box">
            <div className="item">
              <p className="key">
                키
              </p>
              <p className="value">
                <span className="number">129</span>cm
              </p>
            </div>
            <div className="item">
              <p className="key">
                몸무게
              </p>
              <p className="value">
              <span className="number">25</span>kg
              </p>
            </div>
            <div className="item">
              <p className="key">
                머리둘레
              </p>
              <p className="value">
                <span className="number">46</span>cm
              </p>
            </div>
            <div className="item">
              <p className="key">
                발길이
              </p>
              <p className="value">
                <span className="number">19</span>mm
              </p>
            </div>
          </div>
        </div>
        <div className="white-wrap delivery-wrap">
          <strong>배송</strong>
          <div className="text-wrap">
            <span>일반배송</span>
            <span>무료배송</span>
            <p>3.25(월) 이내 발송 예정</p>
          </div>
        </div>
        <div className="white-wrap">
          <div 
            className="img-wrap white-dim-hidden" 
            style={{height: imgMore ? 'auto' : '300rem', paddingBottom: imgMore ? '100rem' : '0'}}
          >
          {
            (description && description.blocks) && <>
              {
                description.blocks.map((block, index) => (
                  <div key={block+index} className="mb20">
                    {parse(parse(block.data.text))}
                  </div>
                ))   
              }
            </>
          }
          {
            bodyMediaData.map((images,index) => (
                <div className="img-wrap" key={images+index}>
                  <img src={images.url} alt={images.alt}></img>
                  {
                    images.alt && <p className="mb20">{images.alt}</p> 
                  }
                </div>
            ))
          }
          </div>
          <button 
            type="button" 
            className={imgMore ? "btn big round more f14 active" : "btn big round more f14"}
            onClick={()=>setImgMore(prevImgMore => !prevImgMore)}
          >
            상품정보 더 보기
          </button>
        </div>
        <div 
          className={isOpen ? "white-wrap toggle-wrap active" : "white-wrap toggle-wrap"}
        >
          <div className="h4-title-wrap">
            <h4 className="title mb0">
              배송/교환/환불 안내
            </h4>
            <button 
              type="button" 
              title="토글버튼" 
              className="toggle big active"
              onClick={()=>setIsOpen(!isOpen)}
            >
              <span className="icon-under"></span>
            </button>
          </div>
          <div className="text-wrap mt16">
            <strong className="f14 f-black80">반품/교환 사유에 따른 요청 가능 기간</strong>
            <p className="f12 f-black80 mt6 mb20">
              구매자 단순 변심은 상품 수령 후 7일 이내 <br/>
              표시/광고와 상이, 계약 내용과 다르게 이행된 경우 상품 수령 후 3개월 이내 혹은 표시/광고와 다른 사실을 안 날로부터 30일 이내 (판매자 반품 배송비 부담)둘 중 하나 경과 시 반품/교환 불가
            </p>
            <strong className="f14 f-black80">반품/교환 불가능 사유</strong>
            <p className="f12 f-black80 mt6">
              1. 반품요청기간이 지난 경우 <br/>
              2. 구매자의 책임 있는 사유로  상품 등이 훼손된 경우 <br/>
              3. 포장을 개봉하였으나 포장이 훼손되어 상품가치가 현저히 상실된 경우
            </p>
          </div> 
        </div>
        {
          reviewList.length !== 0 &&
          <div className="white-wrap">
            <div className="h4-class-wrap flex between">
              <h4 className="title a-more-btn">
                <a title="" href="">리뷰 {reviewList.length}</a>
              </h4>
              {/* <a title="" href=""><b className="f14 f-purple a-more-btn">제목으로 리뷰보기</b></a> */}
            </div>
            <div className="stars gap4 middle mt12">
              {
                window.WSgetStarArr(Math.round(averageRating)).map((starClass,index)=>(
                  <span key={starClass+index} className={starClass}></span>
                ))
              }
              <div className="count-wrap ml6">
                <strong>{averageRating}  </strong>
                / 5
              </div>
            </div>
            {/* <div className="review-option-wrap flex between mt20">
              <div className="input check bg">
                <label htmlFor="photoReview">
                  <input type="checkbox" id="photoReview"></input>
                  포토후기
                </label>
              </div>
              <ul className="options">
                <li className="option active">
                  <button type="button">베스트순</button>
                </li>
                <li className="option">
                  <button type="button">최신순</button>
                </li>
              </ul>
            </div> */}
            <div className="card review2 pl0 pr0">
              <div className="profile-wrap">
                <div className="img-wrap">
                  <img src={reviewList[0].node.user.metafields.profileImg} alt=""></img>
                </div>
                <strong className="f12">{reviewList[0].node.user.metafields.nickName}</strong>
                <span className="date">{window.WSdate(reviewList[0].node.createdAt)}</span>
              </div>
              <div className="stars gap4">
                {
                  window.WSgetStarArr(Math.round(averageRating)).map((starClass,index)=>(
                    <span key={starClass+index} className={starClass}></span>
                  ))
                }
              </div>
              <div className="review">
                <div className="img-wrap">
                  {/* <img src={reviewList[0].node.user.metafields.profileImg} alt=""></img> */}
                </div>
                <p className="f12">
                  {reviewList[0].node.text}
                </p>
              </div>
            </div>
            <button 
              type="button" 
              className="btn big round more mt16 f14"
              onClick={()=>{setActiveTab('리뷰')}}
            >
              더 많은 리뷰보기
            </button>
          </div>
        }
        <div className="white-wrap">
          <div className="h4-class-wrap flex between">
            <h4 
              className="title"
            >
              <Link to={`/product/${Page2depth}/inquiry`}
                onClick={() => setActiveTab('상품문의')}
              >
                <b className="f14 f-purple a-more-btn">
                  문의 {pageData.length}
                </b>
              </Link>
            </h4>
          </div>
          {/* <div className="inquiry-tab-wrap">
            <ul className="tabs">
              <li className="tab active">
                <button type="button">
                  ALL
                </button>
              </li>
              <li className="tab">
                <button type="button">
                  상품문의
                </button>
              </li>
              <li className="tab">
                <button type="button">
                  사이즈문의
                </button>
              </li>
              <li className="tab">
                <button type="button">
                  배송문의
                </button>
              </li>
              <li className="tab">
                <button type="button">
                  기타
                </button>
              </li>
            </ul>
          </div> */}
          {pageData.map((page, index) => (
            <ul className="inquiry-list">
              <li key={page+index} className="card inquiry">
                <p className="text">{page.node.title}</p>
                <div className="status-wrap">
                  <span className="status">
                    {!page?.node?.metafields?.answer 
                      ? "답변 대기" 
                      : "답변 완료"}
                  </span>
                  <span className="name">
                    {page.node.metafields.user_id.length > 3 
                    ? `${page.node.metafields.user_id.substring(0, 3)}***` // 닉네임 뒤에 ***표시
                    : page.node.metafields.user_id}
                  </span>
                  <span className="date">
                    {new Date(page.node.publicationDate).toLocaleDateString().replace(/\.$/, '')}
                  </span>
                </div>
              </li>
            </ul>
          ))}
        </div>
      </section>
    </>
  );
};

export default Product01Section;

