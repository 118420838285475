import { gql } from '@apollo/client';
import { client } from '../apolloClient';

// 바우처 목록 조회 함수
export function fetchVouchers(productId="") {
  const GET_VOUCHERS = gql`
    query GetVouchers($channel: String!, $productId: [ID!]) {
      TGvouchers(first: 10, channel: $channel, filter: {productId: $productId}) {
        edges {
          node {
            id
            name 
            type
            code
            startDate
            endDate
            usageLimit
            used
            isDownloaded
            minSpent {
              amount
              currency
            }
            singleUse
            applyOncePerCustomer
            discountValueType
            discountValue
            currency
            metadata {
              key
              value
            }
            products(first: 10) {
              edges {
                node {
                  id
                }
              }
            }
            metafields
            countries {
              code
              country
            }
          }
        }
      }
    }
  `;

  const variables = { channel: 'ko' };
  if (productId) {
    variables.productId = productId;
  }

  return client.query({
    query: GET_VOUCHERS,
    variables: variables,
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}

// 바우처 유저 목록 조회 함수, 이메일 인자를 받도록 수정
export function fetchUserVouchers(email) {
  const GET_USER_VOUCHERS = gql`
    query GetUserVouchers($email: String!) {
      TGUserVouchers(email: $email) {
        voucher {
          id
          code
          name
          metadata {
            key
            value
          }
        }
        user {
          id
          email
        }
      }
    }
  `;

  return client.query({
    query: GET_USER_VOUCHERS,
    variables: { email },
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}

// TGVoucherDownload 뮤테이션 등록 함수
export function TGVoucherDownload(voucherCode) {
  const DOWNLOAD_TG_VOUCHER = gql`
    mutation TGVoucherDownload($input: TGVoucherDownloadInput!) {
      TGVoucherDownload(input: $input) {
        response
        errors {
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: DOWNLOAD_TG_VOUCHER,
    variables: {
      input: {
        voucherCode
      }
    }
  });
}

// 체크아웃에 프로모션 코드 추가 함수, 체크아웃 ID와 프로모션 코드를 인자로 받도록 설정
export function addPromoCodeToCheckout(checkoutId, promoCode) {
  const CHECKOUT_ADD_PROMO_CODE = gql`
    mutation AddPromoCodeToCheckout($checkoutId: ID!, $promoCode: String!) {
      checkoutAddPromoCode(checkoutId: $checkoutId, promoCode: $promoCode) {
        checkout {
          id
          totalPrice {
            gross {
              amount
              currency
            }
          }
          discountName
          discount {
            amount
            currency
          }
        }
        errors {
          field
          message
        }
      }
    }
  `;


  return client.mutate({
    mutation: CHECKOUT_ADD_PROMO_CODE,
    variables: {
      checkoutId,
      promoCode
    }
  });
}
