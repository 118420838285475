import React, { useState, useEffect } from 'react';
import LogoImage from "../../assets/images/common/logo.svg";
import { useNavigate, Link } from 'react-router-dom';
import useAuthStore from '../../store/common/useAuthStore';
import { getCheckout } from '../../services/GQ_apis/checkout';

const TopNav = () => {

  const { user, globalObserver } = useAuthStore();

  const navigate = useNavigate();
  const [showTabs, setShowTabs] = useState(false);
  const [shoppingItems, setShoppingItems] = useState([]); // 장바구니 리스트

  const toggleTabs = () => {
    setShowTabs(!showTabs);
  };

  const fetchDelivery = () => {

    if (! localStorage.getItem('cartToken')) return;

    getCheckout(localStorage.getItem('cartToken')).then((res)=>{
      if(res.data.checkout) setShoppingItems(res.data.checkout.lines);
    })

  }

  useEffect(()=>{
    console.log('globalObserver update');
    fetchDelivery();
  },[globalObserver,localStorage.getItem('cartToken')])

  // Mounted[START] ------------------------------------ 
  useEffect(() => {
    document.addEventListener("mousedown", setShowTabs(false));
    return () => {
      document.removeEventListener("mousedown", setShowTabs(false));
    };
  }, []);
  // Mounted[END] --------------------------------------

  return (
    <div className="top-bar">
      <div className="top-bar-inner">
        <Link to="/" title="zaezae 메인으로 이동">
          <h1 id="logo">
            <img src={LogoImage} alt="zaezae" />
          </h1>
        </Link>
        <div className="actions">
          <button className="icon-search" type="button" onClick={()=>navigate('/search/process')}></button>
          <div className="language-wrap">
            <button className="icon-language" type="button" onClick={toggleTabs}></button>
            {showTabs && (
              <>
                <ul className="tabs">
                  <li className="tab"><button type="button">한국어</button></li>
                  <li className="tab"><button type="button">English</button></li>
                </ul>
                <div className="dim white" onClick={()=>setShowTabs(false)}></div>
              </>
            )}
          </div>
          <button className="icon-notification" type="button" onClick={()=>navigate('/alert')}>
          </button>
          <button className="icon-b2b" type="button" onClick={()=>navigate('/biz')}></button>
          {
              localStorage.getItem('user') && user && user.checkout ?
              <button className="icon-shoppingbag" type="button" onClick={()=>navigate('/pay/shopping')}>
                {
                  shoppingItems.length > 0 &&
                  <span className="num">
                    {shoppingItems.length}
                  </span>
                }
              </button>
              :
              <button 
                className="icon-shoppingbag" 
                type="button" 
                onClick={()=>navigate('/login/main2')}
              >
              </button>
          }
        </div>
      </div>
    </div>
  );
  
};

export default TopNav;
