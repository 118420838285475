import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useAuthStore from '../../store/common/useAuthStore';

const Init = () => {

  const { pathname } = useLocation();
  const { fetchUser } = useAuthStore();

  useEffect(() => {
    
    // 페이지 내의 모든 article 태그를 찾습니다.
    const articles = document.querySelectorAll('article');
    
    // 각 article 태그의 스크롤 위치를 최상단으로 조정합니다.
    articles.forEach(article => {
      article.scrollTop = 0;
    });

    fetchUser();
    
  }, [pathname]); // 경로가 변경될 때마다 실행

  return null;
};

export default Init;
