import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../store/common/useAuthStore';
import { getCheckout } from '../../services/GQ_apis/checkout';

const TitleActionBar = ({ title = '타이틀을 입력해주세요.' }) => {
  
  const navigate = useNavigate();
  const { user,globalObserver } = useAuthStore();
  const [shoppingItems, setShoppingItems] = useState([]); // 장바구니 리스트

  // 뒤로 가기 함수
  const goBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/'); // 홈페이지로 리다이렉트
    }
  };

  const fetchDelivery = () => {

    if (! localStorage.getItem('cartToken')) return;

    getCheckout(localStorage.getItem('cartToken')).then((res)=>{
      if(res.data.checkout) setShoppingItems(res.data.checkout.lines);
    })
  }

  useEffect(()=>{
    fetchDelivery();
    console.log('globalObserver update');
  },[globalObserver])

  return (
    <div className="top-bar">
      <div className="top-bar-inner">
        <span onClick={goBack} title="클릭시 이전 페이지로" className="title" style={{cursor: 'pointer'}}>
          {title}
        </span>
        <div className="actions">
          <button className="icon-search" type="button" onClick={()=>navigate('/search/process')}></button>
          <button className="icon-notification" type="button" onClick={()=>navigate('/alert')}>
          </button>
          {
            localStorage.getItem('user') && user && user.checkout ?
            <button className="icon-shoppingbag" type="button" onClick={()=>navigate('/pay/shopping')}>
              {
                shoppingItems.length > 0 &&
                <span className="num">
                  {shoppingItems.length}
                </span>
              }
            </button>
            :
            <button className="icon-shoppingbag" type="button" onClick={()=>navigate('/login/main2')}>
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default TitleActionBar;
