import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { meData } from '../../services/GQ_apis/me';
import { updateAccount } from '../../services/GQ_apis/auth';
import usePopupStore from '../../store/common/usePopupStore'

import SampleImage from "../../assets/images/common/icons/icon_profile.svg";

const MyMenuProfileModifySection = () => {
  
  const navigate = useNavigate();
  
  // 필수 입력 항목 검사
  const validateInput = () => {
    return nickName && phoneNumber && name;
  };

  // SnackBar 팝업 사용 함수 openSnackBarPopup
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  const [profileImg, setProfileImg] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [nickName, setNickName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');

  useEffect(()=>{
    
    meData().then((res)=>{

      console.log('meData',res);

      setEmail(res.data.me.email);
      setName(res.data.me.firstName);
      setNickName(res.data.me.metafields.nickName);
      setPhoneNumber(res.data.me.metafields.phone);
      setProfileImg(res.data.me.metafields.profileImg);
      if(res.data.me.defaultShippingAddress) {
        setAddress1(res.data.me.defaultShippingAddress.streetAddress1);
        setAddress2(res.data.me.defaultShippingAddress.streetAddress2);
      }
    })
  
  },[])

  const modifySave = () => {

    let metadata = [
      { key: "phone", value: phoneNumber },
      { key: "nickName", value: nickName },
      { key: "profileImg", value: profileImg }
    ];

    updateAccount(name, metadata).then((res)=>{

      console.log('Response:', res);
      openSnackBarPopup('정상적으로 회원정보가 수정되었습니다.','','');
      navigate('/mymenu/profile');
    })

  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImg(e.target.result)
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <>
      <section className="section-mymenu-profile-profile">
        <div className="inner">

          {/* 개발 디버그용 [START] */}
          {/* <div className="debug-box">
            <p><b>이름 name :</b> {name} </p>
            <p><b>닉네임 nickName :</b> {nickName} </p>
            <p><b>휴대폰 번호 phoneNumber :</b> {phoneNumber} </p>
            <p><b>프로필 이미지 :</b> {profileImg} </p>
          </div> */}
          {/* 개발 디버그용 [END] */}

          <div className="gray-box">
            <b>계정 분실 시 본인인증 정보로 활용됩니다.</b>
          </div>

          {/* 프로필 영역 [START] */}
          <div className="profile-img-wrap">
            <label htmlFor="profileImgInput" className="upload-button">
              <div className="img-wrap">
                <img 
                  src={
                    profileImg || SampleImage 
                  } 
                  alt="프로필 이미지" 
                  title="클릭시 프로필 사진 첨부 이동"
                />
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="profileImgInput"
              />
              {/* <p className="title">이름</p> */}
            </label>
          </div>
          {/* 프로필 영역 [END] */}

          {/* 이메일 [START] */}
          <div className="input-box">
            <label htmlFor="email" className="title">
              이메일
            </label>
            <div className="input">
              <input 
                id="email" 
                type="email" 
                placeholder="이메일을 입력해주세요."
                disabled
                value={email}
              />
            </div>
          </div>
          {/* 이메일 [END] */}

          {/* 이름 [START] */}
          <div className="input-box">
            <label htmlFor="name" className="title starred">
              이름
            </label>
            <div className="input">
              <input 
                id="name" 
                type="text" 
                placeholder="이름을 입력해주세요."
                value={name}
                onInput={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          {/* 이름 [END] */}

          {/* 닉네임 [START] */}
          <div className="input-box">
            <label htmlFor="nickname" className="title starred">
              닉네임
            </label>
            <div className="input">
              <input 
                id="nickname" 
                type="text" 
                placeholder="닉네임을 입력해주세요."
                value={nickName}
                onInput={(e) => setNickName(e.target.value)}
              />
              {/* <span className="caption f-purple">사용 가능한 닉네임입니다.</span> */}
            </div>
          </div>
          {/* 닉네임 [END] */}

          {/* 생년월일 [START] */}
          {/* <div className="input-box">
            <label htmlFor="date" className="title">
              생년월일
            </label>
            <div className="input">
              <input 
                id="date" 
                type="date"
                placeholder="생년월일을 입력해주세요."
                value={input.birthday}
                onChange={(e) => actions.setBrithday(e.target.value)}
              />
            </div>
          </div> */}
          {/* 생년월일 [END] */}

          {/* 연락처 [START] */}
          <div className="input-box">
            <label htmlFor="number" className="title starred">
              연락처
            </label>
            <div className="input">
              <input 
                id="number" 
                type="number" 
                placeholder="연락처를 입력해주세요."
                value={phoneNumber}
                onInput={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>
          {/* 연락처 [END] */}

          {/* 주소 [START] */}
          <div className="input-box">
            <div className="title-wrap">
              <label htmlFor="adress1" className="title">
                주소
              </label>
              <button 
                type="button" 
                className="btn purple"
                onClick={()=>navigate('/delivery/list')}
              >
                배송지 관리
              </button>
            </div>
            <div className="input">
              <input 
                id="adress1" 
                type="text" 
                placeholder="주소를 입력해주세요."
                value={address1}
                readOnly
              />
            </div>
            <div className="input">
              <label htmlFor="adress2" className="title hide">
              </label>
              <input 
                id="adress2" 
                type="text" 
                placeholder="상세 주소를 입력해주세요."
                value={address2}
                readOnly
              />            
            </div>
          </div>
          {/* 주소 [END] */}

          {/* 내 아이 정보 입력하기 [START] */}
          <div className="btn-wrap center mt20">
            <button 
              type="button" 
              className="btn add round yellow"
              onClick={()=>navigate('/register/kid-1')}
            >
              <span className="icon-plus-yellow"></span>
              내 아이 정보 입력하기
            </button>
          </div>
          {/* 내 아이 정보 입력하기 [END] */}
          
        </div>
      </section>
      {/* 수정사항 저장하기 버튼 [START]*/}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big"
              disabled={! validateInput()}
              onClick={modifySave}
            >
              수정사항 저장하기
            </button>
          </div>
        </div>
      </div>
      {/* 수정사항 저장하기 버튼 [END]*/}
    </>
  );
};

export default MyMenuProfileModifySection;

