import React, { useState, useEffect } from 'react';
import blackImg from '../../assets/images/sample/black_img.svg';

const ThumbnailImage = ({ thumbnailUrl, thumbnailAlt }) => {
  const [src, setSrc] = useState(thumbnailUrl || blackImg);

  useEffect(() => {
    setSrc(thumbnailUrl || blackImg);
  }, [thumbnailUrl]);

  const handleError = () => {
    setSrc(blackImg);
  };

  return (
    <img src={src} alt={thumbnailAlt} onError={handleError} />
  );
};

export default ThumbnailImage;
