import { gql } from '@apollo/client';
import { client, nonTokenClient } from '../apolloClient';

const GET_COLLECTION = gql`
  query GetCollections($slugs: [String!] = "") {
    collections(filter: {slugs: $slugs}, first: 50) {
      edges {
        node {
          id
          name
          description
          slug,
          metadata {
            key
            value
          }
          privateMetadata {
            key
            value
          }
        }
      }
    }
  }
`;

export function getCollection(slugs) {
  return nonTokenClient.query({
    query: GET_COLLECTION,
    variables: {
      slugs
    },
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}
