import React from 'react';
import { useNavigate } from 'react-router-dom';
import usePopupStore from '../../store/common/usePopupStore'

const TitleZaelookBar = ({ title = '타이틀을 입력해주세요.' }) => {
  
  const navigate = useNavigate();

  // 뒤로 가기 함수
  const goBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/'); // 홈페이지로 리다이렉트
    }
  };

  // SnackBar 팝업 사용 함수 openSnackBarPopup
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  // URL을 클립보드에 복사하는 함수
  const copyToClipboard = () => {
    // 현재 페이지의 URL을 가져옵니다.
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      // alert('URL이 클립보드에 복사되었습니다.');
      openSnackBarPopup('URL이 클립보드에 복사되었습니다.','','');
    }).catch(err => {
      console.error('클립보드 복사에 실패했습니다:', err);
    });
  };

  return (
    <div className="top-bar">
      <div className="top-bar-inner">
        <span 
          onClick={goBack} 
          title="클릭시 이전 페이지로" 
          className="title" 
          style={{cursor: 'pointer'}}
        >
          {title}
        </span>
        <div className="actions">
          <button 
            onClick={copyToClipboard} // 공유 버튼에 클릭 이벤트 핸들러 추가
            className="icon-share" 
            type="button"
            title="클릭시 URL을 클립보드에 복사"
          >
          </button>
        </div>
      </div>
    </div>
  );
};

export default TitleZaelookBar;
