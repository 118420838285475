import React from "react";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();
  return (
    <div style={{ margin: "30vh 0 0 0" }}>
      <div className="d-flex align-items-center text-center error-page  pt-5 pb-4 h-100">
        <div className="row flex-grow">
          <div className="col-lg-8 mx-auto text-white">
            <div className="row align-items-center d-flex flex-row">
              <div className="col-lg-6 text-lg-right pr-lg-4">
                <h1 className="display-1 mb-0">404</h1>
              </div>
              <div className="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                <h2>SORRY!</h2>
                <h5 className="font-weight-light">
                  The page you’re looking for was not found.
                </h5>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 text-center mt-xl-2">
                <span
                  className="font-weight-medium"
                  onClick={() => navigate("/")}
                  style={{ color: "#8772f9" }}
                >
                  Back to home
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;

// <div>
//   <h1>404</h1>

//   <h3 className="font-weight-light">
//     The page you’re looking for was not found.
//   </h3>

//   <div className="col-12 text-center mt-xl-2" onClick={() => navigate("/")}>
//     Back to home
//   </div>
// </div>;
