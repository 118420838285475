import { gql } from '@apollo/client';
import { client } from '../apolloClient';

// 체크아웃 생성
export function createCheckout(lines, userEmail) {
  const CREATE_CHECKOUT = gql`
    mutation CreateCheckout(
      $email: String!, 
      $channel: String!, 
      $lines: [CheckoutLineInput!]!
    ) {
      checkoutCreate(input: {
        email: $email,
        channel: $channel,
        lines: $lines
      }) {
        checkout {
          id
          quantity
          token
        }
        errors {
          field
          message
          addressType
        }
      }
    }
  `;

  return client.mutate({
    mutation: CREATE_CHECKOUT,
    variables: {
      email: userEmail,
      channel: "ko",
      lines
    }
  });
}

// 체크아웃에 배송지 메소드 설정
export function updateShippingMethod(checkoutId, shippingMethodId) {
  const UPDATE_SHIPPING_METHOD = gql`
    mutation UpdateShippingMethod($checkoutId: ID!, $shippingMethodId: ID!) {
      checkoutShippingMethodUpdate(checkoutId: $checkoutId, shippingMethodId: $shippingMethodId) {
        checkout {
          id
          shippingMethod {
            id
            name
          }
        }
        errors {
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: UPDATE_SHIPPING_METHOD,
    variables: {
      checkoutId,
      shippingMethodId
    }
  });
}

// 체크아웃 기본 배송지 설정
export function updateCheckoutAddress(checkoutId, shippingAddress) {
  const UPDATE_CHECKOUT_ADDRESS = gql`
    mutation UpdateCheckoutAddress($checkoutId: ID!, $shippingAddress: AddressInput!) {
      checkoutShippingAddressUpdate(checkoutId: $checkoutId, shippingAddress: $shippingAddress) {
        checkout {
          id
          shippingAddress {
            firstName
            lastName
            streetAddress1
            streetAddress2
            city
            postalCode
            country {
              code
            }
          }
        }
        errors {
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: UPDATE_CHECKOUT_ADDRESS,
    variables: {
      checkoutId,
      shippingAddress
    }
  });
}

// Checkout 추가 함수
export function addToCheckoutLine(checkoutId, lines) {

  // 상품을 Checkout에 추가하는 뮤테이션
  const ADD_TO_CHECKOUT = gql`
    mutation addToCheckoutLine(
      $checkoutId: ID!, 
      $lines: [CheckoutLineInput!]!
    ) {
      checkoutLinesAdd(checkoutId: $checkoutId, lines: $lines) {
        checkout {
          id
          lines {
            id
            quantity
            variant {
              product {
                name
              }
            }
          }
        }
        errors {
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: ADD_TO_CHECKOUT,
    variables: {
      checkoutId,
      lines
    }
  });
}

// Checkout Line 업데이트 함수
export function updateToCheckoutLine(
  checkoutId, 
  lines
) {

  const UPDATE_TO_CHECKOUT = gql`
    mutation checkoutLineUpdate(
      $checkoutId : ID!, 
      $lines: [CheckoutLineUpdateInput!]!
    ) {
    checkoutLinesUpdate(
      checkoutId : $checkoutId,
      lines : $lines
    ) 
    {
      checkout {
        id
        quantity
      }
      errors {
        field
        message
        addressType
      }
    }
    }
  `;

  return client.mutate({
    mutation: UPDATE_TO_CHECKOUT,
    variables: {
      checkoutId,
      lines
    }
  });
}

// Checkout 삭제 함수
export function deleteToCheckoutLine(checkoutId, lineId) {

  const DELETE_TO_CHECKOUT = gql`
    mutation checkoutLineDelete(
      $checkoutId: ID!, 
      $lineId: ID!
    ) {
      checkoutLineDelete(
        checkoutId: $checkoutId, 
        lineId: $lineId
      ) {
        checkout {
          id
        }
        errors {
          field
          message
          addressType
        }
      }
    }
  `;

  return client.mutate({
    mutation: DELETE_TO_CHECKOUT,
    variables: {
      checkoutId,
      lineId
    }
  });
}

// Checkout 조회 함수
export function getCheckout(token) {
  const GET_CHECKOUT = gql`
    query GetCheckout($token: UUID!) {
      checkout(token: $token) {
        id
        token
        discountName
        quantity
        availableShippingMethods {
          id
          name
          price {
            amount
            currency
          }
          metadata {
            key
            value
          }
        }
        discount {
          amount
          currency
        }
        deliveryMethod {
          ... on ShippingMethod {
            id
            name
            description
            minimumDeliveryDays
            maximumDeliveryDays
            price {
              currency
              amount
            }
          }
        }
        shippingPrice {
          gross {
            amount
            currency
          }
        }
        lines {
          id
          quantity
          totalPrice {
            gross {
              amount
            }
          }
          undiscountedTotalPrice {
            amount
          }
          undiscountedUnitPrice {
            currency
            amount
          }
          unitPrice {
            gross {
              amount
              currency
            }
          }
          variant {
            name
            id
            attributes {
              attribute {
                name
                id
              }
              values {
                id
                name
              }
            }
            product {
              name
              vendor {
                id
                storeNameKo
                commissionData {
                  totalRate
                }
              }
              thumbnail {
                url
                alt
              }
              attributes {
                values {
                  name
                }
                attribute {
                  name
                }
              }
              pricing {
                priceRange {
                  start {
                    gross {
                      currency
                      amount
                    }
                    currency
                  }
                  stop {
                    gross {
                      amount
                      currency
                    }
                    currency
                  }
                }
              }
            }
          }
        }
        totalPrice {
          gross {
            amount
            currency
          }
        }
        subtotalPrice {
          gross {
            amount
            currency
          }
        }
        shippingAddress {
          firstName
          lastName
          companyName
          streetAddress1
          streetAddress2
          city
          postalCode
          country {
            code
            country
          }
          countryArea
          phone
        }
      }
    }
  `;

  return client.query({
    query: GET_CHECKOUT,
    variables: {
      token
    },
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}

// Checkout 완료 함수
export function completeCheckout(token) {
  const COMPLETE_CHECKOUT = gql`
    mutation CompleteCheckout($token: UUID!) {
      checkoutComplete(token: $token) {
        order {
          id
          status
          token
          created
          discounts {
            id
            name
            amount {
              amount
              currency
            }
            type
          }
          total {
            gross {
              amount
              currency
            }
          }
          subtotal {
            gross {
              amount
              currency
            }
          }
          shippingPrice {
            gross {
              amount
              currency
            }
          }
          lines {
            id
            quantity
            productName
            productSku
            unitPrice {
              gross {
                amount
                currency
              }
            }
            totalPrice {
              gross {
                amount
                currency
              }
            }
            thumbnail {
              alt
              url
            }
            variant {
              name
              id
              product {
                name
                thumbnail {
                  url
                  alt
                }
                vendor {
                  id
                  storeNameEn
                  storeNameKo
                  commissionData {
                    totalRate
                  }
                }
              }
            }
          }
          shippingAddress {
            firstName
            lastName
            streetAddress1
            streetAddress2
            city
            postalCode
            country {
              code
              country
            }
            phone
          }
          billingAddress {
            firstName
            lastName
            streetAddress1
            streetAddress2
            city
            postalCode
            country {
              code
              country
            }
            phone
          }
        }
        errors {
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: COMPLETE_CHECKOUT,
    variables: {
      token
    }
  });
}


