import React from "react";

const MyMenuRewardSection = () => {
  return (
    <>
      <section className="section-mymenu-reward">
        <div className="able-reward">
          <b>사용가능 적립금</b>
          <strong>10,000</strong>
        </div>
        <div className="gray-box">
          <b>구매적립내역</b>
          <span className="f-purple">
            적립금
          </span>
        </div>
        <ul className="reward-list">
          <li>
            <div className="top">
              <b>구매적립 1%</b><span className="date">2024.02.22</span>
            </div>
            <div className="center">
              <p>아이사랑T셔츠 구매</p>
              <b className="f-purple">2,500원</b>
            </div>
            <div className="bottom">
              <span className="balance">잔액 20,500원</span>
            </div>
          </li>
          <li>
            <div className="top">
              <b>구매적립 1%</b><span className="date">2024.02.22</span>
            </div>
            <div className="center">
              <p>아이사랑T셔츠 구매</p>
              <b className="f-purple">2,500원</b>
            </div>
            <div className="bottom">
              <span className="balance">잔액 20,500원</span>
            </div>
          </li>
          <li>
            <div className="top">
              <b>구매적립 1%</b><span className="date">2024.02.22</span>
            </div>
            <div className="center">
              <p>아이사랑T셔츠 구매</p>
              <b className="f-red">-500원</b>
            </div>
            <div className="bottom">
              <span className="balance">잔액 20,500원</span>
            </div>
          </li>
        </ul>
      </section>
    </>
  );
};

export default MyMenuRewardSection;

