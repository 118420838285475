import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import useInputStore from '../../store/common/useInputStore'
import usePopupStore from '../../store/common/usePopupStore'
import api from "../../services/api"
import InputPhone from '../../components/Inputs/InputPhone'

const LoginFindIdSection = () => {
  
  const navigate = useNavigate();

  const { 
    input,
    actions,
  } = useInputStore(state => ({
    input: state.input,
    actions: state.actions,
  }));


  // 아이디 찾기 - 데이터
  const [fiCode, setFiCode] = useState(null);

  // SnackBar 팝업 사용 함수 openSnackBarPopup
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  // 아이디 복사하기
  const copyToClip = (text) => {
    navigator.clipboard.writeText(text)
    .then(()=>{
      openSnackBarPopup('아이디가 복사되었습니다.','','success');
    })
  }

  // 아이디 찾기 관련 메소드 --------------------------------------------------------

  const validateInput = () => {    
    return input.name &&
           input.phoneNumber &&  
           input.isPhoneVerification;
};

  const findID = () => {
    api.post('/api-tg/fid', {
      name: input.name,
      phone: input.phoneNumber
    }).then(res => {
      const data = res.data
      actions.setEmail(data.email)
    }).catch(e => {
      openSnackBarPopup('일치하는 정보가 없습니다.\n다른 가입 정보로 다시 시도해주세요.', '', 'error')
    })
  };

  useEffect(() => {
    if (validateInput()) {
      findID();
    } else if (input.isPhoneVerification === false) {
      openSnackBarPopup('인증번호가 일치하지 않습니다.', '', 'error');
    }
  }, [input.name, input.phoneNumber, input.isPhoneVerification]);

  
  return (
    <>
      <section className="section-login-findid">
        <div className="inner">

          {/* 개발 디버그용 [START] */}
          {/* <div className="debug-box">
            <p><b>이름 name :</b> {input.name} </p>
            <p><b>휴대폰 번호 phoneNumber :</b> {input.phoneNumber} </p>
            <p><b>휴대폰 인증번호 phoneCode :</b> {fiCode} </p>
          </div> */}
          {/* 개발 디버그용 [END] */}

          <div className="h3-title-wrap mt0">
            <h3 className="title">아이디를 잊으셨나요?</h3>
          </div>

          {/* 이름 [START] */}
          <div className="input-box">
            <label htmlFor="name" className="title starred">
              이름
            </label>
            <div className="input">
              <input 
                id="name" 
                type="text" 
                placeholder="이름을 입력해주세요."
                onInput={(e) => actions.setName(e.target.value)}
                // disabled={input.isPhoneSend}
                value={input.name}
              />
            </div>
          </div>
          {/* 이름 [END] */}

          {/* 휴대전화 [START] */}
          <InputPhone disabled={!input.name}/>
          {/* 휴대전화 [END] */}

          {
            validateInput() &&
            <>
              {/* 아이디를 찾았어요. [START] */}
              <div className="input-box">
                <div className="title">
                  아이디를 찾았어요.
                </div>
                <div className="gray-box">
                  <strong className="f-purple">
                    { input.email }
                  </strong>
                  <a 
                    className="under-line" 
                    title="" 
                    href="" 
                    onClick={(e) => {
                      e.preventDefault();
                      copyToClip(input.email);
                    }}
                  >
                    아이디 복사하기
                  </a>
                </div>
              </div>
              {/* 아이디를 찾았어요. [END] */}
            </>
          }

        </div>
      </section>
      <div className="bottom-bar">
        <div className="inner">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big"
              disabled={!validateInput()}
              onClick={()=>navigate('/login/main2')}
            >
              로그인하러 가기
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginFindIdSection;
