import React from 'react';
import { useNavigate } from 'react-router-dom';

const TitleBar = ({ 
  title = '타이틀을 입력해주세요.', 
  url='',
}) => {
  
  const navigate = useNavigate();

  // 뒤로 가기 함수
  const goBack = () => {

    if(url) {
      navigate(url) 
      return;
    }

    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <div className="top-bar border">
      <div className="top-bar-inner">
        <span 
          onClick={goBack} 
          title="클릭시 이전 페이지로" 
          className="title" 
          style={{cursor: 'pointer'}}
        >
          <span>{title}</span>
        </span>
        {/* <span 
          onClick={()=>navigate('/')} 
          title="클릭시 홈으로" 
          className="title reverse" 
          style={{cursor: 'pointer'}}
        >
          다음에 하기
        </span> */}
        {/* <div className="actions">
          <button className="icon-search" type="button" onClick={()=>navigate('/search/process')}></button>
          <button className="icon-notification" type="button" onClick={()=>navigate('/alert')}>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default TitleBar;
