import React, { useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import useInputStore from '../../store/common/useInputStore'
import usePopupStore from '../../store/common/usePopupStore'
import useAuthStore from '../../store/common/useAuthStore';
import { accountLogin } from '../../services/GQ_apis/auth';

const LoginMain02Section = () => {

  const navigate = useNavigate();
  const { login } = useAuthStore();

  const { 
    input,
    actions,
  } = useInputStore(state => ({
    input: state.input,
    actions: state.actions,
  }));

  // SnackBar 스낵바 팝업 사용 함수 openSnackBarPopup
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup, // 스낵바 오픈 함수
  }));
  
  const handleLogin = () => {
    accountLogin(input.email, input.password)
      .then((response) => {
        console.log('응답 데이터:', response); // 응답 로그 추가
        if (response.data && response.data.tokenCreate && response.data.tokenCreate.token) {
          
          login(response.data);
          openSnackBarPopup('정상적으로 로그인되었습니다.', '', '');
          navigate("/");

        } else {
          openSnackBarPopup('아이디와 비밀번호를 확인해주세요.', '', '');
        }
      })
      .catch((error) => {
        console.log('로그인 오류:', error); // 에러 로그 추가
        openSnackBarPopup('로그인 도중 오류가 발생했습니다.', '', '');
        navigate("/login/main2");
      });
  };
  


  // 마운트시 resetInput 초기화
  useEffect(() => {
    actions.resetInput();
  }, [actions.resetInput]);

  return (
    <section className="section-login-main02">
      <div className="inner">
        <span className="icon-logo-yellow">
        </span>

        <div className="input-box">
          <div className="input">
            <label htmlFor="email" className="hide">이메일</label>
            <input 
              id="email" 
              type="email" 
              placeholder="아이디를 입력해주세요."
              onInput={(e) => actions.setEmail(e.target.value)}
            />
          </div>
          <div className="input">
            <label htmlFor="password" className="hide">비밀번호</label>
            <input 
              id="password" 
              type="password" 
              placeholder="비밀번호를 입력해주세요."
              onInput={(e) => {
                actions.setPassword(e.target.value);
                actions.validatePasswords();
              }}
            />
          </div>
        </div>
        <button 
          type="button" 
          className="btn purple big mt14"
          onClick={handleLogin} 
        >
          로그인
        </button>
        <ul className="a-list">
          <li><Link to="/login/findid" title="">아이디 찾기</Link></li>
          <li><Link to="/login/findpw" title="">비밀번호 찾기</Link></li>
          <li><Link to="/register/1" title="">회원가입</Link></li>
        </ul>
        <div className="sns-wrap">
          <p className="title">
            SNS 계정으로 로그인
          </p>
          <ul className="sns-list">
            <li>
              <a className="icon-kakao" href="#none" title="카카오 로그인">
              </a>
            </li>
            <li>
              <a className="icon-naver" href="#none" title="네이버 로그인">
              </a>
            </li>
            <li>
              <a className="icon-google" href="#none" title="구글 로그인">
              </a>
            </li>
          </ul>
        </div>
        <div className="under-line">
        <Link className="under-line" to="/" title="">
          혜택 받지 않고 둘러보기
        </Link>
        </div>

      </div>

      {/* 개발 디버그용 [START] */}
      {/* <div className="debug-box">
        <p><b>아이디(이메일) email :</b> {input.email}</p>
        <p><b>비밀번호 password :</b> {input.password} </p>
      </div> */}
      {/* 개발 디버그용 [END] */}

    </section>
  );
};

export default LoginMain02Section;
