import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { orderRequestExchange } from '../../services/GQ_apis/order';
import usePopupStore from '../../store/common/usePopupStore';

const MyMenuRequestExchangeSection = () => {

  const navigate = useNavigate();
  const { Page3depth } = useParams();
  const [selectedReason, setSelectedReason] = useState(""); // 취소 사유를 저장할 상태
  const [otherReason, setOtherReason] = useState(""); // '기타 사유' 입력값을 저장할 상태

  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  // 사유 선택 핸들러
  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  // '기타 사유' 입력값 변경 핸들러
  const handleOtherReasonChange = (event) => {
    setOtherReason(event.target.value);
  };
  
  const textReason = useMemo(()=>{
    if (selectedReason !== 'etc') {
      return selectedReason
    } else { 
      return otherReason
    }
  },[selectedReason, otherReason])

  const confirmOrderExchange = () => {

    if (! Page3depth || ! textReason) {
      openSnackBarPopup('교환 사유를 선택해주세요.', '', '');
      return
    }

    orderRequestExchange(Page3depth, textReason).then((res)=>{
      console.log('orderRequestExchange', res);
      navigate('/order/exchange-list')
      openSnackBarPopup('교환요청을 보냈습니다.', '', '');
    })
  }

  // useEffect(()=>{
  //   // console.log('Page2depth',Page3depth)
  //   console.log('textReason : ', textReason)
  // },[Page3depth, textReason])

  return (
    <>
      <section className="section-mymenu-request-exchange">
        <div className="white-wrap pt16">
          <h4 className="title mb10">교환 사유를 선택해주세요.</h4>
          <div className="input-box">
            <ul className="input-list">
              <li className="input radio">
                <label htmlFor="radio1">
                  <input 
                    id="radio1" 
                    name="radio" 
                    type="radio"
                    value="상품이 마음에 들지 않음 (단순변심)" 
                    onChange={handleReasonChange}
                  ></input> 상품이 마음에 들지 않음 (단순변심)
                </label>
              </li>
              <li className="input radio">
                <label htmlFor="radio2">
                  <input 
                    id="radio2" 
                    name="radio" 
                    type="radio"
                    value="다른 상품 추가 후 재주문 예정" 
                    onChange={handleReasonChange}
                  ></input> 다른 상품 추가 후 재주문 예정
                </label>
              </li>
              <li className="input radio etc">
                <label htmlFor="radio3">
                  <input 
                    id="radio3" 
                    name="radio" 
                    type="radio"
                    value="etc" 
                    onChange={handleReasonChange}
                  ></input> 기타 사유
                </label>
                <textarea 
                  disabled={selectedReason !== "etc"}
                  placeholder="상세 사유를 입력하세요"
                  value={otherReason}
                  onChange={handleOtherReasonChange}
                ></textarea>
              </li>
            </ul>
          </div>
          <div className="debug-box mt20">
            <p><b>선택 사항 selectedReason :</b> {selectedReason}</p>
            <p><b>기타 사유 상세 otherReason :</b> {otherReason}</p>
          </div>
        </div>
        <div className="white-wrap">
          <div className="common-tab-wrap">
            <p className="f13 f-purple mb16">수거지주소는 배송지주소와 동일합니다.</p>
            <div className="tabs">  
              <button type="button" className="tab">
                수거지 선택
              </button>
              <button type="button" className="tab active">
                배송지 정보 통일
              </button>
              <button type="button" className="tab">
                수거지 변경
              </button>
            </div>
            <ul className="contents">
              <li className="content">
                <div className="text-wrap">
                  <strong className="title">
                    수거지 주소
                  </strong>
                  <p className="text">
                    <span>김현주</span>
                    <span>(16944)경기도 용인시 수지구 상현동</span>
                    <span>1116-1 광교푸르지오시티 1단지 921호</span>
                    <span className="f-black60">010-1234-5678</span>
                  </p>
                </div>
                {/* <div className="text-wrap">
                  <strong className="title">
                    환불 예정 금액
                  </strong>
                  <p className="text">
                    <span>환불예정금액은 판매자가 접수처리 완료 후 주문상세조회에서 확인할 수 있습니다.</span>
                  </p>
                </div> */}
                <div className="text-wrap">
                  <strong className="title">
                    배송비 안내
                  </strong>
                  <p className="text">
                    <span>취소/교환/반품하시는 상품 및 신청사유에 따라 배송비 환불  또는 추가 배송비가 발생할 수 있습니다.</span>
                    <span>배송비는 쇼핑몰 정책에 따라 책정됩니다.</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* 교환 요청 버튼 [START]*/}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button"
              // onClick={() => navigate('/mymenu/exchange-detail')}
              onClick={() => confirmOrderExchange()}
              disabled={!selectedReason}
              className="btn purple big"
            >
              교환 요청
            </button>
          </div>
        </div>
      </div>
      {/* 교환 요청 버튼 [END]*/}

    </>
  );
};

export default MyMenuRequestExchangeSection;

