import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import TopBar from '../../layouts/top_bar/TitleBar';
import { getCheckout, completeCheckout } from '../../services/GQ_apis/checkout';
import { markOrderAsPaid } from '../../services/GQ_apis/order';``

export function TossSuccessPage() {

  const navigate = useNavigate();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [searchParams] = useSearchParams();
  const paymentKey = searchParams.get("paymentKey");
  const orderId = searchParams.get("orderId");
  const amount = searchParams.get("amount");

  async function confirmPayment() {
    // TODO: API를 호출해서 서버에게 paymentKey, orderId, amount를 넘겨주세요.
    // 서버에선 해당 데이터를 가지고 승인 API를 호출하면 결제가 완료됩니다.
    // https://docs.tosspayments.com/reference#결제-승인
  
    const secretKey = 'dGVzdF9nc2tfZG9jc19PYVB6OEw1S2RtUVhrelJ6M3k0N0JNdzY6';
    const response = await fetch("https://api.tosspayments.com/v1/payments/confirm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + secretKey
      },
      body: JSON.stringify({
        paymentKey,
        orderId,
        amount
      })
    });

    // response.json()을 사용하여 응답 데이터를 파싱합니다.
    const responseData = await response.json(); // JSON 형태의 응답 본문을 파싱
  
    if (response.ok) {
      setIsConfirmed(true); // 상태 업데이트
      console.log("Response OK:", responseData); // 성공 응답 데이터 출력

    } else {
      console.error("Response Error:", responseData); // 에러 응답 데이터 출력
    }
  
    saleorCompleteCheckout();
  }

  const [payData, setPayData] = useState({});

  const saleorCompleteCheckout = () => {
    completeCheckout(localStorage.getItem('paymentToken')).then((res)=>{
      console.log(res);
      setPayData(res.data.checkoutComplete.order);
      markOrderAsPaid(res.data.checkoutComplete.order.id).then((res2)=>{
        console.log('order : ', res2);
      });
    });
  }  

  useEffect(()=>{
    confirmPayment()
  },[])

  if (! isConfirmed || ! payData || ! payData || ! payData.shippingAddress) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }
  return (
    <article id="payPage">
      <TopBar  
        title={'결제 성공'}
        url='/'
      />
      <section className="section-pay-result">
        <div className="white-wrap">
          <div className="title-wrap">
            <strong className="title">
              주문이 완료되었어요
            </strong>
            <p className="text">
              주문번호
            </p>
            <span className="f12 break-word">{payData.id}</span>
          </div>
          <div className="gray-box">
            <div className="h4-title-wrap">
              <h4 className="title">
                배송지
              </h4>
              <Link 
                to ="/delivery/list" 
                className="a-more-btn" 
                title=""
              >
                변경하기
              </Link>
            </div>
            <p className="text">
              {payData.shippingAddress.firstName} {window.WSformatPhoneNumber(payData.shippingAddress.phone)}
            </p>
            <span className="f-gray">
              {payData.shippingAddress.streetAddress1}
              {payData.shippingAddress.streetAddress2}
            </span>
          </div>
        </div>
        <div className="white-wrap pb60">
          <div className="h4-title-wrap border">
            <h4 className="title">
              결제 정보
            </h4>
          </div>
          <ul className="order-list">
            <li><span>총 주문 금액</span><b>{window.WSformatPrice(payData.total.gross.amount - payData.shippingPrice.gross.amount)}</b></li>
            <li><span>총 배송비</span><b>{window.WSformatPrice(payData.shippingPrice.gross.amount)}</b></li>
            {/* <li><span>총 할인금액</span><b>3,000원</b></li> */}
            {/* <li><span>총 쿠폰할인금액</span><b>3000원</b></li> */}
            {/* <li><span>총 사용적립금</span><b>3000원</b></li> */}
            <li><span>총 결제 금액</span><b className="f-purple f16">{window.WSformatPrice(payData.total.gross.amount)}</b></li>
          </ul>
          {/* <div className="gray-box flex between pt10 pb10">
            <b className="f14">가상계좌</b>
            <span className="f14">42,000원</span>
          </div> */}
        </div>
      </section>
      {/* 계속 쇼핑하기, 구매내역 확인 [START]*/}
      <div className="bottom-bar">
        <div className="inner">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn big gray"
              onClick={()=>navigate('/')}
            >
              계속 쇼핑하기
            </button>
            <button 
              type="button" 
              className="btn big purple"
              onClick={()=>navigate(`/order/order-detail/${payData.id}`)}
            >
              구매내역 확인  
            </button>
          </div>
        </div>
      </div>
      {/* 계속 쇼핑하기, 구매내역 확인 [END]*/}
    </article>
  );
}

export default TossSuccessPage;
