import React from 'react';
import GridSection from '../../sections/grid/GridSection';

const SearchResultSection = () => {

  return (
    <>
      <section className="section-search">
        <div className="inner">
          <h4 className="title mb10">
            검색결과 총 2건
          </h4>
          <GridSection 
            type="big"
            search=""
          />
        </div>
      </section>
    </>
  );
};

export default SearchResultSection;

