import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getProductByVendor, toggleProductWishList, getWishlistItems } from '../../services/GQ_apis/product';
import useAuthStore from '../../store/common/useAuthStore';
import ThumbnailImage from '../../components/common/ThumbnailImage';

const GridSection = ({
  type='middle',
  option=false, 
  category='',
  collection='',
  meta='',
  infinity=false,
  vid=''
}) => {

  // 카드 타입 변경 매소드 [START]
  const [gridType, setGridType] = useState(type);

  const { globalObserver, observerUpdate } = useAuthStore();

  const toggleGridType = () => {
    setGridType(prevType => (prevType === 'middle' ? 'big' : 'middle'));
  };
  // 카드 타입 변경 매소드 [END]

  // SORT 변경 매소드 [START]
  const [sortOption, setSortOption] = useState('1');  // Sort option state 추가
  // SORT 변경 매소드 [END]

  // 상품 데이터 호출 메소드 [START]
  const [gridLi, setGridLi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [afterCursor, setAfterCursor] = useState(null);
  const observer = useRef();  // Intersection Observer를 위한 ref 생성
  const lastGridElementRef = useRef(null); // 마지막 그리드 요소를 참조하기 위한 ref
  const [wishList, setWishList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchProducts = useCallback(() => {

    if ((loading || !hasMore)) return;
    
    if(localStorage.getItem('user') !== null) {
      getWishlistItems().then((res)=>{
        setWishList(res.data.userWishlistItems);
      }).catch((e)=>{
        console.log(e);
      });
    }

    let sortField = null;
    let sortDirection = null;
    // alert(sortOption)

    if (sortOption === '1') {  // 최신순
      sortField = 'CREATED_AT';
      sortDirection = 'DESC';
    } else if (sortOption === '3') {  // 고가순
      sortField = 'PRICE';
      sortDirection = 'DESC';
    } else if (sortOption === '4') {  // 저가순
      sortField = 'PRICE';
      sortDirection = 'ASC';
    }

    setLoading(true);
    getProductByVendor(
      afterCursor, // Next 페이지 ID
      category, // 카테고리
      collection, // 콜렉션
      meta,
      vid
    ).then((res) => { // getProductByVendor 호출 시 현재 커서 전달
      const data = res.data.products.edges;
      setTotalCount(res.data.products.totalCount)

      if (gridLi.length === 0) setGridLi(data);
      else setGridLi(prev => [...prev, ...data]); // 기존 목록에 새로운 데이터 추가
      if(data[data.length-1]) setAfterCursor(data[data.length - 1].cursor); // 다음 페이지 로드를 위한 커서 업데이트
      
      if (infinity) setHasMore(res.data.products.pageInfo.hasNextPage); // 더 불러올 데이터가 있는지 업데이트
      else setHasMore(false);

      setLoading(false);
    });
  }, [afterCursor, hasMore, loading, category, collection, meta, vid]);

  // useEffect(() => {
    // setAfterCursor(null);   
    // setGridLi([]);          
    // setHasMore(true);       
    // setLoading(false);      
    // fetchProducts();  
  // }, [sortOption]); 
  

  useEffect(() => {
    if(localStorage.getItem('user') !== null) {
      getWishlistItems().then((res)=>{
        setWishList(res.data.userWishlistItems);
      }).catch((e)=>{
        console.log(e);
      });
    }
    
  },[globalObserver])

  useEffect(() => {
    const currentObserver = observer.current;
    if (currentObserver) currentObserver.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          fetchProducts();
        }
      });

      if (lastGridElementRef.current) {
        observer.current.observe(lastGridElementRef.current);
      }

    return () => {
      if (currentObserver) currentObserver.disconnect();
    };

  }, [fetchProducts, loading, hasMore, infinity]);

  // 상품 데이터 호출 메소드 [END]

  const updateWishList = (productId) => {
    toggleProductWishList(productId).then(()=>{ observerUpdate(); })
  }

  const isWishList = (productId) => {
    if (!wishList || wishList.length === 0) return false;
  
    // wishList 내의 각 아이템의 product.id와 주어진 productId를 비교
    return wishList.some(item => item.product.id === productId);
  }

  const handleSortChange = (event) => {
    console.log('event:: ', event.target.value)
    setSortOption(event.target.value);
  };

  // 썸네일 업데이트를 위한 상태 추가
  const [selectedThumbnails, setSelectedThumbnails] = useState({});

  const colorClick = (color, variants, productId) => {
    console.log(`Selected color: ${color}, for product: ${productId}`);
  
    const selectedVariant = variants.find(variant =>
      variant.attributes.some(attr => 
        attr.values.some(value => value.name.toLowerCase().trim() === color.toLowerCase().trim())
      )
    );
  
    if (selectedVariant && selectedVariant.media.length > 0) {
      const newThumbnailUrl = selectedVariant.media[0].url;
      console.log(`New thumbnail URL for ${color}:`, newThumbnailUrl);
  
      setSelectedThumbnails(prev => ({
        ...prev,
        [productId]: newThumbnailUrl  // productId를 키로 사용
      }));
    } else {
      setSelectedThumbnails(prev => ({
        ...prev,
      }));
      console.log(`No matching variant or media found for color: ${color}`);
    }
  };
  
  useEffect(()=>{
    console.log('selectedThumbnails', selectedThumbnails);
  },[selectedThumbnails])

  useEffect(()=>{

    setGridLi([]);
    setHasMore(true);
    setAfterCursor(null);
    if(infinity) fetchProducts();
    
  },[category, meta, vid])

  // vid로 필터링된 gridLi 생성
  const filteredGridLi = vid ? gridLi.filter(item => item.node.vendor?.id === vid) : gridLi;

  if (filteredGridLi.length === 0 && loading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }
  
  return (
    <>
      {/* 옵션 [START] */}
      {option && (
        <div className="grid-option-wrap">
          <span className='mt8'>상품 <b className="f-purple">{infinity ? totalCount : filteredGridLi.length}개</b></span>
          <div className="options">
            <div className="input-box">
              <div className="input">
                <select className='option-select' value={sortOption} onChange={handleSortChange}>
                  <option value="0">인기순</option>
                  <option value="1">최신순</option>
                  <option value="2">판매순</option>
                  <option value="3">고가순</option>
                  <option value="4">저가순</option>
                </select>
            </div>
          </div>
            <div className="view-options mt8">
              <button className={`option ${gridType}`} onClick={toggleGridType}>
              </button>
            </div>
          </div>
        </div>
      )}
      {/* 옵션 [END] */}

      {/* 그리드(리스트) [START] */}
      <section className="section-grid">
        <div className="grid-wrap">
          <ul className={`grid gap16 ${gridType === 'big' ? 'grid2' : gridType === 'middle' ? 'grid1' : 'grid3'}`}>
            {filteredGridLi.map((item, index) => (
              <li 
                className={`card ${gridType}`} 
                key={index}
              >
                <div className="card-inner">
                  <div className="img-wrap">
                    <Link 
                      title="클릭시 해당 상품 페이지로 이동"
                      to={"/product/"+item.node.id}
                    > 
                      {
                        selectedThumbnails[item.node.id] ? 
                        <ThumbnailImage thumbnailUrl={selectedThumbnails[item.node.id]} thumbnailAlt={item.node.thumbnail.alt} /> :
                        <ThumbnailImage thumbnailUrl={item.node.thumbnail.url} thumbnailAlt={item.node.thumbnail.alt} />
                      }
                    </Link>
                    <button 
                      type="button" 
                      className={`icon-heart white ${isWishList(item.node.id) ? 'active' : ''}`}
                      onClick={()=>{updateWishList(item.node.id)}}
                    >
                    </button>
                  </div>
                  <div className="info-wrap">
                    {item.node.vendor !== null && 
                      <Link 
                        title="클릭시 해당 상품 페이지로 이동"
                        to={"/brand/detail/"+item.node.vendor.id}
                        className="brand"
                      >
                        {item.node.vendor.storeNameKo}
                      </Link>

                    }
                    <Link 
                      title="클릭시 해당 상품 페이지로 이동"
                      to={"/product/"+item.node.id}
                    >
                      <div className="title">
                        {item.node.name}
                      </div>
                      <div className="price-wrap">
                        {
                          item.node.pricing.priceRange.start.gross.amount !== item.node.pricing.priceRange.stop.gross.amount &&
                          (
                            <div className="before-price">
                              <span className="f-purple">쿠폰할인가</span>
                              <span className="price">
                                {/* {item.node.pricing.priceRange.stop.gross.currency}  */}
                                {item.node.pricing.priceRange.stop.gross.amount}
                              </span>
                            </div>
                          )
                        }
                        <div className="now-price">
                          {/* <span className="f-purple">{item.discount.discounted.raw}%</span> */}
                          <span className="price">
                            {/* {item.node.pricing.priceRange.stop.gross.currency}  */}
                            {window.WSformatPrice(item.node.pricing.priceRange.start.gross.amount)}
                          </span>
                        </div>
                      </div>
                      {/* <div className="badge-wrap">
                        <span className="badge red">
                          한정판매
                        </span>
                      </div> */}
                    </Link>
                      <div className="colors-wrap">
                        <ul className="colors">
                          {item.node.color.values.length > 0 &&
                            item.node.color.values.map((colorItem, colorIndex) => (
                              <li className="color" key={colorItem + colorIndex}>
                                <button
                                  type="button"
                                  className={colorItem.name}
                                  onClick={() => colorClick(colorItem.name, item.node.variants, item.node.id)}  // productId 추가
                                >
                                </button>
                              </li>
                            ))}
                        </ul>
                      </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
      {/* 그리드(리스트) [END] */}
      
      {/* 스크롤 타켓 [START] */}
      <div className="scroll-target" ref={lastGridElementRef}>
        {
          hasMore && loading &&
          <div className="loading-wrap">
            <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
          </div>
        }
      </div>
      {/* 스크롤 타켓 [END] */}
    </>
  );
};

export default GridSection;
