import React from "react";
import TopBar from '../../layouts/top_bar/TitleZaelookBar';
import sampleImage from "../../assets/images/sample/sample_product_detail.png";
import { useNavigate } from 'react-router-dom';
import FAB from '../../components/Buttons/FAB';

function ZaeLookPage() {
  const navigate = useNavigate();

  return (
    <>
      <TopBar title="재룩"/>
      <article id="zaeLookPage">
        <div className="inner">
          {/* 프로필 [START] */}
          <div className="profile-wrap">
            <div className="img-wrap">
              <img src={sampleImage} alt=""></img>
            </div>
            <div className="title">
              mvptest1
            </div>
          </div>
          {/* 프로필 [END] */}
          {/* 회색박스 [START] */}
          <div className="gray-box-wrap">
            <div className="gray-box">
                <b className="f-purple">2</b> 게시물
            </div>
            <div className="gray-box">
                <b className="f-purple">2</b> 친구
            </div>
          </div>
          {/* 회색박스 [END] */}
          {/* 카드 [START] */}
          <div className="zaezae-option-wrap">
            <div className="input check bg">
              <label htmlFor="checkAll">
                <input id="checkAll" type="checkbox"></input>
                <b>총 2개</b>
              </label>
              <button type="button" className="f14 ml10 f-black50">
                선택삭제
              </button>
            </div>
            <div className="btn-wrap">
              <button type="button" className="icon-option">
              </button>
            </div>
          </div>
          <div className="grid-wrap">
            <ul className="grid grid2">
              <li className="card big">
                <div className="img-wrap">
                  <div className="input innercheck bg">
                    <label htmlFor="checkAll">
                      <input id="checkAll" type="checkbox"></input>
                    </label>
                  </div>
                  <img src={sampleImage} alt=""
                    onClick={() => navigate('/zaelook/:Page2depth/:Page3depth')}
                  ></img>
                </div>
                <div className="card very-small mt10">
                  <div className="img-wrap">
                    <img src={sampleImage} alt=""></img>
                  </div>
                  <div className="info-wrap">
                    <b className="f12">베리클로젯</b>
                  </div>
                </div>
                <button type="button" className="mt10 lock-btn">
                  나만보기
                </button>
              </li>
              <li className="card big">
                <div className="img-wrap">
                  <div className="input innercheck bg">
                    <label htmlFor="checkAll">
                      <input id="checkAll" type="checkbox"></input>
                    </label>
                  </div>
                  <img src={sampleImage} alt=""
                    onClick={() => navigate('/zaelook/:Page2depth/:Page3depth')}
                  ></img>
                </div>
                <div className="card very-small mt10">
                  <div className="img-wrap">
                    <img src={sampleImage} alt=""></img>
                  </div>
                  <div className="info-wrap">
                    <b className="f12">베리클로젯</b>
                  </div>
                </div>
                <button type="button" className="mt10 unlock lock-btn">
                  전체공개
                </button>
              </li>
            </ul>
          </div>
          {/* 카드 [END] */}
        </div>
      </article>
      <FAB />
      {/* 필요시 BottonNav 추가  */}
    </>
  )
}

export default ZaeLookPage;
