import api from '../api';

export const getBannersByCategory = async (categoryId, channel=window.ZZ_CHANNEL) => {
  try {
      const response = await api.post('/api-tg/vendor/banners/category', {  
        channel,
        category_id: categoryId });
      return response.data;
  } catch (error) {
      console.error('Error fetching banners by category:', error);
      throw error;
  }
};

export const getBannersById = async (bannerId, channel=window.ZZ_CHANNEL) => {
  try {
      const response = await api.post('/api-tg/vendor/banners/category-b', { 
        channel,
        banner_id: bannerId 
      });
      return response.data;
  } catch (error) {
      console.error('Error fetching banners by category:', error);
      throw error;
  }
};
