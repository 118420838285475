import { useEffect, useRef, useState } from "react";
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";
import useAuthStore from '../../store/common/useAuthStore';

const generateRandomString = () => window.btoa(Math.random()).slice(0, 20);

const TossCheckoutPage = ({payData}) => {
  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);
  const agreementWidgetRef = useRef(null);
  const { user } = useAuthStore();

  useEffect(() => {

    const secretKey = process.env.REACT_APP_TOSS_CLIENT_KEY;
    console.log("Checking loaded key:", process.env.REACT_APP_TOSS_CLIENT_KEY);

    (async () => {
      const paymentWidget = await loadPaymentWidget(secretKey,  ANONYMOUS); // 비회원 customerKey

      if (paymentWidgetRef.current == null) {
        paymentWidgetRef.current = paymentWidget;
      }

      /**
       * 결제창을 렌더링합니다.
       * @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods%EC%84%A0%ED%83%9D%EC%9E%90-%EA%B2%B0%EC%A0%9C-%EA%B8%88%EC%95%A1
       */
      const paymentMethodsWidget = paymentWidgetRef.current.renderPaymentMethods(
        "#payment-method",
        { value: payData.totalPrice.gross.amount },
        { variantKey: "DEFAULT" }
      );

      /**
       * 약관을 렌더링합니다. 
       * @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement%EC%84%A0%ED%83%9D%EC%9E%90-%EC%98%B5%EC%85%98
       */
      agreementWidgetRef.current = paymentWidgetRef.current.renderAgreement('#agreement', { variantKey: 'DEFAULT' });

      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    
      console.log('----토스 결제 정보----');
      console.log(payData);
      console.log('----토스 결제 정보----');
      
    })();
  }, [payData]);

  return (
    <div className="wrapper w-100">
      <div className="max-w-540 w-100">
        <div id="payment-method" className="w-100" />
        <div id="agreement" className="w-100" />
        {/* 결제하기 영역 [START]*/}
          <div className="bottom-bar">
            <button
              type="button" 
              className="btn pay"
              onClick={async () => {
                const paymentWidget = paymentWidgetRef.current;
                try {
                  /**
                   * 결제 요청
                   * @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment%EA%B2%B0%EC%A0%9C-%EC%A0%95%EB%B3%B4
                   */
                  await paymentWidget?.requestPayment({
                    orderId: generateRandomString(),
                    orderName: 'ZaeZae',
                    customerName: user.email,
                    customerEmail: user.email,
                    successUrl: window.location.origin + "/pay/toss/success" + window.location.search,
                    failUrl: window.location.origin + "/pay/toss/fail" + window.location.search
                  });
                } catch (error) {
                  // TODO: 에러 처리
                  console.log(error);
                }
              }}
            >
              <span>총 {payData.quantity}개</span>
              <strong>
                {window.WSformatPrice(payData.totalPrice.gross.amount)} 결제하기
              </strong>
            </button>
          </div>
        {/* 결제하기 영역 [END]*/}
      </div>
    </div>
  );
}

export default TossCheckoutPage;
