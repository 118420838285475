import React from 'react';

const SearchProcessSection = () => {

  return (
    <>
      <section className="section-search">
        <div className="inner">
          <h4 className="title">
            최근 검색어
          </h4>
          <div className="category-tabs-wrap recent-search">
            <ul className="tabs">
              <li>
                <button type="button" className="tab">
                  엄마와딸
                </button>
              </li>
              <li>
                <button type="button" className="tab">
                  가족티셔츠
                </button>
              </li>
              <li>
                <button type="button" className="tab">
                  잠옷
                </button>
              </li>
              <li>
                <button type="button" className="tab">
                  슬리퍼
                </button>
              </li>
              <li>
                <button type="button" className="tab">
                  휴대폰케이스
                </button>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchProcessSection;

