import React, { useState, useEffect } from "react";
import OrderStatusCard from '../../components/Card/OrderStatusCard'

const OrderReturnListSection = ({orderList}) => {

  return (
    <>
      <section className="section-mymenu-orderlist">
        <div className="order-list-wrap">
          <ul className="order-list">
            {
              orderList.map((order,index)=>(
                (
                  order.node.status === 'REQUEST_RETURN' || // 반품 요청됨
                  order.node.status === 'RETURNED' // 반품 처리됨
                ) 
                && <OrderStatusCard order={order} index={index}/>
              ))
            }
          </ul>
        </div>
      </section>
    </>
  );
};

export default OrderReturnListSection;

