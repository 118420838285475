import React from 'react';

const PopupRegisterDetail18 = ({closePopup}) => {
  return (
    <>
    <div className="popup-body">
      <div className="h3-title-wrap">
        <h3 className="title">
          입점 정책 동의
        </h3>
      </div>
      <div className="text-wrap mt20 mb40">
        <div className="h4-title-wrap mb6">
          <h4 className="title f18">Zaezae 입점 안내</h4>
        </div>
        <ul className="text-type-dot">
          <li>
            심사 결과는 신청하시는 각 판매 채널별로 영업일 기준 최대 3~5일 정도 소요됩니다.<br/>
            (기재해주신 담당자 이메일, 휴대폰 문자 또는 알림톡으로 결과 안내)
          </li>
          <li>재입점 신청 시에도 동일 기간이 소요됩니다.</li>
          <li>사업자 등록 번호와 통신판매업 번호가 확인되어야 합니다.<br/>(사이트 하단 정보 기재 및 서류 제출 필수)</li>
        </ul>
      </div>
      <div className="text-wrap mb40">
        <div className="h4-title-wrap mb6">
          <h4 className="title f-purple f18">입점 신청 전, 아래 서류를 준비해주세요!</h4>
        </div>
        <div className="gray-box">
          <ul className="text-type-dot">
            <li>
              사업자등록증 사본 1부 (필수)
            </li>
            <li>통신판매업신고증 사본 1부 (필수)</li>
            <li>본사로부터 상품을 공급받아 판매하는 경우 : 본사와의 계약 내용이 확인되는 '위탁 계약서' 사본 1부 (필수)</li>
          </ul>
        </div>
      </div>
      <div className="text-wrap mb40">
        <div className="h4-title-wrap mb6">
          <h4 className="title f-purple f18">입점 신청 전, 아래의 내용을 반드시 확인해주세요!</h4>
        </div>
        <div className="gray-box">
          <div className="h5-title-wrap mb6">
            <h5 className="title f18">판매 채널 기본 정책</h5>
          </div>
          <div className="table-wrap mt10">
            <table>
              <thead>
                <tr>
                  <th scope="col">
                    Zaezae
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul className="text-type-dot">
                      <li>상품 등록을 통한 판매 가능</li>
                      <li>판매중인 상품군을 확인 및 검수할 수 있는 웹사이트 보유 필수</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="h5-title-wrap mb6 mt10">
            <h5 className="title f18">판매 불가 상품</h5>
          </div>
          <p>입점을 신청하는 판매채널에서 취급하지 않는 카테고리의 상품을 다수 판매하는 스토어인 경우, 각 판매채널에 대해 입점 불가합니다.</p>
          <div className="h6-title-wrap mb6 mt10">
            <h6 className="title">
              전체 판매 채널
            </h6>
          </div>
          <ul className="text-type-dot">
            <li>
              성인용품, 성인 란제리 등 선정성 짙은 상품 및 현행법에 위반되는 불법적인 상품
            </li>
            <li>
              병원 처방이 필요한 '의약품'에 해당되는 화장품
            </li>
            <li>
              일정 기간 프리오더 형식으로 판매되는 공구 상품
            </li>
            <li>
              가품 및 타 브랜드 이미테이션 상품
            </li>
            <li>
              타인의 지적 재산권 등 제 3자의 권리를 침해하는 상품을 판매하는 스토어
            </li>
            <li>
              안전기준 준수대상 생활용품에 해당하는 상품군에 속하나, 인증되지 않은 상품
            </li>
            <li>
              이 외에도 입점 심사 시, 해당 판매 채널의 컨셉과 상이하다고 판단되는 스토어의 입점은 불가
            </li>
           
          </ul>
          <div className="h6-title-wrap mb6 mt10">
            <h6 className="title">
              고객 응대 정책
            </h6>
          </div>
          <ul className="text-type-dot">
            <li>
              주문, 배송 및 CS 일체를 파트너사측에서 직접 원활하게 처리 가능한 경우에만 입점 가능합니다.
            </li>
            <li>
              구매자 문의 응대가 원활할 수 있도록 반드시 연락 가능한 연락처 정보를 기재하셔야 합니다.
            </li>
          </ul>
          <div className="h6-title-wrap mb6 mt10">
            <h6 className="title">
              배송 및 클레임(취소/교환/반품) 정책
            </h6>
          </div>
          <ul className="text-type-dot">
            <li>
              Zaezae는 전자상거래법에 따라 아래와 같은 기본 취소/교환/반품 정책을 원칙으로 하며, 이에 반하는 정책으로 운영하는 파트너사의 입점은 불가합니다.
            </li>
            <li>
              단순 변심에 의한 교환, 환불, 반품 처리가 가능해야 합니다.
            </li>
            <li>
              고객에게 별도의 주문 취소 수수료를 부과하는 경우 불공정 약관으로 문제가 될 수 있어 입점이 불가합니다.
            </li>
            <li>
              대형 가전 및 가구 등 ‘추가 배송비’가 부과되는 라이프 상품 판매 스토어의 경우, <br />
              현재 시스템 상 추가 배송비 부과 설정은 불가하므로 판매자 &lt;&gt; 구매자간 별도 협의를 통해 배송비 수취해주셔야하는 점 반드시 참고해주시기 바랍니다.
            </li>
          </ul>
          <div className="h6-title-wrap mb6 mt10">
            <h6 className="title">
              고객 응대 정책
            </h6>
          </div>
          <ul className="text-type-dot">
            <li>
              구매 시 개인통관고유부호를 입력해야하는 위 분류의 스토어는 운영 정책에 부합하지 않는 관계로 입점 불가합니다.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="popup-footer">
      <p className="f-red mb20">입점 조건에 맞지 않는 쇼핑몰은 별도의 유선 안내 없이 입점이 거절되며, 이후 재입점 신청을 해주셔야 합니다.</p>
      <div className="btn-wrap">
        <button type="button" className="btn purple" onClick={closePopup}>완료</button>
      </div>
    </div>
  </>
  );
};

export default PopupRegisterDetail18;
