import React, { useState, useEffect } from "react";
import sampleImage from "../../assets/images/sample/sample_product_detail.png";
import { fetchPages } from "../../services/GQ_apis/page";
import useProductStore from '../../store/common/useProductStore';

// 별점 컴포넌트 정의
const StarRating = ({ rating, maxRating }) => {
  const stars = Array.from({ length: maxRating }, (_, index) => index + 1);

  return (
    <div className="stars gap4">
      {stars.map((star) => (
        <a
          key={star}
          href="#none"
          title={`${star}점`}
          className={star <= rating ? 'icon-star-fill' : 'icon-star-empty'}
        ></a>
      ))}
    </div>
  );
};

const Product03Section = () => {
  const [pageData, setPageData] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const { nowProductData } = useProductStore((state) => ({ nowProductData: state.nowProductData }));

  useEffect(() => {
    console.log('nowProductData', nowProductData);
    console.log('nowProductData name', nowProductData.id);
  
    const filter = {
      pageTypes: "UGFnZVR5cGU6NQ==",
      metadata: [{key: "product_id", value: nowProductData.id}] // 선택한 상품만 선택
    };
  
    fetchPages(filter).then((res) => {
      console.log('Fetched pages:', res.data.pages.edges);
      setPageData(res.data.pages.edges);
  
      // 별점 평균 계산
      const totalRating = res.data.pages.edges.reduce((sum, page) => {
        const rating = parseFloat(page.node.metafields.rating); // 문자열을 숫자로 변환
        return sum + (isNaN(rating) ? 0 : rating); // rating이 숫자가 아니면 0을 더함
      }, 0);
      
      const average = res.data.pages.edges.length > 0 ? totalRating / res.data.pages.edges.length : 0;
      setAverageRating(average);
      console.log(averageRating);
  
    }).catch(error => {
      console.error('Error fetching pages:', error);
    });
  }, []);  

  return (
    <>
      <section className="section-product-info">
        <div className="white-wrap">
          <div className="h4-class-wrap flex between">
            <h4 className="title">
              리뷰 <b className="f-purple">{pageData.length}</b>
            </h4>
          </div>
          <StarRating rating={averageRating} maxRating={5} /> {/* 별점 평균 구현 */}
          <div className="review-option-wrap flex between mt20">
            <div className="input check bg">
              <label htmlFor="photoReview">
                <input type="checkbox" id="photoReview"></input>
                포토후기
              </label>
            </div>
            <ul className="options">
              <li className="option active">
                <button type="button">베스트순</button>
              </li>
              <li className="option">
                <button type="button">최신순</button>
              </li>
            </ul>
          </div>
        </div>

        {pageData.map((page, index) => (
          <div className="card review2 mt20" key={index}>
            <div className="profile-wrap">
              <div className="img-wrap">
                <img src={page.node.metafields.user_thumbnail} alt="Profile" />
              </div>
              <strong className="f12">
                {page.node.metafields.user_id.length > 3 
                  ? `${page.node.metafields.user_id.substring(0, 3)}***` // 닉네임 뒤에 ***표시
                  : page.node.metafields.user_id}
              </strong>
              <span className="date">{new Date(page.node.publicationDate).toLocaleDateString().replace(/\.$/, '')}</span>
            </div>
            {
              (page.node && page.node.attributes.length > 0) && <StarRating rating={page.node.attributes[4].values[0].name} maxRating={5} />
            }

            <div className="review">
              <div className="flex between">
                <p className="f12">
                  [{nowProductData.vendor.storeNameKo}]{nowProductData.name}
                </p>
                <StarRating rating={page.node.metafields.rating} maxRating={5} />
              </div>
              <p className="f12">
                XS / 네이비 {/* 데이터 연결 필요 */}
              </p>
              <p className="f12 mt20">
                {page.node.title}
              </p>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default Product03Section;
