import React, { useEffect } from "react";
import RecentlyGridSection from '../../sections/grid/RecentlyListGridSection';
import { getRecentlyItems } from '../../services/GQ_apis/product'

const MyMenuRecentProductSection = () => {

  return (
    <>
      <section className="section-mymenu-recent-product">
      <div className="inner">
        {/* MIDDLE 그리드(리스트) [START] */}
        <RecentlyGridSection type="middle" option={true}/>
        {/* MIDDLE 그리드(리스트) [END] */}
      </div>
      </section>
    </>
  );
};

export default MyMenuRecentProductSection;

