import React,{useEffect} from "react";
import { useNavigate } from "react-router-dom";

import useAuthStore from '../../../store/common/useAuthStore';
import GridSection from '../../../sections/grid/GridSection';
import H3Title from '../../../layouts/title/H3Title';
import SwipeGridSection from '../../../sections/grid/SwipeGridSection';

import BannerASection from '../../../sections/banner/BannerASection';
import BannerBSection from '../../../sections/banner/BannerBSection';
import BannerCSection from '../../../sections/banner/BannerCSection';
import BannerDSection from '../../../sections/banner/BannerDSection';
import BannerESection from '../../../sections/banner/BannerESection';

function CouponBenfitPage() {

  const navigate = useNavigate();

  const { userToken } = useAuthStore();

  return (
    <article id="CouponBenfitPage" className="pt74">
      <div className="white-wrap">
        <div className="banner-wrap vw100">
          {/*[E] 배너[START] */}
          <BannerESection id='coupon_b1'/>
          {/*[E] 배너[END] */}
        </div>
        <div className="banner-wrap mt20">
          {/*[B] 배너[START] */}
          <BannerBSection id='coupon_b2'/>
          {/*[B] 배너[END] */}
        </div>
        <div className="banner-wrap mt20">
          {/*[D] 배너[START] */}
          <BannerDSection id='coupon_b3'/>
          {/*[D] 배너[END] */}
        </div>
        <div className="banner-wrap mt20">
          {/*[D] 배너[START] */}
          <BannerDSection id='coupon_b4'/>
          {/*[D] 배너[END] */}
        </div>
        <div className="banner-wrap mt20">
          {/*[B] 배너[START] */}
          <BannerBSection id='coupon_b5'/>
          {/*[B] 배너[END] */}
        </div>
        <div className="banner-wrap mt20">
          {/*[B] 배너[START] */}
          <BannerBSection id='coupon_b6'/>
          {/*[B] 배너[END] */}
        </div>
        <div className="banner-wrap mt20">
          {/*[D] 배너[START] */}
          <BannerDSection id='coupon_b7'/>
          {/*[D] 배너[END] */}
        </div>
        <div className="banner-wrap mt20">
          {/*[D] 배너[START] */}
          <BannerDSection id='coupon_b8'/>
          {/*[D] 배너[END] */}
        </div>

      </div>

    </article>
  )
}

export default CouponBenfitPage;
