import {create} from 'zustand';
import {checkEmail} from '../../services/GQ_apis/auth'


// 인풋 state [START]
const input = {

  input : {
    uuid: '',
    profileImg : '',
    email: '',
    emailAlert: '',
    emailAlertType: '',
    emailCode: '',
    password: '',
    password_re: '',
    passwordAlert: '',
    passwordAlertType: '',
    name : '',
    nickName : '',
    phoneNumber : '',
    phoneCode : '',
    address1 : '',
    address2 : '',
    birthday : '',
    businessNumber : '',

    kidNickName : '',
    kidGender : '0',
    kidBirthDay : '',
    kidHeight : '',
    kidWeight : '',
    kidFootSize : '',
    kidHeadSize : '',
    kidProfileImg : '',
    kidBrandList : '',
    kidColorList : '',
    kidItemList : '',

    isPhoneVerification:'',
    isPhoneSend:'',
  }
}
// 인풋 state [END]

const useInputStore = create((set, get) => ({

  ...input,

  actions : {

    setUUID: (uuid) => set(() => ({ input: { ...get().input, uuid } })),
    setEmail: (email) => set(() => ({ input: { ...get().input, email } })),
    setEmailCode: (emailCode) => set(() => ({ input: { ...get().input, emailCode } })),
    setBusinessNumber: (businessNumber) => set(() => ({ input: { ...get().input, businessNumber } })),
    setPassword: (password) => set(() => ({ input: { ...get().input, password } })),
    setPasswordRe: (password_re) => set(() => ({ input: { ...get().input, password_re } })),
    setName: (name) => set(() => ({ input: { ...get().input, name } })),
    setNickName : (nickName)=> set(()=>({ input: {...get().input, nickName}})),
    setPhoneNumber : (phoneNumber)=> set(()=>({ input: {...get().input, phoneNumber}})),
    setPhoneCode : (phoneCode)=> set(()=>({ input: {...get().input, phoneCode}})),
    setProfileImg : (profileImg)=> set(()=>({ input: {...get().input, profileImg}})),
    setKidNickName : (kidNickName)=> set(()=>({ input: {...get().input, kidNickName}})),
    setKidGender : (kidGender)=> set(()=>({ input: {...get().input, kidGender}})),
    setKidBirthDay : (kidBirthDay)=> set(()=>({ input: {...get().input, kidBirthDay}})),
    setKidHeight : (kidHeight)=> set(()=>({ input: {...get().input, kidHeight}})),
    setKidWeight : (kidWeight)=> set(()=>({ input: {...get().input, kidWeight}})),
    setKidFootSize : (kidFootSize)=> set(()=>({ input: {...get().input, kidFootSize}})),
    setKidHeadSize : (kidHeadSize)=> set(()=>({ input: {...get().input, kidHeadSize}})),
    setKidProfileImg : (kidProfileImg)=> set(()=>({ input: {...get().input, kidProfileImg}})),
    setKidBrandList : (kidBrandList)=> set(()=>({ input: {...get().input, kidBrandList}})),
    setKidColorList : (kidColorList)=> set(()=>({ input: {...get().input, kidColorList}})),
    setKidItemList : (kidItemList)=> set(()=>({ input: {...get().input, kidItemList}})),

    setIsPhoneVerification : (isPhoneVerification) => set(() => ({ input: { ...get().input, isPhoneVerification } })),
    setIsPhoneSend : (isPhoneSend) => set(() => ({ input: { ...get().input, isPhoneSend } })),

    // state 초기화 메소드
    resetInput: () => set({ input : { ...input } }),


    handleFileChange: (event) => {
      const file = event.target.files[0]; // 선택된 파일 가져오기
      if (file) {
        const reader = new FileReader(); // 파일리더 객체 생성
        reader.onload = (e) => {
          // 파일리더로 읽은 결과(이미지 URL)를 상태에 저장
          set(() => ({ input: { ...get().input, profileImg: e.target.result } }));
        };
        reader.readAsDataURL(file); // 파일리더가 파일을 읽도록 함
      }
    },

    handleImageChange: (event) => {
      const file = event.target.files[0]; 
      if (file) {
        const allowedTypes = [
          'image/jpeg',
          'image/png',
          'image/gif',
          'image/bmp',
          'image/tiff',
          'image/webp',
        ];

        if (!allowedTypes.includes(file.type)) {
          alert('이미지 파일만 첨부 가능합니다.');
          return false;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          set(() => ({ input: { ...get().input, kidProfileImg: e.target.result } }));
        };
        reader.readAsDataURL(file); 
        return true;
      }
    },

    // 비밀번호, 비밀번호 재입력 검증 메소드
    validatePasswords: () => {
      
      const { password, password_re } = get().input;
      
      // 숫자, 영문, 특수문자 조합, 최소 6자 이상인지 확인하는 정규 표현식
      const isValidPassword = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*\W).{6,}$/.test(password);

      if(! password_re) {
        // 재입력하지 않았을 경우,
        set(() => ({ input: { ...get().input, passwordAlert: '', passwordAlertType: 'error' } }));
        return;
      }

      if (!isValidPassword) {
        // 비밀번호가 유효성 조건을 만족하지 않는 경우
        set(() => ({ input: { ...get().input, passwordAlert: '숫자, 영문, 특수문자 조합 최소 6자 이상 입력해주세요.', passwordAlertType: 'error' } }));
        return;
      } else if (password !== password_re) {
        // 비밀번호와 비밀번호 확인이 일치하지 않는 경우
        set(() => ({ input: { ...get().input, passwordAlert: '비밀번호가 일치하지 않습니다.', passwordAlertType: 'error' } }));
        return;
      }
      
      // 모든 조건을 만족하는 경우
      set(() => ({ input: { ...get().input, passwordAlert: '비밀번호가 일치합니다.', passwordAlertType: 'success' } }));
    },

    // 이메일 validtor 메소드
    handleCheckEmail: async () => {
      const { email } = get().input;
      if (!get().validateEmail(email)) {
        get().setEmailAlert('유효한 이메일 형식이 아닙니다.', 'error');
        return;
      }
      
      const result = await checkEmail(email);

      if (result.includes('이미 존재하는 이메일입니다.')) {
        get().setEmailAlert(result, 'error');
      } else {
        get().setEmailAlert('사용 가능한 이메일입니다.', 'success');
      }
    }

  },

  setEmailAlert: (message, type) => set(() => ({ input: { ...get().input, emailAlert: message, emailAlertType: type } })),

  // 이메일 검증 메소드
  validateEmail: (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  },

  

}));

export default useInputStore;
