import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAuthStore from '../../../store/common/useAuthStore';
import GridSection from '../../../sections/grid/GridSection';
import H3Title from '../../../layouts/title/H3Title';
import SwipeGridSection from '../../../sections/grid/SwipeGridSection';

import BannerASection from '../../../sections/banner/BannerASection';
import BannerBSection from '../../../sections/banner/BannerBSection';
import BannerCSection from '../../../sections/banner/BannerCSection';
import BannerDSection from '../../../sections/banner/BannerDSection';
import BannerESection from '../../../sections/banner/BannerESection';

import { getCollection } from '../../../services/GQ_apis/collection';

const slugs = [
  'overseas1',
  'overseas2',
  'overseas3',
  'overseas4',
  'overseas5',
  'overseas6'
];

function OverseasPage() {
  const navigate = useNavigate();
  const [collection, setCollection] = useState([]);
  const { userToken } = useAuthStore();

  useEffect(() => {
    getCollections();
  }, []);

  const getCollections = async () => {
    try {
      const res = await getCollection(slugs);
      const data = res.data.collections.edges;

      const formattedData = data.map(el => {
        let title = '타이틀을 입력해주세요.';
        if (el.node.description) {
          const parsedDescription = JSON.parse(el.node.description);

          title = parsedDescription.blocks
            .map(block => block.data.text)
            .join('<br>');
        }
        
        return {
          title: title,
          id: el.node.id
        };
      });

      setCollection(formattedData);
    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };

  return (
    <article id="OverseasPage" className="pt74">
      <div className="white-wrap">
        <div className="banner-wrap vw100">
          <BannerDSection id='oversea_b1'/>
          <div className="banner-wrap mt20 mb16">
            <BannerCSection id='oversea_b2'/>
          </div>
        </div>
        {collection[0] && (
          <SwipeGridSection collection={collection[0].id} />
        )}
      </div>

      <div className="white-wrap">
        {collection[1] && (
          <>
            <H3Title title={collection[1].title}/>
            <div className="slide-tabs purple2"> 
              <div className="tabs">
                <button>
                  패션
                </button>
                <button>
                  라이프
                </button>
              </div>
            </div>
            <div className="banner-wrap mb8">
              <BannerDSection id='oversea_b3'/>
            </div>
            <GridSection 
              type="big" 
              option={false}
              collection={collection[1].id}
            />
            <button 
              type="button" 
              className="btn big round more mt40"
              onClick={() => navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
            >
              더 보기
            </button>
          </>
        )}
      </div>

      <div className="white-wrap">
        {collection[2] && (
          <>
            <H3Title title={collection[2].title}/>
            <div className="banner-wrap mb16">
              <BannerDSection id='oversea_b4'/>
            </div>
            <SwipeGridSection collection={collection[2].id} />
            <div className="banner-wrap mt36 mb8">
              <BannerDSection id='oversea_b5'/>
            </div>
            <SwipeGridSection collection={collection[3].id} />
          </>
        )}
      </div>

      <div className="white-wrap">
        {collection[4] && (
          <>
            <H3Title title={collection[4].title}/>
            <GridSection 
              type="big" 
              option={false}
              collection={collection[4].id}
            />
            <button 
              type="button" 
              className="btn big round more mt40"
              onClick={() => navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
            >
              더 보기
            </button>
          </>
        )}
      </div>

      <div className="white-wrap">
        {collection[5] && (
          <>
            <H3Title title={collection[5].title}/>
            <GridSection 
              type="big" 
              option={false}
              collection={collection[5].id}
            />
            <button 
              type="button" 
              className="btn big round more mt40"
              onClick={() => navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
            >
              더 보기
            </button>
          </>
        )}
      </div>
    </article>
  );
}

export default OverseasPage;
