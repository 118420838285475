import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import closeIcon from '../../assets/images/common/icons/icon_close_btn.svg';

const ImageSwipeGridSection = ({ photos, onRemovePhoto }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // props로 받은 photos 배열을 직접 사용하여 SwiperSlide를 렌더링합니다.
    setImages(photos);
  }, [photos]); // photos가 변경될 때마다 실행됩니다.

  const handleRemove = (index) => {
    console.log("Remove image at position:", index);
    const newImages = images.filter((_, i) => i !== index); // 선택한 인덱스의 이미지를 제거
    setImages(newImages);
  };

  return (
    <section className="section-grid">
      <Swiper
        modules={[Autoplay, FreeMode]}
        spaceBetween={8}
        slidesPerView='auto'
        loop={false}
        freeMode={true}
        className="card-tabs"
        touchMoveStopPropagation={true}
      >
        {photos.map((photo, index) => (
          <SwiperSlide key={index} style={{ width: '110rem' }}>
            <div className='tab card small'>
              <div className="img-wrap">
                <button 
                  className="close-button"
                  onClick={() => onRemovePhoto(index)}
                  style={{
                    position: 'absolute', 
                    top: '10px', 
                    left: '10px', 
                    background: 'none', 
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  <img src={closeIcon} alt="Remove attachment" style={{ width: '24px', height: '24px' }} />
                </button>
                <img src={photo} alt={`Attached image ${index + 1}`} />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default ImageSwipeGridSection;
