import React from "react";
import SampleImage from "../../assets/images/sample/banner.png";
import { useNavigate } from "react-router-dom";

const MyMenuExchangeDetailSection = () => {

  const navigate = useNavigate();

  return (
    <>
      <section className="section-mymenu-exchange-detail">
        <div className="order-card selected">
          <div className="title-wrap">
            <strong className="title">교환완료 6/9(금)</strong>
            <span className="caption">접수번호 6675543</span> 
          </div>
          <div className="item-info-wrap">
            <div className="img-wrap">
              <img src={SampleImage} alt=""></img>
            </div>
            <div className="text-wrap">
              <p className="date">2023-12-28</p>
              <p className="brand">베리클로젯</p>
              <p className="name">스타일 레더 패딩 (겨울)</p>
              <p className="price">72,900 / 1개</p>
            </div>
          </div>
          <div className="btn-wrap mt16">
            <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate('/pay/shopping')}
            >
              장바구니 담기
            </button>
          </div>
        </div>
        <div className="white-wrap">
          <h4 className="title">
            교환 사유
          </h4>
          <div className="text-wrap">
            <span>상품이 파손되어 배송됨</span>
          </div>
        </div>
        <div className="white-wrap exchange-wrap">
          <h4 className="title">
            교환정보
          </h4>
          <div className="text-wrap">
            <b>김현주</b>
            <span>(022223) 서울특별시 종로구 줄로 1가 1동 111-1111</span>
            <span>종로빌딩 101호</span>
            <span className="f-black60">010-1111-1111</span>
          </div>
        </div>
        <div className="white-wrap delivery-wrap">
          <h4 className="title">
            배송요청사항
          </h4>
          <div className="text-wrap">
            <span>문앞에 놓아주세요.</span>
          </div>
          <span className="border-black40"></span>
          <ul className="order-infos">
            <li className="info"><strong>환불수단</strong><span>롯데카드 / 일시불 88,600원</span></li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default MyMenuExchangeDetailSection;

