import React from 'react';
import { useNavigate } from 'react-router-dom';

const SearchBar = () => {

  const navigate = useNavigate();

  // 뒤로 가기 함수
  const goBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/'); // 홈페이지로 리다이렉트
    }
  };

  return (
    <div className="top-bar border pt8 pb0">
      <div className="top-bar-inner gap10">
        <span
          onClick={goBack} 
          title="클릭시 이전 페이지로" 
          className="title" 
          style={{cursor: 'pointer'}}
        >
        </span>
        <div className="input-box w100p">
          <div className="input">
            <input 
              type="search"
              placeholder='검색어를 입력하세요'
            >
            </input>
            <button 
              type="button" 
              className="icon-search"
              onClick={()=>navigate('/search/result')}
            >
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default SearchBar;
