import { gql } from '@apollo/client';
import { client, nonTokenClient } from '../apolloClient';

export function fetchOrders(filtering) {
  const GET_ORDERS = gql`
    query GetOrders($first: Int!, $channel: String, $filter: OrderFilterInput) {
      orders(first: $first, channel: $channel, filter: $filter) {
        edges {
          node {
            id
            created
            status
            lines {
              productSku
              quantity
              productName
              thumbnail {
                alt
                url
              }
              totalPrice {
                gross {
                  amount
                }
              }
              variant {
                preorder {
                  endDate
                }
                product {
                  id
                  attributes {
                    values {
                      name
                    }
                    attribute {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
                
  return client.query({
    query: GET_ORDERS,
    variables: {
      first: 10,
      channel: 'ko',
      filter: filtering
    },
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}

// 메타데이터 업데이트 뮤테이션
export function updateMetadata(id, input) {
  const UPDATE_METADATA = gql`
  mutation UpdateMetadata($id: ID!, $input: [MetadataInput!]!) {
    updateMetadata(id: $id, input: $input) {
      errors {
        code
        field
      }
    }
  }
  `;

  return client.mutate({
    mutation: UPDATE_METADATA,
    variables: {
      id,       
      input: input  
    },
    fetchPolicy: 'network-only'
  });
}

export function fetchOrderById(orderId) {
  const GET_ORDER_BY_ID = gql`
    query GetOrderById($id: ID!) {
      order(id: $id) {
        id
        created
        status
        lines {
          productSku
          quantity
          productName
          thumbnail {
            alt
            url
          }
          totalPrice {
            gross {
              amount
            }
          }
          variant {
            preorder {
              endDate
            }
            product {
              id
              vendor {
                id
              }
              attributes {
                values {
                  name
                }
                attribute {
                  name
                }
              }
              pricing {
                discount {
                  gross {
                    amount
                  }
                }
                priceRange {
                  start {
                    gross {
                      amount
                    }
                  }
                }
              }
            }
          }
        }
        isPaid
        shippingMethod {
          name
          maximumDeliveryDays
        }
        shippingAddress {
          firstName
          lastName
          streetAddress1
          streetAddress2
          city
          postalCode
          phone
        }
      }
    }
  `;

  return client.query({
    query: GET_ORDER_BY_ID,
    variables: {
      id: orderId
    },
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}

export function markOrderAsPaid(orderId) {
  const MARK_ORDER_AS_PAID = gql`
    mutation OrderMarkAsPaid($id: ID!) {
      orderMarkAsPaid(id: $id) {
        order {
          id
          isPaid
        }
        errors {
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: MARK_ORDER_AS_PAID,
    variables: {
      id: orderId
    }
  });
}

export function orderRequestCancel(orderId) {
  const ORDER_REQUEST_CANCEL = gql`
    mutation orderRequestCancel($id: ID!) {
      orderRequestCancel(orderId: $id) {
        message
        success
      }
    }
  `;

  return client.mutate({
    mutation: ORDER_REQUEST_CANCEL,
    variables: {
      id: orderId
    }
  });
}

export function orderRequestExchange(orderId, reason) {
  const ORDER_REQUEST_EXCHANGE = gql`
    mutation orderRequestExchange($id: ID!, $reason: String!) {
      orderRequestExchange(orderId: $id, reason: $reason) {
        message
        success
      }
    }
  `;

  return client.mutate({
    mutation: ORDER_REQUEST_EXCHANGE,
    variables: {
      id: orderId,
      reason: reason
    }
  });
}
