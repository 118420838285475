import React, { useState, useEffect } from "react";
import usePopupStore from '../../store/common/usePopupStore';
import useProductStore from '../../store/common/useProductStore';
import useAuthStore from '../../store/common/useAuthStore';
import { useNavigate, useParams, useLocation  } from 'react-router-dom'; 

import TopBar from '../../layouts/top_bar/TitleActionBar';
import Product01Section from "../../sections/product/Product01Section";
import Product02Section from "../../sections/product/Product02Section";
import Product03Section from "../../sections/product/Product03Section";
import Product04Section from "../../sections/product/Product04Section";

import { getProductDetail, addRecentlyViewedProduct } from '../../services/GQ_apis/product'; 
import { createProductLog } from '../../services/REST_apis/product'; 

function ProductPage() {
  const navigate = useNavigate();  // useNavigate 훅 사용
  const location = useLocation();  // useLocation 훅 추가
  const { Page2depth } = useParams();
  const [activeTab, setActiveTab] = useState('상품설명');
  const { user } = useAuthStore();
  const userUUID = user?.id || null;

  const tabs = [
    { name: '상품설명', class: 'explain', route: 'explain' },
    { name: '상세정보', class: 'detail', route: 'detail' },
    { name: '리뷰', class: 'review', route: 'review' },
    { name: '상품문의', class: 'inquiry', route: 'inquiry' },
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab.name);
    navigate(`/product/${Page2depth}/${tab.route}`);  // 라우팅 처리
  };

  const { openToastPopup } = usePopupStore((state) => ({
    openToastPopup: state.openToastPopup,
  }));

  const { nowProductData, setNowProductData } = useProductStore((state) => ({
    nowProductData: state.nowProductData,
    setNowProductData: state.setNowProductData,
  }));

  const [isLoading, setIsLoading] = useState(true);
  const [reviewList, setReviewList] = useState([]);

  useEffect(() => {
    getProductDetail(Page2depth)
      .then((res) => {
        setIsLoading(false);
        setNowProductData(res.data.product);
        const product = res.data.product
        const productId = product.id;
        const vendorId = product.vendor.id;
        console.log('location:: ', location)
        const referrer = location.state?.from || document.referrer;
        // createProductLog(productId, vendorId, 'view', userUUID, referrer); 
      });

    addRecentlyViewedProduct(Page2depth);

    // URL 경로에 따른 activeTab 설정
    const currentTab = tabs.find(tab => `/product/${Page2depth}/${tab.route}` === location.pathname);
    if (currentTab) {
      setActiveTab(currentTab.name);
    }
  }, [Page2depth, location.pathname]);  // location.pathname 추가

  if (isLoading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <div className="product-wrap">
      <TopBar title="상품 상세"/>
      <div className="slide-tabs purple center">
        <div className="tabs">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => handleTabClick(tab)}
              className={`tab ${tab.class} ${activeTab === tab.name ? 'active' : ''}`}
            >
              {tab.name}
            </button>
          ))}
        </div>
      </div>

      <article id="productPage">
        {activeTab === '상품설명' && <Product01Section reviewList={reviewList} setActiveTab={setActiveTab} />}
        {activeTab === '상세정보' && <Product02Section />}
        {activeTab === '리뷰' && <Product03Section reviewList={reviewList} />}
        {activeTab === '상품문의' && <Product04Section />}
      </article>

      <div className="bottom-bar border">
        <div className="inner">
          <div className="btn-wrap">
            <button type="button" className="heart-btn">
              찜하기
              <span className="icon-heart"></span>
            </button>
            <button 
              type="button" 
              className="btn purple big round"
              onClick={() => openToastPopup('구매하기')}
            >
              구매하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductPage;
