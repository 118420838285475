// eslint-disable-next-line
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getProductList, toggleProductWishList, getWishlistItems } from '../../services/GQ_apis/product';
import { useLocation } from 'react-router-dom'; // URL을 확인하기 위해 React Router의 useLocation 훅 사용

const WishListGridSection = ({
  type='middle',
  option=false, 
  category='',
  collection='',
  meta=''
}) => {

  // 카드 타입 변경 매소드 [START]
  const [gridType, setGridType] = useState(type);

  const toggleGridType = () => {
    setGridType(prevType => (prevType === 'middle' ? 'big' : 'middle'));
  };
  // 카드 타입 변경 매소드 [END]

  // SORT 변경 매소드 [START]
  const [sortDirection, setSortDirection] = useState('오름차순');
  const toggleSortDirection = () => {
    setGridLi(prevGrids => [...prevGrids].reverse()); // 항목 배열을 뒤집음
    setSortDirection(prevDirection => (prevDirection === '오름차순' ? '내림차순' : '오름차순')); // 정렬 방향 토글
  };
  // SORT 변경 매소드 [END]

  // 상품 데이터 호출 메소드 [START]
  const [gridLi, setGridLi] = useState([]);
  const [loading, setLoading] = useState(false); // 페이지 변수 삭제
  const [hasMore, setHasMore] = useState(true); // 'hasMore' 상태 관리를 변경 가능하도록 수정
  const [afterCursor, setAfterCursor] = useState(null); // 커서 기반 페이지네이션을 위한 상태 추가
  const observer = useRef();  // Intersection Observer를 위한 ref 생성
  const lastGridElementRef = useRef(null); // 마지막 그리드 요소를 참조하기 위한 ref
  const [wishList, setWishList] = useState([]);

  const fetchProducts = useCallback(() => {

    if ((loading || !hasMore)) return; // 로딩 중이거나 더 불러올 데이터가 없다면 early return 처리
    
    getWishlistItems().then((res)=>{
      console.log('----위시리스트----')
      console.log(res.data.userWishlistItems)
      setWishList(res.data.userWishlistItems);
      console.log('----위시리스트----')
    });

    setLoading(true);
    getProductList(
      afterCursor, // Next 페이지 ID
      category, // 카테고리
      collection, // 콜렉션
      meta,
    ).then((res) => { // getProductList 호출 시 현재 커서 전달
      const data = res.data.products.edges;
      if (gridLi.length === 0) setGridLi(data);
      else setGridLi(prev => [...prev, ...data]); // 기존 목록에 새로운 데이터 추가
      if(data[data.length-1]) setAfterCursor(data[data.length - 1].cursor); // 다음 페이지 로드를 위한 커서 업데이트
      setHasMore(res.data.products.pageInfo.hasNextPage); // 더 불러올 데이터가 있는지 업데이트
      setLoading(false);
      // console.log('data GET :', res);
    });
  }, [afterCursor, hasMore, loading]);
  
  useEffect(() => {
    const currentObserver = observer.current;
    if (currentObserver) currentObserver.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && !loading) {
        fetchProducts();
      }
    });

    if (lastGridElementRef.current) {
      observer.current.observe(lastGridElementRef.current);
    }

    return () => {
      if (currentObserver) currentObserver.disconnect();
    };
  }, [fetchProducts, loading, hasMore]);
  // 상품 데이터 호출 메소드 [END]

  const updateWishList = (productId) => {
    toggleProductWishList(productId).then((res)=>{
      console.log('----업데이트----')
      console.log(res)

      getWishlistItems().then((res2)=>{
        console.log('----위시리스트----')
        console.log(res2.data.userWishlistItems)
        setWishList(res2.data.userWishlistItems);
        console.log('----위시리스트----')
      });

      console.log('----업데이트----')
    })
  }

  const isWishList = (productId) => {
    if (!wishList || wishList.length === 0) return false;
  
    // wishList 내의 각 아이템의 product.id와 주어진 productId를 비교
    return wishList.some(item => item.product.id === productId);
  }

  const location = useLocation(); // 현재 위치 정보를 가져옴
  const isInWishListPage = location.pathname.includes('wish-list'); // URL 검사

  useEffect(()=>{
    setGridLi([]);
    setHasMore(true);
    setAfterCursor(null);
    fetchProducts();
  },[category, meta])

  if (gridLi.length === 0 && loading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }
  
  return (
    <>
      {/* 옵션 [START] */}
      {option && (
        <div className="grid-option-wrap">
          <span>상품 <b className="f-purple">{wishList.length}개</b></span>
          <div className="options">
            <div className="sort-options">
              <button className="option" onClick={toggleSortDirection}>
                {sortDirection}
              </button>
            </div>
            <div className="view-options">
              <button className={`option ${gridType}`} onClick={toggleGridType}>
              </button>
            </div>
          </div>
        </div>
      )}
      {/* 옵션 [END] */}

      {/* 그리드(리스트) [START] */}
      <section className="section-grid">
        <div className="grid-wrap">
          <ul className={`grid gap16 ${gridType === 'big' ? 'grid2' : gridType === 'middle' ? 'grid1' : 'grid3'}`}>
            {gridLi.map((item, index) => {

              if (isInWishListPage && isWishList(item.node.id)) {
                return(
                  <li 
                    className={`card ${gridType}`} 
                    key={index}
                  >
                    <div className="card-inner">
                      <div className="img-wrap">
                        <Link 
                          title="클릭시 해당 상품 페이지로 이동"
                          to={"/product/"+item.node.id}
                        > 
                          {
                            item.node.thumbnail && 
                            <img src={item.node.thumbnail.url} alt={item.node.thumbnail.alt} />
                          }
                        </Link>
                        <button 
                          type="button" 
                          className={`icon-heart white ${isWishList(item.node.id) ? 'active' : ''}`}
                          onClick={()=>{updateWishList(item.node.id)}}
                        >
                        </button>
                      </div>
                      <div className="info-wrap">
                        <Link 
                          title="클릭시 해당 상품 페이지로 이동"
                          to="/brand/1"
                          className="brand"
                        >
                          {
                            item.node.attributes.find(attr => attr.attribute.name === '판매자') && 
                            item.node.attributes.find(attr => attr.attribute.name === '판매자')?.values[0]?.name
                          }
                        </Link>
                        <Link 
                          title="클릭시 해당 상품 페이지로 이동"
                          to={"/product/"+item.node.id}
                        >
                          <div className="title">
                            {item.node.name}
                          </div>
                          <div className="price-wrap">
                            {
                              item.node.pricing.priceRange.start.gross.amount !== item.node.pricing.priceRange.stop.gross.amount &&
                              (
                                <div className="before-price">
                                  <span className="f-purple">쿠폰할인가</span>
                                  <span className="price">
                                    {item.node.pricing.priceRange.stop.gross.currency} 
                                    {item.node.pricing.priceRange.stop.gross.amount}
                                  </span>
                                </div>
                              )
                            }
                            <div className="now-price">
                              {/* <span className="f-purple">{item.discount.discounted.raw}%</span> */}
                              <span className="price">
                                {window.WSformatPrice(item.node.pricing.priceRange.start.gross.amount)}
                              </span>
                            </div>
                          </div>
                          {/* <div className="badge-wrap">
                            <span className="badge red">
                              한정판매
                            </span>
                          </div> */}
                        </Link>
                      </div>
                    </div>
                  </li>
                )
              }

            })}
          </ul>
        </div>
      </section>
      {/* 그리드(리스트) [END] */}
      
      {/* 스크롤 타켓 [START] */}
      <div className="scroll-target" ref={lastGridElementRef}>
        {
          hasMore && loading &&
          <div className="loading-wrap">
            <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
          </div>
        }
      </div>
      {/* 스크롤 타켓 [END] */}
    </>
  );
};

export default WishListGridSection;
