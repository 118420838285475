import { useState, useEffect } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import TopBar from '../../layouts/top_bar/TitleBar';
import { completeCheckout } from '../../services/GQ_apis/checkout';
import { markOrderAsPaid, updateMetadata } from '../../services/GQ_apis/order';

export function EasyPaySuccessPage() {
  const navigate = useNavigate();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [searchParams] = useSearchParams();

  // URL에서 파라미터 추출
  const authorizationId = searchParams.get("authorizationId");
  const mallId = searchParams.get("mallId");
  const shopTransactionId = searchParams.get("shopTransactionId");
  const shopOrderNo = searchParams.get("shopOrderNo");
  const approvalReqDate = searchParams.get("approvalReqDate");

  const [payData, setPayData] = useState([]);
  
  async function confirmPayment() {
    try {
      const response = await fetch("https://testpgapi.easypay.co.kr/api/ep9/trades/approval", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          authorizationId,
          mallId,
          shopTransactionId,
          shopOrderNo,
          approvalReqDate,
        })
      });

      const responseData = await response.json();
  
      if (response.ok) {
        console.log("Payment Confirmation Successful:", responseData);
        await saleorCompleteCheckout();
      } else {
        console.error("Payment Confirmation Failed:", responseData);
      }
    } catch (error) {
      console.error("Payment confirmation error:", error);
    }
  }

  const saleorCompleteCheckout = async () => {
    // 모든 paymentToken_ 키를 가져옵니다.
    const vendorKeys = Object.keys(localStorage).filter(key => key.startsWith('paymentToken_'));
    const completedOrders = [];

    for (const vendorKey of vendorKeys) {
      const checkoutToken = localStorage.getItem(vendorKey);

      if (checkoutToken) {
        try {
          const res = await completeCheckout(checkoutToken);

          // completeCheckout 호출 결과가 null인지 확인
          if (res && res.data && res.data.checkoutComplete && res.data.checkoutComplete.order) {
            const order = res.data.checkoutComplete.order;

            // 주문이 성공적으로 완료되면 해당 체크아웃을 마크하고, localStorage에서 제거합니다.
            await markOrderAsPaid(order.id);
            completedOrders.push(res.data.checkoutComplete);
            
            const vendorKeyWithoutPaymentToken = vendorKey.replace(/^paymentToken_/, '');
            
            console.log('Original vendorKey:', vendorKey);
            console.log('Processed vendorKey:', vendorKeyWithoutPaymentToken);
            
            const vendorData = [{ key: "ZZ_VID", value: vendorKeyWithoutPaymentToken }];
            
            console.log('vendorData:', vendorData);
            
            await updateMetadata(order.id, vendorData).then((res) => {
              console.log('updateMetadata order:', res);
            });


            // 완료된 checkout을 localStorage에서 제거합니다.
            localStorage.removeItem(vendorKey);
          } else {
            console.error(`Checkout completion failed for ${vendorKey}:`, res);
          }
        } catch (error) {
          console.error(`Error completing checkout for ${vendorKey}:`, error);
        }
      }
    }
    console.log('completedOrders result',completedOrders);
    setPayData(completedOrders);
    setIsConfirmed(true);
  };

  useEffect(() => {
    confirmPayment();
  }, []); // 빈 배열은 컴포넌트가 처음 렌더링될 때만 이 함수가 실행되도록 합니다.

  useEffect(() => {
    console.log('payData result',payData);
  },[payData])

  if (!isConfirmed) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <article id="payPage">
      <TopBar title={'결제 성공'} url='/' />
        
      <section className="section-pay-result pb80">
        <>
          <div className="order-list-wrap">
            <ul className="order-list">
              {
                payData.map((order,index)=>(
                  <li key={index} className="card order2">
                    <div className="order-number-wrap">
                      <span className="order-number">
                        주문번호 {order.order.id}                      
                      </span>
                      <Link to={`/order/order-detail/${order.order.id}`} className="more-btn" title="">
                        주문 상세보기
                      </Link>
                    </div>
                    <div className="status-wrap">
                      <strong className="status">{window.WSgetStatus(order.order.status)}</strong>
                      <span className="caption">{window.WSdate(order.order.created)}</span>
                    </div>
                    {
                      (order.order.lines && order.order.lines.length > 0) &&
                      order.order.lines.map((line,line_index)=>(
                        <div key={line_index} className="item-info-wrap">
                          <div className="img-wrap">
                            <img src={line.thumbnail.url} alt=""></img>
                          </div>
                          <div className="text-wrap">
                            <p className="date">05/23(목)까지 배송 예정</p>
                            <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                            <p className="name">{line.productName} / {line.productSku}</p>
                            <p className="price">{line.totalPrice.gross.amount}원 / {line.quantity}개</p>
                          </div>
                        </div>
                      ))
                    }
                    {/* <div className="btn-wrap flex mt16">
                      <button 
                        type="button" 
                        className="btn black20"
                        onClick={()=>navigate('/mymenu/request-cancel1')}
                      >
                        취소요청
                      </button>
                    </div> */}
                  </li>
                ))
              }
            </ul>
          </div>
        </>
      </section>

      <div className="bottom-bar">
        <div className="inner">
          <div className="btn-wrap">
            <button type="button" className="btn big gray" onClick={() => navigate('/')}>계속 쇼핑하기</button>
            <button type="button" className="btn big purple" onClick={() => navigate(`/order/order-list`)}>구매내역 확인</button>
            {/* <button type="button" className="btn big purple" onClick={() => navigate(`/order/order-detail/${payData[0]?.id}`)}>구매내역 확인</button> */}
          </div>
        </div>
      </div>
    </article>
  );
}

export default EasyPaySuccessPage;
