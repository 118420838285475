import React from "react";
import SampleImage from "../../assets/images/sample/sample_profile.png";

const MyMenuRewardSection = () => {
  return (
    <>
      <section className="section-mymenu-reward">
        <div className="white-wrap my-kids-wrap">
          <ul className="my-kids-list type-star">
            <li className="kid">
              <div className="img-wrap">
                <img src={SampleImage} alt="하늘이 이미지" />
                <span className="badge purple">Kids</span>
              </div>
              <div className="text-wrap">
                <div className="title-wrap">
                  <strong className="title">하늘이<span className="info">정보입력 23.07.06</span></strong>
                  <div className="actions-wrap">
                    <button type="button" className="icon-star-empty gray active">
                    </button>
                    <div className="more-dots-wrap">
                      <button type="button" className="icon-more-dots">
                      </button>
                    </div>
                  </div>
                  <div className="span-wrap">
                    <span className="age">첫째 (만) 9세</span>
                    <span className="date">2014.06.04</span>
                  </div>
                </div>
                <ul className="body-list">
                  <li><span>키</span><b>129cm</b></li>
                  <li><span>몸무게</span><b>25kg</b></li>
                  <li><span>발길이</span><b>19.9cm</b></li>
                  <li><span>머리 둘레</span><b>46cm</b></li>
                </ul>
            </div>
            </li>
            <li className="kid">
              <div className="img-wrap">
                <img src={SampleImage} alt="하늘이 이미지" />
                <span className="badge purple gradient">Kids</span>
              </div>
              <div className="text-wrap">
                <div className="title-wrap">
                    <strong className="title">하늘이<span className="">정보입력 23.07.06</span></strong>
                    <div className="actions-wrap">
                      <button type="button" className="icon-star-empty gray">
                      </button>
                      <div className="more-dots-wrap">
                        <button type="button" className="icon-more-dots">
                        </button>
                        <ul className="more-dots-list">
                          <li> <a title="" href="#none">편집하기</a> </li>
                          <li> <a title="" href="#none">삭제하기</a> </li>
                        </ul>
                      </div>
                    </div>
                    <div className="span-wrap">
                      <span className="age">첫째 (만) 9세</span>
                      <span className="date">2014.06.04</span>
                    </div>
                  </div>
                <ul className="body-list">
                  <li><span>키</span><b>129cm</b></li>
                  <li><span>몸무게</span><b>25kg</b></li>
                  <li><span>발길이</span><b>19.9cm</b></li>
                  <li><span>머리 둘레</span><b>46cm</b></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default MyMenuRewardSection;

