import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const OrderTab = ({ orderList = [] }) => {
  const navigate = useNavigate();
  const { Page2depth } = useParams();

  const [orderStatusCounts, setOrderStatusCounts] = useState({
    UNCONFIRMED: 0, // 배송중
    UNFULFILLED:0, // 배송중
    FULFILLED: 0, // 배송중
    DELIVERED: 0, // 배송완료
  });

  useEffect(() => {
    const statusCounts = orderList.reduce((acc, order) => {
      const status = order.node.status;
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    setOrderStatusCounts({
      DRAFT: statusCounts.DRAFT || 0,
      UNFULFILLED: (statusCounts.UNFULFILLED || 0) + (statusCounts.UNCONFIRMED || 0), // 각 값이 없을 때 0으로 설정
      FULFILLED: statusCounts.FULFILLED || 0,
      DELIVERED : statusCounts.DELIVERED || 0
    });
  }, [orderList]);

  const tabs = [
    { name: '주문', path: '/order/order-list', active: 'order-list' },
    { name: '교환', path: '/order/exchange-list', active: 'exchange-list' },
    { name: '반품', path: '/order/return-list', active: 'return-list' },
    { name: '취소', path: '/order/cancel-list', active: 'cancel-list' },
  ];

  return (
    <div className="order-info-wrap">
      <ul className="order-info-list">
        {/* <li>
          <strong className="f-purple">{orderStatusCounts.DRAFT}</strong>
          <p>대기중</p>
        </li> */}
        <li>
          <strong className="f-purple">{orderStatusCounts.UNFULFILLED}</strong>
          <p>상품 준비중</p>
        </li>
        <li>
          <strong className="f-purple">{orderStatusCounts.FULFILLED}</strong>
          <p>배송중</p>
        </li>
        <li>
          <strong className="f-purple">{orderStatusCounts.DELIVERED}</strong>
          <p>배송완료</p>
        </li>
      </ul>
      <div className="order-tabs">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            type="button"
            className={`tab ${Page2depth === tab.active ? 'active' : ''}`}
            onClick={() => navigate(tab.path)}
          >
            {tab.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default OrderTab;
