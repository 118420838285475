import React from "react";
import { useParams } from 'react-router-dom';

import TopBar from '../../layouts/top_bar/TitleBar';

import DeliveryListSection from '../../sections/delivery/DeliveryListSection'; 
import DeliveryDomesticSection from '../../sections/delivery/DeliveryDomesticSection'; 
import DeliveryOverseasSection from '../../sections/delivery/DeliveryOverseasSection'; 

function DeliveryPage() {

  const { Page2depth } = useParams();

  const getTitle = (Page2depth) => {
    switch (Page2depth) {
      default:
        return '배송지 관리'
    }
  };

  return (
    <>
      {
        <TopBar  
          title={getTitle(Page2depth)}
        />
      }

      <article 
        id="deliveryPage" 
      >
        {Page2depth === 'list' && <DeliveryListSection/>}
        {Page2depth === 'domestic' && <DeliveryDomesticSection/>}
        {Page2depth === 'overseas' && <DeliveryOverseasSection/>}
      </article>
    </>
  )
}

export default DeliveryPage;
