import { gql } from '@apollo/client';
import { client, nonTokenClient } from '../apolloClient';

export function fetchPages(filtering) {

  const GET_PAGES = gql`
    query GetPages(
      $first: Int!, 
      $after: String, 
      $filter: PageFilterInput
    ) {
      pages(
        first: $first, 
        after: $after, 
        filter: $filter
      ) {
        edges {
          node {
            id
            title
            content
            contentJson
            isPublished
            publicationDate
            metafields
            pageType {
              name
              id
            }
            attributes {
              attribute {
                name
                id
              }
              values {
                name
                plainText
                reference
                externalReference
                value
                file {
                  url
                  contentType
                }
              }
            }

          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  `;

  return client.query({
    query: GET_PAGES,
    variables: {
      first: 10,
      filter: filtering
    },
    fetchPolicy: 'network-only'
  });
}

// 리뷰
export function createReview(productId, text, rating) {
  const CREATE_REVIEW = gql`
    mutation CreateReview($productId: ID!, $text: String!, $rating: Int!) {
      reviewCreate(input: {productId: $productId, text: $text, rating: $rating}) {
        errors
        review {
          id
        }
      }
    }
  `;

  return client.mutate({
    mutation: CREATE_REVIEW,
    variables: {
      productId,
      text,
      rating
    },
    fetchPolicy: 'network-only'
  });
}

export function getReviews(filtering) {
  const GET_REVIEWS = gql`
    query GetReviews(
      $first: Int!, 
      $after: String, 
      $filter: ReviewFilterInput
    ) {
      reviews(
        first: $first, 
        after: $after, 
        filter: $filter
      ) {
        edges {
          node {
            id
            text
            rating
            createdAt
            user {
              id
              email
              metafields
            }
            product {
              id
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  `;

  return client.query({
    query: GET_REVIEWS,
    variables: {
      first: 10,
      after: null,  // 이 값은 페이징을 위한 커서 값으로, 필요에 따라 업데이트할 수 있습니다.
      filter: filtering
    },
    fetchPolicy: 'network-only'  // 매번 서버로부터 데이터를 새로 가져오도록 설정
  });
}

// 문의
// 페이지 생성 뮤테이션
export function createPage(title, pageTypeId, publicationDate) {
  const CREATE_PAGE = gql`
  mutation CreatePage($title: String!, $pageType: ID!, $publicationDate: String!) {
    pageCreate(
      input: {title: $title, pageType: $pageType, publicationDate: $publicationDate}
    ) {
      page {
        id
        title
        pageType {
          name
          id
        }
      }
        
    }
  }
  `;

  return client.mutate({
    mutation: CREATE_PAGE,
    variables: {
      title,
      pageType: pageTypeId,
      publicationDate,
    },
    fetchPolicy: 'network-only'
  });
}

// 메타데이터 업데이트 뮤테이션
export function updateMetadata(id, input) {
  const UPDATE_METADATA = gql`
  mutation UpdateMetadata($id: ID!, $input: [MetadataInput!]!) {
    updateMetadata(id: $id, input: $input) {
      errors {
        code
        field
      }
    }
  }
  `;

  return client.mutate({
    mutation: UPDATE_METADATA,
    variables: {
      id,       
      input: input  
    },
    fetchPolicy: 'network-only'
  });
}

// 페이지 이미지 등록 뮤테이션
export function createPageMedia(productId, pageId, image) {
  const CREATE_PAGE_MEDIA = gql`
  mutation CreatePageMedia($productId: ID!, $pageId: ID!, $image: Upload!) {
    pageMediaCreate(
      input: {productId: $productId, pageId: $pageId, image: $image}
    ) {
      pageMedia {
        createdAt
        id
        pageImgUrl
        updatedAt
        productDescription
        etc
      }
      pageErrors {
        attributes
        code
        message
        field
        values
      }
      errors {
        attributes
        code
        field
        message
        values
      }
    }
  }
  `;
  return client.mutate({
    mutation: CREATE_PAGE_MEDIA,
    variables: {
      productId,
      pageId,
      image
    },
    context: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    fetchPolicy: 'network-only'
  });
}

// 상품 문의 가져오기
export function getQuestion(filtering) {
  const GET_QUESTION = gql`
    query GetQuestion(
      $first: Int!, 
      $after: String, 
      $filter: ReviewFilterInput
    ) {
      reviews(
        first: $first, 
        after: $after, 
        filter: $filter
      ) {
        edges {
          node {
            id
            text
            rating
            createdAt
            user {
              id
              email
              metafields
            }
            product {
              id
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  `;

  return client.query({
    query: GET_QUESTION,
    variables: {
      first: 10,
      after: null,  // 이 값은 페이징을 위한 커서 값으로, 필요에 따라 업데이트할 수 있습니다.
      filter: filtering
    },
    fetchPolicy: 'network-only'  // 매번 서버로부터 데이터를 새로 가져오도록 설정
  });
}