import React, { useState } from "react";
import useInputStore from '../../store/common/useInputStore'
import { useNavigate } from "react-router-dom";

const colorList = [
  { name: '블랙', class: 'black' },
  { name: '그레이', class: 'grey' },
  { name: '화이트', class: 'white' },
  { name: '네이비', class: 'navy' },
  { name: '블루', class: 'blue' },
  { name: '스카이', class: 'sky' },
  { name: '핑크', class: 'pink' },
  { name: '노랑', class: 'yellow' },
  { name: '연두', class: 'lime' },
  { name: '그린', class: 'green' },
  { name: '브라운', class: 'brown' },
  { name: '베이지', class: 'beige' },
  { name: '퍼플', class: 'purple' },
  { name: '오렌지', class: 'orange' },
  { name: '레드', class: 'red' }
];

const KidRegister03Section = () => {

  const navigate = useNavigate();
  const {
    input,
    actions,
  } = useInputStore(state => ({
    input : state.input,
    actions: state.actions,
  }));

  const [checkedStates, setCheckedStates] = useState(new Array(colorList.length).fill(false));
  const [selectedColors, setSelectedColors] = useState([]);

  const handleCheckboxChange = (position) => {
    if (selectedColors.length >= 3 && !checkedStates[position]) {
      return;
    }

    const updatedCheckedStates = checkedStates.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedStates(updatedCheckedStates);

    if (!checkedStates[position]) {
      setSelectedColors([...selectedColors, colorList[position]]);
    } else {
      setSelectedColors(selectedColors.filter((brand) => brand !== colorList[position]));
    }
  };

  const nextPage = () => {
    actions.setKidColorList(JSON.stringify(selectedColors))
    navigate('/register/kid-4')
  }

  return (
    <>
      <section className="section-register">
        <div className="inner">

          {/* 타이틀 영역 [START] */}
          <div className="h3-title-wrap mt0">
            <h3 className="title">선호 색상</h3>
            <p className="text">
              평소 선호하는 색상을<br/>
              1순위부터 3순위까지 선택해주세요.
            </p>
            <div className="pagination">
              <span className="item">
                1
              </span>
              <span className="item">
                2
              </span>
              <span className="item active">
                3
              </span>
              <span className="item">
                4
              </span>
            </div>
          </div>
          {/* 타이틀 영역 [END] */}

          {/* 아이템 체크 영역[START]*/}
          <div className="check-wrap mt30">
            <ul className="check-list color">
              {colorList.map((item, index) => (
                <li key={index} className={item.class}>
                  {/* selectedColors의 길이가 3이상이고, 
                      현재 항목이 선택되지 않았다면 disabled 클래스를 추가 */}
                  <label 
                    htmlFor={`check${index}`} 
                    className={`${checkedStates[index] ? 'active' : ''} 
                    ${selectedColors.length >= 3 && !checkedStates[index] ? 'disabled' : ''}`}
                  >
                    {item.name}
                    <input 
                      id={`check${index}`} 
                      type="checkbox"
                      checked={checkedStates[index]}
                      onChange={() => handleCheckboxChange(index)}
                      disabled={selectedColors.length >= 3 && !checkedStates[index]}
                    />
                  </label>
                </li>
              ))}
            </ul>
          </div>
          {/* 아이템 체크 영역[END]*/}
          
          {/* 디버그 박스 [START] */}
          {/* <div className="debug-box mt20">
            <p><b>선호 색상 순위:</b> {selectedColors.map(brand => brand.name).join(', ')}</p>
          </div> */}
          {/* 디버그 박스 [END] */}

        </div>
      </section>
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              disabled={!(selectedColors.length >= 3)}
              className="btn purple big"
              onClick={()=>nextPage()}
            >
              다음
            </button>
            <a 
              href="#"
              className="skip-link"
              onClick={() => navigate('/')}
            >
              아이 등록 다음에 하기
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default KidRegister03Section;

