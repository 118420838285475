import React, { useState } from "react";
import RankingFashionSection from '../../../sections/ranking/RankingFashionSection';
import RankingLifeSection from '../../../sections/ranking/RankingLifeSection';
import RankingBrandSection from '../../../sections/ranking/RankingBrandSection';

function RankingPage() {

  const [active2depthTab, setActive2depthTab] = useState("fashion");

  const handleSmallTabClick = (key) => {
    setActive2depthTab(key);
  };

  return (
    <article id="RankingPage" className="pt94">
      <div className="slide-tabs no-fixed pl16 pr16">
        <div className="tabs">
          <button
            className={active2depthTab === "fashion" ? "tab active" : "tab"}
            onClick={() => handleSmallTabClick("fashion")}
          >
            패션
          </button>
          <button
            className={active2depthTab === "life" ? "tab active" : "tab"}
            onClick={() => handleSmallTabClick("life")}
          >
            라이프
          </button>
          <button
            className={active2depthTab === "brand" ? "tab active" : "tab"}
            onClick={() => handleSmallTabClick("brand")}
          >
            브랜드
          </button>
        </div>
      </div>
      {active2depthTab === 'fashion' && <RankingFashionSection />}
      {active2depthTab === 'life' && <RankingLifeSection />}
      {active2depthTab === 'brand' && <RankingBrandSection />}
    </article>
  );
}

export default RankingPage;
