import React from 'react';
import { useNavigate } from 'react-router-dom';

const PopupKidNext = ({ closePopup }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="popup-body">
        <div className="h3-title-wrap">
          <h3 className="title">
            알림
          </h3>
          <p className="mt16 f16">
            내 아이 프로필을 다음에 등록할까요?
          </p>
        </div>
      </div>
      <div className="popup-footer">
        <div className="btn-wrap">
          <button 
            type="button" 
            className="btn gray w280" 
            onClick={() => {
              closePopup();
              navigate('/');
            }}
          >
            다음에 하기
          </button>
          <button 
            type="button" 
            className="btn purple" 
            onClick={closePopup}
          >
            계속하기
          </button>
        </div>
      </div>
    </>
  );
};

export default PopupKidNext;
