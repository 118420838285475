import React from 'react';
import IconSearch from '../../assets/images/common/icons/icon_search_big.svg';

const SearchEmptySection = () => {

  return (
    <>
      <section className="section-search">
        <div className="inner pt30">
          <div className="img-wrap flex center">
            <img className="w120" src={IconSearch} alt="검색결과가 없습니다."></img>
          </div>
          <strong className="f22 mt20 flex center">검색결과가 없습니다.</strong>
          <p className="f14 mt10 flex center">다른 키워드로 검색해보세요.</p>
        </div>
      </section>
    </>
  );
};

export default SearchEmptySection;

